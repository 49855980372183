import React, { useState } from "react";
import BackButton from "../../../shared/BackButton";
import FolderView from "../../../asset/images/folderview.png";
import FolderViewThree from "../../../asset/images/foldervthree.png";
import FolderDocuments from "./FolderDocuments";
import CommonModal from "../../../shared/CommonModal";
import AddFileModal from "./modal/AddFileModal";
import { GoFileDirectory } from "react-icons/go";
import { TbFileFilled } from "react-icons/tb";

const MoneyReceiptFolderView = () => {
  const [active, setIsActive] = useState(null);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const handleClick = (index) => {
    if (active === index) {
      setIsActive(null);
    } else {
      setIsActive(index);
    }
  };

  return (
    <div>
      <div className="flex  items-center justify-between">
        <BackButton title={"Money Receipt Folder"} />
        {active !== null && (
          <button
            onClick={() => setIsOpen(true)}
            className="bg-gradient-to-r from-[#0C768A] via-[#1e788a] to-[#2894a8] hover:bg-gradient-to-r hover:from-[#0b5b6b] hover:via-[#15606f] hover:to-[#257a88] text-white text-[14px] px-[18px] py-[10px] rounded-[5px]"
          >
            Add
          </button>
        )}
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5  xl:grid-cols-7  mt-10 gap-5">
        {[...Array(5).keys()].map((item, i) => (
          <div
            key={i}
            onClick={() => handleClick(i)}
            className={`w-[96px]  justify-self-center `}
          >
            {active === i ? <TbFileFilled className={`h-[87px] w-[87px] object-fill cursor-pointer`} />: <GoFileDirectory className={`h-[87px] w-[87px] object-fill cursor-pointer`} />}
            <p className="text-[12px] font-[500] text-[#333547]">
              Booking Money date (dd-mm-yy)
            </p>
          </div>
        ))}
      </div>

      {/* Folder documents component */}
      {active !== null && <FolderDocuments active={active} />}

      {/* common modal  */}
      <CommonModal
        modalIsOpen={modalIsOpen}
        closeModal={() => setIsOpen(false)}
        openModal={() => setIsOpen(true)}
      >
        <AddFileModal setIsOpen={setIsOpen} />
      </CommonModal>
    </div>
  );
};

export default MoneyReceiptFolderView;
