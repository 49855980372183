import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../../../BaseUrl";
import BackButton from "../../../../shared/BackButton";
import { errorMessage } from "../../../../asset/commoncssConstant/CommonCssConstant";

const CreateUser = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const allRole = useLocation().state.allRole;
  const projectList = useLocation().state.projectList;
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedProject, setSelectedProject] = useState([]);
  const animatedComponents = makeAnimated();

  // --------------assign project options--------------
  const projectOptions = projectList.map((project) => {
    return {
      ...project,
      label: project.project_name,
      value: project.project_name,
    };
  });
  // ----------assign role and project handler start---------------
  const handleAssignRole = (e) => {
    const roal = JSON.parse(e.target.value);
    setSelectedRole(roal);
  };
  const handleProject = (e) => {
    setSelectedProject(e);
  };

  // ----------assign role and project handler end---------------

  /* ------- create user starts --------- */
  const onHandleSubmit = (data) => {
    data.role_id = selectedRole?.id;
    for (let i = 0; i < selectedProject.length; i++) {
      data[`project_id[${i}]`] = selectedProject[i].id;
    }
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (value instanceof FileList) {
        for (let i = 0; i < value.length; i++) {
          formData.append(key, value.item([i]));
        }
      } else {
        formData.append(key, value);
      }
    });

    axios
      .post(baseUrl + `/register`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          navigate(-1);
        }
      });
  };
  /* ------- create user ends --------- */

  return (
    <div>
      <BackButton title={"Create User"} />
      <form
        onSubmit={handleSubmit(onHandleSubmit)}
        className="bg-[#FFFFFF] px-16 pt-6 pb-9 flex flex-col gap-6"
      >
        <div className="bg-[#FFF] border border-[#C7C6C6] rounded-lg">
          <h3 className="text-center bg-[#F5F6F8] px-2 py-2 rounded-t-lg border-b border-b-[#C7C6C6] text-[#87898f] text-xl font-semibold">
            User Information
          </h3>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 p-4 md:px-20 md:py-7">
            <div className="common_input_wraper">
              <label>User Name</label>
              <input
                {...register(`username`, {
                  required: "username is required",
                  pattern: {
                    value: /^[A-Za-z]+[A-Za-z0-9\-_~(\s]*[A-Za-z0-9\s)]$/,
                    message: "Insert valid user name",
                  },
                })}
                type="text"
                className="input input-sm w-full max-w-xs focus:outline-none border border-[#C7C6C6]"
                placeholder="User Name"
              />
              {errors?.username && (
                <p className={errorMessage}>{errors?.username?.message}</p>
              )}
            </div>
            <div className="common_input_wraper">
              <label>Designation</label>
              <input
                {...register(`designation`, {
                  required: "user designation is required",
                  pattern: {
                    value: /^[A-Za-z]+[A-Za-z0-9\-_~(\s]*[A-Za-z0-9\s)]$/,
                    message: "Insert valid designation",
                  },
                })}
                type="text"
                className="input input-sm w-full max-w-xs focus:outline-none border border-[#C7C6C6]"
                placeholder="User Designation"
              />
              {errors?.designation && (
                <p className={errorMessage}>{errors?.designation?.message}</p>
              )}
            </div>
            <div className="common_input_wraper">
              <label>Email Address</label>
              <input
                {...register(`email`, {
                  required: "user email is required",
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: "Insert valid email address",
                  },
                })}
                type="text"
                className="input input-sm w-full max-w-xs focus:outline-none border border-[#C7C6C6]"
                placeholder="User Email"
              />
              {errors?.email && (
                <p className={errorMessage}>{errors?.email?.message}</p>
              )}
            </div>
            <div className="common_input_wraper">
              <label>Assign Role</label>
              <select
                className="select select-sm w-full max-w-xs focus:outline-none border-[#C7C6C6] text-base font-normal"
                onChange={(e) => handleAssignRole(e)}
                required
                style={{ padding: "0px 13px" }}
              >
                <option value="" disabled selected>
                  Select Role
                </option>
                {allRole?.map((role, index) => (
                  <option key={index} value={JSON.stringify(role)}>
                    {role?.name}
                  </option>
                ))}
              </select>
            </div>
            {selectedRole?.name === "Admin" && (
              <div className="common_input_wraper">
                <label>Assign Project</label>
                <Select
                  onChange={handleProject}
                  className="w-full max-w-xs"
                  components={animatedComponents}
                  isMulti
                  options={projectOptions}
                />
              </div>
            )}
            <div className="common_input_wraper">
              <label>Password</label>
              <input
                {...register("password", {
                  required: "password is required",
                  minLength: {
                    value: 6,
                    message: "password can't les than than 6",
                  },
                  maxLength: {
                    value: 10,
                    message: "password can't greater than than 10",
                  },
                  pattern: {
                    value:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{6,10}$/,
                    message: "need uppercase, lowercase, number & symbol",
                  },
                })}
                type="text"
                className="input input-sm w-full max-w-xs focus:outline-none border border-[#C7C6C6]"
                placeholder="Create Password"
              />
              {errors?.password && (
                <p className={errorMessage}>{errors?.password?.message}</p>
              )}
            </div>
          </div>
        </div>
        <div className="bg-[#FFF] border border-[#C7C6C6] rounded-lg">
          <h3 className="text-center bg-[#F5F6F8] px-2 py-2 rounded-t-lg border-b border-b-[#C7C6C6] text-[#87898f] text-xl font-semibold">
            Personal Information
          </h3>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 p-4 md:px-20 md:py-7">
            <div className="common_input_wraper">
              <label>Full Name</label>
              <input
                {...register(`name`, {
                  required: "name is required",
                  pattern: {
                    value: /^[A-Za-z]+[A-Za-z0-9\-_~(\s]*[A-Za-z0-9\s)]$/,
                    message: "Insert valid name",
                  },
                })}
                type="text"
                className="input input-sm w-full max-w-xs focus:outline-none border border-[#C7C6C6]"
                placeholder="Full Name"
              />
              {errors?.name && (
                <p className={errorMessage}>{errors?.name?.message}</p>
              )}
            </div>
            <div className="common_input_wraper">
              <label>Phone Number</label>
              <input
                {...register(`mobile_no`, {
                  required: "Phone number is required",
                  pattern: {
                    value: /^(0|\+?[1-9][0-9]*)$/,
                    message: "Insert country code before your number",
                  },
                })}
                type="text"
                className="input input-sm w-full max-w-xs focus:outline-none border border-[#C7C6C6]"
                placeholder="Mobile Number"
              />
              {errors?.mobile_no && (
                <p className={errorMessage}>{errors?.mobile_no?.message}</p>
              )}
            </div>
            <div className="common_input_wraper">
              <label>Address</label>
              <input
                {...register(`address`, {
                  required: "address is required",
                })}
                type="text"
                className="input input-sm w-full max-w-xs focus:outline-none border border-[#C7C6C6]"
                placeholder="Address"
              />
              {errors?.address && (
                <p className={errorMessage}>{errors?.address?.message}</p>
              )}
            </div>
            <div className="common_input_wraper">
              <label>NID Number</label>
              <input
                {...register(`nid`, {
                  required: "NID number is required",
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Insert valid nid number",
                  },
                })}
                type="text"
                className="input input-sm w-full max-w-xs focus:outline-none border border-[#C7C6C6]"
                placeholder="NID Number"
              />
              {errors?.nid && (
                <p className={errorMessage}>{errors?.nid?.message}</p>
              )}
            </div>

            <div className="common_input_wraper">
              <label>Image</label>
              <input
                {...register("image")}
                type="file"
                accept="image/png, image/gif, image/jpeg"
                className="w-full max-w-xs"
                multiple
              />
            </div>

            {/* <div className="form-control w-full max-w-xs relative">
              <label className="label">
                <span className="label-text">Image</span>
              </label>
              <input
                {...register("image")}
                type="file"
                accept="image/png, image/gif, image/jpeg"
                className="hidden"
                id="imageUpload"
              />
              <input
                value={
                  watch("image") && watch("image").length !== 0
                    ? watch("image")[0].name
                    : "select a image"
                }
                type="text"
                className="input input-sm w-full max-w-xs focus:outline-none border border-[#C7C6C6]"
                readOnly
              />
              <label htmlFor="imageUpload">
                <img
                  className="h-5 w-5 absolute end-2 bottom-2 cursor-pointer"
                  src={fileUpload}
                  alt=""
                />
              </label>
            </div> */}
          </div>
        </div>
        <div className="bg-[#FFF] border border-[#C7C6C6] rounded-lg">
          <h3 className="text-center bg-[#F5F6F8] px-2 py-2 rounded-t-lg border-b border-b-[#C7C6C6] text-[#87898f] text-xl font-semibold">
            Document
          </h3>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 p-4 md:px-20 md:py-7">
            <div className="common_input_wraper">
              <label>Remarks</label>
              <input
                {...register("remarks")}
                type="text"
                className="input input-sm w-full max-w-xs focus:outline-none border border-[#C7C6C6]"
              />
            </div>

            <div className="common_input_wraper">
              <label>Upload Doc/Image</label>
              <input
                {...register("document")}
                type="file"
                accept="image/png, image/gif, image/jpeg"
                className="w-full max-w-xs"
                multiple
              />
            </div>

            {/*  <div className="form-control w-full max-w-xs relative">
              <label className="label">
                <span className="label-text">Upload Doc/Image</span>
              </label>
              <input
                {...register("document")}
                type="file"
                className="hidden"
                id="document_file"
              />
              <input
                value={
                  watch("document") && watch("document").length > 0
                    ? watch("document")[0].name
                    : "select a file"
                }
                type="text"
                className="input input-sm w-full max-w-xs focus:outline-none border border-[#C7C6C6]"
                readOnly
              />
              <label htmlFor="document_file">
                <img
                  className="h-5 w-5 absolute end-2 bottom-2 cursor-pointer"
                  src={fileUpload}
                  alt=""
                />
              </label>
            </div> */}
          </div>
        </div>
        <button
          type="submit"
          className="w-full sm:w-[50%] md:w-[30%] lg:w-[20%] xl:w-[20%] mx-auto px-6 py-2 border-none bg-gradient-to-r from-[#0C768A] via-[#1e788a] to-[#2894a8] text-[#FFF] text-center text-base font-semibold rounded-md hover:bg-gradient-to-r hover:from-[#0b5b6b] hover:via-[#15606f] hover:to-[#257a88]"
        >
          Create User
        </button>
      </form>
    </div>
  );
};

export default CreateUser;
