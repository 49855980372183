import React from "react";
import BackButton from "../../../shared/BackButton";
import FileImage from "../../../asset/images/fileImage.png";
const EditClientLandDescription = () => {
  return (
    <div>
      <BackButton title={"Edit Land Description"} />
      <div className="  mt-3 gap-5">
        <div className=" client_info_section p-[40px] shadow-lg">
          <form className="mt-10">
            <div className="xl:grid lg:grid-cols-2  gap-4">
              <div className="common_input_wraper">
                <label>File / Plot No.</label>
                <input disabled type="text" placeholder="File / Plot No." />
              </div>
              <div className="common_input_wraper">
                <label>Customer ID</label>
                <input type="text" disabled placeholder=" Customer ID" />
              </div>
              <div className="common_input_wraper">
                <label>Customer Name</label>
                <input type="text" disabled placeholder=" Customer Name" />
              </div>
              <div className="common_input_wraper">
                <label>Project Name</label>
                <input type="text" disabled placeholder="Project Name" />
              </div>
              <div className="common_input_wraper">
                <label>Land Size</label>
                <input type="text" disabled placeholder="Land Size" />
              </div>
              <div className="common_input_wraper">
                <label>Mouza Name</label>
                <input type="text" disabled placeholder="Mouza Name" />
              </div>
              <div className="lg:col-span-3 lg:grid lg:grid-cols-3 gap-4">
                <div className="common_input_wraper">
                  <label>Land Registry Complete Date</label>
                  <input type="date" />
                </div>
                <div className="common_input_wraper">
                  <label>Land Registry Sub-deed No.</label>
                  <input type="text" placeholder="Sub-deed No." />
                </div>
                <div className="common_input_wraper">
                  <label>Registry Office</label>
                  <input type="text" placeholder="Registry Office" />
                </div>
                <div className="common_input_wraper">
                  <label>Namejari No</label>
                  <input type="text" placeholder="Namejari No" />
                </div>
                <div className="common_input_wraper">
                  <label>Namejari Date</label>
                  <input type="date" />
                </div>
              </div>
              <div className="common_input_wraper row-span-4">
                <label>Supported Document (Multiple)</label>
                {/* <input type="file" /> */}
                <img src={FileImage} alt="" />
              </div>
              {/* <div className="common_input_wraper">
                <label>Supported Document (Multiple)</label>
                <input type="file" />
              
              </div> */}
              <div className="col-span-4 grid md:grid-cols-3 lg:grid-cols-5 gap-4">
                <div className="common_input_wraper">
                  <label>CS Daag No.</label>
                  <input type="text" placeholder="CS Daag No." />
                </div>
                <div className="common_input_wraper">
                  <label>SA Daag No.</label>
                  <input type="text" placeholder="SA Daag No." />
                </div>
                <div className="common_input_wraper">
                  <label>RS Daag No.</label>
                  <input type="text" placeholder="RS Daag No." />
                </div>
                <div className="common_input_wraper">
                  <label>BS Daag No.</label>
                  <input type="text" placeholder="BS Daag No." />
                </div>
                <div className="common_input_wraper">
                  <label>CS Khotian</label>
                  <input type="text" placeholder="Type" />
                </div>
                <div className="common_input_wraper">
                  <label>SA Khotian</label>
                  <input type="text" placeholder="Type" />
                </div>
                <div className="common_input_wraper">
                  <label>RS Khotian</label>
                  <input type="text" placeholder="Type" />
                </div>
                <div className="common_input_wraper">
                  <label>BS Khotian</label>
                  <input type="text" placeholder="Type" />
                </div>
              </div>
            </div>
            <div className="text-center pb-12 mt-10">
              <button type="submit" className="cancel_button mr-3">
                Cancel
              </button>
              <button type="submit" className="submit_button">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditClientLandDescription;
