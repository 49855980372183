import React, { useEffect, useState } from "react";
import Pagination from "../../../shared/pagination/Pagination";
import {
  commonAction,
  commonCaption,
  commonSearchInput,
  commonTableHeadingTwo,
  commonTableRow,
  commonTitle,
  dashboardBodyWraper,
} from "../../../asset/commoncssConstant/CommonCssConstant";
import CommonPrintPdfBtn from "../../../shared/CommonPrintPdfBtn";
import ViewImg from "../../../asset/images/clientlist/view.png";
import AddImage from "../../../asset/images/clientpaymentinformation/add.png";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../../BaseUrl";
import { LuArrowDownUp } from "react-icons/lu";
import useCommonPDF from "../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../shared/useCommonPrint/useCommonPrint";

const AgentPaymentList = () => {
  const [agentPaymentList, setAgentPaymentList] = useState([]);
  const token = localStorage.getItem("token");
  const [searchValue, setSearchValue] = useState("");

  const [paginationData, setPaginationData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const { toPDF, targetRef } = useCommonPDF({ title: "agent-payment-list" });

  const handlePrint = useCommonPrint({
    targetRef,
    title: "agent-payment-list",
    message: "thank you",
  });

  // pagination part start here
  const handleNext = () => {
    if (page === pageCount) {
      return page;
    } else {
      return setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page === 1) {
      return page;
    } else {
      return setPage(page - 1);
    }
  };

  //-----------get agent payment list start --------------------
  useEffect(() => {
    axios
      .get(baseUrl + `/agent/workList`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setAgentPaymentList(res?.data?.data);
      });
  }, [token, page]);
  //-----------get agent payment list end --------------------
  return (
    <div>
      <h1 className={`${commonTitle}`}>Agent Payment List</h1>
      <div className={dashboardBodyWraper}>
        <div className={commonCaption}>
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} />
          <input
            onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
            type="text"
            placeholder="Search"
            className={commonSearchInput}
          />
        </div>

        <div ref={targetRef} className="table_responsive mt-[40px]">
          <table>
            <thead>
              <tr className={`${commonTableRow}`}>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Booking Date</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Agent Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Customer’s Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Project Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> File / Plot No.</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Agent Money</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Agent Extra Amount</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Other Expanse</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Total Amount</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>
                      {" "}
                      Agent Money <br /> Paid Amount
                    </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>
                      {" "}
                      Agent Money <br /> Due Amount
                    </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`py-2`}>
                    <span> Create Payment</span>{" "}
                  </div>
                </th>
                <th>
                  <div className={`py-2`}>
                    <span> Add Payment</span>{" "}
                  </div>
                </th>
                <th>
                  <div className={`py-2`}>
                    <span>
                      {" "}
                      Agent Payment <br /> Statement{" "}
                    </span>{" "}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {paginationData
                ?.filter((info) => {
                  return searchValue.toLowerCase() !== ""
                    ? info?.booking_date?.toLowerCase().includes(searchValue) ||
                        info?.agent?.name
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.customer_info
                          ?.map((cus) => {
                            return cus?.name;
                          })
                          ?.join("")
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.plot_info?.project_name
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.plot_info?.file_no
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.agent_price?.agent_money
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.agent_price?.extra_money
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        (
                          info?.agent_price?.agent_conveyance +
                          info?.agent_price?.entertainment +
                          info?.agent_price?.mobile_bill
                        )
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        (
                          info?.agent_price?.agent_money +
                          info?.agent_price?.extra_money +
                          info?.agent_price?.agent_conveyance +
                          info?.agent_price?.entertainment +
                          info?.agent_price?.mobile_bill
                        )
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.agent_price?.agent_money_paid
                          .toString()
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        (
                          info?.agent_price?.agent_money -
                          info?.agent_price?.agent_money_paid
                        )
                          .toString()
                          .toLowerCase()
                          .includes(searchValue)
                    : info;
                })
                ?.map((item, i) => (
                  <tr key={i}>
                    <td className="text-center">{item?.booking_date}</td>
                    <td className="text-center">{item?.agent?.name}</td>
                    <td className="text-center">
                      {item?.customer_info?.map((cus) => (
                        <p key={cus?.id}>{cus?.name}</p>
                      ))}
                    </td>
                    <td className="text-center">
                      {" "}
                      {item?.plot_info?.project_name}
                    </td>
                    <td className="text-center">
                      <span className="font-bold">
                        {item?.plot_info?.file_no}
                      </span>
                    </td>
                    <td className="text-center">
                      <span className="font-bold">
                        {item?.agent_price?.agent_money}
                      </span>
                    </td>
                    <td className="text-center">
                      <span className="font-bold">
                        {item?.agent_price?.extra_money}
                      </span>
                    </td>
                    <td className="text-center">
                      <span className="font-bold">
                        {item?.agent_price?.agent_conveyance +
                          item?.agent_price?.entertainment +
                          item?.agent_price?.mobile_bill}
                      </span>
                    </td>
                    <td className="text-center">
                      <span className="font-bold">
                        {item?.agent_price?.agent_money +
                          item?.agent_price?.extra_money +
                          item?.agent_price?.agent_conveyance +
                          item?.agent_price?.entertainment +
                          item?.agent_price?.mobile_bill}
                      </span>
                    </td>
                    <td className="text-center">
                      <span className="font-bold">
                        {item?.agent_price?.agent_money_paid}
                      </span>
                    </td>
                    <td className="text-center">
                      <span className="font-bold">
                        {item?.agent_price?.agent_money -
                          item?.agent_price?.agent_money_paid}
                      </span>
                    </td>
                    <td className="text-center">
                      <Link
                        to={`/dashboard/agent-payment-list/create`}
                        state={{
                          item: item,
                        }}
                      >
                        <span className="bg-[#00A3E2] whitespace-nowrap  text-white  rounded-[4px] px-[10px] py-[4px] text-[12px]">
                          Create Payment
                        </span>
                      </Link>
                    </td>
                    <td className="text-center">
                      <span className="flex gap-3 justify-center">
                        <Link
                          to={`/dashboard/agent-payment-list/add`}
                          state={{
                            item: item,
                          }}
                        >
                          <img className={commonAction} src={AddImage} alt="" />
                        </Link>
                      </span>
                    </td>
                    <td className="text-center">
                      <span className="flex gap-3 justify-center">
                        <Link
                          to={`/dashboard/agent-payment-list/view/${item?.id}`}
                          state={{
                            item,
                          }}
                        >
                          <img className={commonAction} src={ViewImg} alt="" />
                        </Link>
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <Pagination
          data={agentPaymentList}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          setPageCount={setPageCount}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      </div>
    </div>
  );
};

export default AgentPaymentList;
