import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { baseUrl } from "../../../BaseUrl";
import CommonPrintPdfBtn from "../../../shared/CommonPrintPdfBtn";
import {
  commonAction,
  commonCaption,
  commonSearchInput,
  commonTableHeadingTwo,
  commonTableRow,
  commonTitle,
} from "../../../asset/commoncssConstant/CommonCssConstant";
import Pagination from "../../../shared/pagination/Pagination";
// import CommonModal from "../../../shared/CommonModal";
// import TotalStateMent from "../TotalStateMent";
import ViewImg from "../../../asset/images/clientlist/view.png";
import AddImage from "../../../asset/images/clientpaymentinformation/add.png";
import { LuArrowDownUp } from "react-icons/lu";
import { GoFileDirectory } from "react-icons/go";
import useCommonPDF from "../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../shared/useCommonPrint/useCommonPrint";

const ClientPaymentAndDueList = () => {
  const [customerPaymentInfoList, setCustomerPaymentInfoList] = useState([]);
  const [paymentStatusData, setPaymentStatusData] = useState([]);
  // const [modalIsOpen, setIsOpen] = React.useState(false);
  const [searchFile, setSearchFile] = useState("");
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const [paginationData, setPaginationData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const { toPDF, targetRef } = useCommonPDF({
    title: "client-payment & due-list",
  });

  const handlePrint = useCommonPrint({
    targetRef,
    title: "client payment list",
    message: "thank you",
  });

  // pagination part start here
  const handleNext = () => {
    if (page === pageCount) {
      return page;
    } else {
      return setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page === 1) {
      return page;
    } else {
      return setPage(page - 1);
    }
  };

  //------get data for customer/paymentInformation/view start-----------------
  useEffect(() => {
    axios
      .get(baseUrl + `/customer/paymentInformation/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setPaymentStatusData(res.data.data);
        setCustomerPaymentInfoList(res.data.data);
      });
  }, [token, page]);

  /* payment status handler */
  const handlePaymentStatus = (e) => {
    if (e.target.value === "all") {
      const allData = customerPaymentInfoList.filter(
        (data) => data?.total_due_amount >= 0
      );
      setPaymentStatusData(allData);
    } else if (e.target.value === "complete") {
      const completeData = customerPaymentInfoList.filter(
        (data) => parseFloat(data?.total_due_amount) === 0
      );
      setPaymentStatusData(completeData);
    } else if (e.target.value === "due") {
      const dueData = customerPaymentInfoList.filter(
        (data) => data?.total_due_amount > 0
      );
      setPaymentStatusData(dueData);
    }
  };
  //------get data for customer/paymentInformation/view end-----------------
  return (
    <div>
      <h1 className={commonTitle}>Client Payment List </h1>
      <div className="bg-[#FFFFFF] rounded-[4px] px-[20px] py-[30px] my-[30px]">
        <div className={commonCaption}>
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} />
          <input
            onChange={(e) => setSearchFile(e.target.value.toLowerCase())}
            type="text"
            placeholder="search by file"
            className={commonSearchInput}
          />
        </div>
        <div className="flex flex-col md:flex-row md:justify-center md:items-center gap-5 my-5">
          <p className="text-[#47484A] text-[14px] md:text-[16px] lg:text-[18px] mx-[10px]">
            Payment Status
          </p>
          <div className="common_input_wraper mx-[10px] w-[165px] md:w-[200px] lg:w-[245px] ">
            <select
              style={{ marginTop: 0 }}
              className="h-[36px] md:h-[40px]"
              name=""
              id=""
              defaultValue=""
              onChange={(e) => handlePaymentStatus(e)}
            >
              <option value="" disabled>
                Select Payment Status
              </option>
              <option value="all">ALL</option>
              <option value="complete">Payment Complete</option>
              <option value="due">Payment Due</option>
            </select>
          </div>
          {/* <button
            onClick={() => setIsOpen(true)}
            className=" mx-[10px] w-[165px] md:w-[200px] lg:w-[245px] bg-gradient-to-r from-[#1e788a] to-[#00A3E2] h-[36px] md:h-[40px] rounded-[5px]  text-white"
          >
            Total Statement
          </button> */}
        </div>

        <div ref={targetRef} className="table_responsive text-center mt-[40px]">
          <table>
            <thead>
              <tr className={`${commonTableRow}`}>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Booking Date</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>

                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Customer’s Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span className="whitespace-nowrap">
                      File / Plot Number
                    </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Project Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Booking Money</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Payment Complete</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Payment Due</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Down Payment </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Down Payment Complete</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>

                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Due Down Payment</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Total Instalment</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Instalment Complete</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Due Instalment</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Total Price</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Total Payment Amount</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Total Due</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>
                      Add Payment <br /> Details
                    </span>{" "}
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>
                      {" "}
                      View Payment <br /> Statement
                    </span>{" "}
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Money Receipt Folder</span>{" "}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {paginationData
                ?.filter((info) => {
                  return searchFile.toLowerCase() !== ""
                    ? info?.land_info?.plot_info?.file_no
                        ?.toString()
                        ?.toLowerCase()
                        .includes(searchFile) ||
                        info?.land_info?.plot_info?.no_of_plot
                          ?.toString()
                          ?.toLocaleLowerCase()
                          .includes(searchFile)
                    : info;
                })
                .map((item, i) => (
                  <tr className="text-center" key={item?.id}>
                    <td>{item?.land_info?.booking_date}</td>
                    <td>
                      {item?.land_info?.customer_info?.map((cus) => (
                        <span key={cus?.id}>{cus?.name}</span>
                      ))}
                    </td>
                    <td>
                      {item?.land_info?.plot_info?.file_no} /{" "}
                      {item?.land_info?.plot_info?.no_of_plot}
                    </td>
                    <td>{item?.land_info?.plot_info?.project_name}</td>
                    <td>
                      <span>{item?.total_booking_money} TK</span>
                    </td>
                    <td>
                      <span>{item?.booking_money_paid} TK</span>
                    </td>
                    <td>
                      <span>{item?.due_booking_amount} TK</span>
                    </td>
                    <td>
                      <span>{item?.total_downpayment_amount} TK</span>
                    </td>
                    <td>
                      <span>{item?.downpayment_paid} TK</span>
                    </td>
                    <td>
                      <span>{item?.due_downpayment_amount} TK</span>
                    </td>
                    <td>
                      <span>{item?.total_installment_amount} TK</span>
                    </td>
                    <td>
                      <span>{item?.total_installment_amount_paid} TK</span>
                    </td>
                    <td>
                      <span>{item?.due_installment_amount} TK</span>
                    </td>
                    <td>
                      <span>{item?.total_amount} TK</span>
                    </td>
                    <td>
                      <span>{item?.total_payment_amount} TK</span>
                    </td>
                    <td>
                      <span>{item?.total_due_amount} TK</span>
                    </td>
                    <td>
                      <span className="flex gap-3 justify-center">
                        <Link
                          to={`/dashboard/client-payment-and-due-list/add`}
                          state={{
                            item: item,
                          }}
                        >
                          <img className={commonAction} src={AddImage} alt="" />
                        </Link>
                      </span>
                    </td>
                    <td>
                      <span className="flex gap-3 justify-center">
                        <Link
                          to={`/dashboard/client-payment-and-due-list/view/${item?.id}`}
                          state={{
                            item,
                          }}
                        >
                          <img className={commonAction} src={ViewImg} alt="" />
                        </Link>
                      </span>
                    </td>

                    <td>
                      <div
                        onClick={() =>
                          navigate(
                            `/dashboard/client-payment-and-due-list/money-receipt-folder`
                          )
                        }
                      >
                        <GoFileDirectory
                          className={`text-[#333547] h-8 w-10 rounded-lg mx-auto cursor-pointer`}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <Pagination
          data={paymentStatusData}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          setPageCount={setPageCount}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      </div>
      {/* <CommonModal
        modalIsOpen={modalIsOpen}
        closeModal={() => setIsOpen(false)}
        openModal={() => setIsOpen(true)}
      >
        <TotalStateMent />
      </CommonModal> */}
    </div>
  );
};

export default ClientPaymentAndDueList;
