import React from "react";
import Pagination from "../../../shared/pagination/Pagination";
import AddImg from "../../../asset/images/clientpaymentinformation/add.png";
import ViewImg from "../../../asset/images/clientlist/view.png";
import ActionImg from "../../../asset/images/clientlist/action.png";
import AddUserImg from "../../../asset/images/clientlist/add-user.png";
import { useNavigate } from "react-router-dom";
const RegistryCompleteLandInformation = () => {
  const navigate = useNavigate();
  return (
    <>
      <h1 className="text-[#333547] font-[700] text-[24px]">
        Registry Complete Land Information (From Land Owner)
      </h1>
      <div className="bg-[#FFFFFF]   rounded-[4px] px-[20px] py-[30px] my-[30px]">
        <button
          onClick={() =>
            navigate(
              "/dashboard/registry-complete-land-information/add-customer"
            )
          }
          className="bg-[#16A085] text-white text-[14px] px-[13px] py-[10px] rounded-[5px] flex items-center ml-auto"
        >
          Add Land  Details <img className="ml-[30px]" src={AddUserImg} alt="" />
        </button>
        <div className="flex justify-end items-end mt-[20px]">
          <input
            type="text"
            placeholder="Select Search Type"
            className="max-w-[245px] px-[10px] py-[10px] outline-none border-[1px] border-[#38414A] rounded-[5px] text-[16px] font-[500]"
          />
        </div>

        <div className="table_responsive mt-[40px]">
          <table>
            <thead>
              <tr>
                <th className="text-[#00CA08] text-start">
                  {" "}
                  Land Purchase/ Sub-deed Date
                </th>

                <th className="text-[#00CA08] text-start">Sub-deed Number</th>
                <th className="text-[#00CA08]">Project Name</th>
                <th className="text-[#00CA08] text-start">Mouza Name</th>
                <th className="text-[#00CA08] text-start">
                  Purchased Land Size (Decimal)
                </th>
                <th className="text-[#B7A400]">Purchased Land CS/SA Daag No</th>
                <th className="text-[#B7A400]">Purchased Land CS/SA KH No</th>
                <th className="text-[#0039CA]">Purchased Land RS Daag No</th>
                <th className="text-[#0039CA] text-start">
                  Purchased Land RS KH No
                </th>
                <th className="text-[#AE009D]">Purchased Land BS Daag No</th>

                <th className="text-[#AE009D]">Purchased Land BS KH No</th>
                <th className="text-[#00CA08] text-center">
                  Edit / View Details
                </th>
                <th className="text-[#00CA08] text-center">
                  Previous Land Summery
                </th>
                <th className="text-[#00CA08] text-center">
                  Previous Land Dispute Solved by Court
                </th>
              </tr>
            </thead>
            <tbody>
              {[...Array(20).keys()].map((item, i) => (
                <tr key={i}>
                  <td>dd-mm-yy</td>
                  <td className="font-[700]">521073</td>
                  <td>Munshiganj Project</td>
                  <td>Mouza Name</td>
                  <td className="font-[700]">521073</td>
                  <td className="font-[700]">521073</td>
                  <td className="font-[700]">521073</td>
                  <td className="font-[700]">521073</td>
                  <td className="font-[700]">521073</td>
                  <td className="font-[700]">521073</td>
                  <td className="font-[700]">521073</td>
                  <td className="w-[230px]">
                    <div className="h-full flex">
                      <img onClick={()=>navigate(`/dashboard/registry-complete-land-information/edit/${1}`)} className="mr-2" src={ActionImg} alt="" />
                      <img className="mr-2" src={ViewImg} alt="" />
                    </div>
                  </td>
                  <td className="w-[230px]">
                    <div className="h-full flex">
                      <img className="mr-2" src={AddImg} alt="" />
                      <img className="mr-2" src={ViewImg} alt="" />
                    </div>
                  </td>
                  <td className="w-[230px]">
                    <div className="h-full flex">
                      <img className="mr-2" src={AddImg} alt="" />
                      <img className="mr-2" src={ViewImg} alt="" />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <Pagination />
      </div>
    </>
  );
};

export default RegistryCompleteLandInformation;
