import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import sideBarData from "../../asset/constant/sidebar";
import "./sidebar.css";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";

const Sidebar = () => {
  const [filterSidebar, setFilterSidebar] = useState([]);
  const [active, setIsActive] = useState(null);
  const [url, setUrl] = useState("");
  const navigate = useNavigate();
  const locationUrl = window.location.pathname;
  

  useEffect(() => {
    sideBarData.forEach((item, i) => {
      if (item?.children) {
        const isFind = item?.children?.find(
          (child) => child.path === locationUrl
        );
        if (isFind) {
          setIsActive(i);
        }
      }
    });
    setUrl(locationUrl);
    const jsonUser = localStorage.getItem("user");
    const user = JSON.parse(jsonUser);
    if (user.username === "superadmin") {
      setFilterSidebar(sideBarData);
    } else {
      const newSideBar = sideBarData.filter(
        (data) => data.title !== "Role Permission"
      );
      setFilterSidebar(newSideBar);
    }
  }, [locationUrl]);

  return (
    <div className="drawer-side z-40 w-full sm:w-64 md:w-72 lg:w-96">
      <div className="bg-[white] hidden sticky top-0 z-50 items-center px-4 h-[55px] md:flex text-base md:text-lg text-[#000000] font-[700] w-[231px]">
        <Link to="/">
          <span className="text-sm">Munshiganj Land Project</span>
        </Link>
      </div>
      <label htmlFor="my-drawer-2" className="drawer-overlay"></label>
      <ul className="menu box-content w-[215px] font-normal sidebar bg-[#0C768A] text-white text-base min-h-[100vh] overflow-auto">
        {filterSidebar?.map((item, i) => (
          <Fragment key={i}>
            <li
              onClick={() => {
                if (active === i) {
                  setIsActive(null);
                } else {
                  setIsActive(i);
                  if (item?.path) {
                    navigate(item?.path);
                  }
                }
              }}
              className={`  ${
                url === item.path
                  ? "bg-gradient-to-r from-[#18acc7] via-[#1bb1cf] to-[#7fccdb] rounded-[10px]  my-1 text-[14px] font-[400]"
                  : "hover:bg-gradient-to-r hover:from-[#18acc7] hover:via-[#1bb1cf] hover:to-[#7fccdb]  hover:rounded-[10px] rounded-[5px] my-1 text-[14px] font-[400]"
              }`}
            >
              <div
                className={`py-2 md:py-2 text-[12px] flex items-center justify-between leading-[15px] ${
                  !item?.children?.length > 0
                    ? "focus:bg-white px-2"
                    : "focus:bg-none px-2"
                }`}
              >
                <div className="flex justify-start items-center">
                  <div className="px-1 text-white">{item.icon}</div>
                  <div className="px-1 text-white">{item.title}</div>
                </div>
                {item?.children?.length > 0 && (
                  <div className="text-white">
                    {" "}
                    {active !== i ? (
                      <IoIosArrowForward />
                    ) : (
                      <IoIosArrowDown />
                    )}{" "}
                  </div>
                )}
              </div>
            </li>

            {item?.children?.length > 0 && (
              <ul className={` ${active !== i ? "hide" : "show"}`}>
                {item?.children?.map((child, j) => (
                  <li
                    key={j}
                    className={`leading-[15px] ${
                      url === child?.path
                        ? "py-0 my-2 ml-[15px] bg-gradient-to-r from-[#18acc7] via-[#1bb1cf] to-[#7fccdb] rounded-[10px]  text-[12px] font-[100]"
                        : "py-0 my-2 ml-[15px] hover:bg-gradient-to-r hover:from-[#18acc7] hover:via-[#1bb1cf] hover:to-[#7fccdb] hover:rounded-[10px] text-[12px] font-[100]"
                    }`}
                  >
                    <Link
                      to={child?.path}
                      className={` text-[12px] text-white`}
                      style={{ gridAutoColumns: "initial" }}
                    >
                      {child.title}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </Fragment>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
