import React, { useState } from "react";
import BackButton from "../../../../shared/BackButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { baseUrl } from "../../../../BaseUrl";
import {
  commonBankColumnSpan,
  commonBankMenu,
  commonFormTitle,
  errorMessage,
} from "../../../../asset/commoncssConstant/CommonCssConstant";

const EditBsDisputeList = () => {
  const dagKhaytanForSelect = useLocation().state.dagKhaytanForSelect;
  const item = useLocation().state.item;
  const [defaultData, setDefaultData] = useState(item?.bs_dag_info);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  //-------get data by id select option start---------------
  const handleSelectById = (e) => {
    console.log(e.target.value);
    e.preventDefault();
    setDefaultData(
      dagKhaytanForSelect?.find((list) => list.bs_dag_no === e.target.value)
    );
  };
  //-------get data by id select option end---------------
  //--------landDisputeBank/bsDisputeList/update start---------
  const onSubmit = (data) => {
    data.bs_id = defaultData?.id;
    data.id = item.id;
    axios
      .post(baseUrl + `/landDisputeBank/bsDisputeList/update`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          navigate("/dashboard/bs-dispute-list");
        }
      });
  };
  //--------landDisputeBank/bsDisputeList/update end---------

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BackButton title={"Edit BS Dispute"} />
      <div className={`bg-[#FFFFFF] rounded-[4px]  my-[30px]`}>
        <div className="p-[30px]  ">
          <p className={commonFormTitle}>BS Dispute Information</p>
          <div className={commonBankMenu}>
            <div className="common_input_wraper">
              <label>BS Dag *</label>
              <select onChange={(e) => handleSelectById(e)}>
                <option value={defaultData?.bs_dag_no || item?.bs_dag_no}>
                  {" "}
                  {defaultData?.bs_dag_no || item?.bs_dag_no}
                </option>
                {dagKhaytanForSelect.map((list) => (
                  <option key={list.id} value={list.bs_dag_no}>
                    {list.bs_dag_no}
                  </option>
                ))}
              </select>
            </div>
            <div className="common_input_wraper">
              <label>BS Khatiyan</label>
              <input
                type="text"
                placeholder="BS Khatiyan"
                readOnly
                disabled
                value={
                  defaultData?.bs_khatiyan_no ||
                  item?.bs_dag_info?.bs_khatiyan_no
                }
              />
            </div>
            <div className="common_input_wraper">
              <label>Dispute Date</label>
              <input
                {...register("dispute_date", {
                  required: "Dispute Date is required",
                })}
                type="date"
                defaultValue={item.dispute_date}
              />
              {errors?.dispute_date && (
                <p className={errorMessage}>{errors?.dispute_date?.message}</p>
              )}
            </div>
            <div className="common_input_wraper">
              <label>Dispute No.</label>
              <input
                {...register(`dispute_no`, {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Insert valid dispute no.",
                  },
                })}
                type="text"
                defaultValue={item.dispute_no}
              />
              {errors?.dispute_no && (
                <p className={errorMessage}>{errors?.dispute_no?.message}</p>
              )}
            </div>
            <div className="common_input_wraper">
              <label>Dispute Land Size</label>
              <input
                {...register(`dispute_land_size`, {
                  pattern: {
                    value: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Insert valid land size",
                  },
                })}
                type="text"
                step="0.01"
                defaultValue={item.dispute_land_size}
              />
              {errors?.dispute_land_size && (
                <p className={errorMessage}>
                  {errors?.dispute_land_size?.message}
                </p>
              )}
            </div>
            <div className="common_input_wraper">
              <label>Dispute Court Name</label>
              <input
                {...register("dispute_court_name")}
                type="text"
                defaultValue={item.dispute_court_name}
              />
            </div>
            <div className="common_input_wraper">
              <label>Dispute Judgement Date</label>
              <input
                {...register("dispute_judgement_date", {
                  required: "Judgement Date is required",
                })}
                type="date"
                defaultValue={item.dispute_judgement_date}
              />
              {errors?.dispute_judgement_date && (
                <p className={errorMessage}>
                  {errors?.dispute_judgement_date?.message}
                </p>
              )}
            </div>
            <div className="common_input_wraper">
              <label>Case Badi Name</label>
              <input
                {...register(`case_badi_name`, {
                  pattern: {
                    value: /^[A-Za-z]+[A-Za-z0-9\-_~(\s]*[A-Za-z0-9\s)]$/,
                    message: "Insert valid name",
                  },
                })}
                type="text"
                defaultValue={item.case_badi_name}
              />
              {errors?.case_badi_name && (
                <p className={errorMessage}>
                  {errors?.case_badi_name?.message}
                </p>
              )}
            </div>
            <div className="common_input_wraper">
              <label>Case Bibadi Name</label>
              <input
                {...register(`case_bibadi_name`, {
                  pattern: {
                    value: /^[A-Za-z]+[A-Za-z0-9\-_~(\s]*[A-Za-z0-9\s)]$/,
                    message: "Insert valid name",
                  },
                })}
                type="text"
                defaultValue={item.case_bibadi_name}
              />
              {errors?.case_bibadi_name && (
                <p className={errorMessage}>
                  {errors?.case_bibadi_name?.message}
                </p>
              )}
            </div>
            <div className="col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-5"></div>

            <div className={`common_input_wraper w-full lg:w-1/2`}>
              <label>Judgement Description</label>
              <textarea
                rows={4}
                {...register("judgement_description")}
                placeholder="Buyer & Seller All Personal & Land Information"
                type="text"
              />
            </div>
          </div>
          <div className="text-center py-10">
            <button type="submit" className="submit_button">
              Update
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditBsDisputeList;
