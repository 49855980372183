import React from 'react';
import BackButton from '../../../../shared/BackButton';
import { PDFViewer } from '@react-pdf/renderer';
import { CreateLandBrokerPaymentPdf } from './CreateLandBrokerPaymentPdf';
import { useLocation } from 'react-router-dom';

const ViewLandBrokerPaymentPdf = () => {
    const { item, statement } = useLocation()?.state || {};
    return (
<div className={`w-full h-full`}>
      <BackButton title={"Back"} />
      <PDFViewer className={`w-full h-full pt-5`}>
        <CreateLandBrokerPaymentPdf item={item} statement= {statement} />
      </PDFViewer>
    </div>
    );
};

export default ViewLandBrokerPaymentPdf;