import { Margin, Resolution, usePDF } from "react-to-pdf";

const useCommonPDF = ({title}) => {
  const { toPDF, targetRef } = usePDF({
    method: "save",
    resolution: Resolution.HIGH,
    filename: `${title}.pdf`,
    page: { margin: Margin.MEDIUM, orientation: "landscape" },
  });
  return { toPDF, targetRef };
};

export default useCommonPDF;
