import { Link } from "react-router-dom";
import AddUserImg from "../../../asset/images/clientlist/add-user.png";
import Pagination from "../../../shared/pagination/Pagination";
import CommonPrintPdfBtn from "../../../shared/CommonPrintPdfBtn";
import MapImg from "../../../asset/images/map.png";
import {
  addCustomerCss,
  commonAction,
  commonAddButton,
  commonCaption,
  commonSearchInput,
  commonTableHeadingTwo,
  commonTableRow,
  commonTitle,
  dashboardBodyWraper,
} from "../../../asset/commoncssConstant/CommonCssConstant";
import ActionBtn from "../../../asset/images/clientlist/action.png";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl, baseUrlImg } from "../../../BaseUrl";
import { LuArrowDownUp } from "react-icons/lu";
import useCommonPDF from "../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../shared/useCommonPrint/useCommonPrint";

const MouzaInformation = () => {
  const [projectList, setProjectList] = useState([]);
  const [mouzaInfoList, setMouzaInfoList] = useState([]);
  const token = localStorage.getItem("token");
  const [searchValue, setSearchValue] = useState("");

  const [paginationData, setPaginationData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const { toPDF, targetRef } = useCommonPDF({ title: "mouza-info" });

  
  const handlePrint = useCommonPrint({
    targetRef,
    title: "mouza-info",
    message: "thank you",
  });

  // pagination part start here
  const handleNext = () => {
    if (page === pageCount) {
      return page;
    } else {
      return setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page === 1) {
      return page;
    } else {
      return setPage(page - 1);
    }
  };

  //------get data for projectList and mouzaInfoList  start-----------------
  useEffect(() => {
    axios
      .get(baseUrl + `/landInformationBank/projectInformation/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setProjectList(res.data.data);
      });
    axios
      .get(baseUrl + `/landInformationBank/mouzaInformation/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setMouzaInfoList(res.data.data);
      });
  }, [token, page]);
  //------get data for projectList and mouzaInfoList  end-----------------

  return (
    <div>
      <h1 className={`${commonTitle}`}>Mouza Information</h1>
      <div className={dashboardBodyWraper}>
        <div className={commonAddButton}>
          <Link
            to={`/dashboard/mouza-info/add`}
            state={{
              projectList: projectList,
            }}
          >
            <button className={addCustomerCss}>
              Add Mouza Information
              <img className="ml-[30px]" src={AddUserImg} alt="" />
            </button>
          </Link>
        </div>
        <div className={commonCaption}>
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} />
          <input
            onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
            type="text"
            placeholder="Search"
            className={commonSearchInput}
          />
        </div>

        <div ref={targetRef} className="table_responsive mt-[40px]">
          <table>
            <thead>
              <tr className={`${commonTableRow}`}>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Create Date</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Project Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Mouza Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`py-2`}>
                    <span>Mouza Map</span>{" "}
                  </div>
                </th>
                <th>
                  <div className={`py-2`}>
                    <span>
                      {" "}
                      Action <br />
                      Update
                    </span>{" "}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {paginationData
                ?.filter((info) => {
                  return searchValue.toLowerCase() !== ""
                    ? info?.created_at
                        .slice(0, 10)
                        .toLowerCase()
                        .includes(searchValue) ||
                        info?.project_info?.project_name
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.mouza_name?.toLowerCase().includes(searchValue)
                    : info;
                })
                ?.map((mouza) => (
                  <tr className="text-center" key={mouza.id}>
                    <td>{mouza.created_at.slice(0, 10)}</td>
                    <td>{mouza.project_info.project_name}</td>
                    <td>{mouza.mouza_name}</td>
                    <td>
                      <img
                        className="w-11 rounded-lg mx-auto"
                        src={
                          mouza.map_image
                            ? baseUrlImg + mouza.map_image
                            : MapImg
                        }
                        alt=""
                      />
                    </td>
                    <td>
                      <span className="flex  justify-center">
                        <Link
                          to={`/dashboard/mouza-info/edit`}
                          state={{
                            mouza: mouza,
                            projectList: projectList,
                          }}
                        >
                          <img
                            className={commonAction}
                            src={ActionBtn}
                            alt=""
                          />
                        </Link>
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <Pagination
          data={mouzaInfoList}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          setPageCount={setPageCount}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      </div>
    </div>
  );
};

export default MouzaInformation;
