import React, { useEffect, useState } from "react";
import Pagination from "../../../shared/pagination/Pagination";
import {
  commonAction,
  commonCaption,
  commonSearchInput,
  commonTableHeadingTwo,
  commonTableRow,
  commonTitle,
  dashboardBodyWraper,
} from "../../../asset/commoncssConstant/CommonCssConstant";
import CommonPrintPdfBtn from "../../../shared/CommonPrintPdfBtn";
import ViewBtn from "../../../asset/images/clientlist/view.png";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../../BaseUrl";
import { LuArrowDownUp } from "react-icons/lu";
import useCommonPDF from "../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../shared/useCommonPrint/useCommonPrint";

const AgentWorkList = () => {
  const [agentWorkList, setAgentWorkList] = useState([]);
  const token = localStorage.getItem("token");
  const [searchValue, setSearchValue] = useState("");

  const [paginationData, setPaginationData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const { toPDF, targetRef } = useCommonPDF({ title: "agent-work-list" });

  const handlePrint = useCommonPrint({
    targetRef,
    title: "agent-work-list",
    message: "thank you",
  });

  // pagination part start here
  const handleNext = () => {
    if (page === pageCount) {
      return page;
    } else {
      return setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page === 1) {
      return page;
    } else {
      return setPage(page - 1);
    }
  };

  // console.log(agentWorkList);

  //-----------get agent work list start --------------------
  useEffect(() => {
    axios
      .get(baseUrl + `/agent/workList`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setAgentWorkList(res.data.data);
      });
  }, [token, page]);
  //-----------get agent work list end --------------------
  return (
    <div>
      <h1 className={`${commonTitle}`}>Agent Work List</h1>
      <div className={dashboardBodyWraper}>
        <div className={commonCaption}>
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} />
          <input
            onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
            type="text"
            placeholder="Search"
            className={commonSearchInput}
          />
        </div>

        <div ref={targetRef} className="table_responsive mt-[40px]">
          <table>
            <thead>
              <tr className={`${commonTableRow}`}>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Booking Date </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Agent Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Present Address</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>agent mobile 1</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>agent mobile 2</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>
                      {" "}
                      Customer’s <br />
                      Name
                    </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Customer mobile</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Project Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> File / Plot No.</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>

                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Total Price</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Agent Money</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`py-2`}>
                    <span> View</span>{" "}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {paginationData
                ?.filter((info) => {
                  return searchValue.toLowerCase() !== ""
                    ? info?.booking_date?.toLowerCase().includes(searchValue) ||
                        info?.agent?.name
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.agent?.present_address
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.agent?.mobile_number_1
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.agent?.mobile_number_2
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.customer_info
                          ?.map((cus) => cus?.name)
                          ?.join("")
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.customer_info
                          ?.map((cus) => {
                            return [
                              ...cus?.mobile_number_1,
                              ...cus?.mobile_number_2,
                            ];
                          })
                          .join("")
                          .split(",")
                          .join("")
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.plot_info?.project_name
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.plot_info?.file_no
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.customer_land_price?.total_amount
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.agent_price?.agent_money
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue)
                    : info;
                })
                ?.map((item) => (
                  <tr className="text-center" key={item.id}>
                    <td>{item?.booking_date}</td>
                    <td>{item?.agent?.name}</td>
                    <td>{item?.agent?.present_address}</td>
                    <td>
                      <span>{item?.agent?.mobile_number_1}</span>
                    </td>
                    <td>
                      <span>{item?.agent?.mobile_number_2}</span>
                    </td>
                    <td>
                      {item?.customer_info?.map((cus) => (
                        <p key={cus?.id}>{cus?.name}</p>
                      ))}
                    </td>
                    <td>
                      {item?.customer_info?.map((cus) => (
                        <p key={cus?.id}>
                          {cus?.mobile_number_1} & {cus?.mobile_number_2}
                        </p>
                      ))}
                    </td>
                    <td> {item?.plot_info?.project_name}</td>
                    <td>
                      <span className="font-bold">
                        {item?.plot_info?.file_no}
                      </span>
                    </td>
                    <td>
                      <span className="font-bold">
                        {item?.customer_land_price?.total_amount}
                      </span>
                    </td>
                    <td>
                      <span className="font-bold">
                        {item?.agent_price?.agent_money}
                      </span>
                    </td>

                    <td>
                      <div className="flex justify-center">
                        <Link
                          to={`/dashboard/agent-work-list/view`}
                          state={{
                            item: item,
                          }}
                        >
                          <img className={commonAction} src={ViewBtn} alt="" />
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <Pagination
          data={agentWorkList}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          setPageCount={setPageCount}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      </div>
    </div>
  );
};

export default AgentWorkList;
