import { Link } from "react-router-dom";
import AddUserImg from "../../../asset/images/clientlist/add-user.png";
import Pagination from "../../../shared/pagination/Pagination";
import CommonPrintPdfBtn from "../../../shared/CommonPrintPdfBtn";
import {
  addCustomerCss,
  commonAction,
  commonAddButton,
  commonCaption,
  commonSearchInput,
  commonTableHeadingTwo,
  commonTableRow,
  commonTitle,
  dashboardBodyWraper,
} from "../../../asset/commoncssConstant/CommonCssConstant";
import ActionBtn from "../../../asset/images/clientlist/action.png";
import ViewBtn from "../../../asset/images/clientlist/view.png";
import FolderImg from "../../../asset/images/clientlandinformation/back-folder.png";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl, baseUrlImg } from "../../../BaseUrl";
import { LuArrowDownUp } from "react-icons/lu";
import useCommonPDF from "../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../shared/useCommonPrint/useCommonPrint";

const BsDagAndKhatiyan = () => {
  const [rsDagKhatiyanInfoList, setRsDagKhatiyanInfoList] = useState([]);
  const [bsDagKhatiyanInfoList, setBsDagKhatiyanInfoList] = useState([]);
  const token = localStorage.getItem("token");
  const [searchValue, setSearchValue] = useState("");
  const [paginationData, setPaginationData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const { toPDF, targetRef } = useCommonPDF({ title: "bs dag & khatiyan" });

  const handlePrint = useCommonPrint({
    targetRef,
    title: "bs dag & khatiyan",
    message: "thank you",
  });

  // pagination part start here
  const handleNext = () => {
    if (page === pageCount) {
      return page;
    } else {
      return setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page === 1) {
      return page;
    } else {
      return setPage(page - 1);
    }
  };

  //------get data for bsDagKhatiyanList and mouzaInfoList  start-----------------
  useEffect(() => {
    axios
      .get(baseUrl + `/landInformationBank/bsDagKhatiyan/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setBsDagKhatiyanInfoList(res.data.data);
      });
    axios
      .get(baseUrl + `/landInformationBank/rsDagKhatiyan/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setRsDagKhatiyanInfoList(res.data.data);
      });
  }, [token, page]);
  //------get data for bsDagKhatiyanList and mouzaInfoList end-----------------

  return (
    <div>
      <h1 className={`${commonTitle}`}>BS Dag & Khatiyan</h1>
      <div className={dashboardBodyWraper}>
        <div className={commonAddButton}>
          <Link
            to={`/dashboard/bs-dag-khatiyan/add`}
            state={{
              rsDagKhatiyanInfoList: rsDagKhatiyanInfoList,
            }}
          >
            <button className={addCustomerCss}>
              Add BS Dag & Khatiyan
              <img className="ml-[30px]" src={AddUserImg} alt="" />
            </button>
          </Link>
        </div>
        <div className={commonCaption}>
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} />
          <input
            onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
            type="text"
            placeholder="Search"
            className={commonSearchInput}
          />
        </div>

        <div ref={targetRef} className="table_responsive mt-[40px]">
          <table>
            <thead>
              <tr className={`${commonTableRow}`}>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> BS Create</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Project Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Mouza Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> BS Dag</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> BS Khatiyan</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Total BS Area</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Total BS Use Area</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`py-2`}>
                    <span> BS Porca Scan Copy</span>{" "}
                  </div>
                </th>
                <th>
                  <div className={`py-2`}>
                    <span> BS Dag Map Scan Copy</span>{" "}
                  </div>
                </th>
                <th>
                  <div className={`py-2`}>
                    <span>
                      {" "}
                      Action <br />
                      Update / View
                    </span>{" "}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {bsDagKhatiyanInfoList
                ?.filter((info) => {
                  return searchValue.toLowerCase() !== ""
                    ? info?.created_at
                        ?.slice(0, 10)
                        .toLowerCase()
                        .includes(searchValue) ||
                        info?.rs_info?.sa_info?.cs_info?.mouza_info?.project_info?.project_name
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.rs_info?.sa_info?.cs_info?.mouza_info?.mouza_name
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.bs_dag_no
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.bs_khatiyan_no
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.total_bs_area
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.total_bs_use_area
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue)
                    : info;
                })
                ?.map((item, i) => (
                  <tr className="text-center" key={item.id}>
                    <td>{item?.created_at?.slice(0, 10)}</td>
                    <td>
                      {
                        item?.rs_info?.sa_info?.cs_info?.mouza_info
                          ?.project_info?.project_name
                      }
                    </td>
                    <td>
                      {item?.rs_info?.sa_info?.cs_info?.mouza_info?.mouza_name}
                    </td>
                    <td>{item?.bs_dag_no}</td>
                    <td>{item?.bs_khatiyan_no}</td>
                    <td>{item?.total_bs_area}</td>
                    <td>{item?.total_bs_use_area}</td>
                    <td>
                      <img
                        className="w-11 rounded-lg mx-auto"
                        src={
                          item.bs_porca_scan_copy
                            ? baseUrlImg + item.bs_porca_scan_copy
                            : FolderImg
                        }
                        alt=""
                      />
                    </td>
                    <td>
                      <img
                        className="w-11 rounded-lg mx-auto"
                        src={
                          item.bs_dag_map_scan_copy
                            ? baseUrlImg + item.bs_dag_map_scan_copy
                            : FolderImg
                        }
                        alt=""
                      />
                    </td>
                    <td>
                      <span className="flex gap-2 justify-center">
                        <Link
                          to={`/dashboard/bs-dag-khatiyan/edit`}
                          state={{
                            bsDagKhatiyan: item,
                            rsDagKhatiyanInfoList: rsDagKhatiyanInfoList,
                          }}
                        >
                          <img
                            className={commonAction}
                            src={ActionBtn}
                            alt=""
                          />
                        </Link>
                        <Link
                          to={`/dashboard/bs-dag-khatiyan/view`}
                          state={{
                            bsDagKhatiyan: item,
                          }}
                        >
                          <img className={commonAction} src={ViewBtn} alt="" />
                        </Link>
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <Pagination
          data={bsDagKhatiyanInfoList}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          setPageCount={setPageCount}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      </div>
    </div>
  );
};

export default BsDagAndKhatiyan;
