import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import {
  commonFormTitle,
  dashboardBodyWraper,
} from "../../../asset/commoncssConstant/CommonCssConstant";
import LandInformationForm from "../addClient/form/LandInformationForm";
import CustomersForm from "../addClient/form/CustomersForm";
import LandPriceInformation from "../addClient/form/LandPriceInformation";
import AgentInformationForm from "../addClient/form/AgentInformationForm";
import BackButton from "../../../shared/BackButton";
import axios from "axios";
import { baseUrl } from "../../../BaseUrl";

const EditClient = () => {
  const [customerForm, setCustomerForm] = useState([]);
  const [defaultLandInfo, setDefaultLandInfo] = useState(null);
  const [defaultAgentInfo, setDefaultAgentInfo] = useState(null);
  const clientData = useLocation().state.clientData;
  const plotList = useLocation().state.plotList;
  const agentList = useLocation().state.agentList;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    setCustomerForm(clientData?.customer_info);
  }, [clientData?.customer_info]);
  //-------get LandInfo data by id select option start---------------
  const handleSelectByLandInfoId = (e) => {
    e.preventDefault();
    setDefaultLandInfo(
      plotList?.find((list) => list.id === parseInt(e.target.value))
    );
  };
  //-------get LandInfo data by id select option end---------------
  //-------get LandInfo data by id select option start---------------
  const handleSelectByAgentInfoId = (e) => {
    e.preventDefault();
    setDefaultAgentInfo(
      agentList?.find((list) => list.id === parseInt(e.target.value))
    );
  };
  //-------get LandInfo data by id select option end---------------

  //--------Add More Customer Form or delete functionality start---------
  const handleCustomerFormAdd = () => {
    setCustomerForm([
      ...customerForm,
      {
        id: "",
        name: "",
        father_name: "",
        mother_name: "",
        spouse_name: "",
        spouse_type: "",
        nid: "",
        dob: "",
        mobile_number_1: "",
        mobile_number_2: "",
        other_file_no: "",
        profession: "",
        designation: "",
        email: "",
        mailing_address: "",
        permanent_address: "",
        office_address: "",
        image: null,
      },
    ]);
    reset();
  };
  const handleCustomerFormRemove = () => {
    let lastElement = [...customerForm].pop();
    if (!lastElement.id) {
      setCustomerForm([...customerForm].slice(0, -1));
    }
  };
  //--------Add More Customer Form or delete functionality end-----------
  const onSubmit = (data) => {
    data.customer = data?.customer?.slice(0, customerForm?.length);
    data.id = clientData?.id;
    data.plot_id = defaultLandInfo?.id || clientData?.plot_info?.id;
    data.agentID = defaultAgentInfo?.id || clientData?.agent?.id;
    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      if (typeof value == "object") {
        if (key === "customer") {
          for (let i = 0; i < value.length; i++) {
            let names = Object.keys(value[i]);
            names.forEach((name) => {
              if (value[i][name] instanceof FileList) {
                formData.append(
                  `customer[${i}][${name}]`,
                  value[i][name].item(0)
                );
              } else {
                formData.append(`customer[${i}][${name}]`, value[i][name]);
              }
            });
          }
        }
        if (key === "agreements") {
          for (let i = 0; i < value.length; i++) {
            if (value instanceof FileList) {
              data[`agreements[${i}]`] = value[i];
              delete data.agreements;
              formData.append(`agreements[${i}]`, value[i]);
            }
          }
        }
      } else {
        formData.append(key, value);
      }
    });
    axios
      .post(baseUrl + `/customer/update`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          navigate("/dashboard/client-list");
        }
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BackButton title={"Edit Client"} />
      <div className={`${dashboardBodyWraper} py-[10px] `}>
        <LandInformationForm
          register={register}
          errors={errors}
          clientData={clientData}
          plotList={plotList}
          handleSelectByLandInfoId={handleSelectByLandInfoId}
          defaultLandInfo={defaultLandInfo}
        />

        <p className={commonFormTitle}>Customer Information</p>
        {customerForm.map((customer, i) => {
          return (
            <Fragment key={i}>
              <CustomersForm register={register} customer={customer} i={i} errors={errors} />
              {customerForm.length - 1 === i &&
                customerForm.length > 1 &&
                ![...customerForm].pop().id && (
                  <button
                    className={`delete_customer mb-4`}
                    type="button"
                    onClick={handleCustomerFormRemove}
                  >
                    Delete{" "}
                  </button>
                )}
              {customerForm.length > 1 ? <hr className="" /> : null}
            </Fragment>
          );
        })}
        <div className="py-10">
          <button
            type="button"
            className="add_customer"
            onClick={handleCustomerFormAdd}
          >
            Add More Customer <span className="plus pb-1">+</span>
          </button>
        </div>

        <LandPriceInformation register={register} errors={errors} clientData={clientData} />

        <AgentInformationForm
          register={register}
          errors={errors}
          clientData={clientData}
          agentList={agentList}
          handleSelectByAgentInfoId={handleSelectByAgentInfoId}
          defaultAgentInfo={defaultAgentInfo}
        />

        <div className="text-center pt-5">
          <button type="submit" className="submit_button">
            Update
          </button>
        </div>
      </div>
    </form>
  );
};

export default EditClient;
