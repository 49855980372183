import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../clientPriceInfoCommonDesign/clientPriceInfoCommonDesign.css";
import PlusImg from "../../../asset/images/clientpaymentinformation/plus.png";
import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { baseUrl } from "../../../BaseUrl";
import {
  commonUserInfo,
  commonUserRow,
  errorMessage,
} from "../../../asset/commoncssConstant/CommonCssConstant";
import BackButton from "../../../shared/BackButton";

const AddClientPriceInfo = () => {
  const [numberOfDownpayment, setNumberOfDownpayment] = useState(1);
  const navigate = useNavigate();
  const item = useLocation().state.item;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const token = localStorage.getItem("token");

  //-----------handleDownpayment functionality start--------------
  const handleDownpayment = () => {
    setNumberOfDownpayment((numberOfDownpayment) => numberOfDownpayment + 1);
  };
  //-----------handleDownpayment functionality end--------------
  //--------customer/priceInformation/store start---------
  const onSubmit = (data) => {
    data.customer_land_info_id = item?.id;
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (typeof value == "object") {
        if (key === "downpayment") {
          for (let i = 0; i < value.length; i++) {
            let names = Object.keys(value[i]);
            names.forEach((name) => {
              formData.append(`downpayment[${i}][${name}]`, value[i][name]);
            });
          }
        }
        if (key === "installment") {
          for (let i = 0; i < value.length; i++) {
            let names = Object.keys(value[i]);
            names.forEach((name) => {
              formData.append(`installment[${i}][${name}]`, value[i][name]);
            });
          }
        }
      } else {
        formData.append(key, value);
      }
    });

    axios
      .post(baseUrl + `/customer/priceInformation/store`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          navigate("/dashboard/client-price-information");
        }
      });
  };
  //--------customer/priceInformation/store end---------
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <BackButton title={"Add Client Payment"} />
        <div className="bg-[#FFFFFF]   rounded-[4px] px-[20px] py-[30px] my-[30px]">
          <button
            type="button"
            className="bg-gradient-to-r from-[#0C768A] via-[#1e788a] to-[#2896a9] font-bold text-white px-[50px] py-[5px] rounded-lg pointer-events-none"
          >
            Previously Submitted Informations
          </button>
          <div className={commonUserInfo}>
            <div className={commonUserRow}>
              <div>Customer Name </div>
              <div>:</div>
              <div>
                {" "}
                {item?.customer_info?.map((cus) => (
                  <span key={cus?.id}>{cus?.name}</span>
                ))}
              </div>
            </div>
            <div className={commonUserRow}>
              <div>Project Name </div>
              <div>:</div>
              <div>{item?.plot_info?.project_name}</div>
            </div>
            <div className={commonUserRow}>
              <div>File / Plot No </div>
              <div>:</div>
              <div>{item?.plot_info?.file_no}</div>
            </div>
            <div className={commonUserRow}>
              <div>Land Size (Katha)</div>
              <div>:</div>
              <div>{item?.plot_info?.plot_size_katha}</div>
            </div>
            <div className={commonUserRow}>
              <div>Land Size (Ojutangsho)</div>
              <div>:</div>
              <div>{item?.plot_info?.plot_size_ojutangsho}</div>
            </div>
            <div className={commonUserRow}>
              <div>Total Price </div>
              <div>:</div>
              <div>{item?.customer_land_price?.total_amount} TK</div>
            </div>
            <div className={commonUserRow}>
              <div>Booking Money</div>
              <div>:</div>
              <div>{item?.customer_land_price?.total_booking_money} TK</div>
            </div>
            <div className={commonUserRow}>
              <div>Down payment</div>
              <div>:</div>
              <div>
                {item?.customer_land_price?.total_downpayment_amount} TK
              </div>
            </div>
            <div className={commonUserRow}>
              <div>Instalment Amount</div>
              <div>:</div>
              <div>
                {item?.customer_land_price?.total_installment_amount} TK
              </div>
            </div>
            <div className={commonUserRow}>
              <div>No of Instalment </div>
              <div>:</div>
              <div>{item?.customer_land_price?.no_of_installment}</div>
            </div>
            <div className={commonUserRow}>
              <div>Per Month Instalment </div>
              <div>:</div>
              <div>{item?.customer_land_price?.per_month_installment} TK</div>
            </div>
            <div className={commonUserRow}>
              <div>Registration Date </div>
              <div>:</div>
              <div>{item?.expected_reg_date}</div>
            </div>
          </div>
          <div className="grid xl:grid-cols-3 gap-5">
            <div className="instalment_money_box mt-5">
              <p className="imb_top_title">Booking Money Information</p>
              <div className="common_date_with_input">
                <div className="label_wraper">
                  <label>Booking Money</label>
                  <label>Start Date</label>
                </div>
                <div className="text_date_wraper">
                  <input
                    {...register("booking_money", {
                      pattern: {
                        value: /^[0-9]+(\.[0-9]+)?$/,
                        message: "Insert valid amount",
                      },
                    })}
                    type="text"
                    defaultValue={
                      item?.customer_land_price?.total_booking_money
                    }
                  />

                  <input {...register("booking_money_date")} type="date" />
                </div>
                {errors?.booking_money && (
                  <p className={errorMessage}>
                    {errors?.booking_money?.message}
                  </p>
                )}
              </div>
            </div>
            <div className="instalment_money_box mt-5">
              <p className="imb_top_title">Downpayment Information</p>
              {[...Array(numberOfDownpayment)].map((item, i) => (
                <div key={i}>
                  <div className="common_date_with_input mb-4">
                    <div className="label_wraper">
                      <label>Downpayment {i + 1}</label>
                      <label>Start Date</label>
                    </div>
                    <div className="text_date_wraper">
                      <input
                        className="hidden"
                        {...register(`downpayment[${i}].downpayment_no`)}
                        type="text"
                        value={`Downpayment ${i + 1}`}
                      />
                      <input
                        {...register(`downpayment[${i}].amount`, {
                          pattern: {
                            value: /^[0-9]+(\.[0-9]+)?$/,
                            message: "Insert valid amount",
                          },
                        })}
                        type="text"
                        placeholder="Downpayment Amount"
                      />

                      <input
                        {...register(`downpayment[${i}].start_date`)}
                        type="date"
                      />
                    </div>
                    {errors && errors.downpayment && errors.downpayment[i] && (
                      <p className={errorMessage}>
                        {errors?.downpayment[i]?.amount?.message}
                      </p>
                    )}
                  </div>
                </div>
              ))}
              <p onClick={handleDownpayment} className="plus_img_wraper">
                <img src={PlusImg} alt="" />
              </p>
            </div>
            <div className="instalment_money_box mt-5">
              <p className="imb_top_title">Installment Information</p>
              {[...Array(+item?.customer_land_price?.no_of_installment)].map(
                (item, i) => {
                  return (
                    <div key={i}>
                      <div className="common_date_with_input mb-4">
                        <div className="label_wraper">
                          <label>Installment Money {i + 1}</label>
                          <label>Start Date</label>
                        </div>
                        <div className="text_date_wraper">
                          <input
                            className="hidden"
                            {...register(`installment[${i}].installment_no`)}
                            type="text"
                            value={`Installment ${i + 1}`}
                          />
                          <input
                            {...register(`installment[${i}].amount`, {
                              pattern: {
                                value: /^[0-9]+(\.[0-9]+)?$/,
                                message: "Insert valid amount",
                              },
                            })}
                            type="text"
                            placeholder="Installment Amount"
                          />

                          <input
                            {...register(`installment[${i}].start_date`)}
                            type="date"
                          />
                        </div>
                        {errors &&
                          errors.installment &&
                          errors.installment[i] && (
                            <p className={errorMessage}>
                              {errors?.installment[i]?.amount?.message}
                            </p>
                          )}
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
          <div className="text-center pt-10">
            <button type="submit" className="submit_button">
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddClientPriceInfo;
