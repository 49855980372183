import React, { useState } from "react";
import BackButton from "./../../../../shared/BackButton";
import {
  commonBankColumnSpan,
  commonFormTitle,
  dashboardBodyWraper,
  errorMessage,
} from "../../../../asset/commoncssConstant/CommonCssConstant";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { baseUrl } from "../../../../BaseUrl";

const EditCsMutation = () => {
  const [defaultData, setDefaultData] = useState(null);
  const dagKhaytanForSelect = useLocation().state.dagKhaytanForSelect;
  const item = useLocation().state.item;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  //-------get data by id select option start---------------
  const handleSelectById = (e) => {
    e.preventDefault();
    setDefaultData(
      dagKhaytanForSelect?.find((list) => list.cs_dag_no === e.target.value)
    );
  };
  //-------get data by id select option end---------------

  //--------landMutationBank/csMutationList/update start---------
  const onSubmit = (data) => {
    data.id = item.id;
    data.cs_id = defaultData?.id || item.cs_info.id;
    axios
      .post(baseUrl + `/landMutationBank/csMutationList/update`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          navigate("/dashboard/cs-mutation-list");
        }
      });
  };
  //--------landMutationBank/csMutationList/update end---------
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BackButton title={"CS Mutation Information"} />
      <div className={`${dashboardBodyWraper} py-[4px] `}>
        <p className={commonFormTitle}>Land Information</p>
        <div className="grid gap-4 px-4 md:px-4 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
          <div className="common_input_wraper">
            <label>CS Dag *</label>
            <select onChange={(e) => handleSelectById(e)}>
              <option value={defaultData?.cs_dag_no || item.cs_info.cs_dag_no}>
                {defaultData?.cs_dag_no || item.cs_info.cs_dag_no}
              </option>
              {dagKhaytanForSelect?.map((list) => (
                <option key={list.id} value={list.cs_dag_no}>
                  {list.cs_dag_no}
                </option>
              ))}
            </select>
          </div>
          <div className="common_input_wraper">
            <label>CS Khatiyan</label>
            <input
              type="text"
              placeholder="CS Khatiyan"
              readOnly
              disabled
              value={defaultData?.cs_khatiyan_no || item.cs_info.cs_khatiyan_no}
            />
          </div>
          <div className="common_input_wraper">
            <label>Mutation Date</label>
            <input
              {...register("mutation_date", {
                required: "Mutation Date is required",
              })}
              type="date"
              defaultValue={item.mutation_date}
            />
            {errors?.mutation_date && (
              <p className={errorMessage}>{errors?.mutation_date?.message}</p>
            )}
          </div>
          <div className="common_input_wraper">
            <label>Mutation No</label>
            <input
              {...register(`mutation_no`, {
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Insert valid mutation no.",
                },
              })}
              type="text"
              placeholder="Mutation No"
              defaultValue={item.mutation_no}
            />
            {errors?.mutation_no && (
              <p className={errorMessage}>{errors?.mutation_no?.message}</p>
            )}
          </div>
          <div className="common_input_wraper">
            <label>Mutation Land Size</label>
            <input
               {...register(`land_size`, {
                pattern: {
                  value: /^[0-9]+(\.[0-9]+)?$/,
                  message: "Insert valid land size",
                },
              })}
           type="text"
              step="0.01"
              defaultValue={item.land_size}
            />
            {errors?.land_size && (
              <p className={errorMessage}>{errors?.land_size?.message}</p>
            )}
          </div>
          <div className="common_input_wraper">
            <label>Mutation Owner Name</label>
            <input
               {...register(`owner_name`, {
                pattern: {
                  value: /^[A-Za-z]+[A-Za-z0-9\-_~(\s]*[A-Za-z0-9\s)]$/,
                  message: "Insert valid owner name",
                },
              })}
              type="text"
              placeholder="Owner Name"
              defaultValue={item.owner_name}
            />
            {errors?.owner_name && (
              <p className={errorMessage}>{errors?.owner_name?.message}</p>
            )}
          </div>
          <div className="common_input_wraper">
            <label>Reference Deed No (if any)</label>
            <input
              {...register("ref_deed_no", {
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Insert valid deed no.",
                },
              })}
              type="text"
              placeholder="Reference Deed No"
              defaultValue={item.ref_deed_no}
            />
            {errors?.ref_deed_no && (
              <p className={errorMessage}>{errors?.ref_deed_no?.message}</p>
            )}
          </div>
          <div className="common_input_wraper">
            <label>Reference Deed No Date (if any)</label>
            <input
              {...register(`ref_deed_no`)}
              type="date"
              defaultValue={item.ref_deed_no_date}
            />
          </div>
          <div className="common_input_wraper">
            <label>Misscase No (if any)</label>
            <input
              {...register(`misscase_no`, {
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Insert valid misscase no.",
                },
              })}
              type="text"
              placeholder="Misscase No"
              defaultValue={item.misscase_no}
            />
            {errors?.misscase_no && (
              <p className={errorMessage}>{errors?.misscase_no?.message}</p>
            )}
          </div>
          <div className="common_input_wraper">
            <label>Misscase Date (if any)</label>
            <input
              {...register("misscase_date")}
              type="date"
              defaultValue={item.misscase_date}
            />
          </div>
          <div className="common_input_wraper">
            <label>Misscase Judgment date (if any)</label>
            <input
              {...register("misscase_judgement_date")}
              type="date"
              defaultValue={item.misscase_judgement_date}
            />
          </div>
          <div className="col-span-1 md:col-span-2 lg:columns-3 xl:col-span-4"></div>
          <div className={`common_input_wraper ${commonBankColumnSpan}`}>
            <label>Description</label>
            <textarea
              {...register("description")}
              rows={4}
              type="text"
              placeholder="Mutation Complete Summery , Misscase Judgement Complete Summery "
            />
          </div>
        </div>

        <div className="text-center py-12">
          <button type="submit" className="submit_button">
            Update
          </button>
        </div>
      </div>
    </form>
  );
};

export default EditCsMutation;
