import React, { useEffect, useState } from "react";
import CommonPrintPdfBtn from "../../../shared/CommonPrintPdfBtn";
import {
  addCustomerCss,
  commonAction,
  commonAddButton,
  commonCaption,
  commonSearchInput,
  dashboardBodyWraper,
} from "../../../asset/commoncssConstant/CommonCssConstant";
import Pagination from "../../../shared/pagination/Pagination";
import { Link } from "react-router-dom";
import ActionImg from "../../../asset/images/clientlist/action.png";
import ViewImg from "../../../asset/images/clientlist/view.png";
import AddUserImg from "../../../asset/images/clientlist/add-user.png";
import axios from "axios";
import { baseUrl } from "../../../BaseUrl";
import { LuArrowDownUp } from "react-icons/lu";
import {
  commonTableHeadingOne,
  commonTableRow,
  commonTitle,
} from "../../../asset/commoncssConstant/CommonCssConstant";
import useCommonPDF from "../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../shared/useCommonPrint/useCommonPrint";

const SellerAgreementList = () => {
  const [sellerAgreementList, setSellerAgreementList] = useState([]);
  const [dolilVaritationForSelect, setDolilVaritationForSelect] = useState([]);
  const [bsInfoForSelect, setBsInfoForSelect] = useState([]);
  const [landSellerForSelect, setLandSellerForSelect] = useState([]);
  const [landBokerForSelect, setLandBokerForSelect] = useState([]);
  const token = localStorage.getItem("token");
  const [searchValue, setSearchValue] = useState("");
  const [paginationData, setPaginationData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const { toPDF, targetRef } = useCommonPDF({ title: "seller-agreement-list" });

  const handlePrint = useCommonPrint({
    targetRef,
    title: "seller-agreement-list",
    message: "thank you",
  });

  // pagination part start here
  const handleNext = () => {
    if (page === pageCount) {
      return page;
    } else {
      return setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page === 1) {
      return page;
    } else {
      return setPage(page - 1);
    }
  };

  //------------get data landSeller/sellerAgreement/view, dolilVaritation/list, landInformationBank/bsDagKhatiyan/view, /landSeller/view,  /landBroker/view start------------
  useEffect(() => {
    axios
      .get(baseUrl + `/landSeller/sellerAgreement/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setSellerAgreementList(res.data.data);
      });
    axios
      .get(baseUrl + `/landInformationBank/bsDagKhatiyan/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setBsInfoForSelect(res.data.data);
      });
    axios
      .get(baseUrl + `/landSeller/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLandSellerForSelect(res.data.data);
      });
    axios
      .get(baseUrl + `/dolilVaritation/list`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setDolilVaritationForSelect(res.data.data);
      });
    axios
      .get(baseUrl + `/landBroker/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLandBokerForSelect(res.data.data);
      });
  }, [token, page]);
  //------------get data landSeller/sellerAgreement/view, dolilVaritation/list, landInformationBank/bsDagKhatiyan/view, /landSeller/view,  /landBroker/view  end------------

  return (
    <div>
      <h1 className={`${commonTitle}`}>Land Seller Agreement</h1>
      <div className={dashboardBodyWraper}>
        <div className={commonAddButton}>
          <Link
            to={`/dashboard/sellerAgreement/add`}
            state={{
              bsInfoForSelect,
              landSellerForSelect,
              dolilVaritationForSelect,
              landBokerForSelect,
            }}
          >
            <button className={addCustomerCss}>
              <span>Add Land Seller Agreement</span>
              <img className="ml-[30px]" src={AddUserImg} alt="" />
            </button>
          </Link>
        </div>
        <div className={commonCaption}>
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} />
          <input
            onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
            type="text"
            // placeholder="Search Seller Name / NID"
            placeholder="Search Seller Name"
            className={commonSearchInput}
          />
        </div>

        <div ref={targetRef} className="table_responsive mt-[40px]">
          <table>
            <thead>
              <tr className={`${commonTableRow}`}>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span>join Date</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span> Seller Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span> Father Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span> Dolil Variation</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span>Agreement Date</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span>Purchase Deed No</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span>Land Price (Per Katha) </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span>Total Land Price </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span> Seller Land ize (Katha)</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span> BS Dag</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span> BS Khatiyan</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span> Project Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span> Land Broker Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span> Land Broker Money</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span>
                      {" "}
                      Action <br />
                      Update / View
                    </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {paginationData
                ?.filter((info) => {
                  return searchValue.toLowerCase() !== ""
                    ? info?.seller_info
                        ?.map((seller) => {
                          return seller?.name;
                        })
                        ?.join("")
                        .toLowerCase()
                        .includes(searchValue)
                    : info;
                })
                ?.map((item, i) => (
                  <tr className="text-center" key={item.id}>
                    <td>
                      {" "}
                      {item?.seller_info?.map((seller, i) => (
                        <span key={seller?.id}>{seller?.join_date}</span>
                      ))}
                    </td>
                    <td>
                      {item?.seller_info?.map((seller) => (
                        <span key={seller?.id}>{seller?.name}</span>
                      ))}
                    </td>
                    <td>
                      {item?.seller_info?.map((seller) => (
                        <span key={seller?.id}>{seller?.father_name}</span>
                      ))}
                    </td>
                    <td>{item?.dolil_variation?.name}</td>
                    <td>{item?.agreement_date}</td>
                    <td>{item?.purchase_deed_no}</td>
                    <td>{item?.price_info?.price_per_katha}</td>
                    <td>{item?.price_info?.total_price}</td>
                    <td>{item?.land_size_katha}</td>
                    <td>
                      {item?.bs_info?.map((bs) => (
                        <span key={bs?.id}>{bs?.bs_dag_no}</span>
                      ))}
                    </td>
                    <td>
                      {item?.bs_info?.map((bs) => (
                        <span key={bs?.id}>{bs?.bs_khatiyan_no}</span>
                      ))}
                    </td>
                    <td>
                      {item?.bs_info?.map((bs, i) => (
                        <span key={bs?.id}>
                          {
                            bs?.rs_info?.sa_info?.cs_info?.mouza_info
                              ?.project_info?.project_name
                          }
                        </span>
                      ))}
                    </td>
                    <td>{item?.land_broker?.name}</td>
                    <td>{item?.broker_price_info?.broker_money}</td>
                    <td>
                      <span className="flex justify-center gap-2">
                        <Link
                          to={`/dashboard/sellerAgreement/edit`}
                          state={{
                            item: item,
                            bsInfoForSelect: bsInfoForSelect,
                            landSellerForSelect: landSellerForSelect,
                            dolilVaritationForSelect: dolilVaritationForSelect,
                            landBokerForSelect: landBokerForSelect,
                          }}
                        >
                          <img
                            className={commonAction}
                            src={ActionImg}
                            alt=""
                          />
                        </Link>
                        <Link
                          to={`/dashboard/sellerAgreement/view`}
                          state={{
                            item: item,
                          }}
                        >
                          <img className={commonAction} src={ViewImg} alt="" />
                        </Link>
                      </span>
                    </td>
                    {/* <td>
                      <img className="mx-auto" src={BFolderImg} alt="" />
                    </td> */}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <Pagination
          data={sellerAgreementList}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          setPageCount={setPageCount}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      </div>
    </div>
  );
};

export default SellerAgreementList;
