import React, { useEffect, useState } from "react";
import ViewBtn from "../../asset/images/clientlist/view.png";
import Pagination from "../../shared/pagination/Pagination";
import CommonPrintPdfBtn from "../../shared/CommonPrintPdfBtn";
import {
  commonAction,
  commonCaption,
  commonSearchInput,
  commonTableHeadingTwo,
  commonTitle,
  dashboardBodyWraper,
} from "../../asset/commoncssConstant/CommonCssConstant";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../BaseUrl";
import { LuArrowDownUp } from "react-icons/lu";
import useCommonPDF from "../../shared/useCommonPDF/useCommonPDF";

const ClientPlotDetailsList = () => {
  const token = localStorage.getItem("token");
  const [customers, setCustomers] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [paginationData, setPaginationData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const { toPDF, targetRef } = useCommonPDF({
    title: "client-plot-details",
  });

  // pagination part start here
  const handleNext = () => {
    if (page === pageCount) {
      return page;
    } else {
      return setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page === 1) {
      return page;
    } else {
      return setPage(page - 1);
    }
  };

  useEffect(() => {
    axios
      .get(baseUrl + `/customer/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setCustomers(res.data.data);
      });
  }, [token, page]);

  return (
    <div>
      <h1 className={commonTitle}>Client Plot Details List</h1>
      <div className={dashboardBodyWraper}>
        <div className={commonCaption}>
          <CommonPrintPdfBtn toPDF={toPDF} />
          <input
            onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
            type="text"
            placeholder="Search"
            className={commonSearchInput}
          />
        </div>

        <div ref={targetRef} className="table_responsive mt-[40px]">
          <table>
            <thead className="uppercase">
              <tr className="text-[#e3e4e7] text-center bg-[#0C768A]">
                <th>
                  <div className={commonTableHeadingTwo}>
                    <span>File / Plot No</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={commonTableHeadingTwo}>
                    <span>Booking Date</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={commonTableHeadingTwo}>
                    <span>Customer’s Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={commonTableHeadingTwo}>
                    <span>Project Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={commonTableHeadingTwo}>
                    <span>Road No</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={commonTableHeadingTwo}>
                    <span>Plot Facing</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={commonTableHeadingTwo}>
                    <span>Sector</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={commonTableHeadingTwo}>
                    <span> Plot Size (Katha)</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={commonTableHeadingTwo}>
                    <span>
                      {" "}
                      Plot Size <br /> (Ojutangsho)
                    </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={commonTableHeadingTwo}>
                    <span>Plot Dimension</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={commonTableHeadingTwo}>
                    <span> Register Date</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={commonTableHeadingTwo}>
                    <span>View</span>{" "}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {paginationData
                ?.filter((info) => {
                  return searchValue.toLowerCase() !== ""
                    ? info?.plot_info?.file_no
                        .toLowerCase()
                        .includes(searchValue) ||
                        info?.booking_date
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.customer_info
                          ?.map((c) => {
                            return c.name;
                          })
                          .join(" ")
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.plot_info?.project_name
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.plot_info?.road_number_name
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.plot_info?.sector
                          .toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.plot_info?.plot_size_katha
                          .toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.plot_info?.plot_size_ojutangsho
                          .toString()
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.plot_info?.plot_dimension
                          .toString()
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.expected_reg_date
                          ?.toLowerCase()
                          .includes(searchValue)
                    : info;
                })
                .map((item) => {
                  return (
                    <tr key={item?.id} className="text-center">
                      <td>
                        <span className="font-bold">
                          {item?.plot_info?.file_no}
                        </span>
                      </td>
                      <td>{item?.booking_date}</td>
                      <td>
                        {item?.customer_info?.map((cus) => (
                          <p key={cus?.id}>{cus?.name}</p>
                        ))}
                      </td>
                      <td>{item?.plot_info?.project_name}</td>
                      <td>{item?.plot_info?.road_number_name}</td>
                      <td>{item?.plot_info?.plot_facing}</td>
                      <td>{item?.plot_info?.sector}</td>
                      <td>{item?.plot_info?.plot_size_katha}</td>
                      <td>{item?.plot_info?.plot_size_ojutangsho}</td>
                      <td>{item?.plot_info?.plot_dimension}</td>
                      <td>{item?.expected_reg_date}</td>
                      <td>
                        <Link
                          to="/dashboard/client-list/view"
                          state={{
                            clientData: item,
                          }}
                        >
                          <img
                            className={`${commonAction} mx-auto`}
                            src={ViewBtn}
                            alt=""
                          />
                        </Link>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        <Pagination
          data={customers}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          setPageCount={setPageCount}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      </div>
    </div>
  );
};

export default ClientPlotDetailsList;
