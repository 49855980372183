import React from "react";
import BackButton from "../../../../shared/BackButton";
import CommonPrintPdfBtn from "../../../../shared/CommonPrintPdfBtn";
import { useLocation } from "react-router-dom";
import useCommonPDF from "../../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../../shared/useCommonPrint/useCommonPrint";

const ViewLandBrokerWork = () => {
  const item = useLocation().state.item;
  const { toPDF, targetRef } = useCommonPDF({ title: "broker-work-details" });

  const handlePrint = useCommonPrint({
    targetRef,
    title: "broker-work-details",
    message: "thank you",
  });
  return (
    <div>
      <BackButton title={"Land Broker Work List View"} />
      <div className=" mt-3 gap-5">
        <div className="client_info_section p-[40px] ">
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} />
          <div ref={targetRef}>
            <h1 className="client_info_title mt-3">
              Land Broker Work Information
            </h1>

            <div className="agent_view_table  ">
              <table>
                <tbody>
                  <tr>
                    <td>Land Broker Agreement Date</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.agreement_date}</td>
                  </tr>
                  <tr>
                    <td>Land Broke ID</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.land_broker?.id}</td>
                  </tr>
                  <tr>
                    <td>Land Broke Name</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.land_broker?.name}</td>
                  </tr>
                  <tr>
                    <td>Land Broke Present Address</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.land_broker?.present_address}</td>
                  </tr>
                  <tr>
                    <td>Land Broke mobile no. 1</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.land_broker?.mobile_number_1}</td>
                  </tr>
                  <tr>
                    <td>Land Broke mobile no. 2</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.land_broker?.mobile_number_2}</td>
                  </tr>
                  <tr>
                    <td>Land Seller ID</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>
                      {item?.seller_info?.map((seller) => (
                        <div key={seller.id}>{seller.id}</div>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <td>Land Seller Name</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>
                      {item?.seller_info?.map((seller) => (
                        <div key={seller.id}>{seller.name}</div>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <td>Project Name</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>
                      {" "}
                      {item?.bs_info?.map((bs) => (
                        <div key={bs.id}>
                          {
                            bs?.rs_info?.sa_info?.cs_info?.mouza_info
                              ?.project_info?.project_name
                          }
                        </div>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <td>Total Price</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.price_info?.total_price}</td>
                  </tr>
                  <tr>
                    <td>Land Broke Money</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.broker_price_info?.broker_money}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewLandBrokerWork;
