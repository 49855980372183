import React, { useEffect, useState } from "react";
import {
  addCustomerCss,
  commonAction,
  commonAddButton,
  commonCaption,
  commonSearchInput,
  commonTableHeadingTwo,
  commonTableRow,
  commonTitle,
  dashboardBodyWraper,
} from "../../../asset/commoncssConstant/CommonCssConstant";
import CommonPrintPdfBtn from "../../../shared/CommonPrintPdfBtn";
import Pagination from "../../../shared/pagination/Pagination";
import AddUserImg from "../../../asset/images/clientlist/add-user.png";
import { Link } from "react-router-dom";
import ViewBtn from "../../../asset/images/clientlist/view.png";
import ActionImg from "../../../asset/images/clientlist/action.png";
import axios from "axios";
import { baseUrl } from "../../../BaseUrl";
import { LuArrowDownUp } from "react-icons/lu";
import useCommonPDF from "../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../shared/useCommonPrint/useCommonPrint";

const RsSubDeedList = () => {
  const [rsSubDeedList, setRsSubDeedList] = useState([]);
  const [dagKhaytanForSelect, setDagKhaytanForSelect] = useState([]);
  const token = localStorage.getItem("token");
  const [searchValue, setSearchValue] = useState("");

  const [paginationData, setPaginationData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const { toPDF, targetRef } = useCommonPDF({ title: "rs sub deed list" });

  const handlePrint = useCommonPrint({
    targetRef,
    title: "rs sub deed list",
    message: "thank you",
  });

  // pagination part start here
  const handleNext = () => {
    if (page === pageCount) {
      return page;
    } else {
      return setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page === 1) {
      return page;
    } else {
      return setPage(page - 1);
    }
  };

  //------get data for rsSubDeedList and rsDagKhatiyanList start-----------------
  useEffect(() => {
    axios
      .get(baseUrl + `/landSubDeedBank/rsSubDeed/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setRsSubDeedList(res.data.data);
      });
    axios
      .get(baseUrl + `/landInformationBank/rsDagKhatiyan/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setDagKhaytanForSelect(res.data.data);
      });
  }, [token, page]);
  //------get data for rsSubDeedList and rsDagKhatiyanList end-----------------
  return (
    <div>
      <h1 className={`${commonTitle}`}>RS Sub-deed</h1>
      <div className={dashboardBodyWraper}>
        <div className={commonAddButton}>
          <Link
            to={`/dashboard/rs-subdeed-list/add`}
            state={{
              dagKhaytanForSelect: dagKhaytanForSelect,
            }}
          >
            <button className={addCustomerCss}>
              <span>Add RS Sub-Deed</span>
              <img className="ml-[30px]" src={AddUserImg} alt="" />
            </button>
          </Link>
        </div>
        <div className={commonCaption}>
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} />
          <input
            onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
            type="text"
            placeholder="Search"
            className={commonSearchInput}
          />
        </div>

        <div ref={targetRef} className="table_responsive mt-[40px]">
          <table>
            <thead>
              <tr className={`${commonTableRow}`}>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>
                      {" "}
                      RS Sub-deed <br />
                      Create Date
                    </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> RS Dag</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> RS Khatiyan</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Sub-deed Date</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Sub-deed No</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>
                      {" "}
                      Sub-deed <br />
                      Land Size
                    </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>
                      {" "}
                      Sub-deed <br />
                      Registry Office
                    </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`py-2`}>
                    <span>
                      {" "}
                      Action <br />
                      Update / View
                    </span>{" "}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {paginationData
                ?.filter((info) => {
                  return searchValue.toLowerCase() !== ""
                    ? info?.created_at
                        ?.slice(0, 10)
                        .toLowerCase()
                        .includes(searchValue) ||
                        info?.rs_dag_info?.rs_dag_no
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.rs_dag_info?.rs_khatiyan_no
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.sub_deed_date
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.sub_deed_no
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.sub_deed_land_size
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.sub_deed_registry_office
                          ?.toLowerCase()
                          .includes(searchValue)
                    : info;
                })
                ?.map((item) => {
                  return (
                    <tr className="text-center" key={item.id}>
                      <td>{item?.created_at?.slice(0, 10)}</td>
                      <td>{item?.rs_dag_info?.rs_dag_no}</td>
                      <td>{item?.rs_dag_info?.rs_khatiyan_no}</td>
                      <td>{item?.sub_deed_date}</td>
                      <td>{item?.sub_deed_no}</td>
                      <td>{item?.sub_deed_land_size}</td>
                      <td>{item?.sub_deed_registry_office}</td>
                      <td>
                        <span className="flex gap-3 justify-center">
                          <Link
                            to={`/dashboard/rs-subdeed-list/edit`}
                            state={{
                              item: item,
                              dagKhaytanForSelect: dagKhaytanForSelect,
                            }}
                          >
                            <img
                              className={commonAction}
                              src={ActionImg}
                              alt=""
                            />
                          </Link>
                          <Link
                            to={`/dashboard/rs-subdeed-list/view`}
                            state={{
                              item: item,
                            }}
                          >
                            <img
                              className={commonAction}
                              src={ViewBtn}
                              alt=""
                            />
                          </Link>
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        <Pagination
          data={rsSubDeedList}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          setPageCount={setPageCount}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      </div>
    </div>
  );
};

export default RsSubDeedList;
