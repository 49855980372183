import React from "react";
import Avatar from "../../../../asset/images/clientlist/avater.png";
import { baseUrlImg } from "../../../../BaseUrl";
import {
  commonFormMenu,
  errorMessage,
} from "../../../../asset/commoncssConstant/CommonCssConstant";

const CustomersForm = ({ customer, i, register, errors }) => {
  return (
    <div className="py-5">
      <div className={commonFormMenu}>
        {/* <div className="common_input_wraper hidden">
          <label>Customer’s id </label>
          <input
            {...register(`customer[${i}].customer_id`)}
            defaultValue={customer?.id || ""}
            type="text"
          />
        </div> */}
        <div className="common_input_wraper">
          <label>Customer’s Name *</label>
          <input
            {...register(`customer[${i}].name`, {
              required: "Customer’s name is required",
              pattern: {
                value:  /^[A-Za-z]+[A-Za-z0-9\-_~(\s]*[A-Za-z0-9\s)]$/,
                message: "Insert valid name",
              },
            })}
            defaultValue={customer?.name || ""}
            type="text"
            placeholder="Customer’s Name"
          />
          {errors && errors.customer && errors.customer[i] && (
            <p className={errorMessage}>{errors?.customer[i]?.name?.message}</p>
          )}
        </div>
        <div className="common_input_wraper">
          <label>Father’s Name *</label>
          <input
            {...register(`customer[${i}].father_name`, {
              required: "Father’s name is required",
              pattern: {
                value:  /^[A-Za-z]+[A-Za-z0-9\-_~(\s]*[A-Za-z0-9\s)]$/,
                message: "Insert valid father name",
              },
            })}
            defaultValue={customer?.father_name || ""}
            type="text"
            placeholder="Father’s Name"
          />
          {errors && errors.customer && errors.customer[i] && (
            <p className={errorMessage}>
              {errors?.customer[i]?.father_name?.message}
            </p>
          )}
        </div>
        <div className="common_input_wraper">
          <label>Mother’s Name *</label>
          <input
            {...register(`customer[${i}].mother_name`, {
              required: "Mother’s name is required",
              pattern: {
                value:  /^[A-Za-z]+[A-Za-z0-9\-_~(\s]*[A-Za-z0-9\s)]$/,
                message: "Insert valid mother name",
              },
            })}
            defaultValue={customer?.mother_name || ""}
            type="text"
            placeholder="Mother’s Name"
          />
          {errors && errors.customer && errors.customer[i] && (
            <p className={errorMessage}>
              {errors?.customer[i]?.mother_name?.message}
            </p>
          )}
        </div>
        <div className="common_input_wraper">
          <label>Spouse Name</label>
          <input
            {...register(`customer[${i}].spouse_name`)}
            defaultValue={customer?.spouse_name || ""}
            type="text"
            placeholder="Spouse Name"
          />
        </div>

        <div className="common_input_wraper">
          <label>National ID Number *</label>
          <input
            {...register(`customer[${i}].nid`, {
              required: "NID number is required",
              pattern: {
                value: /^[0-9]+$/,
                message: "Insert valid NID number",
              },
            })}
            defaultValue={customer?.nid || ""}
            type="text"
            placeholder="National ID Number"
          />
          {errors && errors.customer && errors.customer[i] && (
            <p className={errorMessage}>{errors?.customer[i]?.nid?.message}</p>
          )}
        </div>
        <div className="common_input_wraper">
          <label>Date of Birth *</label>
          <input
            {...register(`customer[${i}].dob`, {
              required: "Date of Birth is required",
            })}
            defaultValue={customer?.dob || ""}
            type="date"
          />
          {errors && errors.customer && errors.customer[i] && (
            <p className={errorMessage}>{errors?.customer[i]?.dob?.message}</p>
          )}
        </div>
        <div className="common_input_wraper">
          <label>Customer Mobile No 1 *</label>
          <input
            {...register(`customer[${i}].mobile_number_1`, {
              required: "Mobile number is required",
              pattern: {
                value: /^(0|\+?[1-9][0-9]*)$/,
                message: "Insert country code before your number",
              },
            })}
            {...register(`customer[${i}].mobile_number_1`)}
            defaultValue={customer?.mobile_number_1 || ""}
            type="text"
            placeholder="Customer Mobile No"
          />
          {errors && errors.customer && errors.customer[i] && (
            <p className={errorMessage}>
              {errors?.customer[i]?.mobile_number_1?.message}
            </p>
          )}
        </div>
        <div className="common_input_wraper">
          <label>Customer Mobile No 2</label>
          <input
            {...register(`customer[${i}].mobile_number_2`, {
              pattern: {
                value: /^(0|\+?[1-9][0-9]*)$/,
                message: "Insert country code before your number",
              },
            })}
            defaultValue={customer?.mobile_number_2 || ""}
            type="text"
            placeholder="Customer Mobile No"
          />
          {errors && errors.customer && errors.customer[i] && (
            <p className={errorMessage}>
              {errors?.customer[i]?.mobile_number_2?.message}
            </p>
          )}
        </div>
        <div className="common_input_wraper">
          <label>Others File No.</label>
          <input
            {...register(`customer[${i}].other_file_no`)}
            defaultValue={customer?.other_file_no || ""}
            type="text"
            placeholder="Others File No."
          />
        </div>
        <div className="common_input_wraper">
          <label>Profession *</label>
          <input
            {...register(`customer[${i}].profession`, {
              required: "Profession is required",
              pattern: {
                value: /^[A-Za-z]+[A-Za-z0-9\-_~(\s]*[A-Za-z0-9\s)]$/,
                message: "Insert valid Profession",
              },
            })}
            defaultValue={customer?.profession || ""}
            type="text"
            placeholder="Profession"
          />
          {errors && errors.customer && errors.customer[i] && (
            <p className={errorMessage}>
              {errors?.customer[i]?.profession?.message}
            </p>
          )}
        </div>
        <div className="common_input_wraper">
          <label>Designation (optional)</label>
          <input
            type="text"
            {...register(`customer[${i}].designation`)}
            defaultValue={customer?.designation || ""}
            placeholder="Designation (optional)"
          />
        </div>
        <div className="common_input_wraper">
          <label>Email Address *</label>
          <input
            {...register(`customer[${i}].email`, {
              required: "Email Address is required",
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: "Insert valid email address",
              },
            })}
            defaultValue={customer?.email || ""}
            type="email"
            placeholder="Email Address"
          />
          {errors && errors.customer && errors.customer[i] && (
            <p className={errorMessage}>
              {errors?.customer[i]?.email?.message}
            </p>
          )}
        </div>
        <div className="common_input_wraper">
          <label>Mailing Address</label>
          <input
            {...register(`customer[${i}].mailing_address`)}
            defaultValue={customer?.mailing_address || ""}
            type="text"
            placeholder="Mailing Address"
          />
        </div>
        <div className="common_input_wraper">
          <label>Permanent Address *</label>
          <input
            {...register(`customer[${i}].permanent_address`, {
              required: "Permanent Address is required",
            })}
            defaultValue={customer?.permanent_address || ""}
            type="text"
            placeholder="Permanent Address"
          />
          {errors && errors.customer && errors.customer[i] && (
            <p className={errorMessage}>
              {errors?.customer[i]?.permanent_address?.message}
            </p>
          )}
        </div>
        <div className="common_input_wraper">
          <label>Office Address (optional)</label>
          <input
            {...register(`customer[${i}].office_address`)}
            defaultValue={customer?.office_address || ""}
            type="text"
            placeholder="Office Address "
          />
        </div>
        <div className="common_input_wraper">
          <label>Client’s Image (PP size 450x570) *</label>
          <input
            {...register(`customer[${i}].image`, {
              required: "Client’s Image is required",
            })}
            type="file"
          />
          {errors && errors.customer && errors.customer[i] && (
            <p className={errorMessage}>
              {errors?.customer[i]?.image?.message}
            </p>
          )}
        </div>
        <div className="flex items-end">
          <img
            className="h-[60px] w-[60px] rounded border p-1 object-cover"
            src={customer.image ? baseUrlImg + customer.image : Avatar}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default CustomersForm;
