import React, { useEffect, useState } from "react";
import BackButton from "./../../../../shared/BackButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { baseUrl } from "../../../../BaseUrl";
import {
  commonTableHeadingTwo,
  commonTableRow,
  commonUserRow,
  errorMessage,
} from "../../../../asset/commoncssConstant/CommonCssConstant";

const EditClientLandSellAgreementCompleteList = () => {
  const item = useLocation().state?.item;
  const dolilVariationList = useLocation().state?.dolilVariationList;
  const [dolilVariationId, setDolilVariationId] = useState(
    +item?.land_agreement?.dolil_variation?.id
  );
  const sellerAgreementList = useLocation().state?.sellerAgreementList;
  const [dataByPurchaseDeedId, setDataByPurchaseDeedId] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  // console.log(item);
  //--------dataByPurchaseDeedId for bs info table data show start-----------
  useEffect(() => {
    setDataByPurchaseDeedId(
      sellerAgreementList?.find(
        (data) => data.id === item?.land_agreement?.land_seller_agreement?.id
      )
    );
  }, [item?.land_agreement?.land_seller_agreement?.id, sellerAgreementList]);
  //--------dataByPurchaseDeedId for bs info table data show end-----------

  //---------handleDolilVariationSelect functionality start--------
  const handleDolilVariationSelect = (id) => {
    setDolilVariationId(+id);
  };
  //---------handleDolilVariationSelect functionality end--------

  //--------customer/landAgreement/update start---------
  const onSubmit = (data) => {
    data.customer_land_information_id = item?.id;
    data.id = item?.land_agreement?.id;
    data.land_seller_agreement_id =
      item?.land_agreement?.land_seller_agreement?.id;
    data.deed_no = item?.land_agreement?.deed_no;
    data.land_size_katha = item?.land_agreement?.land_size_katha;
    data.dolil_variation_id = dolilVariationId;
    if (data.dolil_variation_id === 4) {
      delete data.registry_office;
    }
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (value instanceof FileList) {
        for (let i = 0; i < value.length; i++) {
          formData.append(`${key}[${i}]`, value[i]);
        }
      } else {
        formData.append(key, value);
      }
    });
    axios
      .post(baseUrl + `/customer/landAgreement/update`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          navigate(-1);
        } else {
          alert(res.data.message);
        }
      });
  };
  //--------customer/landAgreement/update end---------
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <BackButton title={"Edit Client Land Sell Agreement"} />
        <div className="  mt-3 gap-5">
          <div className=" client_info_section p-[40px] shadow-lg">
            <div className="border border-[#d5d7d7] rounded-lg p-5 bg-[#F5F6F8] my-3">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                <div className={commonUserRow}>
                  <div>File / Plot No </div>
                  <div>:</div>
                  <div className="mx-3">
                    <p>{item?.plot_info?.file_no}</p>
                  </div>
                </div>
                <div className={commonUserRow}>
                  <div>Customer Name </div>
                  <div>:</div>
                  <div className="mx-3">
                    {item?.customer_info?.map((cus, i) => (
                      <span key={cus?.id}>
                        {cus?.name}
                        {i < item?.customer_info?.length - 1 ? ", " : ""}
                      </span>
                    ))}
                  </div>
                </div>
                <div className={commonUserRow}>
                  <div>Booking Date </div>
                  <div>:</div>
                  <div className="mx-3">
                    <p>{item?.booking_date}</p>
                  </div>
                </div>
                <div className={commonUserRow}>
                  <div>Registry Date </div>
                  <div>:</div>
                  <div className="mx-3">
                    <p>{item?.expected_reg_date}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              <div className="common_input_wraper">
                <label>Agreement Date</label>
                <input
                  {...register("agreement_date", {
                    required: "agreement date is required",
                  })}
                  type="date"
                  defaultValue={item?.land_agreement?.agreement_date}
                />
                {errors?.agreement_date && (
                  <p className={errorMessage}>
                    {errors?.agreement_date?.message}
                  </p>
                )}
              </div>
              <div className="common_input_wraper">
                <label>Land Size (Katha)</label>
                <input
                  value={item?.land_agreement?.land_size_katha}
                  disabled
                  readOnly
                />
              </div>
              <div className="common_input_wraper">
                <label>Land Size (Ojudangsho)</label>
                <input
                  {...register(`land_size_ojutangsho`, {
                    pattern: {
                      value: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Insert valid land size",
                    },
                  })}
                  type="text"
                  placeholder="Land Size (Ojudangsho)"
                  defaultValue={item?.land_agreement?.land_size_ojutangsho}
                />
                {errors?.land_size_ojutangsho && (
                  <p className={errorMessage}>
                    {errors?.land_size_ojutangsho?.message}
                  </p>
                )}
              </div>
              <div className="common_input_wraper">
                <label>Deed No</label>
                <input
                  value={item?.land_agreement?.deed_no}
                  disabled
                  readOnly
                />
              </div>
              <div className="common_input_wraper">
                <label>Land Registry Complete Date</label>
                <input
                  {...register("registry_complete_date")}
                  type="date"
                  defaultValue={item?.land_agreement?.registry_complete_date}
                />
              </div>
              <div className="common_input_wraper">
                <label>Land Registry Sub Deed No</label>
                <input
                  {...register(`registry_sub_deed_no`, {
                    pattern: {
                      value: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Insert valid sub deed",
                    },
                  })}
                  type="text"
                  placeholder="Land Registry Sub Deed No"
                  defaultValue={item?.land_agreement?.registry_sub_deed_no}
                />
                {errors?.registry_sub_deed_no && (
                  <p className={errorMessage}>
                    {errors?.registry_sub_deed_no?.message}
                  </p>
                )}
              </div>
              <div className="common_input_wraper">
                <label>Dolil Variation</label>
                <select
                  onChange={(e) => handleDolilVariationSelect(e.target.value)}
                >
                  <option
                    disabled
                    selected
                    defaultValue={item?.land_agreement?.dolil_variation?.id}
                  >
                    {item?.land_agreement?.dolil_variation?.name}
                  </option>
                  {dolilVariationList?.map((variation) => (
                    <option key={variation.id} value={variation.id}>
                      {variation.name}
                    </option>
                  ))}
                </select>
              </div>
              <div
                className={
                  dolilVariationId === 4 ? `hidden` : `common_input_wraper`
                }
              >
                <label>Registry Office</label>
                <input
                  {...register(`registry_office`, {
                    pattern: {
                      value: /^[A-Za-z]+[A-Za-z0-9\-_~(\s]*[A-Za-z0-9\s)]$/,
                      message: "Insert valid registry office",
                    },
                  })}
                  type="text"
                  placeholder="Registry Office"
                  defaultValue={item?.land_agreement?.registry_office}
                />
                {errors?.registry_office && (
                  <p className={errorMessage}>
                    {errors?.registry_office?.message}
                  </p>
                )}
              </div>
              <div className="common_input_wraper">
                <label>Namejari No</label>
                <input
                  {...register(`namejari_no`, {
                    pattern: {
                      value: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Insert valid namejari no.",
                    },
                  })}
                  type="text"
                  defaultValue={item?.land_agreement?.namejari_no}
                />
                {errors?.namejari_no && (
                  <p className={errorMessage}>{errors?.namejari_no?.message}</p>
                )}
              </div>
              <div className="common_input_wraper">
                <label>Namejari Date</label>
                <input
                  {...register("namejari_date")}
                  type="date"
                  defaultValue={item?.land_agreement?.namejari_date}
                />
              </div>
              <div className="common_input_wraper">
                <label>Documents</label>
                <input {...register("documents")} type="file" multiple />
              </div>
              <div className="col-span-1 md:col-span-2 lg:col-span-3"></div>
              <div className="common_input_wraper">
                <label>Description</label>
                <textarea
                  rows={4}
                  {...register("description")}
                  placeholder="Description"
                  defaultValue={item?.land_agreement?.description}
                ></textarea>
              </div>
            </div>
            <div className="pt-5 text-center">
              <button type="submit" className="submit_button">
                Update
              </button>
            </div>
            {dataByPurchaseDeedId && (
              <div className="table_responsive text-center mt-[30px]">
                <table>
                  <thead>
                    <tr className={commonTableRow}>
                      <th>
                        <div className={commonTableHeadingTwo}>BS Daag No</div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          {" "}
                          BS Khotiyan
                        </div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}>BS Size</div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}> RS Daag No</div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          {" "}
                          RS Khotiyan
                        </div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}>SA Dag No</div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          {" "}
                          SA Khatiyan
                        </div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}> CS Daag No</div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          {" "}
                          CS Khatiyan
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataByPurchaseDeedId?.bs_info?.map((bs) => {
                      return (
                        <tr key={bs?.id}>
                          <td>{bs?.bs_dag_no}</td>
                          <td>{bs?.bs_khatiyan_no}</td>
                          <td>{bs?.total_bs_area}</td>
                          <td>{bs?.rs_info?.rs_dag_no}</td>
                          <td>{bs?.rs_info?.rs_khatiyan_no}</td>
                          <td>{bs?.rs_info?.sa_info?.sa_dag_no}</td>
                          <td>{bs?.rs_info?.sa_info?.sa_khatiyan_no}</td>
                          <td>{bs?.rs_info?.sa_info?.cs_info?.cs_dag_no}</td>
                          <td>
                            {bs?.rs_info?.sa_info?.cs_info?.cs_khatiyan_no}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditClientLandSellAgreementCompleteList;
