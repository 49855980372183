import React, { useEffect, useState } from "react";
import Pagination from "../../../shared/pagination/Pagination";
import ViewImg from "../../../asset/images/clientlist/view.png";
import ActionImg from "../../../asset/images/clientlist/action.png";
import { Link } from "react-router-dom";
import CommonPrintPdfBtn from "../../../shared/CommonPrintPdfBtn";
import axios from "axios";
import { baseUrl } from "../../../BaseUrl";
import { LuArrowDownUp } from "react-icons/lu";
import {
  commonAction,
  // commonCount,
  commonSearchFiled,
  commonTableHeadingTwo,
  commonTableRow,
  commonTitle,
} from "../../../asset/commoncssConstant/CommonCssConstant";
import useCommonPDF from "../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../shared/useCommonPrint/useCommonPrint";

const ClientLandSellRegisteredBaynaList = () => {
  const [lists, setLists] = useState([]);
  const token = localStorage.getItem("token");
  const [dolilVariationList, setDolilVariationList] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const [paginationData, setPaginationData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  
  const { toPDF, targetRef } = useCommonPDF({
    title: "register-bayna-list",
  });

  const handlePrint = useCommonPrint({
    targetRef,
    title: "sell register bayna list",
    message: "thank you",
  });


  // pagination part start here
  const handleNext = () => {
    if (page === pageCount) {
      return page;
    } else {
      return setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page === 1) {
      return page;
    } else {
      return setPage(page - 1);
    }
  };

  useEffect(() => {
    axios
      .get(baseUrl + `/customer/landAgreement/viewByDolilVariation/3`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLists(res.data.data);
      });
    axios
      .get(baseUrl + `/dolilVaritation/list`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => setDolilVariationList(res.data.data));
  }, [token, page]);

  return (
    <>
      <h1 className={`${commonTitle}`}>
        Client Land Sell Registered Bayna List
      </h1>
      <div className="bg-[#FFFFFF]   rounded-[4px] px-[20px] py-[30px] my-[30px]">
        <div className="flex flex-wrap justify-between items-end gap-2 mt-[20px]">
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} />
          {/* <div className="flex justify-between items-center gap-2 order-3 md:order-2 flex-wrap">
            <div className={`${commonCount}`}>
              <span>Number of Deed </span>: <span>5000</span>
            </div>
            <div className={`${commonCount}`}>
              <span>Agreement Complete Number of Client </span>:{" "}
              <span>5000</span>
            </div>
            <div className={`${commonCount}`}>
              <span>Agreement Complete Land Size </span>: <span>5000</span>
            </div>
            <div className={`${commonCount}`}>
              <span>Available Land Size after Sell </span>: <span>5000</span>
            </div>
          </div> */}
          <input
            onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
            type="text"
            // placeholder="Select Search Type"
            placeholder="Search deed no"
            className={`${commonSearchFiled}`}
          />
        </div>

        <div ref={targetRef} className="table_responsive text-center mt-[40px]">
          <table>
            <thead>
              <tr className={`${commonTableRow}`}>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Agreement Date</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Deed No</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Customer Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>File No</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>
                      Land Size <br />
                      (ojudangsho)
                    </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> BS Dag</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>BS Khatiyan</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Project Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>
                      {" "}
                      Land Seller <br />
                      Deed No
                    </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>
                      {" "}
                      Land Seller <br />
                      Deed Date
                    </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>
                      Land Seller <br />
                      Dolil Variation
                    </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Land Seller Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>
                      {" "}
                      Last Seller deed <br />
                      Land Size
                    </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>
                      {" "}
                      Available Land <br />
                      Size After Sell
                    </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>
                      Action <br />
                      Add / Edit / View
                    </span>{" "}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {paginationData
                ?.filter((info) => {
                  return searchValue.toLowerCase() !== ""
                    ? info?.deed_no?.toString().toLowerCase().includes(searchValue)
                    : info;
                })
                .map((item) => (
                  <tr className="text-center" key={item?.id}>
                    <td>{item?.agreement_date}</td>
                    <td>{item?.deed_no}</td>
                    <td>
                      {item?.customer_land_info?.customer_info?.map((cus) => (
                        <span key={cus?.id}>{cus?.name}</span>
                      ))}
                    </td>
                    <td>{item?.customer_land_info?.plot_info?.file_no}</td>
                    <td>{item?.land_size_ojutangsho}</td>
                    <td>
                      {item?.land_seller_agreement?.bs_info?.map((bs) => (
                        <span key={bs?.id}>{bs?.bs_dag_no}</span>
                      ))}
                    </td>
                    <td>
                      {item?.land_seller_agreement?.bs_info?.map((bs) => (
                        <span key={bs?.id}>{bs?.bs_khatiyan_no}</span>
                      ))}
                    </td>
                    <td>{item?.customer_land_info?.plot_info?.project_name}</td>
                    <td>{item?.land_seller_agreement?.purchase_deed_no}</td>
                    <td>{item?.land_seller_agreement?.sub_deed_date}</td>
                    <td>
                      {item?.land_seller_agreement?.dolil_variation?.name}
                    </td>
                    <td>
                      {item?.land_seller_agreement?.seller_info?.map(
                        (seller) => (
                          <span key={seller?.id}>{seller?.name}</span>
                        )
                      )}
                    </td>
                    <td>{item?.land_seller_agreement?.land_size_katha}</td>
                    <td>
                      {item?.land_seller_agreement?.land_size_katha -
                        item?.land_size_katha}
                    </td>

                    <td className="w-[230px]">
                      <div className="flex gap-3 justify-center">
                        <Link
                          to={`/dashboard/client-land-sell-details/edit`}
                          state={{
                            item,
                            dolilVariationList,
                          }}
                        >
                          <img
                            className={commonAction}
                            src={ActionImg}
                            alt=""
                          />
                        </Link>
                        <Link
                          to={`/dashboard/client-land-sell-details/view`}
                          state={{
                            item: item,
                          }}
                        >
                          <img className={commonAction} src={ViewImg} alt="" />
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <Pagination
          data={lists}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          setPageCount={setPageCount}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      </div>
    </>
  );
};

export default ClientLandSellRegisteredBaynaList;
