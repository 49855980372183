import React from "react";
import { useLocation } from "react-router-dom";
import FileImage from "../../../asset/images/addimage.png";
import BackButton from "../../../shared/BackButton";
import CommonPrintPdfBtn from "../../../shared/CommonPrintPdfBtn";
import useCommonPDF from "../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../shared/useCommonPrint/useCommonPrint";

const ViewClientLandSellDetails = () => {
  const { item } = useLocation()?.state ?? {};


  const { toPDF, targetRef } = useCommonPDF({
    title: "land-sell-details",
  });

  const handlePrint = useCommonPrint({
    targetRef,
    title: "land-sell-details",
    message: "thank you",
  });


  return (
    <div>
      <BackButton title={"Previous Land  Summery"} />

      <div className="grid lg:grid-cols-12  mt-3 gap-5">
        <div
          ref={targetRef}
          className="col-span-9 order-2 lg:order-1 client_info_section p-[40px]"
        >
          <div className="client_info_section p-[30px]">
            <div className="grid grid-cols-1 md:grid-cols-2">
              <div className="agent_view_table">
                <table>
                  <tbody>
                    <tr>
                      <td>Agreement Date</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{item?.agreement_date}</td>
                    </tr>
                    <tr>
                      <td>Deed No</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{item?.deed_no}</td>
                    </tr>
                    <tr>
                      <td>Customer Name</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>
                        {item?.customer_land_info?.customer_info?.map(
                          (cus, i) => (
                            <span key={cus?.id}>
                              {cus?.name}
                              {i <
                              item?.customer_land_info?.customer_info?.length -
                                1
                                ? ", "
                                : ""}
                            </span>
                          )
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>File No / Plot No</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{item?.customer_land_info?.plot_info?.file_no}</td>
                    </tr>
                    <tr>
                      <td>Dolil Variation</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>
                        {item?.land_seller_agreement?.dolil_variation?.name}
                      </td>
                    </tr>
                    <tr>
                      <td>Land Size (ojudangsho)</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{item?.land_size_ojutangsho}</td>
                    </tr>
                    <tr>
                      <td>BS Dag</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>
                        {item?.land_seller_agreement?.bs_info?.map((bs, i) => (
                          <span key={bs?.id}>
                            {bs?.bs_dag_no}
                            {i <
                            item?.land_seller_agreement?.bs_info?.length - 1
                              ? ", "
                              : ""}
                          </span>
                        ))}
                      </td>
                    </tr>
                    <tr>
                      <td>BS Khatiyan</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>
                        {item?.land_seller_agreement?.bs_info?.map((bs, i) => (
                          <span key={bs?.id}>
                            {bs?.bs_khatiyan_no}
                            {i <
                            item?.land_seller_agreement?.bs_info?.length - 1
                              ? ", "
                              : ""}
                          </span>
                        ))}
                      </td>
                    </tr>
                    <tr>
                      <td>Project Name</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>
                        {item?.customer_land_info?.plot_info?.project_name}
                      </td>
                    </tr>
                    <tr>
                      <td>Land Seller Deed No</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{item?.land_seller_agreement?.purchase_deed_no}</td>
                    </tr>
                    <tr>
                      <td>Land Seller Deed Date</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{item?.land_seller_agreement?.sub_deed_date}</td>
                    </tr>
                    <tr>
                      <td>Land Seller Dolil Variation</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>
                        {item?.land_seller_agreement?.dolil_variation?.name}
                      </td>
                    </tr>
                    <tr>
                      <td>Land Seller Name</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>
                        {item?.land_seller_agreement?.seller_info?.map(
                          (seller, i) => (
                            <span key={seller?.id}>
                              {seller?.name}
                              {i <
                              item?.land_seller_agreement?.seller_info?.length -
                                1
                                ? ", "
                                : ""}
                            </span>
                          )
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Land Seller deed Land Size</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{item?.land_seller_agreement?.land_size_katha}</td>
                    </tr>
                    <tr>
                      <td>Available Land Size After Sell</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>
                        {item?.land_seller_agreement?.land_size_katha -
                          item?.land_size_katha}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="agent_view_table">
                <h1 className="text-[14px] font-[700] text-[#717A82] pb-2">
                  Supported Document
                </h1>
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
                  {[...Array(10).keys()].map((person) => {
                    return <img src={FileImage} alt="" />;
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* right side button group  */}
        <div className="col-span-3 order-1 lg:order-2  ">
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} isBlock={true} />
        </div>
      </div>
    </div>
  );
};

export default ViewClientLandSellDetails;
