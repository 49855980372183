import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PlusImg from "../../../asset/images/clientpaymentinformation/plus.png";
import "../clientPriceInfoCommonDesign/clientPriceInfoCommonDesign.css";
import axios from "axios";
import { baseUrl } from "../../../BaseUrl";
import { useForm } from "react-hook-form";
import {
  commonUserInfo,
  commonUserRow,
  errorMessage,
} from "../../../asset/commoncssConstant/CommonCssConstant";
import BackButton from "../../../shared/BackButton";

const UpdateClientPriceInfo = () => {
  const item = useLocation().state.item;
  const [numberOfDownpayment, setNumberOfDownpayment] = useState(
    item?.customer_land_price?.downpayment
  );
  const [numberOfInstallment, setNumberOfInstallment] = useState(
    item?.customer_land_price?.installment
  );
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const token = localStorage.getItem("token");

  //---------work for when numberOfDownpayment and numberOfInstallment array empty start---------------------
  useEffect(() => {
    if (numberOfDownpayment.length < 1) {
      setNumberOfDownpayment([
        ...numberOfDownpayment,
        {
          id: "",
          amount: "",
          downpayment_no: "",
          start_date: "",
        },
      ]);
    }
    if (numberOfInstallment.length < 1) {
      let array = [...numberOfInstallment];
      for (let i = 0; i < item.customer_land_price.no_of_installment; i++) {
        array.push({
          amount: "",
          customer_land_price_infos_id: "",
          id: "",
          installment_no: "",
          paid: "",
          start_date: "",
        });
      }
      setNumberOfInstallment(array);
    }
  }, [
    numberOfDownpayment,
    numberOfInstallment,
    item.customer_land_price.no_of_installment,
  ]);
  //---------work for when numberOfDownpayment and numberOfInstallment array empty end---------------------

  //-----------handleDownpayment functionality start--------------
  const handleDownpayment = () => {
    setNumberOfDownpayment([
      ...numberOfDownpayment,
      {
        id: "",
        amount: "",
        downpayment_no: "",
        start_date: "",
      },
    ]);
  };
  //-----------handleDownpayment functionality end--------------
  //--------customer/priceInformation/update start---------
  const onSubmit = (data) => {
    data.customer_land_info_id = item?.id;
    data.customer_land_price_infos_id = item?.customer_land_price?.id;
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (typeof value == "object") {
        if (key === "downpayment") {
          for (let i = 0; i < value.length; i++) {
            let names = Object.keys(value[i]);
            names.forEach((name) => {
              formData.append(`downpayment[${i}][${name}]`, value[i][name]);
            });
          }
        }
        if (key === "installment") {
          for (let i = 0; i < value.length; i++) {
            let names = Object.keys(value[i]);
            names.forEach((name) => {
              formData.append(`installment[${i}][${name}]`, value[i][name]);
            });
          }
        }
      } else {
        formData.append(key, value);
      }
    });

    axios
      .post(baseUrl + `/customer/priceInformation/update`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          navigate("/dashboard/client-price-information");
        }
      });
  };
  //--------customer/priceInformation/update end---------
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <BackButton title={"Update Client Payment"} />
        <div className="bg-[#FFFFFF]   rounded-[4px] px-[20px] py-[30px] my-[30px]">
          <button
            type="button"
            className="bg-gradient-to-r from-[#0C768A] via-[#1e788a] to-[#2896a9] font-bold text-white px-[50px] py-[5px] rounded-lg pointer-events-none"
          >
            Previously Submitted Informations
          </button>
          <div className={commonUserInfo}>
            <div className={commonUserRow}>
              <div>Customer Name </div>
              <div>:</div>
              <div>
                {" "}
                {item?.customer_info?.map((cus) => (
                  <p key={cus?.id}>{cus?.name}</p>
                ))}
              </div>
            </div>
            <div className={commonUserRow}>
              <div>Project Name </div>
              <div>:</div>
              <div>
                <p>{item?.plot_info?.project_name}</p>
              </div>
            </div>
            <div className={commonUserRow}>
              <div>File / Plot No </div>
              <div>:</div>
              <div>
                <p>{item?.plot_info?.file_no}</p>
              </div>
            </div>
            <div className={commonUserRow}>
              <div>Land Size (Katha)</div>
              <div>:</div>
              <div>
                <p>{item?.plot_info?.plot_size_katha}</p>
              </div>
            </div>
            <div className={commonUserRow}>
              <div>Land Size (Ojutangsho)</div>
              <div>:</div>
              <div>
                <p>{item?.plot_info?.plot_size_ojutangsho}</p>
              </div>
            </div>
            <div className={commonUserRow}>
              <div>Total Price </div>
              <div>:</div>
              <div>
                <p>{item?.customer_land_price?.total_amount} TK</p>
              </div>
            </div>
            <div className={commonUserRow}>
              <div>Booking Money </div>
              <div>:</div>
              <div>
                <p>{item?.customer_land_price?.total_booking_money} TK</p>
              </div>
            </div>
            <div className={commonUserRow}>
              <div>Down payment</div>
              <div>:</div>
              <div>
                <p>{item?.customer_land_price?.total_downpayment_amount} TK</p>
              </div>
            </div>
            <div className={commonUserRow}>
              <div>Instalment Amount </div>
              <div>:</div>
              <div>
                <p>{item?.customer_land_price?.total_installment_amount} TK</p>
              </div>
            </div>
            <div className={commonUserRow}>
              <div>No of Instalment </div>
              <div>:</div>
              <div>
                <p>{item?.customer_land_price?.no_of_installment}</p>
              </div>
            </div>
            <div className={commonUserRow}>
              <div>Per Month Instalment </div>
              <div>:</div>
              <div>
                <p>{item?.customer_land_price?.per_month_installment} TK</p>
              </div>
            </div>
            <div className={commonUserRow}>
              <div>Registration Date </div>
              <div>:</div>
              <div>
                <p>{item?.expected_reg_date}</p>
              </div>
            </div>
          </div>
          <div className="grid xl:grid-cols-3 gap-5">
            <div className="instalment_money_box mt-5">
              <p className="imb_top_title">Booking Money Information</p>
              <div className="common_date_with_input">
                <div className="label_wraper">
                  <label>Booking Money</label>
                  <label>Start Date</label>
                </div>
                <div className="text_date_wraper">
                  <input
                    {...register("booking_money", {
                      pattern: {
                        value: /^[0-9]+(\.[0-9]+)?$/,
                        message: "Insert valid amount",
                      },
                    })}
                    type="text"
                    defaultValue={
                      item?.customer_land_price?.total_booking_money
                    }
                  />
                  <input
                    {...register("booking_money_date")}
                    type="date"
                    defaultValue={item?.customer_land_price?.booking_money_date}
                  />
                </div>
                {errors?.booking_money && (
                  <p className={errorMessage}>
                    {errors?.booking_money?.message}
                  </p>
                )}
              </div>
            </div>
            <div className="instalment_money_box mt-5">
              <p className="imb_top_title">Downpayment Information</p>
              {numberOfDownpayment?.map((item, i) => (
                <div key={item.id}>
                  <div className="common_date_with_input mb-4">
                    <div className="label_wraper">
                      <label>Downpayment {i + 1}</label>
                      <label>Start Date</label>
                    </div>
                    <div className="text_date_wraper">
                      <input
                        className="hidden"
                        {...register(`downpayment[${i}].id`)}
                        type="text"
                        defaultValue={item.id || ""}
                      />
                      <input
                        className="hidden"
                        {...register(`downpayment[${i}].downpayment_no`)}
                        type="text"
                        value={`Downpayment ${i + 1}`}
                      />
                      <input
                        {...register(`downpayment[${i}].amount`, {
                          pattern: {
                            value: /^[0-9]+(\.[0-9]+)?$/,
                            message: "Insert valid amount",
                          },
                        })}
                        type="text"
                        placeholder="Downpayment Amount"
                        defaultValue={item?.amount}
                      />
                      <input
                        {...register(`downpayment[${i}].start_date`)}
                        type="date"
                        defaultValue={item?.start_date}
                      />
                    </div>
                    {errors && errors.downpayment && errors.downpayment[i] && (
                      <p className={errorMessage}>
                        {errors?.downpayment[i]?.amount?.message}
                      </p>
                    )}
                  </div>
                </div>
              ))}
              <p onClick={handleDownpayment} className="plus_img_wraper">
                <img src={PlusImg} alt="" />
              </p>
            </div>
            <div className="instalment_money_box mt-5">
              <p className="imb_top_title">Installment Information</p>
              {numberOfInstallment?.map((item, i) => {
                return (
                  <div key={item.id}>
                    <div className="common_date_with_input mb-4">
                      <div className="label_wraper">
                        <label>Installment Money {i + 1}</label>
                        <label>Start Date</label>
                      </div>
                      <div className="text_date_wraper">
                        <input
                          className="hidden"
                          {...register(`installment[${i}].id`)}
                          type="text"
                          defaultValue={item.id || ""}
                        />
                        <input
                          className="hidden"
                          {...register(`installment[${i}].installment_no`)}
                          type="text"
                          value={`Installment ${i + 1}`}
                        />
                        <input
                          {...register(`installment[${i}].amount`, {
                            pattern: {
                              value: /^[0-9]+(\.[0-9]+)?$/,
                              message: "Insert valid amount",
                            },
                          })}
                          type="text"
                          placeholder="Installment Amount"
                          defaultValue={item?.amount}
                        />
                        <input
                          {...register(`installment[${i}].start_date`)}
                          type="date"
                          defaultValue={item?.start_date}
                        />
                      </div>
                      {errors &&
                        errors.installment &&
                        errors.installment[i] && (
                          <p className={errorMessage}>
                            {errors?.installment[i]?.amount?.message}
                          </p>
                        )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="text-center pt-10">
            <button type="submit" className="submit_button">
              Update
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default UpdateClientPriceInfo;
