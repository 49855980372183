import React from "react";
import Avatar from "../../../../../asset/images/clientlist/avater.png";
const SellerForm = ({ customer, i, register }) => {
  return (
    <div>
      <div className="grid gap-4 xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
        <div className="common_input_wraper">
          <label>Seller Name </label>
          <input
            {...register(`customer[${i}].name`)}
            type="text"
            placeholder="Seller Name "
          />
        </div>
        <div className="common_input_wraper">
          <label>Seller Ph No. 01</label>
          <input
            {...register(`customer[${i}].father_name`)}
            type="text"
            placeholder="Seller Ph No. 01"
          />
        </div>
        <div className="common_input_wraper">
          <label>Seller Ph No. 02</label>
          <input
            {...register(`customer[${i}].mother_name`)}
            type="text"
            placeholder="Seller Ph No. 02"
          />
        </div>
        <div className="common_input_wraper">
          <label>Seller NID No.</label>
          <input
            {...register(`customer[${i}].spouse_name`)}
            type="text"
            placeholder="Seller NID No."
          />
        
        </div>
        <div className="common_input_wraper">
          <label>Seller Father’s Namer</label>
          <input
            {...register(`customer[${i}].nid`)}
            type="text"
            placeholder="Seller Father’s Name"
          />
        </div>
        <div className="common_input_wraper">
          <label>Seller Mother’s Name</label>
          <input {...register(`customer[${i}].dob`)} type="text" placeholder="Seller Mother’s Name" />
        </div>
        <div className="common_input_wraper">
          <label>Seller NID Copy</label>
          <input
            {...register(`customer[${i}].mobile_number_1`)}
            type="file"
           
          />
        </div>
        
      </div>
    </div>
  );
};

export default SellerForm;
