import React, { Fragment, useState } from "react";
import {
  commonFormTitle,
  dashboardBodyWraper,
} from "../../../asset/commoncssConstant/CommonCssConstant";
import { useLocation, useNavigate } from "react-router-dom";
import LandInformationForm from "./form/LandInformationForm";
import LandPriceInformation from "./form/LandPriceInformation";
import AgentInformationForm from "./form/AgentInformationForm";
import CustomersForm from "./form/CustomersForm";
import { useForm } from "react-hook-form";
import axios from "axios";
import { baseUrl } from "../../../BaseUrl";
import BackButton from "./../../../shared/BackButton";
import { FaPlusCircle } from "react-icons/fa";

const AddClient = () => {
  const [customerForm, setCustomerForm] = useState([{ customers: "" }]);
  const [defaultLandInfo, setDefaultLandInfo] = useState(null);
  const [defaultAgentInfo, setDefaultAgentInfo] = useState(null);

  const plotList = useLocation().state.plotList;
  const agentList = useLocation().state.agentList;

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const token = localStorage.getItem("token");

  //-------get LandInfo data by id select option start---------------
  const handleSelectByLandInfoId = (e) => {
    e.preventDefault();
    setDefaultLandInfo(
      plotList?.find((list) => list.id === parseInt(e.target.value))
    );
  };
  //-------get LandInfo data by id select option end---------------
  //-------get AgentInfo data by id select option start---------------
  const handleSelectByAgentInfoId = (e) => {
    e.preventDefault();
    setDefaultAgentInfo(
      agentList?.find((list) => +list.id === +e.target.value)
    );
  };
  //-------get AgentInfo data by id select option end---------------

  //--------Add More Customer Form or delete functionality start---------
  const handleCustomerFormAdd = () => {
    setCustomerForm([...customerForm, { customers: "" }]);
  };
  const handleCustomerFormRemove = () => {
    setCustomerForm([...customerForm].slice(0, -1));
  };
  //--------Add More Customer Form or delete functionality end---------

  //--------------------customer Store start------------------------
  const onSubmit = (data) => {
    data.customer = data?.customer?.slice(0, customerForm?.length);
    data.plot_id = defaultLandInfo?.id;
    data.agentID = defaultAgentInfo?.id;
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (typeof value == "object") {
        if (key === "customer") {
          for (let i = 0; i < value.length; i++) {
            let names = Object.keys(value[i]);
            names.forEach((name) => {
              if (value[i][name] instanceof FileList) {
                formData.append(
                  `customer[${i}][${name}]`,
                  value[i][name].item(0)
                );
              } else {
                formData.append(`customer[${i}][${name}]`, value[i][name]);
              }
            });
          }
        }
        if (key === "agreements") {
          for (let i = 0; i < value.length; i++) {
            if (value instanceof FileList) {
              data[`agreements[${i}]`] = value[i];
              delete data.agreements;
              formData.append(`agreements[${i}]`, value[i]);
            }
          }
        }
      } else {
        formData.append(key, value);
      }
    });
    axios
      .post(baseUrl + `/customer/store`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          navigate("/dashboard/client-list");
        }
      });
  };
  //----------------------customer Store end------------------------
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BackButton title={"Add Client"} />
      <div className={`${dashboardBodyWraper} py-[4px] `}>
        <LandInformationForm
          errors={errors}
          register={register}
          plotList={plotList}
          handleSelectByLandInfoId={handleSelectByLandInfoId}
          defaultLandInfo={defaultLandInfo}
        />
        <p className={commonFormTitle}>Customer Information</p>
        {customerForm.map((customer, i) => (
          <Fragment key={i}>
            <CustomersForm
              register={register}
              customer={customer}
              i={i}
              errors={errors}
            />
            {(customerForm.length - 1 === i && customerForm.length) > 1 && (
              <button
                className="delete_customer mb-4"
                type="button"
                onClick={handleCustomerFormRemove}
              >
                Delete{" "}
              </button>
            )}
            {customerForm.length > 1 ? <hr className="" /> : null}
          </Fragment>
        ))}
        <div className="pt-5">
          <button
            type="button"
            className="add_customer"
            onClick={handleCustomerFormAdd}
          >
            Add More Customer{" "}
            <span className="plus">
              <FaPlusCircle />
            </span>
          </button>
        </div>

        <LandPriceInformation errors={errors} register={register} />

        <AgentInformationForm
          errors={errors}
          register={register}
          handleSelectByAgentInfoId={handleSelectByAgentInfoId}
          defaultAgentInfo={defaultAgentInfo}
          agentList={agentList}
        />

        <div className="text-center py-5">
          <button type="submit" className="submit_button">
            Submit
          </button>
        </div>
      </div>
    </form>
  );
};

export default AddClient;
