import React from "react";
import BackButton from "../../../shared/BackButton";
const LandSellerPaymentEdit = () => {
  return (
    <div>
      <BackButton title={"Edit Land Seller Payment"} />
      <div className="  mt-3 gap-5">
        <div className=" client_info_section p-[40px] shadow-lg">
          <form className="mt-10">
            <div className="lg:grid lg:grid-cols-3  gap-4">
              <div className="common_input_wraper">
                <label>File / Plot No.</label>
                <input disabled type="text" placeholder="File / Plot No." />
              </div>
              <div className="common_input_wraper">
                <label>Agreement Date</label>
                <input type="date" disabled />
              </div>
              <div className="common_input_wraper">
                <label>Land Seller Name</label>
                <input type="text" disabled placeholder=" Name" />
              </div>
              <div className="common_input_wraper">
                <label>Land Seller Id</label>
                <input type="text" disabled placeholder=" 5703352" />
              </div>
              <div className="common_input_wraper">
                <label>Project Name</label>
                <input type="text" disabled placeholder="Project Name" />
              </div>
            </div>
            <h1 className="text-[#717A82] font-[700] text-[18px] underline my-5">
              Payment Option
            </h1>
            <div className="lg:grid lg:grid-cols-4  gap-4">
              <div className="common_input_wraper">
                <label>Payment Date</label>
                <input type="date" />
              </div>
              <div className="common_input_wraper">
                <label>Pay By</label>
                <select name="" id="">
                  <option disabled value="">
                    Please Select
                  </option>
                  <option value="">Please Select</option>
                  <option value="">Please Select</option>
                  <option value="">Please Select</option>
                </select>
              </div>
              <div className="common_input_wraper">
                <label>Add Document Image</label>
                <input type="file" />
              </div>
              <div className="common_input_wraper">
                <label>Payment ID</label>
                <input type="text" placeholder="Type" />
              </div>
              <div className="common_input_wraper">
                <label>Money Receipt SL No. </label>
                <input type="text" placeholder="SL No" />
              </div>
              <div className="common_input_wraper">
                <label>Previous Due Amount </label>
                <input disabled type="text" placeholder="5703352" />
              </div>
              <div className="common_input_wraper">
                <label>Pay Amount </label>
                <input type="text" placeholder="Amount" />
              </div>
              <div className="common_input_wraper">
                <label>After payment Due Amount </label>
                <input disabled type="text" placeholder="5703352" />
              </div>
            </div>
            <div className="text-center pb-12 mt-10">
              <button type="submit" className="cancel_button mr-3">
                Cancel
              </button>
              <button type="submit" className="submit_button">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LandSellerPaymentEdit;
