import React from "react";
import Pagination from "../../shared/pagination/Pagination";
import CommonPrintPdfBtn from "../../shared/CommonPrintPdfBtn";
import AddImg from "../../asset/images/clientpaymentinformation/add.png";
import ViewImg from "../../asset/images/clientlist/view.png";
import ActionImg from "../../asset/images/clientlist/action.png";
import { useNavigate } from "react-router-dom";
import { commonSearchInput } from "../../asset/commoncssConstant/CommonCssConstant";
const ClientLandDescription = () => {
  const navigate = useNavigate();
  return (
    <div className="body_container">
      <h1 className="text-[#333547] font-[700] text-[24px]">
        Client Land Registry Complete (Land Description){" "}
      </h1>
      <div className="bg-[#FFFFFF]   rounded-[4px] px-[20px] py-[30px] my-[30px]">
        <div className="flex justify-between items-end mt-[20px]">
          <CommonPrintPdfBtn />

          <input
            type="text"
            placeholder="Search"
            className={commonSearchInput}
          />
        </div>

        <div className="table_responsive mt-[40px]">
          <table>
            <thead>
              <tr>
                <th className="text-[#00CA08] text-start">Customer’s Id</th>

                <th className="text-[#00CA08] text-start">Customer's Name</th>
                <th className="text-[#00CA08]">File / Plot No</th>

                <th className="text-[#00CA08] text-start">Project Name</th>
                <th className="text-[#00CA08]">Mouza Name</th>
                <th className="text-[#00CA08]">Land Size</th>
                <th className="text-[#00CA08]">Land Registry Complete Date</th>
                <th className="text-[#00CA08] text-start">
                  Land Registry Sub-deed No.
                </th>
                {/* <th className="text-[#00CA08]">Registered Land Size</th> */}

                <th className="text-[#00CA08]">Registry Office</th>
                <th className="text-[#00CA08] text-center">Namjari No</th>
                <th className="text-[#00CA08] text-center">Namjari Date</th>
                <th className="text-[#00CA08] text-center">CS Daag No.</th>
                <th className="text-[#00CA08] text-center">CS Khotian</th>
                <th className="text-[#00CA08] text-center">SA Daag No.</th>
                <th className="text-[#00CA08] text-center">SA Khotian</th>
                <th className="text-[#00CA08] text-center">RS Daag No.</th>
                <th className="text-[#00CA08] text-center">RS Khotian</th>
                <th className="text-[#00CA08] text-center">BS Daag No.</th>
                <th className="text-[#00CA08] text-center">BS Khotian</th>
                <th
                  style={{ width: "180px" }}
                  className="text-[#00CA08] text-center"
                >
                  Add/Edit Land Details
                </th>
                {/* <th
                  style={{ width: "120px" }}
                  className="text-[#00CA08] text-center"
                >
                  Previous Land Summery
                </th> */}
                {/* <th
                  style={{ width: "120px" }}
                  className="text-[#00CA08] text-center"
                >
                  Previous Land Dispute Solved by Court
                </th> */}
              </tr>
            </thead>
            <tbody>
              {[...Array(10).keys()].map((item, i) => (
                <tr key={i}>
                  <td>5248678</td>
                  <td>Mr. Abul </td>
                  <td>52A1C4</td>
                  <td>Munshiganj Project</td>
                  <td>Munshiganj Project</td>
                  <td>1091</td>
                  <td>dd-mm-yy</td>
                  <td>01536974</td>
                  {/* <td>1091</td> */}
                  <td>Munshiganj Office</td>
                  <td>01536974</td>
                  <td>dd-mm-yy</td>
                  <td>650214</td>
                  <td>650214</td>
                  <td>650214</td>
                  <td>650214</td>
                  <td>650214</td>
                  <td>650214</td>
                  <td>650214</td>
                  <td>650214</td>

                  <td className="w-[230px]">
                    <div className="h-full flex">
                      <img
                        onClick={() =>
                          navigate(
                            `/dashboard/client-land-description/add/${1}`
                          )
                        }
                        className="mr-2"
                        src={AddImg}
                        alt=""
                      />
                      <img
                        onClick={() =>
                          navigate(
                            `/dashboard/client-land-description/view/${1}`
                          )
                        }
                        className="mr-2"
                        src={ViewImg}
                        alt=""
                      />
                      <img
                        onClick={() =>
                          navigate(
                            `/dashboard/client-land-description/edit/${1}`
                          )
                        }
                        className="mr-2"
                        src={ActionImg}
                        alt=""
                      />
                    </div>
                  </td>

                  {/* <td>
                    <img className="mx-auto" src={BFolderImg} alt="" />
                  </td>
                  <td>
                    <img className="mx-auto" src={BFolderImg} alt="" />
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <Pagination />
      </div>
    </div>
  );
};

export default ClientLandDescription;
