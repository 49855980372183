import React, { useEffect, useState } from "react";
import { commonFormMenu } from "../../../../../asset/commoncssConstant/CommonCssConstant";

const SellerInfo = ({
  landSellerForSelect,
  sellerFromSelect,
  targetSelect,
  setSellerFromSelectDisabled,
  sellerFromSelectDisabled,
  handleSellerFromSelect,
  isSellerReFetchIdx,
}) => {
  const [idx, setIdx] = useState(null);
  const [prevUID, setPrevUID] = useState(null);

  //---------- for selected option disabled functionality start------------
  useEffect(() => {
    setPrevUID(sellerFromSelect[idx]?.sellerInfo?.id);
  }, [idx, sellerFromSelect]);
  useEffect(() => {
    if (sellerFromSelect.length) {
      const itemIdx = sellerFromSelect.findIndex(
        (seller) => seller.targetSelect === targetSelect
      );
      if (itemIdx !== -1) setIdx(itemIdx);
    }
  }, [isSellerReFetchIdx, sellerFromSelect, targetSelect]);

  const handleSellerFromSelectDisabled = (sellerId) => {
    if (prevUID) {
      const arr = sellerFromSelectDisabled;

      const itemIdx = arr.findIndex((item) => item === prevUID);

      arr.splice(itemIdx, 1, sellerId);
      setSellerFromSelectDisabled(arr);
    } else {
      setSellerFromSelectDisabled((prev) => [...prev, sellerId]);
    }

    setPrevUID(sellerId);
  };
  //----------for selected option disabled functionality end------------

  return (
    <div>
      <div className={commonFormMenu}>
        <div className="common_input_wraper">
          <label>Seller Name</label>
          <select
            onChange={(e) => {
              handleSellerFromSelect(targetSelect, +e.target.value);
              handleSellerFromSelectDisabled(+e.target.value);
            }}
            defaultValue={sellerFromSelect[idx]?.sellerInfo?.id}
          >
            <option value="" selected disabled>
              Select Seller Name
            </option>
            {landSellerForSelect?.map((item) => (
              <option
                key={item.id}
                value={item.id}
                disabled={sellerFromSelectDisabled.includes(item.id)}
                selected={sellerFromSelect[idx]?.sellerInfo?.id === item.id}
                className={
                  sellerFromSelectDisabled.includes(item.id)
                    ? "text-red-600"
                    : ""
                }
              >
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <div className="common_input_wraper">
          <label>Seller Mobile No</label>
          <input
            type="text"
            placeholder="Seller Mobile No"
            readOnly
            disabled
            defaultValue={sellerFromSelect[idx]?.sellerInfo?.mobile_no_1}
          />
        </div>
        <div className="common_input_wraper">
          <label>Seller Father Name</label>
          <input
            type="text"
            placeholder="Seller Father Name"
            readOnly
            disabled
            defaultValue={sellerFromSelect[idx]?.sellerInfo?.father_name}
          />
        </div>
        <div className="common_input_wraper">
          <label>Seller Mother Name</label>
          <input
            type="text"
            placeholder="Seller Mother Name"
            readOnly
            disabled
            defaultValue={sellerFromSelect[idx]?.sellerInfo?.mother_name}
          />
        </div>
        <div className="common_input_wraper">
          <label>Seller Address</label>
          <input
            type="text"
            placeholder="Seller Address"
            readOnly
            disabled
            defaultValue={sellerFromSelect[idx]?.sellerInfo?.permanent_address}
          />
        </div>
      </div>
    </div>
  );
};

export default SellerInfo;
