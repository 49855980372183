import React from "react";
import BackButton from "../../../../shared/BackButton";
import {
  commonFormMenu,
  dashboardBodyWraper,
  errorMessage,
} from "../../../../asset/commoncssConstant/CommonCssConstant";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { baseUrl } from "../../../../BaseUrl";

const EditSeller = () => {
  const item = useLocation().state.item;
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const token = localStorage.getItem("token");
  const {
    dob,
    email,
    father_name,
    join_date,
    mobile_no_1,
    mobile_no_2,
    mother_name,
    name,
    nid,
    permanent_address,
    present_address,
  } = item;

  //-------------Update Seller Start----------------------//
  const onSubmit = (data) => {
    data.id = item.id;
    //----------single and multiple file and data apend start---------
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (key === "document") {
        if (value instanceof FileList) {
          for (let i = 0; i < value.length; i++) {
            data[`document[${i}]`] = value[i];
            delete data.other_file;
            formData.append(`document[${i}]`, value[i]);
          }
        }
      }
      if (key !== "document") {
        if (value instanceof FileList) {
          for (let i = 0; i < value.length; i++) {
            formData.append(key, value.item([i]));
          }
        } else {
          formData.append(key, value);
        }
      }
    });
    //----------single and multiple file and data apend end---------

    axios
      .post(baseUrl + `/landSeller/update`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res.data);
        if (res.data.status === "success") {
          navigate("/dashboard/seller-list");
        }
      });
  };
  //-------------Update Seller End----------------------//
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BackButton title={"Edit Land Seller"} />
      <div className={`${dashboardBodyWraper} py-[4px] px-2 `}>
        <div className={commonFormMenu}>
          <div className="common_input_wraper">
            <label>Join Date</label>
            <input
              {...register("join_date", { required: "join date is required" })}
              type="date"
              defaultValue={join_date}
            />
            {errors?.join_date && (
              <p className={errorMessage}>{errors?.join_date?.message}</p>
            )}
          </div>
          <div className="common_input_wraper">
            <label>Seller Name </label>
            <input
              {...register(`name`, {
                pattern: {
                  value: /^[A-Za-z]+[A-Za-z0-9\-_~(\s]*[A-Za-z0-9\s)]$/,
                  message: "Insert valid seller name",
                },
              })}
              type="text"
              placeholder="Seller Name "
              defaultValue={name}
            />
            {errors?.name && (
              <p className={errorMessage}>{errors?.name?.message}</p>
            )}
          </div>
          <div className="common_input_wraper">
            <label>Father’s Name</label>
            <input
              {...register(`father_name`, {
                pattern: {
                  value: /^[A-Za-z]+[A-Za-z0-9\-_~(\s]*[A-Za-z0-9\s)]$/,
                  message: "Insert valid father name",
                },
              })}
              type="text"
              placeholder="Father’s Name"
              defaultValue={father_name}
            />
            {errors?.father_name && (
              <p className={errorMessage}>{errors?.father_name?.message}</p>
            )}
          </div>
          <div className="common_input_wraper">
            <label>Mother’s Name</label>
            <input
              {...register(`mother_name`, {
                pattern: {
                  value: /^[A-Za-z]+[A-Za-z0-9\-_~(\s]*[A-Za-z0-9\s)]$/,
                  message: "Insert valid mother name",
                },
              })}
              type="text"
              placeholder="Mother’s Name"
              defaultValue={mother_name}
            />
            {errors?.mother_name && (
              <p className={errorMessage}>{errors?.mother_name?.message}</p>
            )}
          </div>
          <div className="common_input_wraper">
            <label>Email Address</label>
            <input
              {...register(`email`, {
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Insert valid email address",
                },
              })}
              type="email"
              placeholder="Email Address"
              defaultValue={email}
            />
            {errors?.email && (
              <p className={errorMessage}>{errors?.email?.message}</p>
            )}
          </div>
          <div className="common_input_wraper">
            <label>National ID Number</label>
            <input
              {...register(`nid`, {
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Insert valid NID number",
                },
              })}
              type="text"
              placeholder="National ID Number"
              defaultValue={nid}
            />
            {errors?.nid && (
              <p className={errorMessage}>{errors?.nid?.message}</p>
            )}
          </div>
          <div className="common_input_wraper">
            <label>Date of Birth</label>
            <input
              {...register("dob", { required: "date of birth is required" })}
              type="date"
              placeholder="File No."
              defaultValue={dob}
            />
            {errors?.dob && (
              <p className={errorMessage}>{errors?.dob?.message}</p>
            )}
          </div>
          <div className="common_input_wraper">
            <label>Seller Mobile No. 01</label>
            <input
              {...register(`mobile_no_1`, {
                pattern: {
                  value: /^(0|\+?[1-9][0-9]*)$/,
                  message: "Insert country code before your number",
                },
              })}
              type="text"
              placeholder="Seller Mobile No. 01"
              defaultValue={mobile_no_1}
            />
            {errors?.mobile_no_1 && (
              <p className={errorMessage}>{errors?.mobile_no_1?.message}</p>
            )}
          </div>
          <div className="common_input_wraper">
            <label>Seller Mobile No. 02</label>
            <input
              {...register(`mobile_no_2`, {
                pattern: {
                  value: /^(0|\+?[1-9][0-9]*)$/,
                  message: "Insert country code before your number",
                },
              })}
              type="text"
              placeholder="Seller Mobile No.02"
              defaultValue={mobile_no_2}
            />
            {errors?.mobile_no_2 && (
              <p className={errorMessage}>{errors?.mobile_no_2?.message}</p>
            )}
          </div>

          <div className="col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-5"></div>

          <div className="common_input_wraper">
            <label>Client’s Image (PP size 450x570) *</label>
            <input
              {...register(`image`, {
                required: "Client’s Image is required",
              })}
              type="file"
            />
            {errors?.image && (
              <p className={errorMessage}>{errors?.image?.message}</p>
            )}
          </div>

          <div className="common_input_wraper">
            <label>Other file Upload (Multiple) *</label>
            <input
              {...register(`document`, {
                required: "Other file is required",
              })}
              type="file"
              multiple
            />
            {errors?.document && (
              <p className={errorMessage}>{errors?.document?.message}</p>
            )}
          </div>
          <div className="col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-5"></div>
          <div className="common_input_wraper">
            <label>Present Address</label>
            <textarea
              {...register("present_address", {
                required: "Present Address required",
              })}
              name="present_address"
              rows={3}
              placeholder="Present Address"
              defaultValue={present_address}
            />
            {errors?.present_address && (
              <p className={errorMessage}>{errors?.present_address?.message}</p>
            )}
          </div>
          <div className="common_input_wraper">
            <label>Permanent Address</label>
            <textarea
              {...register("permanent_address", {
                required: "Permanent Address required",
              })}
              name="permanent_address"
              rows={3}
              placeholder="Permanent Address"
              defaultValue={permanent_address}
            />
            {errors?.permanent_address && (
              <p className={errorMessage}>
                {errors?.permanent_address?.message}
              </p>
            )}
          </div>

          {/*   <div className="common_input_wraper">
            <label>Other file Upload (Multiple)</label>
            <label className="multiPleFileLable mt-2" htmlFor="multiplefile">
              Choose Files
              <img src={RightArrow} alt="" />
            </label>
            <input
              {...register("document")}
              style={{ display: "none" }}
              id="multiplefile"
              type="file"
              multiple
            />
          </div> */}
        </div>
        <div className="text-center py-10">
          <button type="submit" className="submit_button">
            Update
          </button>
        </div>
      </div>
    </form>
  );
};

export default EditSeller;
