import React from "react";
import BackButton from "../../../../shared/BackButton";
import { baseUrl } from "../../../../BaseUrl";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  commonUserInfo,
  commonUserRow,
  errorMessage,
} from "../../../../asset/commoncssConstant/CommonCssConstant";

const EditClientPaymentDueList = () => {
  const { statement, item } = useLocation()?.state;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  //--------customer/paymentInformation/update start---------
  const onSubmit = (data) => {
    data.customer_land_price_infos_id = item?.id;
    data.customer_land_info_id = item?.land_info?.id;
    data.downpayment_id = statement?.downpayment_id || "";
    data.installment_id = statement?.installment_id || "";
    data.payment_for = statement?.payment_against;
    data.paid_by = statement?.paid_by;

    const selectPaymentFor = data.payment_for.split(" ", 1)[0];
    if (selectPaymentFor === "Booking") {
      data.payment_against = 1;
      data.customer_payment_statement_booking_money_id = statement?.id;
    } else if (selectPaymentFor === "Downpayment") {
      data.payment_against = 2;
      data.downpayment_id = item?.downpayment?.find(
        (dPayment) => dPayment.downpayment_no === data.payment_for
      ).id;
      data.customer_payment_statement_downpayments_id = statement?.id;
    } else if (selectPaymentFor === "Installment") {
      data.payment_against = 3;
      data.installment_id = item?.installment?.find(
        (iPayment) => iPayment.installment_no === data.payment_for
      ).id;
      data.customer_payment_statement_installments_id = statement?.id;
    }
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (value instanceof FileList) {
        for (let i = 0; i < value.length; i++) {
          formData.append(key, value.item([i]));
        }
      } else {
        formData.append(key, value);
      }
    });

    axios
      .post(baseUrl + `/customer/paymentInformation/update`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          navigate(-1);
        } else {
          alert(res.data.message);
        }
      });
  };
  //--------customer/paymentInformation/update end---------

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <BackButton title={"Edit Payment"} />
        <div className="  mt-3">
          <div className=" client_info_section p-[40px] shadow-lg">
            <h1 className="text-[#717A82] text-[18px] font-[700] underline mb-5">
              User And Project Info
            </h1>
            <div className={`${commonUserInfo} mt-4`}>
              <div className={commonUserRow}>
                <div>File / Plot No </div>
                <div>:</div>
                <div className="mx-3">
                  {item?.land_info?.plot_info?.file_no}
                </div>
              </div>
              <div className={commonUserRow}>
                <div>Project Name </div>
                <div>:</div>
                <div className="mx-3">
                  {item?.land_info?.plot_info?.project_name}
                </div>
              </div>
              <div className={commonUserRow}>
                <div>Booking Date </div>
                <div>:</div>
                <div className="mx-3">{item?.land_info?.booking_date}</div>
              </div>
              <div className={commonUserRow}>
                <div>Customer Name </div>
                <div>:</div>
                <div className="mx-3">
                  {item?.land_info?.customer_info?.map((cus, i) => (
                    <span key={cus?.id}>
                      {cus?.name}{" "}
                      {i < item?.land_info?.customer_info?.length - 1
                        ? ", "
                        : ""}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <h1 className="text-[#717A82] text-[18px] font-[700] underline mb-3">
              Payment Option
            </h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              <div className="common_input_wraper">
                <label>Payment Date</label>
                <input
                  {...register("payment_date", {
                    required: "payment date is required",
                  })}
                  type="date"
                  defaultValue={statement?.payment_date}
                />
                {errors.payment_date && (
                  <p className={errorMessage}>
                    {errors?.payment_date?.message}
                  </p>
                )}
              </div>
              <div className="common_input_wraper">
                <label>Against</label>
                <input
                  {...register("payment_for")}
                  value={statement?.payment_against}
                  readOnly
                  disabled
                />
              </div>
              <div className="common_input_wraper">
                <label>Pay By</label>
                <input
                  {...register("paid_by")}
                  value={statement?.paid_by}
                  readOnly
                  disabled
                />
              </div>
              {statement?.paid_by === "bkash" ||
              statement?.paid_by === "nagad" ||
              statement?.paid_by === "rocket" ? (
                <div className="common_input_wraper">
                  <label>Mobile Account Number</label>
                  <input
                    {...register(`mobile_banking_account_no`, {
                      pattern: {
                        value: /^(0|\+?[1-9][0-9]*)$/,
                        message: "Insert country code before your number",
                      },
                    })}
                    type="text"
                    defaultValue={statement?.mobile_banking_account_no}
                  />
                  {errors.mobile_banking_account_no && (
                    <p className={errorMessage}>
                      {errors?.mobile_banking_account_no?.message}
                    </p>
                  )}
                </div>
              ) : null}
              {statement?.paid_by === "cheque" ? (
                <>
                  <div className="common_input_wraper">
                    <label>Bank Name</label>
                    <input
                      {...register(`bank_name`, {
                        pattern: {
                          value: /^[A-Za-z]+[A-Za-z0-9\-_~(\s]*[A-Za-z0-9\s)]$/,
                          message: "Insert valid Bank Name",
                        },
                      })}
                      type="text"
                      defaultValue={statement?.bank_name}
                    />
                    {errors.bank_name && (
                      <p className={errorMessage}>
                        {errors?.bank_name?.message}
                      </p>
                    )}
                  </div>
                  <div className="common_input_wraper">
                    <label>Bank Account Number</label>
                    <input
                      {...register("bank_account_no")}
                      type="text"
                      defaultValue={statement?.bank_account_no}
                    />
                  </div>
                  <div className="common_input_wraper">
                    <label>Bank Cheque Number</label>
                    <input
                      {...register("bank_cheque_no")}
                      type="text"
                      defaultValue={statement?.bank_cheque_no}
                    />
                  </div>
                </>
              ) : null}
              <div className="common_input_wraper">
                <label>Money Receipt No. </label>
                <input
                  {...register("money_receipt_no")}
                  type="text"
                  placeholder="Money Receipt No"
                  defaultValue={statement?.money_receipt_no}
                />
              </div>
              <div className="common_input_wraper">
                <label>Previous Due Amount </label>
                <input disabled type="text" placeholder="5703352" />
              </div>
              <div className="common_input_wraper">
                <label>Pay Amount</label>
                <input
                  {...register(`amount`, {
                    pattern: {
                      value: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Insert valid amount",
                    },
                  })}
                  type="text"
                  placeholder="Amount"
                  defaultValue={statement?.amount}
                />
                {errors?.amount && (
                  <p className={errorMessage}>{errors?.amount?.message}</p>
                )}
              </div>
              <div className="common_input_wraper">
                <label>After payment Due Amount</label>
                <input disabled type="text" placeholder="5703352" />
              </div>
              <div className="common_input_wraper">
                <label>Document Image Upload</label>
                <input {...register("document_image")} type="file" />
              </div>
            </div>
            <div className=" pt-10 text-center">
              <button type="submit" className="submit_button">
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditClientPaymentDueList;
