import React from "react";
import CommonPrintPdfBtn from "../../../../shared/CommonPrintPdfBtn";
import BackButton from "../../../../shared/BackButton";
import { agent_info_label } from "../../../../asset/commoncssConstant/CommonCssConstant";
import MaskGroup from "../../../../asset/images/clientlist/Maskgroup.png";
import { useLocation } from "react-router-dom";
import { baseUrlImg } from "../../../../BaseUrl";
import useCommonPDF from "../../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../../shared/useCommonPrint/useCommonPrint";
const LandBrokerView = () => {
  const location = useLocation();
  const landBroker = location.state.data;
  const { toPDF, targetRef } = useCommonPDF({ title: "broker-details" });
  const handlePrint = useCommonPrint({
    targetRef,
    title: "broker-details",
    message: "thank you",
  });

  return (
    <div>
      <BackButton title={" View Land Broker Information"} />
      <div className="grid lg:grid-cols-12  mt-3 gap-5">
        <div
          ref={targetRef}
          className="col-span-9 order-2 lg:order-1 client_info_section p-[40px] "
        >
          <h1 className="client_info_title">Land Broker Information</h1>
          <div className="flex flex-col-reverse md:flex-row justify-between">
            <div className="agent_view_table">
              <table>
                <tbody>
                  <tr>
                    <td>Joining Date</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{landBroker.joining_date}</td>
                  </tr>
                  <tr>
                    <td>Land Broker Name</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{landBroker.name}</td>
                  </tr>
                  <tr>
                    <td>Father’s Name</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{landBroker.father_name}</td>
                  </tr>
                  <tr>
                    <td>Mother’s Name</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{landBroker.mother_name}</td>
                  </tr>
                  <tr>
                    <td>Phone Number 1</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{landBroker.mobile_number_1}</td>
                  </tr>
                  <tr>
                    <td>Phone Number 2</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{landBroker.mobile_number_2}</td>
                  </tr>
                  <tr>
                    <td>Email Address</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{landBroker.email}</td>
                  </tr>
                  <tr>
                    <td>National ID Number</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{landBroker.nid}</td>
                  </tr>
                  <tr>
                    <td>Date of Birth</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{landBroker.dob}</td>
                  </tr>
                  <tr>
                    <td>Educational Background</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{landBroker.educational_background}</td>
                  </tr>
                  <tr>
                    <td>Present Address</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{landBroker.present_address}</td>
                  </tr>
                  <tr>
                    <td>Permanent Address</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{landBroker.permanent_address}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className=" flex justify-start pt-4 md:justify-end md:pt-0">
              <img
                className="w-[120px] h-[150px] border border-black p-1"
                src={
                  baseUrlImg + landBroker.image
                    ? baseUrlImg + landBroker.image
                    : MaskGroup
                }
                alt=""
              />
            </div>
          </div>
          {/* signature */}
          <div className="mt-16 flex justify-between">
            <div>
              <div className="border-t-[1px] border-[#333547] w-[160px]">
                <span className={`${agent_info_label} text-center block`}>
                  Customer’s Signature
                </span>
              </div>
            </div>
            <div>
              <div className="border-t-[1px] border-[#333547] w-[160px] lg:ml-auto">
                <span className={`${agent_info_label} text-center block`}>
                  Official Signature
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* right side button group  */}
        <div className="col-span-3 order-1 lg:order-2  ">
          <CommonPrintPdfBtn
            handlePrint={handlePrint}
            toPDF={toPDF}
            isBlock={true}
          />
          <div className="commonprintpdfbtn whitespace-nowrap mt-5">
            <button className="bg-[#F97316]">Legal Paper Folder</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandBrokerView;
