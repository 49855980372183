import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

const CreateAgentPaymentPDF = ({ item, statement }) => {
  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "#fff",
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    title: {
      textAlign: "center",
      textDecoration: "underline",
      marginTop: 15,
      marginBottom: 15,
      color: "#333547",
      fontWeight: "semibold",
      fontSize: 16,
    },
    table: {
      display: "table",
      width: "100%",
      marginTop: 20,
      borderWidth: 1,
      borderRadius: 5,
      borderColor: "#B6B6B6",
      padding: 5,
    },
    tableNext: {
      display: "table",
      width: "50%",
      marginTop: 20,
    },
    tableRow: {
      flexDirection: "row",
      alignItems: "center",
    },
    tableCol: {
      width: "40%",
      flexGrow: 1,
      color: "#8F8F8F",
    },
    tableText: {
      color: "#5F5F5F",
      fontSize: 12,
      fontWeight: "bold",
    },
    /*  tableVerticalLine: {
      borderRight: 1,
      margin: 2,
      borderColor: "#5F5F5F",
    }, */
    verticalLine: {
      borderRight: 1,
      margin: 10,
      borderColor: "#CFCFCF",
    },
    horizontalLine: {
      borderBottom: 1,
      borderBottomColor: "#CFCFCF",
      padding: 20,
    },
    lastTableCol: {
      width: "50%",
    },
    cell: {
      fontSize: 10,
      padding: 3,
    },
    colSeparator: {
      width: 20,
      textAlign: "center",
    },
    signature: {
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
      marginTop: 28,
      fontSize: 12,
    },
    signatureTitle: {
      marginTop: 10,
      marginHorizontal: "auto",
    },
  });
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>Agent Total Payment Informations</Text>
          {/* Broker Heading Part */}

          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={[styles.tableCol, styles.tableVerticalLine]}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.cell}>Agent Money</Text>
                    <Text style={styles.cell}></Text>
                  </View>
                  <View style={styles.colSeparator}>
                    <Text style={styles.cell}>:</Text>
                    <Text style={styles.cell}></Text>
                  </View>
                  <View style={[styles.tableCol, styles.lastTableCol]}>
                    <Text style={styles.tableText}>
                      {item?.agent_price?.agent_money}
                    </Text>
                    <Text style={styles.cell}></Text>
                  </View>
                </View>
              </View>

              <View style={[styles.tableCol, styles.tableVerticalLine]}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.cell}>Paid</Text>
                    <Text style={styles.cell}></Text>
                  </View>
                  <View style={styles.colSeparator}>
                    <Text style={styles.cell}>:</Text>
                    <Text style={styles.cell}></Text>
                  </View>
                  <View style={[styles.tableCol, styles.lastTableCol]}>
                    <Text style={styles.tableText}>
                      {item?.agent_price?.agent_money_paid}
                    </Text>
                    <Text style={styles.cell}></Text>
                  </View>
                </View>
              </View>
              <View style={styles.tableCol}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.cell}>Due</Text>
                    <Text style={styles.cell}></Text>
                  </View>
                  <View style={styles.colSeparator}>
                    <Text style={styles.cell}>:</Text>
                    <Text style={styles.cell}></Text>
                  </View>
                  <View style={[styles.tableCol, styles.lastTableCol]}>
                    <Text style={styles.tableText}>
                      {+item?.agent_price?.agent_money -
                        +item?.agent_price?.agent_money_paid}{" "}
                    </Text>
                    <Text style={styles.cell}></Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={[styles.tableCol, styles.tableVerticalLine]}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.cell}>Extra Money</Text>
                    <Text style={styles.cell}></Text>
                  </View>
                  <View style={styles.colSeparator}>
                    <Text style={styles.cell}>:</Text>
                    <Text style={styles.cell}></Text>
                  </View>
                  <View style={[styles.tableCol, styles.lastTableCol]}>
                    <Text style={styles.tableText}>
                      {item?.agent_price?.extra_money}
                    </Text>
                    <Text style={styles.cell}></Text>
                  </View>
                </View>
              </View>

              <View style={[styles.tableCol, styles.tableVerticalLine]}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.cell}>Paid</Text>
                    <Text style={styles.cell}></Text>
                  </View>
                  <View style={styles.colSeparator}>
                    <Text style={styles.cell}>:</Text>
                    <Text style={styles.cell}></Text>
                  </View>
                  <View style={[styles.tableCol, styles.lastTableCol]}>
                    <Text style={styles.tableText}>
                      {item?.agent_price?.extra_money_paid}
                    </Text>
                    <Text style={styles.cell}></Text>
                  </View>
                </View>
              </View>
              <View style={styles.tableCol}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.cell}>Due</Text>
                    <Text style={styles.cell}></Text>
                  </View>
                  <View style={styles.colSeparator}>
                    <Text style={styles.cell}>:</Text>
                    <Text style={styles.cell}></Text>
                  </View>
                  <View style={[styles.tableCol, styles.lastTableCol]}>
                    <Text style={styles.tableText}>
                      {+item?.agent_price?.extra_money -
                        +item?.agent_price?.extra_money_paid}{" "}
                    </Text>
                    <Text style={styles.cell}></Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={[styles.tableCol, styles.tableVerticalLine]}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.cell}>Conveyance Money</Text>
                    <Text style={styles.cell}></Text>
                  </View>
                  <View style={styles.colSeparator}>
                    <Text style={styles.cell}>:</Text>
                    <Text style={styles.cell}></Text>
                  </View>
                  <View style={[styles.tableCol, styles.lastTableCol]}>
                    <Text style={styles.tableText}>
                      {item?.agent_price?.agent_conveyance}
                    </Text>
                    <Text style={styles.cell}></Text>
                  </View>
                </View>
              </View>

              <View style={[styles.tableCol, styles.tableVerticalLine]}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.cell}>Paid</Text>
                    <Text style={styles.cell}></Text>
                  </View>
                  <View style={styles.colSeparator}>
                    <Text style={styles.cell}>:</Text>
                    <Text style={styles.cell}></Text>
                  </View>
                  <View style={[styles.tableCol, styles.lastTableCol]}>
                    <Text style={styles.tableText}>
                      {item?.agent_price?.agent_conveyance_paid}
                    </Text>
                    <Text style={styles.cell}></Text>
                  </View>
                </View>
              </View>
              <View style={styles.tableCol}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.cell}>Due</Text>
                    <Text style={styles.cell}></Text>
                  </View>
                  <View style={styles.colSeparator}>
                    <Text style={styles.cell}>:</Text>
                    <Text style={styles.cell}></Text>
                  </View>
                  <View style={[styles.tableCol, styles.lastTableCol]}>
                    <Text style={styles.tableText}>
                      {+item?.agent_price?.agent_conveyance -
                        +item?.agent_price?.agent_conveyance_paid}{" "}
                    </Text>
                    <Text style={styles.cell}></Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={[styles.tableCol, styles.tableVerticalLine]}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.cell}>Mobile Bill Money</Text>
                    <Text style={styles.cell}></Text>
                  </View>
                  <View style={styles.colSeparator}>
                    <Text style={styles.cell}>:</Text>
                    <Text style={styles.cell}></Text>
                  </View>
                  <View style={[styles.tableCol, styles.lastTableCol]}>
                    <Text style={styles.tableText}>
                      {item?.agent_price?.mobile_bill}
                    </Text>
                    <Text style={styles.cell}></Text>
                  </View>
                </View>
              </View>

              <View style={[styles.tableCol, styles.tableVerticalLine]}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.cell}>Paid</Text>
                    <Text style={styles.cell}></Text>
                  </View>
                  <View style={styles.colSeparator}>
                    <Text style={styles.cell}>:</Text>
                  </View>
                  <View style={[styles.tableCol, styles.lastTableCol]}>
                    <Text style={styles.tableText}>
                      {item?.agent_price?.mobile_bill_paid}
                    </Text>
                    <Text style={styles.cell}></Text>
                  </View>
                </View>
              </View>
              <View style={styles.tableCol}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.cell}>Due</Text>
                    <Text style={styles.cell}></Text>
                  </View>
                  <View style={styles.colSeparator}>
                    <Text style={styles.cell}>:</Text>
                    <Text style={styles.cell}></Text>
                  </View>
                  <View style={[styles.tableCol, styles.lastTableCol]}>
                    <Text style={styles.tableText}>
                      {+item?.agent_price?.mobile_bill -
                        +item?.agent_price?.mobile_bill_paid}
                    </Text>
                    <Text style={styles.cell}></Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={[styles.tableCol, styles.tableVerticalLine]}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.cell}>Entertainment Money</Text>
                  </View>
                  <View style={styles.colSeparator}>
                    <Text style={styles.cell}>:</Text>
                  </View>
                  <View style={[styles.tableCol, styles.lastTableCol]}>
                    <Text style={styles.tableText}>
                      {item?.agent_price?.entertainment}
                    </Text>
                  </View>
                </View>
              </View>

              <View style={[styles.tableCol, styles.tableVerticalLine]}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.cell}>Paid</Text>
                  </View>
                  <View style={styles.colSeparator}>
                    <Text style={styles.cell}>:</Text>
                  </View>
                  <View style={[styles.tableCol, styles.lastTableCol]}>
                    <Text style={styles.tableText}>
                      {item?.agent_price?.entertainment_paid}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={styles.tableCol}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.cell}>Due</Text>
                  </View>
                  <View style={styles.colSeparator}>
                    <Text style={styles.cell}>:</Text>
                  </View>
                  <View style={[styles.tableCol, styles.lastTableCol]}>
                    <Text style={styles.tableText}>
                      {+item?.agent_price?.entertainment -
                        +item?.agent_price?.entertainment_paid}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          {/* Broker Main Part */}
          <View style={[styles.tableRow, styles.horizontalLine]}>
            {/* Broker Left */}
            <View style={[styles.tableNext, styles.verticalLine]}>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.cell}>Agent ID</Text>
                  <Text style={styles.cell}></Text>
                </View>
                <View style={styles.colSeparator}>
                  <Text style={styles.cell}>:</Text>
                </View>
                <View style={[styles.tableCol, styles.lastTableCol]}>
                  <Text style={styles.tableText}>{item?.agent?.id}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.cell}>Agent Name</Text>
                  <Text style={styles.cell}></Text>
                </View>
                <View style={styles.colSeparator}>
                  <Text style={styles.cell}>:</Text>
                </View>
                <View style={[styles.tableCol, styles.lastTableCol]}>
                  <Text style={styles.tableText}>{item?.agent?.name}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.cell}>Agent</Text>
                  <Text style={styles.cell}>Present Address</Text>
                  <Text style={styles.cell}></Text>
                </View>
                <View style={styles.colSeparator}>
                  <Text style={styles.cell}>:</Text>
                </View>
                <View style={[styles.tableCol, styles.lastTableCol]}>
                  <Text style={styles.tableText}>
                    {item?.agent?.present_address}
                  </Text>
                </View>
              </View>

              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.cell}>Agent</Text>
                  <Text style={styles.cell}>mobile no. 1</Text>
                  <Text style={styles.cell}></Text>
                </View>
                <View style={styles.colSeparator}>
                  <Text style={styles.cell}>:</Text>
                </View>
                <View style={[styles.tableCol, styles.lastTableCol]}>
                  <Text style={styles.tableText}>
                    {item?.agent?.mobile_number_1}
                  </Text>
                </View>
              </View>

              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.cell}>Agent</Text>
                  <Text style={styles.cell}>mobile no. 2</Text>
                </View>
                <View style={styles.colSeparator}>
                  <Text style={styles.cell}>:</Text>
                </View>
                <View style={[styles.tableCol, styles.lastTableCol]}>
                  <Text style={styles.tableText}>
                    {item?.agent?.mobile_number_2}
                  </Text>
                </View>
              </View>
            </View>
            {/* Broker Right */}
            <View style={styles.tableNext}>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.cell}>Booking Date</Text>
                  <Text style={styles.cell}></Text>
                  <Text style={styles.cell}></Text>
                </View>
                <View style={styles.colSeparator}>
                  <Text style={styles.cell}>:</Text>
                </View>
                <View style={[styles.tableCol, styles.lastTableCol]}>
                  <Text style={styles.tableText}>{item?.booking_date}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.cell}>Customer’s ID</Text>
                  <Text style={styles.cell}></Text>
                </View>
                <View style={styles.colSeparator}>
                  <Text style={styles.cell}>:</Text>
                </View>
                <View style={[styles.tableCol, styles.lastTableCol]}>
                  <Text style={styles.tableText}>
                    {item?.customer_info?.map((ci) => (
                      <Text key={ci?.id}>{ci?.id}</Text>
                    ))}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.cell}>Customer’s Name</Text>
                  <Text style={styles.cell}></Text>
                </View>
                <View style={styles.colSeparator}>
                  <Text style={styles.cell}>:</Text>
                </View>
                <View style={[styles.tableCol, styles.lastTableCol]}>
                  <Text style={styles.tableText}>
                    {" "}
                    {item?.customer_info?.map((ci) => (
                      <Text key={ci?.id}>{ci?.name}</Text>
                    ))}{" "}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.cell}>Project Name</Text>
                  <Text style={styles.cell}></Text>
                  <Text style={styles.cell}></Text>
                </View>
                <View style={styles.colSeparator}>
                  <Text style={styles.cell}>:</Text>
                </View>
                <View style={[styles.tableCol, styles.lastTableCol]}>
                  <Text style={styles.tableText}>
                    {item?.plot_info?.project_name}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.cell}>File/ Plot No.</Text>
                  <Text style={styles.cell}></Text>
                  <Text style={styles.cell}></Text>
                </View>
                <View style={styles.colSeparator}>
                  <Text style={styles.cell}>:</Text>
                </View>
                <View style={[styles.tableCol, styles.lastTableCol]}>
                  <Text style={styles.tableText}>
                    {item?.plot_info?.file_no}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.cell}>Total Price</Text>
                  <Text style={styles.cell}></Text>
                  <Text style={styles.cell}></Text>
                  <Text style={styles.cell}></Text>
                </View>
                <View style={styles.colSeparator}>
                  <Text style={styles.cell}>:</Text>
                </View>
                <View style={[styles.tableCol, styles.lastTableCol]}>
                  <Text style={styles.tableText}>
                    {item?.customer_land_price?.total_amount}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.cell}>Agent Money</Text>
                </View>
                <View style={styles.colSeparator}>
                  <Text style={styles.cell}>:</Text>
                </View>
                <View style={[styles.tableCol, styles.lastTableCol]}>
                  <Text style={styles.tableText}>
                    {item?.agent_price?.agent_money}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          {/* <View><Text style={styles.title}>__________________________</Text></View> */}
          <Text style={styles.title}>Payment Information</Text>
          {/* Broker Payment */}
          <View style={styles.tableRow}>
            {/* Broker Payment Left */}
            <View style={[styles.tableNext, styles.verticalLine]}>
              <View style={[styles.tableRow]}>
                <View style={styles.tableCol}>
                  <Text style={styles.cell}>Date</Text>
                  <Text style={styles.cell}></Text>
                </View>
                <View style={styles.colSeparator}>
                  <Text style={styles.cell}>:</Text>
                </View>
                <View style={[styles.tableCol, styles.lastTableCol]}>
                  <Text style={styles.tableText}>
                    {statement?.created_at?.slice(0, 10)}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.cell}>Payment Purpose</Text>
                  <Text style={styles.cell}></Text>
                </View>
                <View style={styles.colSeparator}>
                  <Text style={styles.cell}>:</Text>
                </View>
                <View style={[styles.tableCol, styles.lastTableCol]}>
                  <Text style={styles.tableText}>
                    {statement?.payment_purpose}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.cell}>Payment Type</Text>
                  <Text style={styles.cell}></Text>
                </View>
                <View style={styles.colSeparator}>
                  <Text style={styles.cell}>:</Text>
                </View>
                <View style={[styles.tableCol, styles.lastTableCol]}>
                  <Text style={styles.tableText}>{statement?.payment_by}</Text>
                </View>
              </View>

              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.cell}>Txn ID / </Text>
                  <Text style={styles.cell}>Recite No </Text>
                  <Text style={styles.cell}></Text>
                </View>
                <View style={styles.colSeparator}>
                  <Text style={styles.cell}>:</Text>
                </View>
                <View style={[styles.tableCol, styles.lastTableCol]}>
                  <Text style={styles.tableText}>{statement?.invoice_no}</Text>
                </View>
              </View>

              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.cell}>Payment Amount</Text>
                  <Text style={styles.cell}></Text>
                </View>
                <View style={styles.colSeparator}>
                  <Text style={styles.cell}>:</Text>
                </View>
                <View style={[styles.tableCol, styles.lastTableCol]}>
                  <Text style={styles.tableText}>
                    {+item?.agent_price?.agent_conveyance_paid +
                      +item?.agent_price?.agent_money_paid +
                      +item?.agent_price?.entertainment_paid +
                      +item?.agent_price?.extra_money_paid +
                      +item?.agent_price?.mobile_bill_paid}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.cell}>Due Amount</Text>
                  <Text style={styles.cell}></Text>
                </View>
                <View style={styles.colSeparator}>
                  <Text style={styles.cell}>:</Text>
                </View>
                <View style={[styles.tableCol, styles.lastTableCol]}>
                  <Text style={styles.tableText}>
                    {+item?.agent_price?.agent_money -
                      (+item?.agent_price?.agent_conveyance_paid +
                        +item?.agent_price?.agent_money_paid +
                        +item?.agent_price?.entertainment_paid +
                        +item?.agent_price?.extra_money_paid +
                        +item?.agent_price?.mobile_bill_paid)}
                  </Text>
                </View>
              </View>
            </View>
            {/* Broker Payment Right */}
            <View style={styles.tableNext}>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableText}>
                    Payment Description / Note:
                  </Text>
                  <Text style={styles.cell}></Text>
                  <Text style={styles.cell}>{statement?.note}</Text>
                  <Text style={styles.cell}></Text>
                  <Text style={styles.cell}></Text>
                  <Text style={styles.cell}></Text>
                  <Text style={styles.cell}></Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.signature}>
            <View>
              <Text>___________________</Text>
              <Text style={styles.signatureTitle}>Customer Signature</Text>
            </View>
            <View>
              <Text>________________</Text>
              <Text style={styles.signatureTitle}>Official Signature</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default CreateAgentPaymentPDF;
