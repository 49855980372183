import React, { useState } from "react";
import BackButton from "../../../../shared/BackButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { baseUrl } from "../../../../BaseUrl";
import {
  commonBankColumnSpan,
  commonBankMenu,
  commonFormTitle,
  errorMessage,
} from "../../../../asset/commoncssConstant/CommonCssConstant";

const AddCsDisputeList = () => {
  const [defaultData, setDefaultData] = useState(null);
  const dagKhaytanForSelect = useLocation().state.dagKhaytanForSelect;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  //-------get data by id select option start---------------
  const handleSelectById = (e) => {
    e.preventDefault();
    setDefaultData(
      dagKhaytanForSelect?.find((list) => list.cs_dag_no === e.target.value)
    );
  };
  //-------get data by id select option end---------------
  //--------landDisputeBank/csDisputeList/store start---------
  const onSubmit = (data) => {
    data.cs_id = defaultData?.id;
    axios
      .post(baseUrl + `/landDisputeBank/csDisputeList/store`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          navigate("/dashboard/cs-dispute-list");
        }
      });
  };
  //--------landDisputeBank/csDisputeList/store end---------
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BackButton title={"Add CS Dispute"} />
      <div className={`bg-[#FFFFFF]    rounded-[4px]  my-[30px]`}>
        <div className="p-[30px]  ">
          <p className={commonFormTitle}>CS Dispute Information</p>
          <div className={commonBankMenu}>
            <div className="common_input_wraper">
              <label>CS Dag *</label>
              <select onChange={(e) => handleSelectById(e)} required>
                <option value="">Select CS Dag</option>
                {dagKhaytanForSelect.map((list) => (
                  <option key={list.id} value={list.cs_dag_no}>
                    {list.cs_dag_no}
                  </option>
                ))}
              </select>
            </div>
            <div className="common_input_wraper">
              <label>CS Khatiyan</label>
              <input
                type="text"
                placeholder="CS Khatiyan"
                readOnly
                disabled
                value={defaultData?.cs_khatiyan_no}
              />
            </div>
            <div className="common_input_wraper">
              <label>Dispute Date</label>
              <input
                {...register("dispute_date", {
                  required: "Dispute Date is required",
                })}
                type="date"
              />
              {errors?.dispute_date && (
                <p className={errorMessage}>{errors?.dispute_date?.message}</p>
              )}
            </div>
            <div className="common_input_wraper">
              <label>Dispute No.</label>
              <input
                {...register(`dispute_no`, {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Insert valid dispute no.",
                  },
                })}
                type="text"
              />
              {errors?.dispute_no && (
                <p className={errorMessage}>{errors?.dispute_no?.message}</p>
              )}
            </div>
            <div className="common_input_wraper">
              <label>Dispute Land Size</label>
              <input
                {...register(`dispute_land_size`, {
                  pattern: {
                    value: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Insert valid land size",
                  },
                })}
                type="text"
                step="0.01"
              />
              {errors?.dispute_land_size && (
                <p className={errorMessage}>
                  {errors?.dispute_land_size?.message}
                </p>
              )}
            </div>
            <div className="common_input_wraper">
              <label>Dispute Court Name</label>
              <input {...register("dispute_court_name")} type="text" />
            </div>
            <div className="common_input_wraper">
              <label>Dispute Judgement Date</label>
              <input
                {...register("dispute_judgement_date", {
                  required: "Judgement Date is required",
                })}
                type="date"
              />
              {errors?.dispute_judgement_date && (
                <p className={errorMessage}>
                  {errors?.dispute_judgement_date?.message}
                </p>
              )}
            </div>
            <div className="common_input_wraper">
              <label>Case Badi Name</label>
              <input
                {...register(`case_badi_name`, {
                  pattern: {
                    value: /^[A-Za-z]+[A-Za-z0-9\-_~(\s]*[A-Za-z0-9\s)]$/,
                    message: "Insert valid name",
                  },
                })}
                type="text"
              />
              {errors?.case_badi_name && (
                <p className={errorMessage}>
                  {errors?.case_badi_name?.message}
                </p>
              )}
            </div>
            <div className="common_input_wraper">
              <label>Case Bibadi Name</label>
              <input
                {...register(`case_bibadi_name`, {
                  pattern: {
                    value: /^[A-Za-z]+[A-Za-z0-9\-_~(\s]*[A-Za-z0-9\s)]$/,
                    message: "Insert valid name",
                  },
                })}
                type="text"
              />
              {errors?.case_bibadi_name && (
                <p className={errorMessage}>
                  {errors?.case_bibadi_name?.message}
                </p>
              )}
            </div>
            <div className="col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-5"></div>

            <div className={`common_input_wraper ${commonBankColumnSpan}`}>
              <label>Judgement Description</label>
              <textarea
                rows={4}
                {...register("judgement_description")}
                placeholder="Buyer & Seller All Personal & Land Information"
                type="text"
              />
            </div>
          </div>

          <div className="text-center py-10 pr-4">
            <button type="submit" className="submit_button">
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddCsDisputeList;
