import React from "react";
import {
  commonFormMenu,
  commonFormTitle,
  errorMessage,
} from "../../../../asset/commoncssConstant/CommonCssConstant";

const LandPriceInformation = ({ register, clientData, errors }) => {
  return (
    <div>
      <p className={commonFormTitle}>Land Price Information</p>
      <div className={commonFormMenu}>
        <div className="common_input_wraper">
          <label>Total Amount</label>
          <input
            {...register("total_amount", {
              pattern: {
                value: /^[0-9]+(\.[0-9]+)?$/,
                message: "Insert valid amount",
              },
            })}
            defaultValue={clientData?.customer_land_price?.total_amount || ""}
            type="text"
            placeholder="Total Amount"
          />
          {errors?.total_amount && (
            <p className={errorMessage}>{errors?.total_amount?.message}</p>
          )}
        </div>
        <div className="common_input_wraper">
          <label>Land Price per Decimal</label>
          <input
            {...register("land_price_per_decimal", {
              pattern: {
                value: /^[0-9]+(\.[0-9]+)?$/,
                message: "Insert valid amount",
              },
            })}
            defaultValue={
              clientData?.customer_land_price?.land_price_per_decimal || ""
            }
            type="text"
            placeholder="Land Price per Decimal"
          />
          {errors?.land_price_per_decimal && (
            <p className={errorMessage}>
              {errors?.land_price_per_decimal?.message}
            </p>
          )}
        </div>
        <div className="common_input_wraper">
          <label>Booking Money</label>
          <input
          {...register("total_booking_money", {
            pattern: {
              value: /^[0-9]+(\.[0-9]+)?$/,
              message: "Insert valid amount",
            },
          })}
            defaultValue={
              clientData?.customer_land_price?.total_booking_money || ""
            }
            type="text"
            placeholder="Booking Money"
          />
          {errors?.total_booking_money && (
            <p className={errorMessage}>
              {errors?.total_booking_money?.message}
            </p>
          )}
        </div>
        <div className="common_input_wraper">
          <label>Down Payment</label>
          <input
          {...register("total_downpayment_amount", {
            pattern: {
              value: /^[0-9]+(\.[0-9]+)?$/,
              message: "Insert valid amount",
            },
          })}
            defaultValue={
              clientData?.customer_land_price?.total_downpayment_amount || ""
            }
            type="text"
            placeholder="Down Payment"
          />
          {errors?.total_downpayment_amount && (
            <p className={errorMessage}>
              {errors?.total_downpayment_amount?.message}
            </p>
          )}
        </div>
        <div className="common_input_wraper">
          <label>Total Instalment Amount </label>
          <input
          {...register("total_installment_amount", {
            pattern: {
              value: /^[0-9]+(\.[0-9]+)?$/,
              message: "Insert valid amount",
            },
          })}
            defaultValue={
              clientData?.customer_land_price?.total_installment_amount || ""
            }
            type="text"
            placeholder="Total Instalment Amount"
          />
          {errors?.total_installment_amount && (
            <p className={errorMessage}>
              {errors?.total_installment_amount?.message}
            </p>
          )}
        </div>
        <div className="common_input_wraper">
          <label>No of Instalment </label>
          <input
           {...register("no_of_installment", {
            pattern: {
              value: /^[0-9]+(\.[0-9]+)?$/,
              message: "Insert valid amount",
            },
          })}
            defaultValue={
              clientData?.customer_land_price?.no_of_installment || ""
            }
            type="text"
            placeholder="No of Instalment"
          />
          {errors?.no_of_installment && (
            <p className={errorMessage}>{errors?.no_of_installment?.message}</p>
          )}
        </div>
        <div className="common_input_wraper">
          <label>Per month Instalment amount</label>
          <input
          {...register("per_month_installment", {
            pattern: {
              value: /^[0-9]+(\.[0-9]+)?$/,
              message: "Insert valid amount",
            },
          })}
            defaultValue={
              clientData?.customer_land_price?.per_month_installment || ""
            }
            type="text"
            placeholder="Per month Instalment amount"
          />
          {errors?.per_month_installment && (
            <p className={errorMessage}>
              {errors?.per_month_installment?.message}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default LandPriceInformation;
