import React from "react";

const CustomerLandPriceInfo = ({ register }) => {
  return (
    <div>
      <p className="text-[#6B2CEA] font-bold text-[16px] bg-[#F0EAFD] py-1 my-[40px]  text-center">
        Land Price Information
      </p>

      <div className="grid gap-4 xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
        <div className="common_input_wraper">
          <label>Purchase Land Price (Per Katha)</label>
          <input
            {...register("total_amount")}
            type="text"
            placeholder="Amount"
          />
        </div>
        <div className="common_input_wraper">
          <label>Purchase Land Total Price</label>
          <input
            {...register("land_price_per_decimal")}
            type="text"
            placeholder="Amount"
          />
        </div>
        <div className="common_input_wraper">
          <label>Payment Start Date</label>
          <input {...register("booking_money")} type="date" />
        </div>
        <div className="common_input_wraper">
          <label>Approx Payment Complete Date</label>
          <input {...register("downpayment_amount")} type="date" />
        </div>
        <div className="common_input_wraper">
          <label>Approx Registry Date </label>
          <input {...register("total_installment_amount")} type="date" />
        </div>
      </div>
    </div>
  );
};

export default CustomerLandPriceInfo;
