import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { baseUrl } from "../../../BaseUrl";
import { useNavigate } from "react-router-dom";
import { RxCrossCircled } from "react-icons/rx";

const CreateRole = () => {
  const [moduleList, setModuleList] = useState([]);
  const { register, handleSubmit } = useForm();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(baseUrl + `/allPermission`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const responce = res?.data?.allPermission;
        let array = [
          {
            id: 1,
            name: "Customer",
            customer: responce.filter(
              (item) =>
                item.name === "customer.store" ||
                item.name === "customer.view" ||
                item.name === "customer.update"
            ),
          },
          {
            id: 2,
            name: "Customer Price Info",
            customer: responce.filter(
              (item) =>
                item.name === "customerprice.store" ||
                item.name === "customerprice.view" ||
                item.name === "customerprice.update"
            ),
          },
          {
            id: 3,
            name: "Customer Payment Info",
            customer: responce.filter(
              (item) =>
                item.name === "customerpayment.store" ||
                item.name === "customerpayment.view" ||
                item.name === "customerpayment.update"
            ),
          },
          {
            id: 4,
            name: "Customer Land Agreement",
            customer: responce.filter(
              (item) =>
                item.name === "customerlandagreement.store" ||
                item.name === "customerlandagreement.view" ||
                item.name === "customerlandagreement.update"
            ),
          },
          {
            id: 5,
            name: "Land Seller",
            customer: responce.filter(
              (item) =>
                item.name === "landseller.store" ||
                item.name === "landseller.view" ||
                item.name === "landseller.update"
            ),
          },
          {
            id: 6,
            name: "Seller Agreement",
            customer: responce.filter(
              (item) =>
                item.name === "selleragreement.store" ||
                item.name === "selleragreement.view" ||
                item.name === "selleragreement.update"
            ),
          },
          {
            id: 7,
            name: "Seller Payment",
            customer: responce.filter(
              (item) =>
                item.name === "sellerpayment.store" ||
                item.name === "sellerpayment.view" ||
                item.name === "sellerpayment.update"
            ),
          },
          {
            id: 8,
            name: "Agent",
            customer: responce.filter(
              (item) =>
                item.name === "agent.store" ||
                item.name === "agent.view" ||
                item.name === "agent.update"
            ),
          },
          {
            id: 9,
            name: "Land Broaker",
            customer: responce.filter(
              (item) =>
                item.name === "broker.store" ||
                item.name === "broker.view" ||
                item.name === "broker.update"
            ),
          },
          {
            id: 10,
            name: "Land Information Bank",
            customer: responce.filter(
              (item) =>
                item.name === "landInfobank.store" ||
                item.name === "landInfobank.view" ||
                item.name === "landInfobank.update"
            ),
          },
          {
            id: 11,
            name: "Land Sub-deed Bank",
            customer: responce.filter(
              (item) =>
                item.name === "landsubdeedbank.store" ||
                item.name === "landsubdeedbank.view" ||
                item.name === "landsubdeedbank.update"
            ),
          },
          {
            id: 12,
            name: "Land Dispute Bank",
            customer: responce.filter(
              (item) =>
                item.name === "landdisputebank.store" ||
                item.name === "landdisputebank.view" ||
                item.name === "landdisputebank.update"
            ),
          },
          {
            id: 13,
            name: "Customer Payment",
            customer: responce.filter(
              (item) =>
                item.name === "customerpayment.store" ||
                item.name === "customerpayment.view" ||
                item.name === "customerpayment.update"
            ),
          },
          {
            id: 14,
            name: "Land Mouza Map Bank",
            customer: responce.filter(
              (item) =>
                item.name === "landmouzamapbank.store" ||
                item.name === "landmouzamapbank.view" ||
                item.name === "landmouzamapbank.update"
            ),
          },
        ];
        setModuleList(array);
      });
  }, [token]);

  //-------------Add role Start----------------------//
  const onSubmit = (data) => {
    axios
      .post(baseUrl + `/add-roleWithPermission`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          navigate("/dashboard/userList");
        }
      });
  };
  //-------------Add role End----------------------//
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <h1 className="text-[#47484A] font-[700] text-xl mb-4">Create Role</h1>
        <div className="bg-[#FFFFFF] p-8">
          <div className="border border-[#CBCBCB] rounded-lg">
            <div className="flex justify-between items-center px-8 py-3 bg-[#F5F6F8] rounded-t-lg">
              <h3 className="text-xl text-[#47484A] font-[600]">
                Create New Role
              </h3>
              <button
                onClick={() => navigate(-1)}
                className="text-red-500 text-xl"
              >
                <RxCrossCircled />
              </button>
            </div>
            <div className="px-8 py-3">
              <label
                htmlFor="role"
                className="text-base font-[600] text-[#47484A]"
              >
                Role Name
              </label>
              <input
                {...register("name")}
                className="block w-full border border-[#CBCBCB] rounded-lg p-2 mt-2 outline-none bg-[#F5F6F8]"
                type="text"
                placeholder="Select a Name"
              />
            </div>
            <h4 className=" px-8 py-3 text-[#47484A] text-base font-[600]">
              Assign Permission to Role
            </h4>
            <div className="bg-[#F5F6F8] px-5 py-2">
              <div className="flex justify-between align-middle">
                <div className="flex items-center gap-2 px-8 py-3 text-[#47484A] text-base font-[600]">
                  <h4>Module Name</h4>
                </div>
                <div className="text-[#47484A] text-base font-[600] text-center px-8 py-3">
                  Permission
                </div>
              </div>
            </div>

            <div className="px-5 py-2">
              {moduleList?.map((item) => {
                  return (
                    <div key={item.id}>
                      <div className="flex justify-between align-middle">
                        <div className="text-[#47484A] text-base font-[600]">
                          <div className="flex items-center gap-2 px-8 py-3">
                            <h4>{item.name}</h4>
                          </div>
                        </div>
                        <div className="grid md:grid-cols-3 my-2 py-2 md:my-0 md:py-0">
                          {item?.customer?.map((cus, i) => (
                            <div key={i}>
                              <div className="flex items-center gap-2 ">
                                <input
                                  className="checkbox border-[#CBCBCB]checked:border-[#CBCBCB] h-4 w-4 rounded-sm "
                                  type="checkbox"
                                  name=""
                                  id=""
                                  value={cus.id}
                                  {...register(`permissions`, {
                                    required: {
                                      value: true,
                                      message: `permissionsis required`,
                                    },
                                  })}
                                />
                                {i === 0 && <h4>Create </h4>}
                                {i === 1 && <h4>View </h4>}
                                {i === 2 && <h4>Update </h4>}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="flex justify-end gap-4 py-4 pe-4">
              <button
                onClick={() => navigate(-1)}
                type="button"
                className="border border-[#218194] text-xl text-[#0C768A] font-[600] rounded px-6 py-2"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="border-none bg-gradient-to-r from-[#0C768A] via-[#1e788a] to-[#2894a8] hover:bg-gradient-to-r hover:from-[#0b5b6b] hover:via-[#15606f] hover:to-[#257a88] text-xl text-white font-[600] rounded px-6 py-2"
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CreateRole;
