import React, { useEffect, useState } from "react";

const LandInformation = ({
  bsFromSelect,
  targetSelect,
  bsInfoForSelect,
  setBsFromSelectDisabled,
  handleBsFromSelect,
  bsFromSelectDisabled,
  isBsReFetchIdx,
}) => {
  const [idx, setIdx] = useState(null);
  const [prevUID, setPrevUID] = useState(null);

  //---------- for selected option disabled functionality start------------
  useEffect(() => {
    setPrevUID(bsFromSelect[idx]?.bsInfo?.id);
  }, [idx, bsFromSelect]);

  useEffect(() => {
    if (bsFromSelect.length) {
      const itemIdx = bsFromSelect.findIndex(
        (bs) => bs.targetSelect === targetSelect
      );

      if (itemIdx !== -1) setIdx(itemIdx);
    }
  }, [isBsReFetchIdx, bsFromSelect, targetSelect]);

  const handleBsFromSelectDisabled = (bsId) => {
    if (prevUID) {
      const arr = bsFromSelectDisabled;

      const itemIdx = arr.findIndex((item) => item === prevUID);

      arr.splice(itemIdx, 1, bsId);
      setBsFromSelectDisabled(arr);
    } else {
      setBsFromSelectDisabled((prev) => [...prev, bsId]);
    }
    setPrevUID(bsId);
  };
  //---------- for selected option disabled functionality end------------

  return (
    <div className="grid my-2 py-2 px-4 gap-2 xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
      <div className="common_input_wraper">
        <label>BS ID {++targetSelect}</label>
        <select
          onChange={(e) => {
            handleBsFromSelect(targetSelect, +e.target.value);
            handleBsFromSelectDisabled(+e.target.value);
          }}
          defaultValue={bsFromSelect[idx]?.bsInfo?.id}
        >
          <option value="" disabled selected>
            Select BS ID
          </option>
          {bsInfoForSelect?.map((item) => (
            <option
              key={item.id}
              value={item.id}
              disabled={bsFromSelectDisabled.includes(item.id)}
              selected={bsFromSelect[idx]?.bsInfo?.id === item.id}
              className={
                bsFromSelectDisabled.includes(item.id) ? "text-red-600" : ""
              }
            >
              {item.id}
            </option>
          ))}
        </select>
      </div>
      <div className="common_input_wraper">
        <label>Project ID</label>
        <input
          type="number"
          placeholder="Project ID"
          defaultValue={
            bsFromSelect[idx]?.bsInfo?.rs_info?.sa_info?.cs_info?.mouza_info
              ?.project_info?.id
          }
          disabled
          readOnly
        />
      </div>
      <div className="common_input_wraper">
        <label>Mouza ID</label>
        <input
          type="number"
          placeholder="Mouza ID"
          defaultValue={
            bsFromSelect[idx]?.bsInfo?.rs_info?.sa_info?.cs_info?.mouza_info?.id
          }
          disabled
          readOnly
        />
      </div>
      <div className="common_input_wraper">
        <label>CS ID</label>
        <input
          type="number"
          placeholder="CS ID"
          defaultValue={
            bsFromSelect[idx]?.bsInfo?.rs_info?.sa_info?.cs_info?.id
          }
          disabled
          readOnly
        />
      </div>
      <div className="common_input_wraper">
        <label>SA ID</label>
        <input
          type="number"
          placeholder="SA ID"
          defaultValue={bsFromSelect[idx]?.bsInfo?.rs_info?.sa_info?.id}
          disabled
          readOnly
        />
      </div>
      <div className="common_input_wraper">
        <label>RS ID</label>
        <input
          type="number"
          placeholder="RS ID"
          defaultValue={bsFromSelect[idx]?.bsInfo?.rs_info?.id}
          disabled
          readOnly
        />
      </div>
    </div>
  );
};

export default LandInformation;
