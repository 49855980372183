import React, { useState } from "react";
import FileImage from "../../../../asset/images/fileImage.png";
import CloseIcon from "../../../../asset/images/icon/closeIcon.png";
const AddFileModal = ({ setIsOpen }) => {
  const [fileImage, setFileImage] = useState([
    {
      imgUrl: "",
    },
    {
      imgUrl: "",
    },
    {
      imgUrl: "",
    },
    {
      imgUrl: "",
    },
    {
      imgUrl: "",
    },
  ]);
  const handleFileChange = (e, i) => {
    e.preventDefault();
    const file = e.target.files[0];
    const imageUrl = URL.createObjectURL(file);
    const _data = [...fileImage];
    _data[i].imgUrl = imageUrl;
    setFileImage(_data);
  };
  return (
    <div>
      <div className="max-w-[800px] p-[30px]">
        <div className="mb-[30px] flex justify-between">
          <h1 className=" text-[12px] font-[700] text-[#717A82]">
            Upload Legal Papers (Size 806px X 1344px)
          </h1>
          <img
            onClick={() => setIsOpen(false)}
            className="cursor-pointer"
            src={CloseIcon}
            alt=""
          />
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5  xl:grid-cols-7  mt-10 gap-2">
          {fileImage.map((item, i) => (
            <div>
              <label htmlFor={`file${i}`}>
                <img
                  className="border-[1px] rounded-[4px] w-[126px] h-[200px] object-fill border-[#C8C8C8]"
                  src={item.imgUrl || FileImage}
                  alt=""
                />
              </label>
              <input
                onChange={(e) => handleFileChange(e, i)}
                className="hidden"
                type="file"
                id={`file${i}`}
              />
            </div>
          ))}
        </div>
        <button className="submit_button my-5 block mx-auto">Submit</button>
      </div>
    </div>
  );
};

export default AddFileModal;
