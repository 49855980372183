import React from "react";

const CommonPrintPdfBtn = ({ isBlock, toPDF, handlePrint }) => {
  return (
    <div className="commonprintpdfbtn whitespace-nowrap">
      {/* <button className={`bg-[#00A3E2] ${isBlock ? "lg:block mb-3 " : ""}`}>
        Copy
      </button> */}
      <button
        id="print"
        onClick={() => handlePrint()}
        className={`bg-[#DA5643] ${isBlock ? "lg:block mb-3" : ""}`}
      >
        Print
      </button>
      <button
        onClick={() => toPDF()}
        className={` bg-blue-600  ${isBlock ? "lg:block mb-3" : ""}`}
      >
        PDF
      </button>
    </div>
  );
};

export default CommonPrintPdfBtn;
