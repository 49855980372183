import React, { useEffect, useState } from "react";
import CommonPrintPdfBtn from "../../../shared/CommonPrintPdfBtn";
import {
  addCustomerCss,
  commonAction,
  commonAddButton,
  commonCaption,
  commonSearchInput,
  commonTableHeadingTwo,
  commonTableRow,
  commonTitle,
  dashboardBodyWraper,
} from "../../../asset/commoncssConstant/CommonCssConstant";
import AddUserImg from "../../../asset/images/clientlist/add-user.png";
import Pagination from "../../../shared/pagination/Pagination";
import FolderImg from "../../../asset/images/clientlandinformation/back-folder.png";
import ViewBtn from "../../../asset/images/clientlist/view.png";
import ActionImg from "../../../asset/images/clientlist/action.png";
import axios from "axios";
import { baseUrl } from "../../../BaseUrl";
import { Link } from "react-router-dom";
import { LuArrowDownUp } from "react-icons/lu";
import useCommonPDF from "../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../shared/useCommonPrint/useCommonPrint";

const BsMouzaMapList = () => {
  const [bsMouzaMapList, setBsMouzaMapList] = useState([]);
  const [dagKhaytanForSelect, setDagKhaytanForSelect] = useState([]);
  const token = localStorage.getItem("token");
  const [searchValue, setSearchValue] = useState("");
  const [paginationData, setPaginationData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const { toPDF, targetRef } = useCommonPDF({ title: "bs mouza map list" });

  const handlePrint = useCommonPrint({
    targetRef,
    title: "bs mouza map list",
    message: "thank you",
  });

  
  // pagination part start here
  const handleNext = () => {
    if (page === pageCount) {
      return page;
    } else {
      return setPage(page + 1);
    }
  };
  
  const handlePrevious = () => {
    if (page === 1) {
      return page;
    } else {
      return setPage(page - 1);
    }
  };

  //------get data for bsMouzaMapList and bsDagKhatiyanList start-----------------
  useEffect(() => {
    axios
      .get(baseUrl + `/landMouzaMapBank/bsMouzaMapList/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setBsMouzaMapList(res.data.data);
      });
    axios
      .get(baseUrl + `/landInformationBank/bsDagKhatiyan/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setDagKhaytanForSelect(res.data.data);
      });
  }, [token, page]);
  //------get data for bsMouzaMapList and bsDagKhatiyanList end-----------------
  return (
    <div>
      <h1 className={`${commonTitle}`}>BS Mouza Map</h1>
      <div className={dashboardBodyWraper}>
        <div className={commonAddButton}>
          <Link
            to={`/dashbooard/bs-mouza-map-list/add`}
            state={{
              dagKhaytanForSelect: dagKhaytanForSelect,
            }}
          >
            <button className={addCustomerCss}>
              <span>Add BS Mouza Map</span>
              <img className="ml-[30px]" src={AddUserImg} alt="" />
            </button>
          </Link>
        </div>
        <div className={commonCaption}>
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} />
          <input
            onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
            type="text"
            placeholder="Search"
            className={commonSearchInput}
          />
        </div>

        <div ref={targetRef} className="table_responsive mt-[40px]">
          <table>
            <thead>
              <tr className={`${commonTableRow}`}>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Create Date</span>
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>BS Dag</span>
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>BS Khatiyan</span>
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>BS Land Size (Sotangso)</span>
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>BS Land Size (Ohudangso)</span>
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>BS Land Size (Sq.fit)</span>
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>BS Land East to west (Sq.fit)</span>
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>BS Land North to South (Sq.fit)</span>
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>BS Land East & South (Angle)</span>
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>BS Land East & North (Angle)</span>
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>BS Land West & South (Angle)</span>
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>BS Land West & North (Angle)</span>
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>
                      East , South to
                      <br /> West , North Corner (Length)
                    </span>
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>South , West to North , East Corner (Length)</span>
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>BS Dag Map Image</span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>
                      Action <br />
                      Update / View
                    </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {paginationData
                ?.filter((info) => {
                  return searchValue.toLowerCase() !== ""
                    ? info?.created_at
                        ?.slice(0, 10)
                        .toLowerCase()
                        .includes(searchValue) ||
                        info?.bs_dag_info?.bs_dag_no
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.bs_dag_info?.bs_khatiyan_no
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.land_size_sotangsho
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.land_size_ojutangsho
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.land_size_sq_feet
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.land_eastTowest_sq_feet
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.land_northToSouth_sq_feet
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.land_eastAndSouth_angle
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.land_eastAndNorth_angle
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.land_westAndSouth_angle
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.land_westAndNorth_angle
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.eastSouth_to_westNorth_length
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.southWest_to_northEast_length
                          ?.toLowerCase()
                          .includes(searchValue)
                    : info;
                })
                ?.map((item, i) => (
                  <tr className="text-center" key={i}>
                    <td>{item?.created_at?.slice(0, 10)}</td>
                    <td>{item?.bs_dag_info?.bs_dag_no}</td>
                    <td>{item?.bs_dag_info?.bs_khatiyan_no}</td>
                    <td>{item?.land_size_sotangsho}</td>
                    <td>{item?.land_size_ojutangsho}</td>
                    <td>{item?.land_size_sq_feet}</td>
                    <td>{item?.land_eastTowest_sq_feet}</td>
                    <td>{item?.land_northToSouth_sq_feet}</td>
                    <td>{item?.land_eastAndSouth_angle}</td>
                    <td>{item?.land_eastAndNorth_angle}</td>
                    <td>{item?.land_westAndSouth_angle}</td>
                    <td>{item?.land_westAndNorth_angle}</td>
                    <td>{item?.eastSouth_to_westNorth_length}</td>
                    <td>{item?.southWest_to_northEast_length}</td>
                    <td>
                      <Link
                        to={`/dashbooard/bs-mouza-map-list/bs-dag-map-image`}
                        state={{
                          item: item,
                        }}
                      >
                        <img
                          className={`mx-auto ${commonAction}`}
                          src={FolderImg}
                          alt=""
                        />
                      </Link>
                    </td>
                    <td>
                      <span className="flex gap-2 justify-center">
                        <Link
                          to={`/dashbooard/bs-mouza-map-list/edit`}
                          state={{
                            item: item,
                            dagKhaytanForSelect: dagKhaytanForSelect,
                          }}
                        >
                          <img
                            className={commonAction}
                            src={ActionImg}
                            alt=""
                          />
                        </Link>
                        <Link
                          to={`/dashbooard/bs-mouza-map-list/description`}
                          state={{
                            item: item,
                          }}
                        >
                          <img className={commonAction} src={ViewBtn} alt="" />
                        </Link>
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <Pagination
        data={bsMouzaMapList}
        paginationData={paginationData}
        setPaginationData={setPaginationData}
        page={page}
        setPage={setPage}
        pageCount={pageCount}
        setPageCount={setPageCount}
        handleNext={handleNext}
        handlePrevious={handlePrevious} />
      </div>
    </div>
  );
};

export default BsMouzaMapList;
