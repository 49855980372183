import React, { useEffect, useState } from "react";
import CommonPrintPdfBtn from "../../../shared/CommonPrintPdfBtn";
import {
  addCustomerCss,
  commonAction,
  commonAddButton,
  commonCaption,
  commonSearchInput,
  dashboardBodyWraper,
} from "../../../asset/commoncssConstant/CommonCssConstant";
import Pagination from "../../../shared/pagination/Pagination";
import { Link, useNavigate } from "react-router-dom";
import ActionImg from "../../../asset/images/clientlist/action.png";
import ViewImg from "../../../asset/images/clientlist/view.png";
import AddUserImg from "../../../asset/images/clientlist/add-user.png";
import axios from "axios";
import { baseUrl, baseUrlImg } from "../../../BaseUrl";
import { LuArrowDownUp } from "react-icons/lu";
import {
  commonTableHeadingTwo,
  commonTableRow,
  commonTitle,
} from "../../../asset/commoncssConstant/CommonCssConstant";
import useCommonPDF from "../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../shared/useCommonPrint/useCommonPrint";

const SellerList = () => {
  const [landSellerList, setLandSellerList] = useState([]);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [searchValue, setSearchValue] = useState("");
  const [paginationData, setPaginationData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const { toPDF, targetRef } = useCommonPDF({ title: "seller-list" });

  const handlePrint = useCommonPrint({
    targetRef,
    title: "seller-list",
    message: "thank you",
  });

  // pagination part start here
  const handleNext = () => {
    if (page === pageCount) {
      return page;
    } else {
      return setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page === 1) {
      return page;
    } else {
      return setPage(page - 1);
    }
  };

  //------------get data landSeller/view start------------
  useEffect(() => {
    axios
      .get(baseUrl + `/landSeller/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLandSellerList(res.data.data);
      });
  }, [token, page]);
  //------------get data landSeller/view end------------

  return (
    <div>
      <h1 className={`${commonTitle}`}>Land Seller</h1>
      <div className={dashboardBodyWraper}>
        <div className={commonAddButton}>
          <button
            onClick={() => navigate("/dashboard/seller-list/add")}
            className={addCustomerCss}
          >
            Add Land Seller{" "}
            <img className="ml-[30px]" src={AddUserImg} alt="" />
          </button>
        </div>
        <div className={commonCaption}>
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} />
          <input
            onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
            type="text"
            placeholder="Search Seller Name / NID"
            className={commonSearchInput}
          />
        </div>

        <div ref={targetRef} className="table_responsive mt-[40px]">
          <table>
            <thead>
              <tr className={`${commonTableRow}`}>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> join Date</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Seller Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Father Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Mother Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Email</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> NID No</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Date of Birth</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Picture</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>mobile no. 1</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>mobile no. 2</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>

                <th>
                  <div className={`py-2`}>
                    <span>
                      Action <br />
                      Update / View
                    </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {paginationData
                ?.filter((info) => {
                  return searchValue.toLowerCase() !== ""
                    ? info?.name?.toLowerCase().includes(searchValue) ||
                        info?.nid
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue)
                    : info;
                })
                ?.map((item, i) => (
                  <tr className="text-center" key={item?.id}>
                    <td>{item?.join_date}</td>
                    <td>{item?.name}</td>
                    <td>{item?.father_name}</td>
                    <td>{item?.mother_name}</td>
                    <td>{item?.email}</td>
                    <td>{item?.nid}</td>
                    <td>{item?.dob}</td>
                    <td>
                      <div className="flex justify-center">
                        <img
                          className="w-11 rounded-md"
                          src={baseUrlImg + item?.image}
                          alt=""
                          srcSet=""
                        />
                      </div>
                    </td>
                    <td>
                      <span>{item?.mobile_no_1}</span>
                    </td>
                    <td>
                      <span>{item?.mobile_no_2}</span>
                    </td>
                    <td>
                      <span className="flex justify-center gap-2">
                        <Link
                          to={`/dashboard/seller-list/edit`}
                          state={{
                            item: item,
                          }}
                        >
                          <img
                            className={commonAction}
                            src={ActionImg}
                            alt=""
                          />
                        </Link>
                        <Link
                          to={`/dashboard/seller-list/view`}
                          state={{
                            item: item,
                          }}
                        >
                          <img className={commonAction} src={ViewImg} alt="" />
                        </Link>
                      </span>
                    </td>
                    {/* <td>
                      <img className="mx-auto" src={BFolderImg} alt="" />
                    </td> */}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <Pagination
          data={landSellerList}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          setPageCount={setPageCount}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      </div>
    </div>
  );
};

export default SellerList;
