import React, { useState } from "react";
import BackButton from "../../../../shared/BackButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { baseUrl } from "../../../../BaseUrl";
import axios from "axios";
import {
  commonUserInfo,
  commonUserRow,
  errorMessage,
} from "../../../../asset/commoncssConstant/CommonCssConstant";

const AddClientPaymentDueList = () => {
  const [paymentMethod, setPaymentMethod] = useState(null);
  const item = useLocation().state.item;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  //-------payment method select functionality start--------------------------------
  const handlePaymentMethod = (name) => {
    setPaymentMethod(name);
  };
  //-------payment method select functionality end--------------------------------
  //--------customer/paymentInformation/store start---------
  const onSubmit = (data) => {
    data.customer_land_price_infos_id = item?.id;
    data.customer_land_info_id = item?.land_info?.id;
    data.paid_by = paymentMethod;
    const selectPaymentFor = data.payment_for.split(" ", 1)[0];
    if (selectPaymentFor === "Booking") {
      data.payment_against = 1;
    } else if (selectPaymentFor === "Downpayment") {
      data.payment_against = 2;
      data.downpayment_id = item?.downpayment?.find(
        (dPayment) => dPayment.downpayment_no === data.payment_for
      ).id;
    } else if (selectPaymentFor === "Installment") {
      data.payment_against = 3;
      data.installment_id = item?.installment?.find(
        (iPayment) => iPayment.installment_no === data.payment_for
      ).id;
    }
    if (
      data.paid_by === "bkash" ||
      data.paid_by === "nagad" ||
      data.paid_by === "rocket"
    ) {
      delete data.bank_name;
      delete data.bank_cheque_no;
      delete data.bank_account_no;
    }
    if (data.paid_by === "cheque") {
      delete data.mobile_banking_account_no;
    }
    if (data.paid_by === "cash") {
      delete data.mobile_banking_account_no;
      delete data.bank_name;
      delete data.bank_cheque_no;
      delete data.bank_account_no;
    }

    // console.log(data);
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (value instanceof FileList) {
        for (let i = 0; i < value.length; i++) {
          formData.append(key, value.item([i]));
        }
      } else {
        formData.append(key, value);
      }
    });

    axios
      .post(baseUrl + `/customer/paymentInformation/store`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          navigate("/dashboard/client-payment-and-due-list");
        } else {
          alert(res.data.message);
        }
      });
  };
  //--------customer/paymentInformation/store end---------
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <BackButton title={"Add Payment"} />
        <div className="  mt-3 gap-5">
          <div className=" client_info_section p-[40px] shadow-lg">
            <div>
              <h1 className="text-[#717A82] text-[18px] font-[700] underline my-[10px]">
                User And Project Information
              </h1>
              <div className={commonUserInfo}>
                <div className={commonUserRow}>
                  <div>File / Plot No </div>
                  <div>:</div>
                  <div className="mx-3">
                    {item?.land_info?.plot_info?.file_no}
                  </div>
                </div>
                <div className={commonUserRow}>
                  <div>Project Name </div>
                  <div>:</div>
                  <div className="mx-3">
                    {item?.land_info?.plot_info?.project_name}
                  </div>
                </div>
                <div className={commonUserRow}>
                  <div>Booking Date </div>
                  <div>:</div>
                  <div className="mx-3">{item?.land_info?.booking_date}</div>
                </div>
                <div className={commonUserRow}>
                  <div>Customer Name</div>
                  <div>:</div>
                  <div className="mx-3">
                    {item?.land_info?.customer_info?.map((cus, i) => (
                      <span key={cus?.id}>
                        {cus?.name}{" "}
                        {i < item?.land_info?.customer_info?.length - 1
                          ? ", "
                          : ""}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <h1 className="text-[#717A82] text-[18px] font-[700] underline my-[10px]">
              Payment Option
            </h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              <div className="common_input_wraper">
                <label>Payment Date</label>
                <input
                  {...register("payment_date", {
                    required: "payment date is required",
                  })}
                  type="date"
                />
                {errors.payment_date && (
                  <p className={errorMessage}>
                    {errors?.payment_date?.message}
                  </p>
                )}
              </div>
              <div className="common_input_wraper">
                <label>Against</label>
                <select {...register("payment_for")} required>
                  <option value="">Select Purpose</option>
                  <option value="Booking Money">Booking Money</option>
                  {item?.downpayment?.map((dPayment) => (
                    <option key={dPayment.id} value={dPayment?.downpayment_no}>
                      {dPayment?.downpayment_no}
                    </option>
                  ))}
                  {item?.installment?.map((iPayment) => (
                    <option key={iPayment.id} value={iPayment?.installment_no}>
                      {iPayment?.installment_no}
                    </option>
                  ))}
                </select>
              </div>
              <div className="common_input_wraper">
                <label>Pay By</label>
                <select
                  onChange={(e) => handlePaymentMethod(e.target.value)}
                  required
                >
                  <option value="" disabled selected>
                    Please select One
                  </option>
                  <option value="bkash">Bkash</option>
                  <option value="nagad">Nagad</option>
                  <option value="rocket">Rocket</option>
                  <option value="cheque">Bank Cheque</option>
                  <option value="cash">Cash</option>
                </select>
              </div>
              {paymentMethod === "bkash" ||
              paymentMethod === "nagad" ||
              paymentMethod === "rocket" ? (
                <div className="common_input_wraper">
                  <label>Mobile Account Number</label>
                  <input
                    {...register(`mobile_banking_account_no`, {
                      pattern: {
                        value: /^(0|\+?[1-9][0-9]*)$/,
                        message: "Insert country code before your number",
                      },
                    })}
                    type="text"
                    placeholder={`Mobile Account Number`}
                  />
                  {errors.mobile_banking_account_no && (
                    <p className={errorMessage}>
                      {errors?.mobile_banking_account_no?.message}
                    </p>
                  )}
                </div>
              ) : null}
              {paymentMethod === "cheque" ? (
                <>
                  <div className="common_input_wraper">
                    <label>Bank Name</label>
                    <input
                      {...register(`bank_name`, {
                        pattern: {
                          value: /^[A-Za-z]+[A-Za-z0-9\-_~(\s]*[A-Za-z0-9\s)]$/,
                          message: "Insert valid Bank Name",
                        },
                      })}
                      type="text"
                      placeholder={`Bank Name`}
                    />
                    {errors.bank_name && (
                      <p className={errorMessage}>
                        {errors?.bank_name?.message}
                      </p>
                    )}
                  </div>
                  <div className="common_input_wraper">
                    <label>Bank Account Number</label>
                    <input
                      {...register("bank_account_no")}
                      type="text"
                      placeholder={`Bank Account Number`}
                    />
                  </div>
                  <div className="common_input_wraper">
                    <label>Bank Cheque Number</label>
                    <input
                      {...register("bank_cheque_no")}
                      type="text"
                      placeholder={`Bank Cheque Number`}
                    />
                  </div>
                </>
              ) : null}
              <div className="common_input_wraper">
                <label>Money Receipt No. </label>
                <input
                  {...register(`money_receipt_no`)}
                  type="text"
                  placeholder="Money Receipt No"
                />
              </div>
              <div className="common_input_wraper">
                <label>Previous Due Amount </label>
                <input disabled type="text" placeholder="5703352" />
              </div>
              <div className="common_input_wraper">
                <label>Pay Amount</label>
                <input
                  {...register(`amount`, {
                    pattern: {
                      value: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Insert valid amount",
                    },
                  })}
                  type="text"
                  placeholder="Amount"
                />
                {errors?.amount && (
                  <p className={errorMessage}>{errors?.amount?.message}</p>
                )}
              </div>
              <div className="common_input_wraper">
                <label>After payment Due Amount</label>
                <input disabled type="text" placeholder="5703352" />
              </div>
              <div className="common_input_wraper">
                <label>Document Image Upload</label>
                <input {...register("document_image")} type="file" />
              </div>
            </div>
            <div className="pt-10 text-center">
              <button type="submit" className="submit_button">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddClientPaymentDueList;
