import React, { useEffect, useState } from "react";
import { baseUrl, baseUrlImg } from "../../../BaseUrl";
import axios from "axios";
import { Link } from "react-router-dom";
import { FaRegEye } from "react-icons/fa";
import { TiEdit } from "react-icons/ti";
import {
  addCustomerCss,
  commonTitle,
} from "../../../asset/commoncssConstant/CommonCssConstant";

const UserList = () => {
  const [userList, setUserList] = useState([]);
  const [allRole, setAllRole] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const token = localStorage.getItem("token");

  //-----------get all user,role,project list info star--------------------
  useEffect(() => {
    axios
      .get(baseUrl + `/view-all-users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setUserList(res.data.allusers);
      });
    axios
      .get(baseUrl + `/allRole`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setAllRole(res.data.allRole);
      });

    axios
      .get(baseUrl + `/landInformationBank/projectInformation/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setProjectList(res.data.data);
      });
  }, [token]);
  //-----------get all user,role,project list info end --------------------

  return (
    <div>
      <div className="md:flex justify-between items-center mb-4">
        <h1 className={`${commonTitle} m-3 md:m-0`}>User List</h1>
        <Link
          to={`/dashboard/userList/createUser`}
          state={{
            projectList: projectList,
            allRole: allRole,
          }}
        >
          <button className={addCustomerCss}>+ Create User</button>
        </Link>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 bg-[#FFFFFF] p-4">
        {userList?.map((user) => (
          <div
            key={user.id}
            className=" border border-[#d5d7d7] rounded-lg bg-[#F5F6F8] shadow-[-0.8px -1.2px 2px 0px rgba(0, 71, 62, 0.14), 1px 2px 3px 0px rgba(0, 71, 62, 0.12)]  px-2 opacity-80"
          >
            <div className="flex justify-end">
              <details className="dropdown dropdown-right ">
                <summary className="btn bg-[#F5F6F8] border-none hover:bg-[#F5F6F8] ">
                  <div className="flex gap-2">
                    <button className="border border-[#d5d7d7] p-1 rounded-md">
                      {" "}
                      <Link
                        className="h-6 w-6 p-0 hover:!text-black"
                        to={`/dashboard/userList/userInformation`}
                        state={{
                          data: user,
                        }}
                      >
                        <FaRegEye />
                      </Link>
                    </button>
                    <button className="border border-[#d5d7d7] p-1 rounded-md">
                      {" "}
                      <Link
                        className="h-full w-full p-0 hover:!text-black"
                        to={`/dashboard/userList/editUser`}
                        state={{
                          user: user,
                          projectList: projectList,
                          allRole: allRole,
                        }}
                      >
                        <TiEdit />
                      </Link>
                    </button>
                  </div>
                  {/*  <div className="flex flex-col gap-[1px]">
                    <div className="border border-[#218194] h-1 w-1 rounded-full bg-[#218194]"></div>
                    <div className="border border-[#218194] h-1 w-1 rounded-full bg-[#218194]"></div>
                    <div className="border border-[#218194] h-1 w-1 rounded-full bg-[#218194]"></div>
                  </div> */}
                </summary>
                {/* <ul
                  tabIndex={0}
                  className="dropdown-content left-4 menu shadow bg-base-100 rounded-lg -ml-5 mt-3"
                >
                  <li className="pb-1">
                    <Link
                      className="h-full w-full p-0 hover:!text-black"
                      to={`/dashboard/userList/userInformation`}
                      state={{
                        data: user,
                      }}
                    >
                      <FaRegEye />
                    </Link>
                  </li>
                  <li className="pb-1">
                    <Link
                      className="h-full w-full p-0 hover:!text-black"
                      to={`/dashboard/userList/editUser`}
                      state={{
                        user: user,
                        projectList: projectList,
                        allRole: allRole,
                      }}
                    >
                      <TiEdit />
                    </Link>
                  </li>
                </ul> */}
              </details>
            </div>
            <div className="flex gap-2 justify-between items-center">
              <div className="flex flex-col gap-2">
                <img
                  className="w-[75px] h-[75px] rounded-lg"
                  src={baseUrlImg + user?.image}
                  alt=""
                />
                <span className="text-[10px] font-bold pb-[5px]">
                  {user?.designation}
                </span>
              </div>
                <div className="flex items-center gap-1">
                  <div className="border-l-2 border-l-[#adb2b2e9] h-[30px] md:h-[50px]"></div>
                  <div className="border-l-2 border-l-[#adb2b2e9] h-[50px] md:h-[80px]"></div>
                  <div className="border-l-2 border-l-[#adb2b2e9] h-[30px] md:h-[50px]"></div>
                </div>
              <div>
                <div className="flex gap-1">
                  <span className="font-semibold text-[10px]">Name</span>{" "}
                  <span className="font-semibold text-[10px]">:</span>
                  <span className="text-zinc-700 text-[10px]">
                    {user?.username}
                  </span>
                </div>
                <div className="flex gap-1 ">
                  <span className="font-semibold text-[10px]">Contact</span>
                  <span className="font-semibold text-[10px]">:</span>
                  <span className="text-zinc-700 text-[10px]">
                    {user?.mobile_no}
                  </span>
                </div>
                <div className="flex gap-1">
                  <span className="font-semibold text-[10px]">Email</span>{" "}
                  <span className="font-semibold text-[10px]">:</span>
                  <span className="text-zinc-700 text-[10px]">
                    {user?.email}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserList;
