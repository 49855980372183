import React from "react";
import CommonPrintPdfBtn from "../../../../shared/CommonPrintPdfBtn";
import BackButton from "../../../../shared/BackButton";
import { useLocation } from "react-router-dom";
import useCommonPDF from "../../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../../shared/useCommonPrint/useCommonPrint";

const ViewClientMutationCompletePlotList = () => {
  const item = useLocation().state.item;
  const { toPDF, targetRef } = useCommonPDF({
    title: "client-mutation-complete-details",
  });

  const handlePrint = useCommonPrint({
    targetRef,
    title: "client-mutation-complete-details",
    message: "thank you",
  });
  return (
    <div>
      <BackButton title={"View Client Mutation Details"} />
      <div className="grid lg:grid-cols-12  mt-3 gap-5">
        <div ref={targetRef} className="col-span-9 order-2 lg:order-1 client_info_section p-[40px] ">
          <div className="grid md:grid-cols-2">
            <div className="agent_view_table ">
              <table>
                <tbody>
                  <tr>
                    <td>
                      Customer Registry <br />
                      Complete Date
                    </td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>
                      {
                        item?.customer_land_agreement_info
                          ?.registry_complete_date
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>Registry Deed No</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>
                    {item?.customer_land_agreement_info?.deed_no}
                    </td>
                  </tr>
                  <tr>
                    <td>BS Dag No</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>
                      {item?.customer_land_agreement_info?.land_seller_agreement?.bs_info?.map(
                        (bi) => (
                          <p key={bi?.id}>{bi?.bs_dag_no}</p>
                        )
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Customer Name</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>
                      {item?.customer_land_agreement_info?.customer_land_info?.customer_info?.map(
                        (ci) => (
                          <p key={ci?.id}>{ci?.name}</p>
                        )
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Project Name</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>
                      {
                        item?.customer_land_agreement_info?.customer_land_info
                          .plot_info?.project_name
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>File / Plot No</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>
                      {" "}
                      {
                        item?.customer_land_agreement_info?.customer_land_info
                          .plot_info?.file_no
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="agent_view_table ">
              <table>
                <tbody>
                  <tr>
                    <td>Mutation Date </td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.mutation_date}</td>
                  </tr>
                  <tr>
                    <td>Mutation No </td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.mutation_no}</td>
                  </tr>
                  <tr>
                    <td>Sector </td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>
                      {
                        item?.customer_land_agreement_info?.customer_land_info
                          .plot_info?.sector
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>Plot Size Katha </td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>
                      {
                        item?.customer_land_agreement_info?.customer_land_info
                          .plot_info?.plot_size_katha
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>Plot Size Ojudangsho </td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>
                      {
                        item?.customer_land_agreement_info?.customer_land_info
                          .plot_info?.plot_size_ojutangsho
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>Plot Dimension </td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>
                      {
                        item?.customer_land_agreement_info?.customer_land_info
                          .plot_info?.plot_dimension
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <h3 className="text-[14px] text-[#717A82] font-[700] mt-7">
            Description
          </h3>
          <p className="text-[14px] text-[#8F8F8F] font-[400]">
            {item?.description}
          </p>
        </div>

        {/* right side button group  */}
        <div className="col-span-3 order-1 lg:order-2  ">
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} isBlock={true} />
         {/*  <div className="commonprintpdfbtn whitespace-nowrap mt-5">
            <button className="bg-[#F97316]">Legal Paper Folder</button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ViewClientMutationCompletePlotList;
