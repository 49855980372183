import React from "react";
import { StyleSheet, Document, Page, View, Text } from "@react-pdf/renderer";

const CreatePDF = ({ item, next_payment_date }) => {

  const {
    seller_info,
    dolil_variation,
    agreement_date,
    bs_info,
    sub_deed_no,
    price_info,
  } = item ?? {};
  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "#fff",
    },
    section: {
      margin: 30,
      padding: 10,
      flexGrow: 1,
    },
    title: {
      textAlign: "center",
    },
    table: {
      display: "table",
      width: "auto",
      marginTop: 50,
      marginHorizontal: "auto",
    },
    tableRow: {
      flexDirection: "row",
      alignItems: "center",
      height: 24,
    },
    tableCol: {
      width: "20%",
      flexGrow: 1,
    },
    lastTableCol: {
      width: "50%",
    },
    cell: {
      fontSize: 12,
      padding: 3,
    },
    colSeparator: {
      width: 20,
      textAlign: "center",
    },
    signature: {
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
      marginTop: 350,
      fontSize: 12,
    },
    signatureTitle: {
      marginTop: 10,
      marginHorizontal: "auto",
    },
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>Land Information</Text>

          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.cell}>Seller ID</Text>
              </View>
              <View style={styles.colSeparator}>
                <Text style={styles.cell}>:</Text>
              </View>
              <View style={[styles.tableCol, styles.lastTableCol]}>
                <Text style={styles.cell}>
                  {seller_info?.map((seller, i) => (
                    <React.Fragment key={seller.id}>
                      <Text>{seller?.id}</Text>
                      {i < seller_info?.length - 1 && <Text>, </Text>}
                    </React.Fragment>
                  ))}
                </Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.cell}>Seller Name</Text>
              </View>
              <View style={styles.colSeparator}>
                <Text style={styles.cell}>:</Text>
              </View>
              <View style={[styles.tableCol, styles.lastTableCol]}>
                <Text style={styles.cell}>
                  {seller_info?.map((seller, i) => (
                    <React.Fragment key={seller.id}>
                      <Text>{seller?.name}</Text>
                      {i < seller_info?.length - 1 && <Text>, </Text>}
                    </React.Fragment>
                  ))}
                </Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.cell}>Dolil Variation</Text>
              </View>
              <View style={styles.colSeparator}>
                <Text style={styles.cell}>:</Text>
              </View>
              <View style={[styles.tableCol, styles.lastTableCol]}>
                <Text style={styles.cell}>{dolil_variation?.name}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.cell}>Agreement Date</Text>
              </View>
              <View style={styles.colSeparator}>
                <Text style={styles.cell}>:</Text>
              </View>
              <View style={[styles.tableCol, styles.lastTableCol]}>
                <Text style={styles.cell}>
                  {new Date(agreement_date).toLocaleDateString()}
                </Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.cell}>Seller Deed No</Text>
              </View>
              <View style={styles.colSeparator}>
                <Text style={styles.cell}>:</Text>
              </View>
              <View style={[styles.tableCol, styles.lastTableCol]}>
                <Text style={styles.cell}>{sub_deed_no}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.cell}>BS ID</Text>
              </View>
              <View style={styles.colSeparator}>
                <Text style={styles.cell}>:</Text>
              </View>
              <View style={[styles.tableCol, styles.lastTableCol]}>
                <Text style={styles.cell}>
                  {bs_info?.map((bs, i) => (
                    <React.Fragment key={bs.id}>
                      <Text>{bs?.id}</Text>
                      {i < bs_info?.length - 1 && <Text>, </Text>}
                    </React.Fragment>
                  ))}
                </Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.cell}>Total Land Price</Text>
              </View>
              <View style={styles.colSeparator}>
                <Text style={styles.cell}>:</Text>
              </View>
              <View style={[styles.tableCol, styles.lastTableCol]}>
                <Text style={styles.cell}>{price_info?.total_price}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.cell}>Paid Amount</Text>
              </View>
              <View style={styles.colSeparator}>
                <Text style={styles.cell}>:</Text>
              </View>
              <View style={[styles.tableCol, styles.lastTableCol]}>
                <Text style={styles.cell}>{price_info?.paid_amount}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.cell}>Due Amount</Text>
              </View>
              <View style={styles.colSeparator}>
                <Text style={styles.cell}>:</Text>
              </View>
              <View style={[styles.tableCol, styles.lastTableCol]}>
                <Text style={styles.cell}>{price_info?.total_price-price_info?.paid_amount}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.cell}>Next Payment Date</Text>
              </View>
              <View style={styles.colSeparator}>
                <Text style={styles.cell}>:</Text>
              </View>
              <View style={[styles.tableCol, styles.lastTableCol]}>
                <Text style={styles.cell}>{next_payment_date}</Text>
              </View>
            </View>
          </View>
          <View style={styles.signature}>
            <View>
              <Text>________________</Text>
              <Text style={styles.signatureTitle}>Seller Signature</Text>
            </View>
            <View>
              <Text>________________</Text>
              <Text style={styles.signatureTitle}>Official Signature</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default CreatePDF;
