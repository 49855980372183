import React from "react";
import CommonPrintPdfBtn from "../../../../shared/CommonPrintPdfBtn";
import BackButton from "../../../../shared/BackButton";
import { useLocation } from "react-router-dom";
import useCommonPDF from "../../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../../shared/useCommonPrint/useCommonPrint";

const CsMutationView = () => {
  const item = useLocation().state.item;
  const { toPDF, targetRef } = useCommonPDF({ title: "cs mutation-details" });

  const handlePrint = useCommonPrint({
    targetRef,
    title: "cs mutation-details",
    message: "thank you",
  });
  return (
    <div>
      <BackButton title={"CS Mutation Information"} />
      <div className="grid lg:grid-cols-12  mt-3 gap-5">
        <div ref={targetRef} className="col-span-9 order-2 lg:order-1 client_info_section p-[40px] ">
          <div className="grid  grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 bg-[#F5F6F8]  px-10 border border-[#d5d7d7] rounded-[5px] mb-10">
            <div className="agent_view_table ">
              <table style={{ borderSpacing: "0 8px" }}>
                <tbody>
                  <tr>
                    <td>
                      <strong>Project Name</strong>{" "}
                    </td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>
                      <strong>
                        {item?.cs_info?.mouza_info?.project_info?.project_name}
                      </strong>{" "}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="agent_view_table ">
              <table style={{ borderSpacing: "0 8px" }}>
                <tbody>
                  <tr>
                    <td>
                      <strong>Mouza Name</strong>{" "}
                    </td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>
                      <strong>{item?.cs_info?.mouza_info?.mouza_name}</strong>{" "}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
            <div className="agent_view_table ">
              <table>
                <tbody>
                  <tr>
                    <td>CS Mutation Create Date</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.created_at?.slice(0, 10)}</td>
                  </tr>
                  <tr>
                    <td>CS Dag</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.cs_info?.cs_dag_no}</td>
                  </tr>
                  <tr>
                    <td>CS Khatiyan</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.cs_info?.cs_khatiyan_no}</td>
                  </tr>
                  <tr>
                    <td>Mutation Date</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.mutation_date}</td>
                  </tr>
                  <tr>
                    <td>Mutation No</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.mutation_no}</td>
                  </tr>
                  <tr>
                    <td>Mutation Land Size</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.land_size}</td>
                  </tr>
                  <tr>
                    <td>Mutation Owner Name</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.owner_name}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="agent_view_table ">
              <table>
                <tbody>
                  <tr>
                    <td>Ref. Deed No</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.ref_deed_no}</td>
                  </tr>
                  <tr>
                    <td>Ref. Deed No Date</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.ref_deed_no_date}</td>
                  </tr>
                  <tr>
                    <td>Misscase No</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.misscase_no}</td>
                  </tr>
                  <tr>
                    <td>Misscase Date</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.misscase_date}</td>
                  </tr>
                  <tr>
                    <td>Misscase Judgment Date </td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.misscase_judgement_date}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <h3 className="text-[14px] text-[#717A82] font-[700]">Description</h3>
          <p className="text-[14px] text-[#8F8F8F] font-[400]">
            {item?.description}
          </p>
        </div>

        {/* right side button group  */}
        <div className="col-span-3 order-1 lg:order-2  ">
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} isBlock={true} />
          {/* <div className="commonprintpdfbtn whitespace-nowrap mt-5">
            <button className="bg-[#F97316]">Legal Paper Folder</button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default CsMutationView;
