import React from "react";
import { useLocation } from "react-router-dom";
import { PDFViewer } from "@react-pdf/renderer";
import BackButton from "../../../../shared/BackButton";
import CreateReceiptPDF from "./CreateReceiptPDF";

const ViewClientReceiptPDF = () => {
  const { item, statement } = useLocation()?.state ?? {};

  return (
    <div className={`w-full h-full`}>
      <BackButton title={"Back"} />
      <PDFViewer className={`w-full h-full mt-5`}>
        <CreateReceiptPDF item={item} statement={statement} />
      </PDFViewer>
    </div>
  );
};

export default ViewClientReceiptPDF;
