import React from "react";
import {
  commonFormMenu,
  commonFormTitle,
  errorMessage,
} from "../../../../asset/commoncssConstant/CommonCssConstant";

const LandInformationForm = ({
  errors,
  register,
  clientData,
  handleSelectByLandInfoId,
  plotList,
  defaultLandInfo,
}) => {
  return (
    <div>
      <p className={commonFormTitle}>Land Information</p>

      <div className={commonFormMenu}>
        <div className="common_input_wraper">
          <label>File/Plot No *</label>
          <select onChange={(e) => handleSelectByLandInfoId(e)} required>
            {clientData?.plot_info?.file_no ? (
              <option
                value={defaultLandInfo?.id || clientData?.plot_info?.file_no}
              >
                {defaultLandInfo?.id || clientData?.plot_info?.file_no}
              </option>
            ) : (
              <option value="">Select File/Plot</option>
            )}

            {plotList.map((list) => (
              <option key={list.id} value={list.id}>
                {list.file_no}
              </option>
            ))}
          </select>
        </div>
        <div className="common_input_wraper">
          <label>Project Name</label>
          <input
            value={
              defaultLandInfo?.project_name ||
              clientData?.plot_info?.project_name
            }
            placeholder="Project Name"
            type="text"
            readOnly
            disabled
          />
        </div>
        <div className="common_input_wraper">
          <label>Road No</label>
          <input
            value={
              defaultLandInfo?.road_number_name ||
              clientData?.plot_info?.road_number_name
            }
            placeholder="Road No"
            type="text"
            readOnly
            disabled
          />
        </div>
        <div className="common_input_wraper">
          <label>Plot Facing</label>
          <input
            value={
              defaultLandInfo?.plot_facing || clientData?.plot_info?.plot_facing
            }
            placeholder="Plot Facing"
            type="text"
            readOnly
            disabled
          />
        </div>
        <div className="common_input_wraper">
          <label>Sector</label>
          <input
            value={defaultLandInfo?.sector || clientData?.plot_info?.sector}
            placeholder="Sector"
            type="text"
            readOnly
            disabled
          />
        </div>
        <div className="common_input_wraper">
          <label>Plot Size (Katha)</label>
          <input
            value={
              defaultLandInfo?.plot_size_katha ||
              clientData?.plot_info?.plot_size_katha
            }
            placeholder="Plot Size (Katha)"
            type="text"
            readOnly
            disabled
          />
        </div>
        <div className="common_input_wraper">
          <label>Plot Size (Ojutangsho)</label>
          <input
            value={
              defaultLandInfo?.plot_size_ojutangsho ||
              clientData?.plot_info?.plot_size_ojutangsho
            }
            placeholder="Plot Size (Ojutangsho)"
            type="text"
            readOnly
            disabled
          />
        </div>
        <div className="common_input_wraper">
          <label>Plot Dimension</label>
          <input
            value={
              defaultLandInfo?.plot_dimension ||
              clientData?.plot_info?.plot_dimension
            }
            placeholder="Plot Dimension"
            type="text"
            readOnly
            disabled
          />
        </div>
        <div className="common_input_wraper">
          <label>Booking Date *</label>
          <input
           {...register("booking_date", {
            required: "Booking Date is required",
          })}
            defaultValue={clientData?.booking_date}
            type="date"
          />
          {errors?.booking_date && (
            <p className={errorMessage}>{errors?.booking_date?.message}</p>
          )}
        </div>
        <div className="common_input_wraper">
          <label>Registration Date</label>
          <input
            {...register("expected_reg_date")}
            defaultValue={clientData?.expected_reg_date}
            type="date"
          />
        </div>
        <div className="col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-5"></div>
        <div className="common_input_wraper col-span-1 lg:col-span-2">
          <label>Plot Address Description</label>
          <textarea
            {...register("plot_address_description")}
            defaultValue={clientData?.plot_address_description}
            rows={4}
            placeholder="Plot Address Description"
          />
        </div>
      </div>
    </div>
  );
};

export default LandInformationForm;
