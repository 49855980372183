import React, { useState } from "react";
import BackButton from "../../../../shared/BackButton";
import {
  commonEditTitle,
  commonFormMenu,
  commonFormTitle,
  dashboardBodyWraper,
} from "../../../../asset/commoncssConstant/CommonCssConstant";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../../../BaseUrl";

const AddClientMutationCompletePlotList = () => {
  const landAgreementList = useLocation().state.landAgreementList;
  const [defaultData, setDefaultData] = useState(null);
  const { register, handleSubmit } = useForm();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  // console.log(defaultData);
  //-------get rs info by id select option start---------------
  const handleSelectById = (e) => {
    e.preventDefault();
    setDefaultData(
      landAgreementList.find((list) => list.id === parseInt(e.target.value))
    );
  };
  //-------get rs info by id select option end---------------

  const onSubmit = (data) => {
    data.customer_land_agreement_id = defaultData.id;
    // console.log(data);
    axios
      .post(baseUrl + `/plotBank/mutationComplete/store`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          navigate(-1);
        }
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BackButton title={"Add Client Mutation Complete Plot List"} />
      <div
        style={{ padding: 0, marginTop: "10px" }}
        className={`${dashboardBodyWraper}`}
      >
        <div className="p-[30px]">
        <p className={commonFormTitle}>File / Plot Information</p>
          <div className={commonFormMenu}>
            <div className="common_input_wraper">
              <label>Registry Deed No</label>
              <select onChange={(e) => handleSelectById(e)} required>
                <option value="">Select Registry Deed No</option>
                {landAgreementList?.map((list) => (
                  <option key={list.id} value={list.id}>
                    {list?.land_agreement?.deed_no}
                  </option>
                ))}
              </select>
            </div>
            <div className="common_input_wraper">
              <label>Plot Facing</label>
              <input
                type="text"
                placeholder="Plot Facing"
                value={defaultData?.plot_info?.plot_facing || ""}
                readOnly
                disabled
              />
            </div>
            <div className="common_input_wraper">
              <label>BS ID</label>
              <input
                type="text"
                placeholder="BS ID"
                value={
                  defaultData?.land_agreement?.land_seller_agreement?.bs_info?.map(
                    (bs) => bs.id
                  ) || ""
                }
                readOnly
                disabled
              />
            </div>

            <div className="common_input_wraper">
              <label>BS Dag No</label>
              <input
                type="text"
                placeholder="BS Dag No"
                value={
                  defaultData?.land_agreement?.land_seller_agreement?.bs_info?.map(
                    (bs) => bs.bs_dag_no
                  ) || ""
                }
                readOnly
                disabled
              />
            </div>
            <div className="common_input_wraper">
              <label>BS khatiyan No</label>
              <input
                type="text"
                placeholder="BS khatiyan No"
                value={
                  defaultData?.land_agreement?.land_seller_agreement?.bs_info?.map(
                    (bs) => bs.bs_khatiyan_no
                  ) || ""
                }
                readOnly
                disabled
              />
            </div>
            <div className="common_input_wraper">
              <label>Customer ID</label>
              <input
                {...register("customer_land_agreement_id")}
                type="text"
                placeholder="Customer ID"
                value={defaultData?.customer_info?.map((ci) => ci.id) || ""}
                readOnly
                disabled
              />
            </div>
            <div className="common_input_wraper">
              <label>Customer Name</label>
              <input
                type="text"
                placeholder="Customer Name"
                value={defaultData?.customer_info?.map((ci) => ci.name) || ""}
                readOnly
                disabled
              />
            </div>
            <div className="common_input_wraper">
              <label>Project Name</label>
              <input
                type="text"
                placeholder="Project Name"
                value={defaultData?.plot_info?.project_name || ""}
                readOnly
                disabled
              />
            </div>
            <div className="common_input_wraper">
              <label>File / Plot No</label>
              <input
                type="text"
                placeholder="File / Plot No"
                value={defaultData?.plot_info?.file_no || ""}
                readOnly
                disabled
              />
            </div>
            <div className="common_input_wraper">
              <label>Sector</label>
              <input
                type="text"
                placeholder="Sector"
                value={defaultData?.plot_info?.sector || ""}
                readOnly
                disabled
              />
            </div>
            <div className="common_input_wraper">
              <label>Plot Size Katha</label>
              <input
                type="text"
                placeholder="Plot Size Katha"
                value={defaultData?.plot_info?.plot_size_katha || ""}
                readOnly
                disabled
              />
            </div>
            <div className="common_input_wraper">
              <label>Plot Size Ojudangsho</label>
              <input
                type="text"
                placeholder="Plot Size Ojudangsho"
                value={defaultData?.plot_info?.plot_size_ojutangsho || ""}
                readOnly
                disabled
              />
            </div>
            <div className="common_input_wraper">
              <label>Plot Dimension</label>
              <input
                type="text"
                placeholder="Plot Dimension"
                value={defaultData?.plot_info?.plot_dimension || ""}
                readOnly
                disabled
              />
            </div>
            <div className="common_input_wraper">
              <label>Mutation No</label>
              <input
                {...register("mutation_no")}
                type="text"
                placeholder="Mutation No"
              />
            </div>
            <div className="common_input_wraper">
              <label>Mutation Date</label>
              <input {...register("mutation_date")} type="date" />
            </div>
            <div className="col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-5"></div>
            <div className="common_input_wraper col-span-1 lg:col-span-2 xl:col-span-2">
              <label>Description</label>
              <textarea
                {...register("description")}
                rows={4}
                placeholder="Description"
              />
            </div>
          </div>
          <div className="text-center pb-12 mt-5">
            <button type="submit" className="submit_button">
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddClientMutationCompletePlotList;
