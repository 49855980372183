import React, { useEffect, useState } from "react";
import Pagination from "../../../shared/pagination/Pagination";
import {
  addCustomerCss,
  commonAction,
  commonAddButton,
  commonCaption,
  commonSearchInput,
  commonTableHeadingTwo,
  commonTableRow,
  commonTitle,
  dashboardBodyWraper,
} from "../../../asset/commoncssConstant/CommonCssConstant";
import CommonPrintPdfBtn from "../../../shared/CommonPrintPdfBtn";
import ViewBtn from "../../../asset/images/clientlist/view.png";
import ActionBtn from "../../../asset/images/clientlist/action.png";
import { Link, useNavigate } from "react-router-dom";
import AddUserImg from "../../../asset/images/clientlist/add-user.png";
import axios from "axios";
import { baseUrl, baseUrlImg } from "../../../BaseUrl";
import { LuArrowDownUp } from "react-icons/lu";
import useCommonPDF from "../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../shared/useCommonPrint/useCommonPrint";

const AgentList = () => {
  const navigate = useNavigate();
  const [agentList, setAgentList] = useState([]);
  const token = localStorage.getItem("token");
  const [searchValue, setSearchValue] = useState("");

  const [paginationData, setPaginationData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const { toPDF, targetRef } = useCommonPDF({ title: "agent-list" });

  const handlePrint = useCommonPrint({
    targetRef,
    title: "agent list",
    message: "thank you",
  });

  // pagination part start here
  const handleNext = () => {
    if (page === pageCount) {
      return page;
    } else {
      return setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page === 1) {
      return page;
    } else {
      return setPage(page - 1);
    }
  };

  //-----------get agent list start --------------------
  useEffect(() => {
    axios
      .get(baseUrl + `/agent/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setAgentList(res.data.data);
      });
  }, [token, page]);
  //-----------get agent list end --------------------
  return (
    <div>
      <h1 className={`${commonTitle}`}>Agent List</h1>
      <div className={dashboardBodyWraper}>
        <div className={`${commonAddButton} px-2`}>
          <button
            onClick={() => navigate("/dashboard/agent-list/add")}
            className={addCustomerCss}
          >
            Add Agent <img className="ml-[30px]" src={AddUserImg} alt="" />
          </button>
        </div>

        <div className={commonCaption}>
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} />
          <input
            onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
            type="text"
            placeholder="Search"
            className={commonSearchInput}
          />
        </div>

        <div ref={targetRef} className="table_responsive mt-[40px]">
          <table>
            <thead>
              <tr className={`${commonTableRow}`}>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>join Date</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Agent Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Father Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Mother Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> NID No</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Date of Birth</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Picture</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>
                      Educational <br />
                      Background
                    </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>mobile no. 1</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>mobile no. 2</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>

                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Email</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Present Address</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Permanent Address</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`py-2`}>
                    <span>
                      {" "}
                      Action <br /> Update / View
                    </span>{" "}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {paginationData
                ?.filter((info) => {
                  return searchValue.toLowerCase() !== ""
                    ? info?.join_date?.toLowerCase().includes(searchValue) ||
                        info?.name?.toLowerCase().includes(searchValue) ||
                        info?.father_name
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.mother_name
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.nid
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.dob?.toLowerCase().includes(searchValue) ||
                        info?.educational_background
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.mobile_number_1
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.mobile_number_2
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.email?.toLowerCase().includes(searchValue) ||
                        info?.present_address
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.permanent_address
                          ?.toLowerCase()
                          .includes(searchValue)
                    : info;
                })
                ?.map((item) => (
                  <tr className="text-center" key={item.id}>
                    <td>{item.join_date}</td>
                    <td>
                      <span className="font-bold">{item.name}</span>
                    </td>
                    <td>
                      <span className="font-bold">{item.father_name}</span>
                    </td>
                    <td>
                      <span className="font-bold">{item.mother_name}</span>
                    </td>
                    <td>
                      <span className="font-bold">{item.nid}</span>
                    </td>
                    <td>
                      <span className="font-bold">{item.dob}</span>
                    </td>
                    <td>
                      <div className="flex justify-center pe-4">
                        <img
                          className="w-11 rounded-md"
                          src={baseUrlImg + item.image}
                          alt=""
                          srcSet=""
                        />
                      </div>
                    </td>
                    <td>
                      <span className="font-bold">
                        {item.educational_background}
                      </span>
                    </td>
                    <td>
                      <span className="font-bold">{item.mobile_number_1}</span>
                    </td>
                    <td>
                      <span className="font-bold">{item.mobile_number_2}</span>
                    </td>
                    <td>
                      <span className="font-bold">{item.email}</span>
                    </td>
                    <td>
                      <span className="font-bold">{item.present_address}</span>
                    </td>
                    <td>
                      <span className="font-bold">
                        {item.permanent_address}
                      </span>
                    </td>
                    <td>
                      <span className="flex justify-center gap-2">
                        <Link
                          to={`/dashboard/agent-list/edit`}
                          state={{
                            data: item,
                          }}
                        >
                          <img
                            className={commonAction}
                            src={ActionBtn}
                            alt=""
                          />
                        </Link>
                        <Link
                          to={`/dashboard/agent-list/view`}
                          state={{
                            data: item,
                          }}
                        >
                          <img className={commonAction} src={ViewBtn} alt="" />
                        </Link>
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <Pagination
          data={agentList}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          setPageCount={setPageCount}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      </div>
    </div>
  );
};

export default AgentList;
