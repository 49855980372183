import React from "react";
import Documents from "../../../asset/images/documents.png";
import DownLoadIcon from "../../../asset/images/icon/download.png";
import "./folderDocument.css";
import CommonModal from "../../../shared/CommonModal";
const FolderDocuments = ({ active }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  return (
    <div>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5  xl:grid-cols-7 gap-2  mt-10 ">
        {[...Array(active + 4).keys()].map((item, i) => (
          <div
            onClick={() => setIsOpen(true)}
            key={i}
            className=" folder_documents"
          >
            <img
              className=" h-full w-full  object-fill "
              src={Documents}
              alt=""
            />
            <button className="cm_shadow">
              Demo.jpg
              <img src={DownLoadIcon} alt="" />
            </button>
          </div>
        ))}
      </div>
      <CommonModal
        modalIsOpen={modalIsOpen}
        closeModal={() => setIsOpen(false)}
        openModal={() => setIsOpen(true)}
      >
        <img
          className="w-[550px] h-[750px] object-fill"
          src="https://wallpapers.com/images/hd/pretty-profile-pictures-zenm2vnix8c16700.jpg"
          alt=""
        />
      </CommonModal>
    </div>
  );
};

export default FolderDocuments;
