import React, { useState } from "react";
import BackButton from "../../../../shared/BackButton";
import {
  commonBankColumnSpan,
  commonBankMenu,
  commonFormTitle,
  dashboardBodyWraper,
  errorMessage,
} from "../../../../asset/commoncssConstant/CommonCssConstant";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { baseUrl } from "../../../../BaseUrl";

const AddRsMutationInfo = () => {
  const [defaultData, setDefaultData] = useState(null);
  const dagKhaytanForSelect = useLocation().state.dagKhaytanForSelect;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  //-------get data by id select option start---------------
  const handleSelectById = (e) => {
    e.preventDefault();
    setDefaultData(
      dagKhaytanForSelect?.find((list) => list.rs_dag_no === e.target.value)
    );
  };
  //-------get data by id select option end---------------
  //--------landMutationBank/rsMutationList/store start---------
  const onSubmit = (data) => {
    data.rs_id = defaultData?.id;
    axios
      .post(baseUrl + `/landMutationBank/rsMutationList/store`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          navigate("/dashboard/rs-mutation-list");
        }
      });
  };
  //--------landMutationBank/rsMutationList/store end---------
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BackButton title={"Add RS Mutation Information"} />

      <div className={`${dashboardBodyWraper} py-[4px] `}>
        <p className={commonFormTitle}>Land Information</p>
        <div className={commonBankMenu}>
          <div className="common_input_wraper">
            <label>RS Dag *</label>
            <select onChange={(e) => handleSelectById(e)} required>
              <option value="">Select RS Dag</option>
              {dagKhaytanForSelect.map((list) => (
                <option key={list.id} value={list.rs_dag_no}>
                  {list.rs_dag_no}
                </option>
              ))}
            </select>
          </div>
          <div className="common_input_wraper">
            <label>RS Khatiyan</label>
            <input
              type="text"
              placeholder="RS Khatiyan"
              readOnly
              disabled
              value={defaultData?.rs_khatiyan_no}
            />
          </div>
          <div className="common_input_wraper">
            <label>Mutation Date</label>
            <input
              {...register("mutation_date", {
                required: "Mutation Date is required",
              })}
              type="date"
            />
            {errors?.mutation_date && (
              <p className={errorMessage}>{errors?.mutation_date?.message}</p>
            )}
          </div>
          <div className="common_input_wraper">
            <label>Mutation No</label>
            <input
              {...register(`mutation_no`, {
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Insert valid mutation no.",
                },
              })}
              type="text"
              placeholder="Mutation No"
            />
            {errors?.mutation_no && (
              <p className={errorMessage}>{errors?.mutation_no?.message}</p>
            )}
          </div>
          <div className="common_input_wraper">
            <label>Mutation Land Size</label>
            <input
              {...register(`land_size`, {
                pattern: {
                  value: /^[0-9]+(\.[0-9]+)?$/,
                  message: "Insert valid land size",
                },
              })}
              type="text"
              step="0.01"
            />
            {errors?.land_size && (
              <p className={errorMessage}>{errors?.land_size?.message}</p>
            )}
          </div>
          <div className="common_input_wraper">
            <label>Mutation Owner Name</label>
            <input
              {...register(`owner_name`, {
                pattern: {
                  value: /^[A-Za-z]+[A-Za-z0-9\-_~(\s]*[A-Za-z0-9\s)]$/,
                  message: "Insert valid owner name",
                },
              })}
              type="text"
              placeholder="Owner Name"
            />
            {errors?.owner_name && (
              <p className={errorMessage}>{errors?.owner_name?.message}</p>
            )}
          </div>
          <div className="common_input_wraper">
            <label>Reference Deed No (if any)</label>
            <input
              {...register(`ref_deed_no`, {
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Insert valid deed no.",
                },
              })}
              type="text"
              placeholder="Reference Deed No"
            />
            {errors?.ref_deed_no && (
              <p className={errorMessage}>{errors?.ref_deed_no?.message}</p>
            )}
          </div>
          <div className="common_input_wraper">
            <label>Reference Deed No Date (if any)</label>
            <input {...register("ref_deed_no_date")} type="date" />
          </div>
          <div className="common_input_wraper">
            <label>Misscase No (if any)</label>
            <input
               {...register(`misscase_no`, {
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Insert valid misscase no.",
                },
              })}
              type="text"
              placeholder="Misscase No"
            />
            {errors?.misscase_no && (
              <p className={errorMessage}>{errors?.misscase_no?.message}</p>
            )}
          </div>
          <div className="common_input_wraper">
            <label>Misscase Date (if any)</label>
            <input {...register("misscase_date")} type="date" />
          </div>
          <div className="common_input_wraper">
            <label>Misscase Judgment date (if any)</label>
            <input {...register("misscase_judgement_date")} type="date" />
          </div>
          <div className="col-span-1 md:col-span-2 lg:columns-3 xl:col-span-4"></div>
          <div className={`common_input_wraper ${commonBankColumnSpan}`}>
            <label>Description</label>
            <textarea
              {...register("description")}
              rows={4}
              type="text"
              placeholder="Mutation Complete Summery , Misscase Judgement Complete Summery "
            />
          </div>
        </div>

        <div className="text-center py-10">
          <button type="submit" className="submit_button">
            Submit
          </button>
        </div>
      </div>
    </form>
  );
};

export default AddRsMutationInfo;
