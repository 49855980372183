import React from "react";
import CommonPrintPdfBtn from "../../../../shared/CommonPrintPdfBtn";
import BackButton from "../../../../shared/BackButton";
import { useLocation } from "react-router-dom";
import useCommonPDF from "../../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../../shared/useCommonPrint/useCommonPrint";

const ViewClientPlotPaymentCompleteList = () => {
  const item = useLocation().state.item;
  const { toPDF, targetRef } = useCommonPDF({
    title: "plot-payment-complete-details",
  });

  const handlePrint = useCommonPrint({
    targetRef,
    title: "plot-payment-complete-details",
    message: "thank you",
  });
  return (
    <div>
      <BackButton title={"Client Plot Payment Details"} />
      <div className="grid lg:grid-cols-12  mt-3 gap-5">
        <div
          ref={targetRef}
          className="col-span-9 order-2 lg:order-1 client_info_section p-[40px] "
        >
          <div className="grid md:grid-cols-2">
            <div className="agent_view_table ">
              <table>
                <tbody>
                  <tr>
                    <td>Booking Date</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.land_info?.booking_date}</td>
                  </tr>
                  <tr>
                    <td>Customer Name</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>
                      {" "}
                      {item?.land_info?.customer_info?.map((ci) => (
                        <p key={ci.id}>{ci?.name}</p>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <td>Project Name</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.land_info?.plot_info?.project_name}</td>
                  </tr>
                  <tr>
                    <td>File / Plot No</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.land_info?.plot_info?.file_no}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="agent_view_table ">
              <table>
                <tbody>
                  <tr>
                    <td>Sector </td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.land_info?.plot_info?.sector}</td>
                  </tr>
                  <tr>
                    <td>Plot Size Katha </td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.land_info?.plot_info?.plot_size_katha}</td>
                  </tr>
                  <tr>
                    <td>Plot Size Ojudangsho </td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.land_info?.plot_info?.plot_size_ojutangsho}</td>
                  </tr>
                  <tr>
                    <td>Plot Dimension </td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.land_info?.plot_info?.plot_dimension}</td>
                  </tr>
                  <tr>
                    <td>Total Price </td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.total_amount}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <h3 className="text-[14px] text-[#717A82] font-[700] mt-7">
            Description
          </h3>
          <p className="text-[14px] text-[#8F8F8F] font-[400]">
            {item?.land_info?.plot_address_description}
          </p>
        </div>

        {/* right side button group  */}
        <div className="col-span-3 order-1 lg:order-2  ">
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} isBlock={true} />
          {/* <div className="commonprintpdfbtn whitespace-nowrap mt-5">
            <button className="bg-[#F97316]">Legal Paper Folder</button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ViewClientPlotPaymentCompleteList;
