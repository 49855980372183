import React, { useEffect, useState } from "react";
import {
  addCustomerCss,
  commonAction,
  commonAddButton,
  commonCaption,
  commonSearchInput,
  commonTableHeadingTwo,
  commonTableRow,
  commonTitle,
  dashboardBodyWraper,
} from "../../../asset/commoncssConstant/CommonCssConstant";
import CommonPrintPdfBtn from "../../../shared/CommonPrintPdfBtn";
import AddUserImg from "../../../asset/images/clientlist/add-user.png";
import ActionImg from "../../../asset/images/clientlist/action.png";
import ViewBtn from "../../../asset/images/clientlist/view.png";
import Pagination from "../../../shared/pagination/Pagination";
import { baseUrl } from "../../../BaseUrl";
import axios from "axios";
import { Link } from "react-router-dom";
import { LuArrowDownUp } from "react-icons/lu";
import useCommonPDF from "../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../shared/useCommonPrint/useCommonPrint";

const BsMutationList = () => {
  const [bsMutationList, setBsMutationList] = useState([]);
  const [dagKhaytanForSelect, setDagKhaytanForSelect] = useState([]);
  const token = localStorage.getItem("token");
  const [searchValue, setSearchValue] = useState("");

  const [paginationData, setPaginationData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const { toPDF, targetRef } = useCommonPDF({ title: "bs mutation list" });

  const handlePrint = useCommonPrint({
    targetRef,
    title: "bs mutation list",
    message: "thank you",
  });

  // pagination part start here
  const handleNext = () => {
    if (page === pageCount) {
      return page;
    } else {
      return setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page === 1) {
      return page;
    } else {
      return setPage(page - 1);
    }
  };

  //------get data for bsMutationList and bsDagKhatiyanList start-----------------
  useEffect(() => {
    axios
      .get(baseUrl + `/landMutationBank/bsMutationList/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setBsMutationList(res.data.data);
      });
    axios
      .get(baseUrl + `/landInformationBank/bsDagKhatiyan/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setDagKhaytanForSelect(res.data.data);
      });
  }, [token, page]);
  //------get data for bsMutationList and bsDagKhatiyanList end-----------------
  return (
    <div>
      <h1 className={`${commonTitle}`}>BS Mutation</h1>
      <div className={dashboardBodyWraper}>
        <div className={commonAddButton}>
          <Link
            to={`/dashboard/bs-mutation-list/add`}
            state={{
              dagKhaytanForSelect: dagKhaytanForSelect,
            }}
          >
            <button className={addCustomerCss}>
              <span>Add BS Mutation</span>
              <img className="ml-[30px]" src={AddUserImg} alt="" />
            </button>
          </Link>
        </div>
        <div className={commonCaption}>
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} />
          <input
            onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
            type="text"
            placeholder="Search"
            className={commonSearchInput}
          />
        </div>
        <div ref={targetRef} className="table_responsive mt-[40px]">
          <table>
            <thead>
              <tr className={`${commonTableRow}`}>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Create Date</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>BS Dag</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>BS Khatiyan</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Mutation Date</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Mutation No</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>
                      Mutation <br />
                      Land Size
                    </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>
                      Mutation <br />
                      Owner Name
                    </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Ref. Deed No</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Ref. Deed No Date</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Misscase No</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Misscase Date</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>
                      Misscase <br /> Judgment Date
                    </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>
                      Action <br />
                      Update / View
                    </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {paginationData
                ?.filter((info) => {
                  return searchValue.toLowerCase() !== ""
                    ? info?.created_at
                        ?.slice(0, 10)
                        .toLowerCase()
                        .includes(searchValue) ||
                        info?.bs_dag_info?.bs_dag_no
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.bs_dag_info?.bs_khatiyan_no
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.mutation_date
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.mutation_no
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.land_size
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.owner_name?.toLowerCase().includes(searchValue) ||
                        info?.ref_deed_no
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.ref_deed_no_date
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.misscase_no
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.misscase_date
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.misscase_judgement_date
                          ?.toLowerCase()
                          .includes(searchValue)
                    : info;
                })
                ?.map((item, i) => (
                  <tr className="text-center" key={item?.id}>
                    <td>{item?.created_at?.slice(0, 10)}</td>
                    <td>{item?.bs_dag_info?.bs_dag_no}</td>
                    <td>
                      <span className="font-bold">
                        {item?.bs_dag_info?.bs_khatiyan_no}
                      </span>
                    </td>
                    <td>{item?.mutation_date}</td>
                    <td>
                      <span className="">{item?.mutation_no}</span>
                    </td>
                    <td>{item?.land_size}</td>
                    <td>
                      <span className="font-bold">{item?.owner_name}</span>
                    </td>
                    <td>
                      <span className="font-bold">{item?.ref_deed_no}</span>
                    </td>
                    <td>{item?.ref_deed_no_date}</td>
                    <td>
                      <span className="font-bold">{item?.misscase_no}</span>
                    </td>
                    <td>{item?.misscase_date}</td>
                    <td>{item?.misscase_judgement_date}</td>
                    <td>
                      <span className="flex gap-2 justify-center">
                        <Link
                          to={`/dashboard/bs-mutation-list/edit`}
                          state={{
                            item: item,
                            dagKhaytanForSelect: dagKhaytanForSelect,
                          }}
                        >
                          <img
                            className={commonAction}
                            src={ActionImg}
                            alt=""
                          />
                        </Link>
                        <Link
                          to={`/dashboard/bs-mutation-list/view`}
                          state={{
                            item: item,
                          }}
                        >
                          <img className={commonAction} src={ViewBtn} alt="" />
                        </Link>
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <Pagination
          data={bsMutationList}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          setPageCount={setPageCount}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      </div>
    </div>
  );
};

export default BsMutationList;
