import React, { useState } from "react";
import {
  commonBankColumnSpan,
  commonBankMenu,
  commonFormTitle,
  dashboardBodyWraper,
  errorMessage,
} from "../../../../asset/commoncssConstant/CommonCssConstant";
import BackButton from "../../../../shared/BackButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { baseUrl } from "../../../../BaseUrl";

const AddSaMouzaMap = () => {
  const [defaultData, setDefaultData] = useState(null);
  const dagKhaytanForSelect = useLocation().state.dagKhaytanForSelect;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  //-------get data by id select option start---------------
  const handleSelectById = (e) => {
    e.preventDefault();
    setDefaultData(
      dagKhaytanForSelect?.find((list) => list.sa_dag_no === e.target.value)
    );
  };
  //-------get data by id select option end---------------
  //--------landMouzaMapBank/saMouzaMapList/store start---------
  const onSubmit = (data) => {
    data.sa_id = defaultData?.id;

    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      if (typeof value == "object") {
        if (key === "map_image") {
          for (let i = 0; i < value.length; i++) {
            if (value instanceof FileList) {
              data[`map_image[${i}]`] = value[i];
              delete data.map_image;
              formData.append(`map_image[${i}]`, value[i]);
            }
          }
        }
      } else {
        formData.append(key, value);
      }
    });

    axios
      .post(baseUrl + `/landMouzaMapBank/saMouzaMapList/store`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          navigate("/dashbooard/sa-mouza-map-list");
        }
      });
  };
  //--------landMouzaMapBank/saMouzaMapList/store end---------
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BackButton title={"Add SA Mouza Map"} />
      <div
        style={{ padding: 0, marginTop: "10px" }}
        className={`${dashboardBodyWraper}`}
      >
        <div className="p-[30px]">
          <p className={commonFormTitle}>SA Mouza Map Information</p>
          <div className={commonBankMenu}>
            <div className="common_input_wraper">
              <label>SA Dag *</label>
              <select onChange={(e) => handleSelectById(e)} required>
                <option value="">Select SA Dag</option>
                {dagKhaytanForSelect.map((list) => (
                  <option key={list.id} value={list.sa_dag_no}>
                    {list.sa_dag_no}
                  </option>
                ))}
              </select>
            </div>
            <div className="common_input_wraper">
              <label>SA Khatiyan</label>
              <input
                type="text"
                placeholder="SA Khatiyan"
                readOnly
                disabled
                value={defaultData?.sa_khatiyan_no}
              />
            </div>
            <div className="common_input_wraper">
              <label>SA Land Size (Sotangso)</label>
              <input
                {...register(`land_size_sotangsho`, {
                  pattern: {
                    value: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Insert valid land size",
                  },
                })}
                type="text"
                step="0.01"
              />
              {errors?.land_size_sotangsho && (
                <p className={errorMessage}>
                  {errors?.land_size_sotangsho?.message}
                </p>
              )}
            </div>
            <div className="common_input_wraper">
              <label>SA Land Size (Ojutangsho)</label>
              <input
                {...register(`land_size_ojutangsho`, {
                  pattern: {
                    value: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Insert valid land size",
                  },
                })}
                type="text"
                step="0.01"
              />
              {errors?.land_size_ojutangsho && (
                <p className={errorMessage}>
                  {errors?.land_size_ojutangsho?.message}
                </p>
              )}
            </div>
            <div className="common_input_wraper">
              <label>SA Land Size (Sq.fit)</label>
              <input
                {...register(`land_size_sq_feet`, {
                  pattern: {
                    value: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Insert valid land size",
                  },
                })}
                type="text"
                step="0.01"
              />
              {errors?.land_size_sq_feet && (
                <p className={errorMessage}>
                  {errors?.land_size_sq_feet?.message}
                </p>
              )}
            </div>
            <div className="common_input_wraper">
              <label>SA Land East to west (Sq.fit)</label>
              <input
                {...register(`land_eastTowest_sq_feet`, {
                  pattern: {
                    value: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Insert valid land size",
                  },
                })}
                type="text"
                step="0.01"
              />
              {errors?.land_eastTowest_sq_feet && (
                <p className={errorMessage}>
                  {errors?.land_eastTowest_sq_feet?.message}
                </p>
              )}
            </div>
            <div className="common_input_wraper">
              <label>SA Land North to South (Sq.fit)</label>
              <input
                {...register(`land_northToSouth_sq_feet`, {
                  pattern: {
                    value: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Insert valid land size",
                  },
                })}
                type="text"
                step="0.01"
              />
              {errors?.land_northToSouth_sq_feet && (
                <p className={errorMessage}>
                  {errors?.land_northToSouth_sq_feet?.message}
                </p>
              )}
            </div>
            <div className="common_input_wraper">
              <label>SA Land East & South (Angle)</label>
              <input {...register("land_eastAndSouth_angle")} type="text" />
            </div>
            <div className="common_input_wraper">
              <label>SA Land East & North (Angle)</label>
              <input {...register("land_eastAndNorth_angle")} type="text" />
            </div>
            <div className="common_input_wraper">
              <label>SA Land West & South (Angle)</label>
              <input {...register("land_westAndSouth_angle")} type="text" />
            </div>
            <div className="common_input_wraper">
              <label>SA Land West & North (Angle)</label>
              <input {...register("land_westAndNorth_angle")} type="text" />
            </div>
            <div className="common_input_wraper">
              <label>
                East , South Corner to West , North Corner (Length){" "}
              </label>
              <input
                {...register(`eastSouth_to_westNorth_length`, {
                  pattern: {
                    value: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Insert valid length",
                  },
                })}
                type="text"
                step="0.01"
              />
              {errors?.eastSouth_to_westNorth_length && (
                <p className={errorMessage}>
                  {errors?.eastSouth_to_westNorth_length?.message}
                </p>
              )}
            </div>
            <div className="common_input_wraper">
              <label>
                South , West Corner to North , East Corner (Length){" "}
              </label>
              <input
                {...register(`southWest_to_northEast_length`, {
                  pattern: {
                    value: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Insert valid length",
                  },
                })}
                type="text"
                step="0.01"
              />
              {errors?.southWest_to_northEast_length && (
                <p className={errorMessage}>
                  {errors?.southWest_to_northEast_length?.message}
                </p>
              )}
            </div>

            <div className="common_input_wraper">
              <label>Other file Upload (Multiple)</label>
              <input {...register("map_image")} type="file" multiple />
            </div>

            {/* <div className="common_input_wraper">
              <label>Other file Upload (Multiple)</label>
              <label className="multiPleFileLable mt-2" htmlFor="multiplefile">
                Choose Files
                <img src={RightArrow} alt="" />
              </label>
              <input
                {...register("map_image")}
                style={{ display: "none" }}
                id="multiplefile"
                type="file"
                multiple
              />
            </div> */}
            <div className="col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-5"></div>
            <div className={`common_input_wraper ${commonBankColumnSpan}`}>
              <label>Description</label>
              <textarea {...register("description")} rows={4} type="text" />
            </div>
          </div>
          <div className="text-center py-10">
            <button type="submit" className="submit_button">
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddSaMouzaMap;
