import React from "react";
import { useLocation } from "react-router-dom";
import CommonPrintPdfBtn from "../../../shared/CommonPrintPdfBtn";
import "./clientPriceInfoView.css";
import {
  commonTableHeadingTwo,
  commonTableRow,
  commonUserInfo,
  commonUserRow,
} from "../../../asset/commoncssConstant/CommonCssConstant";
import BackButton from "../../../shared/BackButton";
import useCommonPDF from "../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../shared/useCommonPrint/useCommonPrint";

const ClientPriceInformationView = () => {
  const item = useLocation().state.item;

  const { toPDF, targetRef } = useCommonPDF({
    title: "client-price-details",
  });

  const handlePrint = useCommonPrint({
    targetRef,
    title: "client payment details",
    message: "thank you",
  });

  return (
    <div>
      <BackButton title={"Payment Schedule"} />
      <div className="grid lg:grid-cols-12 grid-cols-1 mt-3 gap-5">
        <div
          ref={targetRef}
          className="lg:col-span-9 col-span-1 order-2 lg:order-1 lg:p-[40px] p-0 bg-[#FFFFFF]"
        >
          <h1 className="client_info_title">Payment Information</h1>
          {/* customer details  */}
          <div className={commonUserInfo}>
            <div className={commonUserRow}>
              <div>Customer Name</div>
              <div>:</div>
              <div>
                {" "}
                {item?.customer_info?.map((cus) => (
                  <span key={cus?.id}>{cus?.name}</span>
                ))}
              </div>
            </div>
            <div className={commonUserRow}>
              <div>Project Name</div>
              <div>:</div>
              <div>{item?.plot_info?.project_name}</div>
            </div>
            <div className={commonUserRow}>
              <div>File / Plot No</div>
              <div>:</div>
              <div>{item?.plot_info?.file_no}</div>
            </div>
            <div className={commonUserRow}>
              <div>Land Size (Katha)</div>
              <div>:</div>
              <div>{item?.plot_info?.plot_size_katha}</div>
            </div>
            <div className={commonUserRow}>
              <div>Land Size (Ojutangsho)</div>
              <div>:</div>
              <div>{item?.plot_info?.plot_size_ojutangsho}</div>
            </div>
            <div className={commonUserRow}>
              <div>Total Price </div>
              <div>:</div>
              <div>{item?.customer_land_price?.total_amount} TK</div>
            </div>
          </div>

          {/* payment information talbe  */}
          <div>
            <h1 className="client_info_title">Booking Money:</h1>
            {item?.customer_land_price?.booking_money_date ? (
              <div className="table_responsive mt-[30px]">
                <table className="">
                  <thead>
                    <tr className={`${commonTableRow} `}>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          <span>Payment Date</span>{" "}
                        </div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          <span>Payable Amount</span>{" "}
                        </div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          <span>Total Amount</span>{" "}
                        </div>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {/* {item?.customer_land_price?.booking_money_date && ( */}
                    <tr className="text-center">
                      <td>{item?.customer_land_price?.booking_money_date}</td>
                      <td>
                        <span className="font-bold">
                          {item?.customer_land_price?.total_booking_money} TK
                        </span>{" "}
                      </td>
                      <td>
                        <span className="font-bold">
                          {item?.customer_land_price?.total_booking_money} TK
                        </span>
                      </td>
                    </tr>
                    {/* )} */}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="text-center text-[red] my-20">
                <h1>Booking Money Payment Information Not Added</h1>
              </div>
            )}
          </div>
          <div>
            <h1 className="client_info_title mt-10">Downpayment:</h1>
            {item?.customer_land_price?.downpayment?.length ? (
              <div className="table_responsive my-[30px]">
                <table>
                  <thead>
                    <tr className={commonTableRow}>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          <span> Payment Date</span>{" "}
                        </div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          <span>Payment Against</span>{" "}
                        </div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          <span>Payable Amount</span>{" "}
                        </div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          <span>Total Amount</span>{" "}
                        </div>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {item?.customer_land_price?.downpayment?.map((dPayment) => (
                      <tr className="text-center" key={dPayment?.id}>
                        <td>{dPayment?.start_date}</td>
                        <td>
                          <span className="font-bold">
                            {dPayment?.downpayment_no}
                          </span>{" "}
                        </td>
                        <td>
                          <span className="font-bold">
                            {dPayment?.amount} TK
                          </span>{" "}
                        </td>
                        <td>
                          <span className="font-bold">
                            {
                              item?.customer_land_price
                                ?.total_downpayment_amount
                            }{" "}
                            TK
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="text-center text-[red] my-20">
                <h1>Downpayment Payment Information Not Added</h1>
              </div>
            )}
          </div>
          <div>
            <h1 className="client_info_title">Installment:</h1>
            {item?.customer_land_price?.installment?.length ? (
              <div className="table_responsive my-[30px]">
                <table>
                  <thead className="uppercase">
                    <tr className={commonTableRow}>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          <span> Payment Date</span>{" "}
                        </div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          <span>Payment Against</span>{" "}
                        </div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          <span> Payable Amount</span>{" "}
                        </div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          <span>Total Amount</span>{" "}
                        </div>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {item?.customer_land_price?.installment?.map((iPayment) => (
                      <tr className="text-center" key={iPayment?.id}>
                        <td>{iPayment?.start_date}</td>
                        <td>
                          <span className="font-bold">
                            {iPayment?.installment_no}
                          </span>{" "}
                        </td>
                        <td>
                          <span className="font-bold">
                            {iPayment?.amount} TK
                          </span>{" "}
                        </td>
                        <td>
                          <span className="font-bold">
                            {
                              item?.customer_land_price
                                ?.total_installment_amount
                            }{" "}
                            TK
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="text-center text-[red] my-20">
                <h1>Installment Payment Information Not Added</h1>
              </div>
            )}
          </div>
        </div>

        {/* right side button group  */}
        <div className="lg:col-span-3 order-1 lg:order-2 pt-5">
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} isBlock={true} />
          {/* <div className="commonprintpdfbtn whitespace-nowrap mt-5">
            <button className="bg-orange-500">Legal Paper Folder</button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ClientPriceInformationView;
