import React from "react";
import {
  commonFormMenu,
  commonFormTitle,
  errorMessage,
} from "../../../../asset/commoncssConstant/CommonCssConstant";

const AgentInformationForm = ({
  register,
  clientData,
  handleSelectByAgentInfoId,
  defaultAgentInfo,
  agentList,
  errors,
}) => {
  return (
    <div>
      <p className={commonFormTitle}>Agent Information</p>
      <div className={commonFormMenu}>
        <div className="common_input_wraper">
          <label>Agent Name</label>
          <select
            onChange={(e) => handleSelectByAgentInfoId(e)}
            required
            defaultValue={defaultAgentInfo?.id || clientData?.agent?.id}
          >
            {agentList?.agent?.id ? (
              <option value={defaultAgentInfo?.id || clientData?.agent?.id}>
                {defaultAgentInfo?.id || clientData?.agent?.id}
              </option>
            ) : (
              <option value="">Select Agent Name</option>
            )}

            {agentList.map((list) => (
              <option key={list.id} value={list.id}>
                {list?.name}
              </option>
            ))}
          </select>
        </div>
        <div className="common_input_wraper">
          <label>Agent Phone Number</label>
          <input
            value={
              defaultAgentInfo?.mobile_number_1 ||
              clientData?.agent?.mobile_number_1
            }
            placeholder="Agent Phone Number"
            type="text"
            readOnly
            disabled
          />
        </div>
        <div className="common_input_wraper">
          <label>Agent Money Amount</label>
          <input
            {...register("agent_money", {
              pattern: {
                value: /^[0-9]+(\.[0-9]+)?$/,
                message: "Insert valid amount",
              },
            })}
            type="text"
            placeholder="Agent Money Amount"
            defaultValue={clientData?.agent_price?.agent_money}
          />
          {errors?.agent_money && (
            <p className={errorMessage}>{errors?.agent_money?.message}</p>
          )}
        </div>
        <div className="common_input_wraper">
          <label>Agreement Upload (Multiple) </label>
          <input
            {...register("agreements")}
            type="file"
            placeholder="Agreement Upload (Multiple)"
            multiple
          />
        </div>
      </div>
    </div>
  );
};

export default AgentInformationForm;
