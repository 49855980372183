import React, { useEffect, useState } from "react";
import CommonPrintPdfBtn from "../../../shared/CommonPrintPdfBtn";
import {
  commonAction,
  commonCaption,
  commonSearchInput,
  commonTableHeadingTwo,
  commonTableRow,
  commonTitle,
  dashboardBodyWraper,
} from "../../../asset/commoncssConstant/CommonCssConstant";
import Pagination from "../../../shared/pagination/Pagination";
import ViewImg from "../../../asset/images/clientlist/view.png";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../../BaseUrl";
import { LuArrowDownUp } from "react-icons/lu";
import useCommonPDF from "../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../shared/useCommonPrint/useCommonPrint";

const ClientRegistryCompletePlotList = () => {
  const [clientRegistryPlotList, setClientRegistryPlotList] = useState([]);
  const token = localStorage.getItem("token");
  const [searchValue, setSearchValue] = useState("");
  const [paginationData, setPaginationData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const { toPDF, targetRef } = useCommonPDF({
    title: "registry-complete-list",
  });

  const handlePrint = useCommonPrint({
    targetRef,
    title: "registry-complete-list",
    message: "thank you",
  });

  // pagination part start here
  const handleNext = () => {
    if (page === pageCount) {
      return page;
    } else {
      return setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page === 1) {
      return page;
    } else {
      return setPage(page - 1);
    }
  };

  //------plotBank/registryCompletePlots---------------
  useEffect(() => {
    axios
      .get(baseUrl + `/plotBank/registryCompletePlots`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setClientRegistryPlotList(res.data.data);
      });
  }, [token, page]);
  //------plotBank/registryCompletePlots end-----------------

  return (
    <div>
      <h1 className={`${commonTitle}`}>Client Registry Complete Plot List</h1>
      <div className={dashboardBodyWraper}>
        <div className={commonCaption}>
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} />
          <input
            onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
            type="text"
            // placeholder="Search by Project Name / Plot Size / Customer Name  / Customer ID /  Registry Date / Registry Deed No  "
            placeholder="Search"
            className={commonSearchInput}
          />
        </div>
        <div ref={targetRef} className="table_responsive mt-[40px] text-center">
          <table>
            <thead>
              <tr className={`${commonTableRow}`}>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Registry Date</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>
                      {" "}
                      Registry <br />
                      Deed No
                    </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> BS Dag no</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Customer Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Project Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> File / Plot No</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Road No / Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Plot Facing</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Sector</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Plot Size Katha</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>
                      {" "}
                      Plot Size <br />
                      Ojudangsho
                    </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Plot Dimension</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`py-2`}>
                    <span>
                      {" "}
                      Action <br /> View
                    </span>{" "}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {paginationData
                ?.filter((info) => {
                  return searchValue.toLowerCase() !== ""
                    ? info?.land_agreement?.agreement_date
                        ?.toLowerCase()
                        .includes(searchValue) ||
                        info?.land_agreement?.deed_no
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.land_agreement?.land_seller_agreement?.bs_info
                          ?.map((bs) => {
                            return bs?.bs_dag_no;
                          })
                          ?.join("")
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.customer_info
                          ?.map((cus) => {
                            return cus?.name;
                          })
                          ?.join("")
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.plot_info?.project_name
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.plot_info?.file_no
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.plot_info?.road_number_name
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.sector?.toLowerCase().includes(searchValue) ||
                        info?.plot_info?.plot_facing
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.plot_info?.sector
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.plot_info?.plot_size_katha
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.plot_info?.plot_size_ojutangsho
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.plot_info?.plot_dimension
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue)
                    : info;
                })
                ?.map((item) => (
                  <tr className="text-center" key={item.id}>
                    <td>{item?.land_agreement?.agreement_date}</td>
                    <td>{item?.land_agreement?.deed_no}</td>
                    <td>
                      {item?.land_agreement?.land_seller_agreement?.bs_info?.map(
                        (bs) => (
                          <p key={bs?.id}>{bs?.bs_dag_no}</p>
                        )
                      )}
                    </td>
                    <td>
                      {item?.customer_info?.map((cus) => (
                        <p key={cus?.id}>{cus?.name}</p>
                      ))}
                    </td>
                    <td>{item?.plot_info?.project_name}</td>
                    <td>{item?.plot_info?.file_no}</td>
                    <td>{item?.plot_info?.road_number_name}</td>
                    <td>{item?.plot_info?.plot_facing}</td>
                    <td>{item?.plot_info?.sector}</td>
                    <td>{item?.plot_info?.plot_size_katha}</td>
                    <td>{item?.plot_info?.plot_size_ojutangsho}</td>
                    <td>{item?.plot_info?.plot_dimension}</td>
                    <td>
                      <span className="flex justify-center">
                        <Link
                          to={`/dashboard/client-registry-complete-plot/view`}
                          state={{
                            item: item,
                          }}
                        >
                          <img className={commonAction} src={ViewImg} alt="" />
                        </Link>
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <Pagination
          data={clientRegistryPlotList}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          setPageCount={setPageCount}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      </div>
    </div>
  );
};

export default ClientRegistryCompletePlotList;
