import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "../shared/Sidebar/Sidebar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MainLayout = () => {
  const navigate = useNavigate();
  const jsonUser = localStorage.getItem("user");
  const user = JSON.parse(jsonUser);
  return (
    <div className="drawer lg:drawer-open ">
      <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content overflow-hidden ">
        <header className="col-start-2 col-span-12 sticky z-10 top-0">
          <nav className="h-[55px] bg-gradient-to-r from-[#0C768A] via-[#1e788a] to-[#2894a8] flex items-center ">
            <label htmlFor="my-drawer-2" className=" lg:hidden ml-3">
              <div>
                <span className="block w-[30px] h-[2px] bg-white mb-1"></span>
                <span className="block w-[30px] h-[2px] bg-white mb-1"></span>
                <span className="block w-[30px] h-[2px] bg-white mb-1"></span>
              </div>
            </label>

            <div className="ml-auto mr-10 ">
              <div className="dropdown dropdown-bottom dropdown-end">
                <label tabIndex={0}>
                  <div className="avatar online">
                    <div className="w-9 rounded-full cursor-pointer">
                      <img
                        className=""
                        alt=""
                        src="https://thumbs.dreamstime.com/b/funny-cartoon-monster-face-showing-tongue-vector-halloween-orange-monster-avatar-110119691.jpg"
                      />
                    </div>
                  </div>
                </label>
                <ul
                  tabIndex={0}
                  className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
                >
                  <li>
                    <h1>Name: {user.name}</h1>
                  </li>
                  <li>
                    <h1>Role: {user?.roles[0]?.name}</h1>
                  </li>
                  <li>
                    <h1
                      onClick={() => {
                        localStorage.removeItem("token");
                        localStorage.removeItem("user");
                        navigate("/login");
                      }}
                    >
                      Logout
                    </h1>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
        <div className=" h-[90vh] overflow-auto bg-[#F5F6F8] w-[100%] p-[17px]">
          <Outlet />
        </div>
      </div>
      <Sidebar />
      <ToastContainer />
    </div>
  );
};

export default MainLayout;
