import React from "react";
import CommonPrintPdfBtn from "../../../../shared/CommonPrintPdfBtn";
import BackButton from "../../../../shared/BackButton";
import { useLocation } from "react-router-dom";
import useCommonPDF from "../../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../../shared/useCommonPrint/useCommonPrint";

const ViewRsDisputeList = () => {
  const item = useLocation().state.item;
  const { toPDF, targetRef } = useCommonPDF({ title: "rs dispute-details" });

  const handlePrint = useCommonPrint({
    targetRef,
    title: "rs dispute-details",
    message: "thank you",
  });
  return (
    <div>
      <BackButton title={"RS Dispute Information"} />
      <div className="grid lg:grid-cols-12  mt-3 gap-5">
        <div
          ref={targetRef}
          className="col-span-9 order-2 lg:order-1 client_info_section p-[40px] "
        >
          <div className="grid md:grid-cols-2 bg-[#F5F6F8] border border-[#d5d7d7] px-10 rounded-[5px] mb-10">
            <div className="agent_view_table ">
              <table style={{ borderSpacing: "0 8px" }}>
                <tbody>
                  <tr>
                    <td>
                      <strong>Project Name</strong>{" "}
                    </td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>
                      <strong>
                        {
                          item?.rs_info?.sa_info?.cs_info?.mouza_info
                            ?.project_info?.project_name
                        }
                      </strong>{" "}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="agent_view_table ">
              <table style={{ borderSpacing: "0 8px" }}>
                <tbody>
                  <tr>
                    <td>
                      <strong>Mouza Name</strong>{" "}
                    </td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>
                      <strong>
                        {
                          item?.rs_info?.sa_info?.cs_info?.mouza_info
                            ?.mouza_name
                        }
                      </strong>{" "}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="grid md:grid-cols-2">
            <div className="agent_view_table ">
              <table>
                <tbody>
                  <tr>
                    <td>RS Dispute Create Date</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item.created_at.slice(0, 10)}</td>
                  </tr>
                  <tr>
                    <td>RS Dag</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.rs_info?.rs_dag_no}</td>
                  </tr>
                  <tr>
                    <td>RS Khatiyan</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.rs_info?.rs_khatiyan_no}</td>
                  </tr>
                  <tr>
                    <td>Dispute Date</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.dispute_date}</td>
                  </tr>
                  <tr>
                    <td>Dispute No</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.dispute_no}</td>
                  </tr>
                  <tr>
                    <td>Dispute Land Size</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.dispute_land_size}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="agent_view_table ">
              <table>
                <tbody>
                  <tr>
                    <td>Dispute Court Name</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.dispute_court_name}</td>
                  </tr>
                  <tr>
                    <td>Dispute Judgement Date</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.dispute_judgement_date}</td>
                  </tr>
                  <tr>
                    <td>Case Badi Name</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.case_badi_name}</td>
                  </tr>
                  <tr>
                    <td>Case Bibadi Name</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.case_bibadi_name}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <h3 className="text-[14px] text-[#717A82] font-[700] mt-5">
            Description
          </h3>
          <p className="text-[14px] text-[#8F8F8F] font-[400]">
            {item?.judgement_description}
          </p>
        </div>

        {/* right side button group  */}
        <div className="col-span-3 order-1 lg:order-2  ">
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} isBlock={true} />
         {/*  <div className="commonprintpdfbtn whitespace-nowrap mt-5">
            <button className="bg-[#F97316]">Legal Paper Folder</button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ViewRsDisputeList;
