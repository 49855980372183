import React from "react";
import BackButton from "../../../../shared/BackButton";
import CommonPrintPdfBtn from "../../../../shared/CommonPrintPdfBtn";
import { useLocation } from "react-router-dom";
import useCommonPDF from "../../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../../shared/useCommonPrint/useCommonPrint";

const AgentWorkListView = () => {
  const item = useLocation().state.item;
  const { toPDF, targetRef } = useCommonPDF({ title: "agent-work-details" });

  const handlePrint = useCommonPrint({
    targetRef,
    title: "agent-work-details",
    message: "thank you",
  });
  return (
    <div>
      <BackButton title={" Agent Work List"} />
      <div className=" mt-3 gap-5">
        <div className="client_info_section p-[40px] ">
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} />
          <div ref={targetRef}>
            <h1 className="client_info_title mt-3">Agent Information</h1>
            <div className="agent_view_table  ">
              <table>
                <tbody>
                  <tr>
                    <td>Agent Name</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.agent?.name}</td>
                  </tr>
                  <tr>
                    <td>Agent Present Address</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.agent?.present_address}</td>
                  </tr>
                  <tr>
                    <td>Agent mobile no. 1</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.agent?.mobile_number_1}</td>
                  </tr>
                  <tr>
                    <td>Agent mobile no. 2</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.agent?.mobile_number_2}</td>
                  </tr>
                  <tr>
                    <td>Booking Date</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.booking_date}</td>
                  </tr>
                  <tr>
                    <td>Customer’s Name</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>
                      {item?.customer_info?.map((cus, i) => (
                        <span key={cus?.id}>
                          {cus?.name}{" "}
                          {i < item?.customer_info?.length - 1 ? ", " : ""}
                        </span>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <td>Project Name</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.plot_info?.project_name}</td>
                  </tr>
                  <tr>
                    <td>File/ Plot No.</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.plot_info?.file_no}</td>
                  </tr>
                  <tr>
                    <td>Total Price</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.customer_land_price?.total_amount}</td>
                  </tr>
                  <tr>
                    <td>Agent Money</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.agent_price?.agent_money}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentWorkListView;
