import React from "react";
import { useLocation } from "react-router-dom";
import { PDFViewer } from "@react-pdf/renderer";
import BackButton from "../../../../shared/BackButton";
import CreatePDF from "./CreatePDF";

const ViewSellerPaymentPDF = () => {
  const { item, next_payment_date } = useLocation()?.state ?? {};
// console.log(payment);
  return (
    <div className={`w-full h-full`}>
      <BackButton title={"Back"} />
      <PDFViewer className={`w-full h-full mt-5`}>
        <CreatePDF item={item} next_payment_date={next_payment_date}/>
      </PDFViewer>
    </div>
  );
};

export default ViewSellerPaymentPDF;
