import React, { useEffect, useState } from "react";
import CommonPrintPdfBtn from "../../../shared/CommonPrintPdfBtn";
import {
  addCustomerCss,
  commonAction,
  commonAddButton,
  commonCaption,
  commonSearchInput,
  commonTableHeadingTwo,
  commonTableRow,
  commonTitle,
  dashboardBodyWraper,
} from "../../../asset/commoncssConstant/CommonCssConstant";
import Pagination from "../../../shared/pagination/Pagination";
import AddUserImg from "../../../asset/images/clientlist/add-user.png";
import ViewImg from "../../../asset/images/clientlist/view.png";
import ActionImg from "../../../asset/images/clientlist/action.png";
import axios from "axios";
import { baseUrl } from "../../../BaseUrl";
import { Link } from "react-router-dom";
import { LuArrowDownUp } from "react-icons/lu";
import useCommonPDF from "../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../shared/useCommonPrint/useCommonPrint";

const PlotList = () => {
  const [ploList, setPloList] = useState([]);
  const token = localStorage.getItem("token");
  const [searchValue, setSearchValue] = useState("");
  const [paginationData, setPaginationData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const { toPDF, targetRef } = useCommonPDF({
    title: "plot-list",
  });

  const handlePrint = useCommonPrint({
    targetRef,
    title: "plot-list",
    message: "thank you",
  });

  // pagination part start here
  const handleNext = () => {
    if (page === pageCount) {
      return page;
    } else {
      return setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page === 1) {
      return page;
    } else {
      return setPage(page - 1);
    }
  };

  //------get data for plotBank/view start-----------------
  useEffect(() => {
    axios
      .get(baseUrl + `/plotBank/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setPloList(res.data.data);
      });
  }, [token, page]);
  //------get data for plotBank/view end-----------------
  return (
    <div>
      <h1 className={`${commonTitle}`}>Plot</h1>
      <div className={dashboardBodyWraper}>
        <div className={commonAddButton}>
          <Link to={`/dashboard/plotlist/add`}>
            <button className={addCustomerCss}>
              <span>Add Plot</span>
              <img className="ml-[30px]" src={AddUserImg} alt="" />
            </button>
          </Link>
        </div>
        <div className={commonCaption}>
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} />
          <input
            onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
            type="text"
            // placeholder="Search by Project Name / Plot Size / Plot Facing  "
            placeholder="Search"
            className={commonSearchInput}
          />
        </div>

        <div ref={targetRef} className="table_responsive mt-[40px]">
          <table>
            <thead>
              <tr className={`${commonTableRow}`}>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Start Date</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Project Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> No of Plot</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Road Width</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> File / Plot No</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Road No / Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Plot Facing</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Sector</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Plot Size Katha</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>
                      {" "}
                      Plot Size <br />
                      Ojudangsho
                    </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Plot Dimension</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`py-2`}>
                    <span>
                      {" "}
                      Action <br />
                      Update / View
                    </span>{" "}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {paginationData
                ?.filter((info) => {
                  return searchValue.toLowerCase() !== ""
                    ? info?.project_start_date
                        ?.toLowerCase()
                        .includes(searchValue) ||
                        info?.project_name
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.no_of_plot
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.road_width
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.file_no
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.road_number_name
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.plot_facing
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.sector
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.plot_size_katha
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.plot_size_ojutangsho
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.plot_dimension
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue)
                    : info;
                })
                ?.map((item) => (
                  <tr className="text-center" key={item.id}>
                    <td>{item?.project_start_date}</td>
                    <td>{item?.project_name}</td>
                    <td>{item?.no_of_plot}</td>
                    <td>{item?.road_width}</td>
                    <td>{item?.file_no}</td>
                    <td>{item?.road_number_name}</td>
                    <td>{item?.plot_facing}</td>
                    <td>{item?.sector}</td>
                    <td>{item?.plot_size_katha}</td>
                    <td>{item?.plot_size_ojutangsho}</td>
                    <td>{item?.plot_dimension}</td>
                    <td>
                      <span className="flex gap-3 justify-center">
                        <Link
                          to={`/dashboard/plotlist/edit`}
                          state={{
                            item: item,
                          }}
                        >
                          <img
                            className={commonAction}
                            src={ActionImg}
                            alt=""
                          />
                        </Link>
                        <Link
                          to={`/dashboard/plotlist/view`}
                          state={{
                            item: item,
                          }}
                        >
                          <img className={commonAction} src={ViewImg} alt="" />
                        </Link>
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <Pagination
          data={ploList}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          setPageCount={setPageCount}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      </div>
    </div>
  );
};

export default PlotList;
