import { Link } from "react-router-dom";
import AddUserImg from "../../../asset/images/clientlist/add-user.png";
import Pagination from "../../../shared/pagination/Pagination";
import CommonPrintPdfBtn from "../../../shared/CommonPrintPdfBtn";
import MapImg from "../../../asset/images/map.png";
import {
  addCustomerCss,
  commonAction,
  commonAddButton,
  commonCaption,
  commonSearchInput,
  commonTableHeadingTwo,
  commonTableRow,
  commonTitle,
  dashboardBodyWraper,
} from "../../../asset/commoncssConstant/CommonCssConstant";
import ActionBtn from "../../../asset/images/clientlist/action.png";
import { useEffect, useState } from "react";
import { baseUrl, baseUrlImg } from "../../../BaseUrl";
import axios from "axios";
import { LuArrowDownUp } from "react-icons/lu";
import useCommonPDF from "../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../shared/useCommonPrint/useCommonPrint";

const ProjectInformation = () => {
  const [projectList, setProjectList] = useState([]);
  const token = localStorage.getItem("token");
  const [searchValue, setSearchValue] = useState("");

  const [paginationData, setPaginationData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const { toPDF, targetRef } = useCommonPDF({ title: "project-info" });

  const handlePrint = useCommonPrint({
    targetRef,
    title: "project-info",
    message: "thank you",
  });

  // pagination part start here
  const handleNext = () => {
    if (page === pageCount) {
      return page;
    } else {
      return setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page === 1) {
      return page;
    } else {
      return setPage(page - 1);
    }
  };

  //----------get all project data landInformationBank/projectInformation/view start---------------
  useEffect(() => {
    axios
      .get(baseUrl + `/landInformationBank/projectInformation/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setProjectList(res.data.data);
      });
  }, [token, page]);
  //----------get all project data landInformationBank/projectInformation/view end---------------

  return (
    <div>
      <h1 className={`${commonTitle}`}>Project Information</h1>
      <div className={dashboardBodyWraper}>
        <div className={commonAddButton}>
          <Link to="/dashboard/project-info/add">
            <button className={addCustomerCss}>
              Add Project Information{" "}
              <img className="ml-[30px]" src={AddUserImg} alt="" />
            </button>
          </Link>
        </div>
        <div className={`${commonCaption}`}>
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} />
          <input
            onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
            type="text"
            placeholder="Search"
            className={commonSearchInput}
          />
        </div>

        <div ref={targetRef} className="table_responsive mt-[40px]">
          <table>
            <thead>
              <tr className={`${commonTableRow}`}>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Create Date</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>

                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Project Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> District</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Thana</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Area</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Num of Mouza</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`py-2`}>
                    <span> Map</span>{" "}
                  </div>
                </th>
                <th>
                  <div className={`py-2`}>
                    <span>
                      {" "}
                      Action <br />
                      Update
                    </span>{" "}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {paginationData
                ?.filter((info) => {
                  return searchValue.toLowerCase() !== ""
                    ? info?.created_at
                        ?.slice(0, 10)
                        .toLowerCase()
                        .includes(searchValue) ||
                        info?.project_name
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.district?.toLowerCase().includes(searchValue) ||
                        info?.thana?.toLowerCase().includes(searchValue) ||
                        info?.area?.toLowerCase().includes(searchValue) ||
                        info?.number_of_mouza
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue)
                    : info;
                })
                ?.map((project) => {
                  return (
                    <tr className="text-center" key={project.id}>
                      <td>{project?.created_at?.slice(0, 10)}</td>
                      <td>{project.project_name}</td>
                      <td>
                        <span className="font-bold">{project.district}</span>
                      </td>
                      <td>{project.thana}</td>
                      <td>{project.area}</td>
                      <td>
                        <span className="font-bold">
                          {project.number_of_mouza}
                        </span>
                      </td>
                      <td>
                        <img
                          className={`${commonAction} w-8 rounded-lg mx-auto`}
                          src={
                            project.map_image
                              ? baseUrlImg + project.map_image
                              : MapImg
                          }
                          alt=""
                        />
                      </td>
                      <td>
                        <span className="flex justify-center">
                          <Link
                            to={`/dashboard/project-info/edit`}
                            state={{
                              project: project,
                            }}
                          >
                            <img
                              className={commonAction}
                              src={ActionBtn}
                              alt=""
                            />
                          </Link>
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        <Pagination
          data={projectList}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          setPageCount={setPageCount}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      </div>
    </div>
  );
};

export default ProjectInformation;
