import React from "react";
import CommonPrintPdfBtn from "../../shared/CommonPrintPdfBtn";
import Pagination from "../../shared/pagination/Pagination";
import BFolderImg from "../../asset/images/clientlandinformation/back-folder.png";
import ActionImg from "../../asset/images/clientlist/action.png";
import ViewImg from "../../asset/images/clientlist/view.png";
import AddImage from "../../asset/images/clientpaymentinformation/add.png";
import { commonSearchInput } from "../../asset/commoncssConstant/CommonCssConstant";
import { useNavigate } from "react-router-dom";
const LandSellerPaymentInformation = () => {
  const navigate = useNavigate();
  return (
    <div>
      <h1 className="text-[#333547] font-[700] text-[24px]">
        Land Seller Payment Information
      </h1>
      <div className="bg-[#FFFFFF]   rounded-[4px] px-[20px] py-[30px] my-[30px]">
        <div className="flex justify-between items-end mt-[20px]">
          <CommonPrintPdfBtn />

          <input
            type="text"
            placeholder="Search Seller Name / ID/ File No..."
            className={commonSearchInput}
          />
        </div>

        <div className="table_responsive mt-[40px]">
          <table>
            <thead>
              <tr>
                <th className="text-[#00CA08] text-start">
                  Land Booking <br />
                  Date
                </th>

                <th className="text-[#00CA08] text-start">
                  File/ <br />
                  Plot No.
                </th>
                <th className="text-[#00CA08]">Project Name</th>
                <th className="text-[#00CA08] text-start">
                  Seller <br />
                  ID
                </th>
                <th className="text-[#00CA08] text-start">
                  Seller <br />
                  Name
                </th>
                <th className="text-[#00CA08]">Mouza Name</th>
                <th className="text-[#00CA08]">
                  Purchase Land <br />
                  Size (Katha)
                </th>
                <th className="text-[#00CA08]">
                  Purchase <br />
                  Land Price <br />
                  per Katha
                </th>
                <th className="text-[#00CA08] text-start">
                  Purchase <br />
                  Land Total <br />
                  Price
                </th>
                <th className="text-[#00CA08]">
                  Paid <br />
                  Amount
                </th>

                <th className="text-[#00CA08]">Due Amount</th>
                <th className="text-[#00CA08] text-center">
                  Other <br />
                  Document
                </th>
                <th
                  style={{ width: "200px" }}
                  className="text-[#00CA08] text-center"
                >
                  Payment Add/Edit <br />
                  Payment Statement <br />
                  Details
                </th>
              </tr>
            </thead>
            <tbody>
              {[...Array(10).keys()].map((item, i) => (
                <tr key={i}>
                  <td>2022-05-21</td>
                  <td>
                    <span className="font-semibold">52A1C4</span>
                  </td>
                  <td>Munshiganj Project </td>
                  <td>
                    <p className="font-semibold">5248678</p>
                  </td>
                  <td>
                    <p className="font-semibold">Mr. Abul </p>
                  </td>
                  <td>
                    <span className="font-semibold">Mouza Name</span>
                  </td>
                  <td>50 </td>
                  <td>
                    <span className="font-bold">200000</span>
                  </td>
                  <td>
                    <span className="font-bold">200000</span>
                  </td>
                  <td>
                    <span className="font-bold">200000</span>
                  </td>
                  <td>
                    <span className="font-bold">200000</span>
                  </td>
                  <td>
                    <img className="mx-auto" src={BFolderImg} alt="" />
                  </td>

                  <td className="w-[230px]">
                    <div className="h-full flex">
                      <img
                        onClick={() =>
                          navigate(
                            `/dashboard/land-seller-payment-information/add/${1}`
                          )
                        }
                        className="mr-2"
                        src={AddImage}
                        alt=""
                      />
                      <img  onClick={() =>
                          navigate(
                            `/dashboard/land-seller-payment-information/view/${1}`
                          )
                        } className="mr-2" src={ViewImg} alt="" />
                      <img
                        onClick={() =>
                          navigate(
                            `/dashboard/land-seller-payment-information/edit/${1}`
                          )
                        }
                        className="mr-2"
                        src={ActionImg}
                        alt=""
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination />
      </div>
    </div>
  );
};

export default LandSellerPaymentInformation;
