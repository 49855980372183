import { useForm } from "react-hook-form";
import {
  commonFormTitle,
  dashboardBodyWraper,
  errorMessage,
} from "../../../../asset/commoncssConstant/CommonCssConstant";
import BackButton from "../../../../shared/BackButton";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../../../BaseUrl";

const EditProjectInformatiion = () => {
  const project = useLocation().state.project;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  
  //--------landInformationBank/projectInformation/update start----------------
  const onSubmit = (data) => {
    data.id = project.id;
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (value instanceof FileList) {
        for (let i = 0; i < value.length; i++) {
          formData.append(key, value.item([i]));
        }
      } else {
        formData.append(key, value);
      }
    });
    axios
      .post(
        baseUrl + `/landInformationBank/projectInformation/update`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          navigate(-1);
        }
      });
  };
  //--------landInformationBank/projectInformation/update end----------------

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BackButton title={"Update Project Information"} />
      <div className={`${dashboardBodyWraper} py-[4px] `}>
        <div>
          <p className={commonFormTitle}>Project Information</p>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 px-4 md:px-4">
            <div className="common_input_wraper">
              <label>Project Name</label>
              <input
                {...register("project_name", {
                  required: "Project name is required",
                  pattern: {
                    value: /^[A-Za-z]+[A-Za-z0-9\-_~(\s]*[A-Za-z0-9\s)]$/,
                    message: "Please insert valid project name",
                  },
                })}
                type="text"
                placeholder="Project Name"
                defaultValue={project.project_name}
              />
              {errors?.project_name && (
                <p className={errorMessage}>{errors?.project_name?.message}</p>
              )}
            </div>
            <div className="common_input_wraper">
              <label>District</label>
              <input
                {...register("district", {
                  required: "District is required",
                })}
                type="text"
                placeholder="District Name"
                defaultValue={project.district}
              />
              {errors?.district && (
                <p className={errorMessage}>{errors?.district?.message}</p>
              )}
            </div>

            <div className="common_input_wraper">
              <label>Thana</label>
              <input
                {...register("thana", {
                  required: "Thana is required",
                })}
                type="text"
                placeholder="Thana Name"
                defaultValue={project.thana}
              />
              {errors?.thana && (
                <p className={errorMessage}>{errors?.thana?.message}</p>
              )}
            </div>
            <div className="common_input_wraper">
              <label>Area</label>
              <input
                {...register("area", {
                  required: "Area is required",
                })}
                type="text"
                placeholder="Area Name"
                defaultValue={project.area}
              />
              {errors?.area && (
                <p className={errorMessage}>{errors?.area?.message}</p>
              )}
            </div>
            <div className="common_input_wraper">
              <label>Number of Mouza</label>
              <input
                {...register("number_of_mouza", {
                  required: "Mouza number is required",
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Please insert number",
                  },
                })}
                type="text"
                placeholder="Number of Mouza"
                defaultValue={project.number_of_mouza}
              />
              {errors?.number_of_mouza && (
                <p className={errorMessage}>
                  {errors?.number_of_mouza?.message}
                </p>
              )}
            </div>
            <div className="common_input_wraper">
              <label>Add Project Map Image (PP size 450x570)</label>
              <input
                 {...register("map_image", {
                  required: "Project image required",
                })}
                type="file"
                placeholder="Area"
              />
               {errors?.map_image && (
                <p className={errorMessage}>{errors?.map_image?.message}</p>
              )}
            </div>
          </div>
        </div>

        <div className="text-center py-10">
          <button type="submit" className="submit_button">
            Update
          </button>
        </div>
      </div>
    </form>
  );
};

export default EditProjectInformatiion;
