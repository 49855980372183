import React from "react";
import BackButton from "../../../shared/BackButton";
import CommonPrintPdfBtn from "../../../shared/CommonPrintPdfBtn";
import FileImg from "../../../asset/images/fileImage.png";
const ClientLandDescriptionView = () => {
  return (
    <div>
      <BackButton title={" View Agent Information"} />
      <div className="grid lg:grid-cols-12  mt-3 gap-5">
        <div className="col-span-9 order-2 lg:order-1 client_info_section p-[40px] ">
          <div className="grid xl:grid-cols-2">
            <div className="agent_view_table ">
              <table style={{ borderSpacing: "0 14px" }}>
                <tbody>
                  <tr>
                    <td>Customer’s Name</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>159852</td>
                  </tr>
                  <tr>
                    <td>File / Plot No</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>52A1C4</td>
                  </tr>
                  <tr>
                    <td>Project Name</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>Munshiganj Project</td>
                  </tr>
                  <tr>
                    <td>Mouza Name</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>Munshiganj Project</td>
                  </tr>
                  <tr>
                    <td>Land Size</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>1091</td>
                  </tr>
                  <tr>
                    <td>Land Registry Complete Date</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>dd-mm-yy</td>
                  </tr>
                  <tr>
                    <td>Land Registry Sub-deed No.</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>01536974</td>
                  </tr>
                  <tr>
                    <td>Registered Land Size</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>01536974</td>
                  </tr>
                  <tr>
                    <td>Registry Office</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>Munshi</td>
                  </tr>
                  <tr>
                    <td>Namjari No</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>01536974</td>
                  </tr>
                  <tr></tr>
                  <tr></tr>
                  <tr>
                    <td>Namjari Date</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>dd-mm--yyyy</td>
                  </tr>
                  <tr>
                    <td>CS Daag No.</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>650214</td>
                  </tr>
                  <tr>
                    <td>CS Khotian</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>650214</td>
                  </tr>
                  <tr>
                    <td>SA Daag No.</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>650214</td>
                  </tr>
                  <tr>
                    <td>SA Khotian</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>650214</td>
                  </tr>
                  <tr>
                    <td>RS Daag No.</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>650214</td>
                  </tr>
                  <tr>
                    <td>RS Khotian</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>650214</td>
                  </tr>
                  <tr>
                    <td>BS Daag No.</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>650214</td>
                  </tr>
                  <tr>
                    <td>BS Khotian</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>650214</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <h1
                style={{ textDecoration: "none", fontSize: "14px" }}
                className="client_info_title "
              >
                Supported Document
              </h1>
              <div className="grid xl:grid-cols-4  lg:grid-cols-3 grid-cols-2 gap-3">
                <div className="w-[126px] justify-self-center h-[200px]">
                  <img className="w-full" src={FileImg} alt="" />
                </div>
                <div className="w-[126px] justify-self-center h-[200px]">
                  <img className="w-full" src={FileImg} alt="" />
                </div>
                <div className="w-[126px] justify-self-center h-[200px]">
                  <img className="w-full" src={FileImg} alt="" />
                </div>
                <div className="w-[126px] justify-self-center h-[200px]">
                  <img className="w-full" src={FileImg} alt="" />
                </div>
                <div className="w-[126px] justify-self-center h-[200px]">
                  <img className="w-full" src={FileImg} alt="" />
                </div>
                <div className="w-[126px] justify-self-center h-[200px]">
                  <img className="w-full" src={FileImg} alt="" />
                </div>
                <div className="w-[126px] justify-self-center h-[200px]">
                  <img className="w-full" src={FileImg} alt="" />
                </div>
                <div className="w-[126px] justify-self-center h-[200px]">
                  <img className="w-full" src={FileImg} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* right side button group  */}
        <div className="col-span-3 order-1 lg:order-2  ">
          <CommonPrintPdfBtn isBlock={true} />
          <div className="commonprintpdfbtn whitespace-nowrap mt-5">
            <button>Legal Paper Folder</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientLandDescriptionView;
