import BackButton from "../../../../shared/BackButton";
import CommonPrintPdfBtn from "../../../../shared/CommonPrintPdfBtn";
import { useLocation } from "react-router-dom";
import { baseUrlImg } from "../../../../BaseUrl";
import useCommonPDF from "../../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../../shared/useCommonPrint/useCommonPrint";

const SellerAgreementListView = () => {
  const { item } = useLocation()?.state ?? {};
  const {
    agreement_date,
    land_size_katha,
    purchase_deed_no,
    dolil_image,
    porca_image,
    other_image,
    land_broker_agreements,
    seller_info,
    price_info,
    land_broker,
    dolil_variation,
    bs_info,
  } = item ?? {};
  const {toPDF, targetRef} = useCommonPDF({ title: "seller-agreement-details"});

  const handlePrint = useCommonPrint({
    targetRef,
    title: "seller-agreement-details",
    message: "thank you",
  });

  return (
    <div>
      <BackButton title={"Land Seller Agreement Informations"} />

      <div className="grid lg:grid-cols-12  mt-3 gap-5">
        <div ref={targetRef} className="col-span-9 order-2 lg:order-1 client_info_section p-[40px] ">
          <div className=" client_info_section p-[40px] ">
            {/* land seller agreement info */}
            <h1 className="client_info_title">
              Land Seller Agreement Information
            </h1>
            <div className="grid grid-cols-1 md:grid-cols-2">
              <div className="agent_view_table  ">
                <table>
                  <tbody>
                    <tr>
                      <td>Agreement Date</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{agreement_date}</td>
                    </tr>
                    <tr>
                      <td>Purchase Deed No</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{purchase_deed_no}</td>
                    </tr>
                    <tr>
                      <td>BS Dag</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>
                        {bs_info.map((item, idx) => (
                          <span key={item.id}>
                            {item?.bs_dag_no}
                            {idx < bs_info.length - 1 ? ", " : ""}
                          </span>
                        ))}
                      </td>
                    </tr>
                    <tr>
                      <td>BS Khatiyan</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>
                        {bs_info.map((item, idx) => (
                          <span key={item.id}>
                            {item?.bs_khatiyan_no}
                            {idx < bs_info.length - 1 ? ", " : ""}
                          </span>
                        ))}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="agent_view_table  ">
                <table>
                  <tbody>
                    <tr>
                      <td>Project Name</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>
                        {bs_info.map((item, idx) => (
                          <span key={item.id}>
                            {
                              item?.rs_info?.sa_info?.cs_info?.mouza_info
                                ?.project_info?.project_name
                            }
                            {idx < bs_info.length - 1 ? ", " : ""}
                          </span>
                        ))}
                      </td>
                    </tr>
                    <tr>
                      <td>Purchase Land Size</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{land_size_katha}</td>
                    </tr>
                    <tr>
                      <td>Dolil Variation</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{dolil_variation.name}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/* land price info */}
            <h1 className="client_info_title mt-10">Land Price Information</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 ">
              <div className="agent_view_table  ">
                <table>
                  <tbody>
                    <tr>
                      <td>Purchase Land Size (Katha)</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{land_size_katha}</td>
                    </tr>
                    <tr>
                      <td>Purchase Land Price (Per Katha)</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{price_info.price_per_katha}</td>
                    </tr>
                    <tr>
                      <td>Purchase Land Total Price</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{price_info.total_price}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="agent_view_table  ">
                <table>
                  <tbody>
                    <tr>
                      <td>Payment Start Date</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{price_info.payment_start_date}</td>
                    </tr>
                    <tr>
                      <td>Approx Payment Complete Date</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{price_info.approx_payment_complete_date}</td>
                    </tr>
                    <tr>
                      <td>Approx Registry Date</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{price_info.approx_registry_date}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/* seller info */}
            <h1 className="client_info_title mt-10">Seller Information</h1>
            {seller_info.map((seller, idx) => {
              return (
                <div className="grid grid-cols-1 md:grid-cols-2  mt-2">
                  <div className="agent_view_table  ">
                    <h1 className="text-[#414141] font-[700] text-[12px]">
                      Seller Information {++idx}
                    </h1>
                    <table>
                      <tbody>
                        <tr>
                          <td>Seller Name</td>
                          <td>
                            <span className="mx-6">:</span>
                          </td>
                          <td>{seller.name}</td>
                        </tr>
                        <tr>
                          <td>Seller Ph No. 01</td>
                          <td>
                            <span className="mx-6">:</span>
                          </td>
                          <td>{seller.mobile_no_1}</td>
                        </tr>
                        <tr>
                          <td>Seller Ph No. 02</td>
                          <td>
                            <span className="mx-6">:</span>
                          </td>
                          <td>{seller.mobile_no_2}</td>
                        </tr>
                        <tr>
                          <td>Seller Father’s Name</td>
                          <td>
                            <span className="mx-6">:</span>
                          </td>
                          <td>{seller.father_name}</td>
                        </tr>
                        <tr>
                          <td>Seller Mother’s Name</td>
                          <td>
                            <span className="mx-6">:</span>
                          </td>
                          <td>{seller.mother_name}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="agent_view_table  ">
                    <table>
                      <tbody>
                        <tr>
                          <td>Seller NID No</td>
                          <td>
                            <span className="mx-6">:</span>
                          </td>
                          <td>{seller.nid}</td>
                        </tr>
                      </tbody>
                    </table>
                    <img
                      className="h-[222px] w-[190px] border border-[#5F646E] p-2"
                      src={baseUrlImg + seller.image}
                      alt=""
                    />
                  </div>
                </div>
              );
            })}

            {/* land broker Info */}
            <h1 className="client_info_title mt-10">Land Broker Information</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 ">
              <div className="agent_view_table  ">
                <table>
                  <tbody>
                    <tr>
                      <td>Land Broker Name</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{land_broker?.name}</td>
                    </tr>
                    <tr>
                      <td>Land Broker Ph No. 01</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{land_broker?.mobile_number_1}</td>
                    </tr>
                    <tr>
                      <td>Land Broker Ph No. 02</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{land_broker?.mobile_number_2}</td>
                    </tr>
                    <tr>
                      <td>Land Broker Father’s Name</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{land_broker?.father_name}</td>
                    </tr>
                    <tr>
                      <td>Land Broker Mother’s Name</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{land_broker?.mother_name}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="agent_view_table md:mt-10">
                <h1 className="text-[#414141] font-[700] text-[14px pb-4">
                  Land Broker Agrement
                </h1>
                <div className="w-[80%]">
                  {land_broker_agreements?.map((item) => (
                    <img
                      key={item?.id}
                      src={baseUrlImg + item?.file_path}
                      alt=""
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mt-10 ">
              <div>
                <h1 className="text-[#414141] font-[700] text-[14px] mb-4">
                  Dolil Image
                </h1>
                <div className="w-[80%]">
                  {dolil_image?.map((item) => (
                    <img
                      className="object-fill"
                      key={item?.id}
                      src={baseUrlImg + item?.file_path}
                      alt=""
                    />
                  ))}
                </div>
              </div>
              <div>
                <h1 className="text-[#414141] font-[700] text-[14px] mb-4">
                  Porca Image
                </h1>
                <div className="w-[80%]">
                  {porca_image?.map((item) => (
                    <img
                      className="object-fill"
                      key={item?.id}
                      src={baseUrlImg + item?.file_path}
                      alt=""
                    />
                  ))}
                </div>
              </div>
              <div>
                <h1 className="text-[#414141] font-[700] text-[14px] mb-4">
                  Another Document Image
                </h1>
                <div className="w-[80%]">
                  {other_image?.map((item) => (
                    <img
                      className="object-fill"
                      key={item?.id}
                      src={baseUrlImg + item?.file_path}
                      alt=""
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* right side button group  */}
        <div className="col-span-3 order-1 lg:order-2  ">
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} isBlock={true} />
        </div>
      </div>
    </div>
  );
};

export default SellerAgreementListView;
