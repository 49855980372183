import React from "react";
import CommonPrintPdfBtn from "../../shared/CommonPrintPdfBtn";
import Pagination from "../../shared/pagination/Pagination";
import BFolderImg from "../../asset/images/clientlandinformation/back-folder.png";
import { commonSearchInput } from "../../asset/commoncssConstant/CommonCssConstant";
import { useNavigate } from "react-router-dom";
const LandSellerInformation = () => {
  const navigate=useNavigate()
  return (
    <div>
      <h1 className="text-[#333547] font-[700] text-[24px]">Land Seller</h1>
      <div className="bg-[#FFFFFF]   rounded-[4px] px-[20px] py-[30px] my-[30px]">
        <div className="flex justify-between items-end mt-[20px]">
          <CommonPrintPdfBtn />

          <input
            type="text"
            placeholder="Search Seller Name / ID..."
            className={commonSearchInput}
          />
        </div>

        <div className="table_responsive mt-[40px]">
          <table>
            <thead>
              <tr>
                <th className="text-[#00CA08] text-start">
                  Land Booking <br />
                  Date
                </th>

                <th className="text-[#00CA08] text-start">
                  File/ <br />
                  Plot No.
                </th>
                <th className="text-[#00CA08]">Project Name</th>
                <th className="text-[#00CA08] text-start">
                  Seller <br />
                  ID
                </th>
                <th className="text-[#00CA08] text-start">
                  Seller <br />
                  Name
                </th>
                <th className="text-[#00CA08]">
                  Land <br />
                  Broker ID
                </th>
                <th className="text-[#00CA08]">
                  Land Broker <br />
                  Name
                </th>
                <th className="text-[#00CA08]">
                  Land Broker <br />
                  Money
                </th>
                <th className="text-[#00CA08] text-start">
                  Land Broker <br />
                  Extra Amount
                </th>
                <th className="text-[#00CA08]">
                  Other <br />
                  Expanse
                </th>

                <th className="text-[#00CA08]">
                  Total <br />
                  Amount
                </th>
                <th className="text-[#00CA08] text-center">
                  Land Broker <br />
                  Paid Amount
                </th>
                <th className="text-[#00CA08] text-center">
                  Land Broker <br />
                  Due Amount
                </th>
                <th className="text-[#00CA08] text-center">
                  Other <br />
                  Document
                </th>
              </tr>
            </thead>
            <tbody>
              {[...Array(10).keys()].map((item, i) => (
                <tr key={i}>
                  <td>2022-05-21</td>
                  <td>
                    <span className="font-semibold">52A1C4</span>
                  </td>
                  <td>Munshiganj Project </td>
                  <td>
                    <p className="font-semibold">5248678</p>
                    <p className="font-semibold">5248678</p>
                    <p className="font-semibold">5248678</p>
                  </td>
                  <td>
                    <p className="font-semibold">Mr. Abul </p>
                    <p className="font-semibold">Mr. Kashem </p>
                    <p className="font-semibold">Mr. Hannan </p>
                  </td>
                  <td>
                    <span className="font-semibold">5248678</span>
                  </td>
                  <td>Mr. Abul Kashem </td>
                  <td><span className="font-bold">200000</span></td>
                  <td><span className="font-bold">200000</span></td>
                  <td><span className="font-bold">200000</span></td>
                  <td><span className="font-bold">200000</span></td>
                  <td><span className="font-bold">200000</span></td>
                  <td><span className="font-bold">200000</span></td>

                  <td>
                    <img onClick={()=>navigate(`/dashboard/land-seller-information/view/${1}`)} className="mx-auto" src={BFolderImg} alt="" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination />
      </div>
    </div>
  );
};

export default LandSellerInformation;
