import { useEffect, useState } from "react";
import {
  customerInfoData,
  sellerInfoData,
} from "../../asset/constant/dashboardCardData";
import "./dashboard.css";
import axios from "axios";
import { baseUrl } from "../../BaseUrl";
const token = localStorage.getItem("token");

const Dashbaord = () => {
  const [datas, setDatas] = useState(null);
  useEffect(() => {
    axios
      .get(baseUrl + `/dashboard`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setDatas(res.data);
      });
  }, []);

  function assignDataToFields(dataFields, targetDataArray, sourceData) {
    dataFields.forEach((field, index) => {
      targetDataArray[index].total = sourceData?.[field];
    });
  }
  
  const customerFields = [
    'totalCustomers', 'paybleAmount', 'paidAmount', 'dueAmount',
    'paymentCompleteCustomer', 'regCompleteCustomer', 'bookingCancelCustomer',
    'bookingCancelPaymentReturn', 'totalAgent', 'paybleAmountAgent',
    'paidAmountAgent', 'dueAmountAgent'
  ];
  
  const sellerFields = [
    'totalLandSeller', 'paybleAmountLandSeller', 'paidAmountLandSeller',
    'dueAmountLandSeller', 'totalLandSize', 'landPurchaseCompleteTotalSeller',
    'landPurchaseCompleteTotalLandSize', 'landDispute', 'totalLandBroker',
    'paybleAmountLandBroker', 'paidAmountLandBroker', 'dueAmountLandBroker'
  ];
  
  assignDataToFields(customerFields, customerInfoData, datas);
  assignDataToFields(sellerFields, sellerInfoData, datas);
  
  return (
    <div id="dashboard">
      <div>
        <h1 className="text-[#47484A] font-[700] text-[24px] mb-3">
          Customer Information
        </h1>
        <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4">
          {customerInfoData.map((item, i) => (
            <figure key={i} className="dashboard_card">
              <div className=" h-[60px] w-[60px] rounded-full flex items-center justify-center">
                <img className="   object-cover" src={item.url} alt="" />
              </div>
              <figure>
                <figcaption className="text-[#47484A] text-end lg:max-w-[180px] max-w-[150px] font-[400] text-[16px]">
                  {item.title}
                </figcaption>
                <figcaption className="text-[#47484A] text-end font-[600] text-[26px] mt-[10px]">
                  {item.total}
                </figcaption>
              </figure>
            </figure>
          ))}
        </div>
      </div>
      <div className="mt-6">
        <h1 className="text-[#333547] font-[700] text-[24px] mb-3">
          Land Seller Information
        </h1>
        <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4">
          {sellerInfoData.map((item, i) => (
            <figure key={i} className="dashboard_card">
              <div className=" h-[68px] w-[68px] rounded-full flex items-center justify-center">
                <img className="   object-cover" src={item.url} alt="" />
              </div>
              <figure>
                <figcaption className="text-[#47484A] text-end lg:max-w-[180px] max-w-[150px] font-[400] text-[16px]">
                  {item.title}
                </figcaption>
                <figcaption className="text-[#47484A] text-end font-[600] text-[26px] mt-[10px]">
                  {item.total}
                </figcaption>
              </figure>
            </figure>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dashbaord;
