import React from "react";
import CommonPrintPdfBtn from "../../../shared/CommonPrintPdfBtn";
import BackButton from "../../../shared/BackButton";
import PrinterImg from "../../../asset/images/printer.png";
import useCommonPrint from "../../../shared/useCommonPrint/useCommonPrint";
import useCommonPDF from "../../../shared/useCommonPDF/useCommonPDF";
const LandSellerPaymentView = () => {
  const {toPDF, targetRef} = useCommonPDF({ title: "seller-payment-details"});
  const handlePrint = useCommonPrint({
    targetRef,
    title: "seller-payment-details",
    message: "thank you",
  });
  return (
    <div>
      <BackButton title={" Land Seller Payment Due Amount Details View"} />
      <div className="grid lg:grid-cols-12  mt-3 gap-5">
        <div className="col-span-9 order-2 lg:order-1 client_info_section p-[40px] ">
          <div className="agent_view_table grid lg:grid-cols-2 bg-[#F8F4FE] p-2 rounded-[5px] lg:gap-5">
            <div>
              <table style={{ borderSpacing: "0 5px" }}>
                <tbody>
                  <tr>
                    <td>Land Sell Aggrement Date</td>
                    <td>
                      <span className="mx-4">:</span>
                    </td>
                    <td>05-07-2023</td>
                  </tr>
                  <tr>
                    <td>Project Name</td>
                    <td>
                      <span className="mx-4">:</span>
                    </td>
                    <td>Munshiganj </td>
                  </tr>
                  <tr>
                    <td>File / Plot No.</td>
                    <td>
                      <span className="mx-4">:</span>
                    </td>
                    <td>2709</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <table style={{ borderSpacing: "0 5px" }}>
                <tbody>
                  <tr>
                    <td>Land Seller ID </td>
                    <td>
                      <span className="mx-4">:</span>
                    </td>
                    <td>20225455</td>
                  </tr>
                  <tr>
                    <td>Land Seller Name</td>
                    <td>
                      <span className="mx-4">:</span>
                    </td>
                    <td>Mr. Kashem</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="payment_shedule_customer_info grid grid-cols-3 my-6">
            <p className="border-1  border p-2 text-center">
              <strong className="text-[#533198]">Total Land Price</strong>{" "}
              <span className="mx-3">:</span> <span>200000 tk</span>
            </p>
            <p className="border-1  border p-2 text-center">
              <strong className="text-[#00CA08]">Total Paid</strong>{" "}
              <span className="mx-3">:</span> <span>10000 tk</span>
            </p>
            <p className="border-1  border p-2 text-center">
              <strong className="text-[#D60000]">Total Due</strong>{" "}
              <span className="mx-3">:</span> <span>90000 tk</span>
            </p>
          </div>
          <div className="table_responsive mt-[40px]">
            <table>
              <thead>
                <tr>
                  <th className="text-[#00CA08] text-start">Date</th>
                  <th className="text-[#00CA08]">Payment ID </th>
                  <th className="text-[#00CA08] text-start">
                    Money Receipt <br />
                    SL No
                  </th>
                  <th className="text-[#00CA08]">
                    Payment <br />
                    Description
                  </th>
                  <th className="text-[#00CA08] text-start">
                    Payment <br />
                    Amount
                  </th>
                  <th className="text-[#00CA08] text-start">
                    Total Payment <br />
                    Amount
                  </th>
                  <th className="text-[#00CA08]">
                    Total Due <br />
                    Amount
                  </th>
                  <th className="text-[#00CA08]">Print</th>
                </tr>
              </thead>
              <tbody>
                {[...Array(14).keys()].map((item, i) => (
                  <tr key={i}>
                    <td>2022-05-21</td>
                    <td>0125897</td>
                    <td>0125897 </td>
                    <td>
                      <span className="font-bold">Bank Payment</span>
                    </td>
                    <td>
                      <span className="text-[#533198]">৳ 500000</span>
                    </td>
                    <td>
                      <span className="text-[#16A085] ">৳ 300000</span>
                    </td>
                    <td>
                      <span className="text-[#E30B0B] ">৳ 200000</span>
                    </td>

                    <td>
                      <img src={PrinterImg} alt="" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
            {/* <Pagination /> */}
        </div>

        {/* right side button group  */}
        <div className="col-span-3 order-1 lg:order-2  ">
          <CommonPrintPdfBtn toPDF={toPDF} handlePrint={handlePrint} isBlock={true} />
        </div>
      </div>
    </div>
  );
};

export default LandSellerPaymentView;
