import {
  RiDashboardFill,
  RiPriceTag2Line,
  RiSecurePaymentFill,
} from "react-icons/ri";
import {
  FaHiking,
  FaUsersCog,
  FaXRay,
  FaMap,
  FaUser,
  FaUserCheck,
  FaUserLock,
} from "react-icons/fa";
import { TbInfoSquare } from "react-icons/tb";
import { BiMapPin, BiLandscape } from "react-icons/bi";
import { BsDatabaseFillLock, BsBank2 } from "react-icons/bs";
import { AiFillBank, AiFillFunnelPlot } from "react-icons/ai";
import { MdOutlineLandslide } from "react-icons/md";
import { GiIceland } from "react-icons/gi";

const sideBarData = [
  {
    path: "/",
    title: "Dashboard",
    icon: <RiDashboardFill />,
    children: [],
  },
  {
    title: "Client Information",
    path: "/dashboard/client-list",
    icon: <FaUsersCog />,
    children: [
      {
        title: "Client List",
        path: "/dashboard/client-list",
      },
      {
        title: "Client Plot Details List",
        path: "/dashboard/client-plot-list",
      },
    ],
  },

  {
    path: "/dashboard/client-price-information",
    title: "Client Price Information",
    icon: <RiPriceTag2Line />,
    children: [],
  },
  {
    title: "Client Payment Information",
    path: "/dashboard/client-payment-and-due-list",
    icon: <RiSecurePaymentFill />,
    children: [
      {
        path: "/dashboard/client-payment-and-due-list",
        title: "Client Payment And Due List",
      },
    ],
  },
  {
    title: "Client Land Sell Details",
    path: "/dashboard/client-land-sell-agreement-complete-list",
    icon: <TbInfoSquare />,
    children: [
      {
        path: "/dashboard/client-land-sell-agreement-complete-list",
        title: "Client Land Sell Agreement Complete List",
      },
      {
        path: "/dashboard/Client-LandSell-SubKobola-deed-CompleteList",
        title: "Client Land Sell Sub Kobola Deed Complete List",
      },
      {
        path: "/dashboard/client-land-sell-power-of-eterny-list",
        title: "Client Land Sell  Power of eternity List ",
      },
      {
        path: "/dashboard/client-land-sell-registered-baynalist",
        title: "Client Land Sell  Registered Bayna List",
      },
      {
        path: "/dashboard/client-land-sell-unregistered-baynalist",
        title: "Client Land Sell  Unregistered Bayna List",
      },
      {
        path: "/dashboard/client-land-sell-powerofeternity-for-court",
        title: "Client Land Sell  Power of eternity for Court",
      },
      // {
      //   path: "/dashboard/client-land-description",
      //   title: "Client Land Description old",
      // },
    ],
  },
  {
    title: "Project Land Purchase Details",
    path: "/dashboard/purchase-agreement-complete-list",
    icon: <FaXRay />,
    children: [
      {
        path: "/dashboard/purchase-agreement-complete-list",
        title: "Purchase Agreement Complete List",
      },
      {
        path: "/dashboard/purchase-sub-kobola-deed-complete-list",
        title: "Purchase Sub Kobola Deed Complete List",
      },
      {
        path: "/dashboard/purchase-power-of-eternity-list",
        title: "Purchase power of eterny list",
      },
      {
        path: "/dashboard/purchase-register-bayna-list",
        title: "Purchase Registered Bayna List",
      },
      {
        path: "/dashboard/purchase-unregister-bayna-list",
        title: "Purchase Unregistered Bayna List",
      },
      {
        path: "/dashboard/purchase-powerof-eterny-forcourt",
        title: "Purchase Power of eternity for Court",
      },
      
      /* {
        path: "/dashboard/purchase-land-map",
        title: "Purchase Land Map",
      }, */

      /* {
      path: "/dashboard/registry-complete-land-information",
       title: "Registry Complete Land Information",
      }, */
    ],
  },
  {
    // path: "/dashboard/land-seller-information",
    title: "Land Seller Information",
    path: "/dashboard/seller-list",
    icon: <FaHiking />,
    children: [
      {
        path: "/dashboard/seller-list",
        title: "Seller List",
      },
      {
        path: "/dashboard/sellerAgreement",
        title: "Seller Agreement List",
      },
      {
        path: "/dashboard/seller-payment",
        title: "Seller Payment List",
      },
      // {
      //   path: "/dashboard/land-seller-information",
      //   title: "Land Seller Information old",
      // },
    ],
  },
  // {
  //   path: "/dashboard/land-seller-payment-information",
  //   title: "Land Seller Payment Information",
  //   // children: [],
  // },
 /*  {
    path: "/dashboard/mouzamap-landpurchase-fromlandowner",
    title: "Mouza Map (Land Purchase  from Land Owner)",
    icon: <FaMap />,
    children: [],
  }, */
 /*  {
    path: "/dashboard/mouzamap-landsell-tocustomer",
    title: "Mouza Map (Land Sell to Customer)",
    icon: <BiMapPin />,
    children: [],
  }, */

  {
    title: "Agent Information",
    path: "/dashboard/agent-list",
    icon: <FaUser />,
    children: [
      {
        path: "/dashboard/agent-list",
        title: "Agent List",
      },
      {
        path: "/dashboard/agent-work-list",
        title: "Agent Work List",
      },
      {
        path: "/dashboard/agent-payment-list",
        title: "Agent Payment List",
      },
    ],
  },
  {
    title: "Land Broker Information",
    path: "/dashboard/land-broker-list",
    icon: <FaUserCheck />,
    children: [
      {
        path: "/dashboard/land-broker-list",
        title: "Land Broker List",
      },
      {
        path: "/dashboard/land-broker-work-list",
        title: "Land Broker Work List",
      },
      {
        path: "/dashboard/land-broker-payment-list",
        title: "Land Broker Payment List",
      },
    ],
  },
  // {
  //   title: "All Statement",
  //   icon: <BsDatabaseFillLock />,
  //   path: "/dashboard/all-statement",
  //   children:[],
  // },
  {
    title: "Land Information Bank",
    path: "/dashboard/project-info",
    icon: <AiFillBank />,
    children: [
      {
        path: "/dashboard/project-info",
        title: "Project Information",
      },
      {
        path: "/dashboard/mouza-info",
        title: "Mouza Information",
      },
      {
        path: "/dashboard/cs-dag-khatiyan",
        title: "CS Dag & Khatiyan",
      },
      {
        path: "/dashboard/sa-dag-khatiyan",
        title: "SA Dag & Khatiyan",
      },
      {
        path: "/dashboard/rs-dag-khatiyan",
        title: "RS Dag & Khatiyan",
      },
      {
        path: "/dashboard/bs-dag-khatiyan",
        title: "BS Dag & Khatiyan",
      },
    ],
  },
  {
    title: "Land Mutation Bank",
    path: "/dashboard/cs-mutation-list",
    icon: <BiLandscape />,
    children: [
      {
        path: "/dashboard/cs-mutation-list",
        title: "CS Mutation List",
      },
      {
        path: "/dashboard/sa-mutation-list",
        title: "SA Mutation List",
      },
      {
        path: "/dashboard/rs-mutation-list",
        title: "RS Mutation List",
      },
      {
        path: "/dashboard/bs-mutation-list",
        title: "BS Mutation List",
      },
    ],
  },
  {
    title: "Land Sub-deed Bank",
    path: "/dashboard/cs-subdeed-list",
    icon: <BsBank2 />,
    children: [
      {
        path: "/dashboard/cs-subdeed-list",
        title: "CS Sub-deed List",
      },
      {
        path: "/dashboard/sa-subdeed-list",
        title: "SA Sub-deed List",
      },
      {
        path: "/dashboard/rs-subdeed-list",
        title: "RS Sub-deed List",
      },
      {
        path: "/dashboard/bs-subdeed-list",
        title: "BS Sub-deed List",
      },
      // {
      //   path: "/dashboard/current-owner-subdeed-details",
      //   title: "Current Owner Sub-deed Details",
      // },
    ],
  },
  {
    title: "Land Dispute Bank",
    path: "/dashboard/cs-dispute-list",
    icon: <MdOutlineLandslide />,
    children: [
      {
        path: "/dashboard/cs-dispute-list",
        title: "CS Dispute List",
      },
      {
        path: "/dashboard/sa-dispute-list",
        title: "SA Dispute List",
      },
      {
        path: "/dashboard/rs-dispute-list",
        title: "RS Dispute List",
      },
      {
        path: "/dashboard/bs-dispute-list",
        title: "BS Dispute List",
      },
      // {
      //   path: "/asdfaf",
      //   title: "Running Dispute List & Status",
      // },
    ],
  },
  {
    title: "Land Mouza Map Bank",
    path: "/dashbooard/cs-mouza-map-list",
    icon: <GiIceland />,
    children: [
      {
        path: "/dashbooard/cs-mouza-map-list",
        title: "CS Mouza Map List",
      },
      {
        path: "/dashbooard/sa-mouza-map-list",
        title: "SA Mouza Map Lis",
      },
      {
        path: "/dashbooard/rs-mouza-map-list",
        title: "RS Mouza Map List",
      },
      {
        path: "/dashbooard/bs-mouza-map-list",
        title: "BS Mouza Map List",
      },
    ],
  },
  {
    title: "Plot  Bank",
    path: "/dashboard/plotlist",
    icon: <AiFillFunnelPlot />,
    children: [
      {
        path: "/dashboard/plotlist",
        title: "Plot",
      },
      {
        path: "/dashboard/plot-a-lot-list-with-customer",
        title: "Plot Alot List with Customer",
      },
      {
        path: "/dashboard/remaining-plot-list",
        title: "Remaining Plot List",
      },
      {
        path: "/dashboard/client-registry-complete-plot",
        title: "Client Registry Complete  Plot List",
      },
      {
        path: "/dashboard/client-mutation-complete-plot-list",
        title: "Client Mutation Complete  Plot List",
      },
      {
        path: "/dashboard/client-plot-payment-complete-list",
        title: "Client Plot Payment  Complete List",
      },
      /* {
        path: "/dashboard/client-registry-payment-complete-list",
        title: "Client Registry Payment  Complete List",
      }, */
    ],
  },
  {
    title: "Role Permission",
    path: "/dashboard/userList",
    icon: <FaUserLock />,
    children: [
      {
        path: "/dashboard/userList",
        title: "User List",
      },
      {
        path: "/dashboard/projectAdminList",
        title: "Project Admin List",
      },
      {
        path: "/dashboard/createRole",
        title: "Create Role",
      },
    ],
  },
];

export default sideBarData;
