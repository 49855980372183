import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
const useCommonPrint = ({targetRef, title, message}) => {
  const handlePrint = useReactToPrint({
    content: () => targetRef.current,
    documentTitle: `${title}`,
    onafterprint: () => toast(`${message}`),
  });
  return handlePrint;
};

export default useCommonPrint;
