import React, { useEffect, useState } from "react";
import Pagination from "../../../shared/pagination/Pagination";
import ViewImg from "../../../asset/images/clientlist/view.png";
import ActionImg from "../../../asset/images/clientlist/action.png";
import { Link } from "react-router-dom";
import CommonPrintPdfBtn from "../../../shared/CommonPrintPdfBtn";
import axios from "axios";
import { baseUrl } from "../../../BaseUrl";
import { LuArrowDownUp } from "react-icons/lu";
import {
  commonAction,
  // commonCount,
  // commonResponsiveAmount,
  commonResponsiveMenu,
  commonSearchFiled,
  commonTableHeadingOne,
  commonTableRow,
  commonTitle,
} from "../../../asset/commoncssConstant/CommonCssConstant";
import useCommonPDF from "../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../shared/useCommonPrint/useCommonPrint";

const PurchaseAgreementCompleteList = () => {
  const [lists, setLists] = useState([]);
  const token = localStorage.getItem("token");
  const [dolilVaritationForSelect, setDolilVaritationForSelect] = useState([]);
  const [bsInfoForSelect, setBsInfoForSelect] = useState([]);
  const [landSellerForSelect, setLandSellerForSelect] = useState([]);
  const [landBokerForSelect, setLandBokerForSelect] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const [paginationData, setPaginationData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const { toPDF, targetRef } = useCommonPDF({
    title: "purchase-agreement-list",
  });

  const handlePrint = useCommonPrint({
    targetRef,
    title: "purchase agreement list",
    message: "thank you",
  });

  // pagination part start here
  const handleNext = () => {
    if (page === pageCount) {
      return page;
    } else {
      return setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page === 1) {
      return page;
    } else {
      return setPage(page - 1);
    }
  };

  //------get data for /purchasedLandDetails/allList, dolilVaritation/list, landInformationBank/bsDagKhatiyan/view, /landSeller/view,  /landBroker/view start-----------------
  useEffect(() => {
    axios
      .get(baseUrl + `/purchasedLandDetails/allList`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLists(res?.data?.data);
      });

    axios
      .get(baseUrl + `/landInformationBank/bsDagKhatiyan/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setBsInfoForSelect(res?.data?.data);
      });
    axios
      .get(baseUrl + `/landSeller/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLandSellerForSelect(res?.data?.data);
      });
    axios
      .get(baseUrl + `/dolilVaritation/list`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setDolilVaritationForSelect(res?.data?.data);
      });
    axios
      .get(baseUrl + `/landBroker/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLandBokerForSelect(res?.data?.data);
      });
  }, [token]);
  //------get data for /purchasedLandDetails/allList, dolilVaritation/list, landInformationBank/bsDagKhatiyan/view, /landSeller/view,  /landBroker/view end-----------------

  return (
    <>
      <h1 className={`${commonTitle}`}>Purchase Agreement Complete List</h1>
      <div className="bg-[#FFFFFF]   rounded-[4px] px-[20px] py-[30px] my-[30px]">
        <div className={commonResponsiveMenu}>
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} />
          {/* <div className={commonResponsiveAmount}>
            <div className={`${commonCount}`}>
              <span>Land Size </span>: <span>5000</span>
            </div>
            <div className={`${commonCount}`}>
              <span>Land Price </span>: <span>5000</span>
            </div>
            <div className={`${commonCount}`}>
              <span>Total Paid </span>: <span>5000</span>
            </div>
            <div className={`${commonCount}`}>
              <span>Total Due </span>: <span>5000</span>
            </div>
            <div className={`${commonCount}`}>
              <span>Number Of Land </span>: <span>5000</span>
            </div>
          </div> */}

          <input
            onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
            type="text"
            placeholder="Search dolil variation"
            // placeholder="Select Search Type"
            className={`${commonSearchFiled}`}
          />
        </div>

        <div ref={targetRef} className="table_responsive text-center mt-[40px]">
          <table>
            <thead>
              <tr className={`${commonTableRow}`}>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span>Agreement Date</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span> Purchase Deed No</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span>BS Dag</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span>BS Khatiyan</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span>Project Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span> Purchase Land Size</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span>
                      {" "}
                      Land Price <br />
                      (Per Katha){" "}
                    </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span>Total Price </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span> Paid Amount</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span> Due Amount</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span> Dolil Variation</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span>Seller Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span>
                      {" "}
                      Action <br />
                      Update / View
                    </span>
                  </div>
                </th>
              </tr>
            </thead>
            {lists.length > 0 && (
              <tbody>
                {paginationData
                  ?.filter((info) => {
                    return searchValue !== ""
                      ? info?.dolil_variation?.name
                          .toLowerCase()
                          .includes(searchValue)
                      : info;
                  })
                  .map((item) => (
                    <tr key={item.id}>
                      <td>{item.agreement_date}</td>
                      <td>{item.purchase_deed_no}</td>
                      <td>
                        <div className="font-bold">
                          {item?.bs_info?.map((bs, i) => (
                            <span key={bs?.id}>{bs?.bs_dag_no}</span>
                          ))}
                        </div>
                      </td>
                      <td>
                        <div className="font-bold">
                          {item?.bs_info?.map((bs, i) => (
                            <span key={bs?.id}>{bs?.bs_khatiyan_no}</span>
                          ))}
                        </div>
                      </td>
                      <td>
                        <div className="font-bold">
                          {item?.bs_info?.map((bs, i) => (
                            <span key={bs?.id}>
                              {
                                bs?.rs_info?.sa_info?.cs_info?.mouza_info
                                  ?.project_info?.project_name
                              }
                            </span>
                          ))}
                        </div>
                      </td>
                      <td>{item?.land_size_katha}</td>
                      <td>{item?.price_info?.price_per_katha}</td>
                      <td>{item?.price_info?.total_price}</td>
                      <td>{item?.price_info?.paid_amount}</td>
                      <td>
                        {item?.price_info?.total_price -
                          item?.price_info?.paid_amount}
                      </td>
                      <td>{item?.dolil_variation?.name}</td>
                      <td>
                        {item?.seller_info?.map((seller, i) => (
                          <div key={seller?.id}>
                            <span>{seller?.name}</span>
                          </div>
                        ))}
                      </td>
                      <td>
                        <div className="flex gap-2 justify-center">
                          <Link
                            to={`/dashboard/sellerAgreement/edit`}
                            state={{
                              item,
                              bsInfoForSelect,
                              landSellerForSelect,
                              dolilVaritationForSelect,
                              landBokerForSelect,
                            }}
                          >
                            <img
                              className={commonAction}
                              src={ActionImg}
                              alt=""
                            />
                          </Link>
                          <Link
                            to={`/dashboard/project-land-purchase-details/view`}
                            state={{
                              item: item,
                            }}
                          >
                            <img
                              className={commonAction}
                              src={ViewImg}
                              alt=""
                            />
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            )}
          </table>
        </div>

        <Pagination
          data={lists}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          setPageCount={setPageCount}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      </div>
    </>
  );
};

export default PurchaseAgreementCompleteList;
