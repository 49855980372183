import React from "react";
import {
  dashboardBodyWraper,
  mapImg,
} from "../../../../asset/commoncssConstant/CommonCssConstant";
import BackButton from "../../../../shared/BackButton";
import { useLocation } from "react-router-dom";
import { baseUrlImg } from "../../../../BaseUrl";

const CsDagMouzaMapImage = () => {
  const item = useLocation().state.item?.map_image;
  return (
    <form>
      <BackButton title={"CS Mouza Map Image"} />
      <div
        style={{ padding: 0, marginTop: "10px" }}
        className={`${dashboardBodyWraper}`}
      >
        <div className="p-[30px]">
          <div className="flex flex-wrap justify-center">
            {item.length > 0 ? (
              item.map((item, i) => (
                <img
                  key={i}
                  className={mapImg}
                  src={baseUrlImg + item?.file_path}
                  alt=""
                />
              ))
            ) : (
              <b>No Map Image Available</b>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default CsDagMouzaMapImage;
