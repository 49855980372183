import React from "react";
import BackButton from "../../../../shared/BackButton";
import {
  commonFormMenu,
  commonFormTitle,
  dashboardBodyWraper,
  errorMessage,
} from "../../../../asset/commoncssConstant/CommonCssConstant";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../../../BaseUrl";
import { useForm } from "react-hook-form";

const EditPlot = () => {
  const item = useLocation().state.item;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  //--------plotBank/update start---------
  const onSubmit = (data) => {
    data.id = item?.id;
    axios
      .post(baseUrl + `/plotBank/update`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          navigate(-1);
        }
      });
  };
  //--------plotBank/update end---------
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BackButton title={"Edit Plot"} />
      <div
        style={{ padding: 0, marginTop: "10px" }}
        className={`${dashboardBodyWraper}`}
      >
        <div className="p-[30px]">
          <p className={commonFormTitle}>Plot Information</p>
          <div className={commonFormMenu}>
            <div className="common_input_wraper">
              <label>Project Start Date</label>
              <input
                {...register(`project_start_date`, {
                  required: "Project start date is required",
                })}
                type="date"
                defaultValue={item?.project_start_date}
              />
              {errors?.project_start_date && (
                <p className={errorMessage}>
                  {errors?.project_start_date?.message}
                </p>
              )}
            </div>
            <div className="common_input_wraper">
              <label>Project Name</label>
              <input
                {...register(`project_name`, {
                  pattern: {
                    value: /^[A-Za-z]+[A-Za-z0-9\-_~(\s]*[A-Za-z0-9\s)]$/,
                    message: "Insert valid project name",
                  },
                })}
                type="text"
                defaultValue={item?.project_name}
              />
              {errors?.project_name && (
                <p className={errorMessage}>{errors?.project_name?.message}</p>
              )}
            </div>
            <div className="common_input_wraper">
              <label>No of Plot </label>
              <input
                {...register(`no_of_plot`, {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Insert valid plot no.",
                  },
                })}
                type="text"
                defaultValue={item?.no_of_plot}
              />
              {errors?.no_of_plot && (
                <p className={errorMessage}>{errors?.no_of_plot?.message}</p>
              )}
            </div>
            <div className="common_input_wraper">
              <label>Road Width </label>
              <input
                {...register(`road_width`, {
                  pattern: {
                    value: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Insert valid road width",
                  },
                })}
                type="text"
                step="0.01"
                defaultValue={item?.road_width}
              />
              {errors?.road_width && (
                <p className={errorMessage}>{errors?.road_width?.message}</p>
              )}
            </div>
            <div className="common_input_wraper">
              <label>File / Plot No.</label>
              <input
                {...register("file_no")}
                type="text"
                defaultValue={item?.file_no}
              />
            </div>
            <div className="common_input_wraper">
              <label>Road No / Name</label>
              <input
                {...register("road_number_name")}
                type="text"
                defaultValue={item?.road_number_name}
              />
            </div>
            <div className="common_input_wraper">
              <label>Plot Facing</label>
              <input
                {...register("plot_facing")}
                type="text"
                defaultValue={item?.plot_facing}
              />
            </div>
            <div className="common_input_wraper">
              <label>Sector</label>
              <input
                {...register(`sector`, {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Insert valid sector",
                  },
                })}
                type="text"
                defaultValue={item?.sector}
              />
              {errors?.sector && (
                <p className={errorMessage}>{errors?.sector?.message}</p>
              )}
            </div>
            <div className="common_input_wraper">
              <label>Plot Size Katha</label>
              <input
                {...register(`plot_size_katha`, {
                  pattern: {
                    value: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Insert valid plot size",
                  },
                })}
                type="text"
                step="0.01"
                defaultValue={item?.plot_size_katha}
              />
              {errors?.plot_size_katha && (
                <p className={errorMessage}>
                  {errors?.plot_size_katha?.message}
                </p>
              )}
            </div>
            <div className="common_input_wraper">
              <label>Plot Size Ojudangsho</label>
              <input
                {...register(`plot_size_ojutangsho`, {
                  pattern: {
                    value: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Insert valid plot size",
                  },
                })}
                type="text"
                step="0.01"
                defaultValue={item?.plot_size_ojutangsho}
              />
              {errors?.plot_size_ojutangsho && (
                <p className={errorMessage}>
                  {errors?.plot_size_ojutangsho?.message}
                </p>
              )}
            </div>
            <div className="common_input_wraper">
              <label>Plot Dimension</label>
              <input
                {...register("plot_dimension")}
                type="text"
                defaultValue={item?.plot_dimension}
              />
            </div>

            <div className="col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-5"></div>

            <div className="common_input_wraper col-span-1 lg:col-span-2 xl:col-span-2">
              <label>Description</label>
              <textarea
                {...register("description")}
                rows={4}
                placeholder="Description"
              />
            </div>
          </div>
          <div className="text-center py-10">
            <button type="submit" className="submit_button">
              Update
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditPlot;
