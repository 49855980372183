import React, { useEffect, useState } from "react";
import BackButton from "../../../../shared/BackButton";
import axios from "axios";
import { baseUrl } from "../../../../BaseUrl";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  commonUserInfo,
  commonUserRow,
  errorMessage,
} from "../../../../asset/commoncssConstant/CommonCssConstant";

const EditAgentPayment = () => {
  const item = useLocation().state.item;
  const statement = useLocation().state.statement;
  const [receivableAmount, setReceivableAmount] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(statement?.payment_by);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  //----------set default receivableAmount by paymentPurpose start----------
  useEffect(() => {
    if (statement?.payment_purpose === "Agent Money") {
      setReceivableAmount(
        item?.agent_price?.agent_money - item?.agent_price?.agent_money_paid
      );
    } else if (statement?.payment_purpose === "Extra Money") {
      setReceivableAmount(
        item?.agent_price?.extra_money - item?.agent_price?.extra_money_paid
      );
    } else if (statement?.payment_purpose === "Conveyance") {
      setReceivableAmount(
        item?.agent_price?.agent_conveyance -
          item?.agent_price?.agent_conveyance_paid
      );
    } else if (statement?.payment_purpose === "Mobile Bill") {
      setReceivableAmount(
        item?.agent_price?.mobile_bill - item?.agent_price?.mobile_bill_paid
      );
    } else if (statement?.payment_purpose === "Entertainment") {
      setReceivableAmount(
        item?.agent_price?.entertainment - item?.agent_price?.entertainment_paid
      );
    } else setReceivableAmount(null);
  }, [
    statement?.payment_purpose,
    item.agent_price.agent_money,
    item.agent_price.agent_money_paid,
    item.agent_price.extra_money,
    item.agent_price.extra_money_paid,
    item.agent_price.agent_conveyance,
    item.agent_price.agent_conveyance_paid,
    item.agent_price.mobile_bill,
    item.agent_price.mobile_bill_paid,
    item.agent_price.entertainment,
    item.agent_price.entertainment_paid,
  ]);
  //----------set default receivableAmount by paymentPurpose end----------
  //-------payment method select functionality start--------------------------------
  const handlePaymentMethod = (method) => {
    setPaymentMethod(method);
  };
  //-------payment method select functionality end--------------------------------
  //-------------Add updateAgentPayment Start----------------------//
  const onSubmit = (data) => {
    data.customer_land_info_id = item?.id;
    data.agent_payment_information_id = item?.agent_price?.id;
    data.agent_payment_statements_id = statement?.id;
    data.payment_purpose = statement?.payment_purpose;
    data.payment_by = paymentMethod;

    if (data.payment_purpose === "Agent Money") {
      data.payment_for = 1;
    } else if (data.payment_purpose === "Extra Money") {
      data.payment_for = 2;
    } else if (data.payment_purpose === "Conveyance") {
      data.payment_for = 3;
    } else if (data.payment_purpose === "Mobile Bill") {
      data.payment_for = 4;
    } else if (data.payment_purpose === "Entertainment") {
      data.payment_for = 5;
    }
    if (
      data.payment_by === "bkash" ||
      data.payment_by === "nagad" ||
      data.payment_by === "rocket"
    ) {
      delete data.bank_name;
      delete data.bank_cheque_no;
      delete data.bank_account_no;
    }
    if (data.payment_by === "cheque") {
      delete data.mobile_account_no;
    }
    if (data.payment_by === "cash") {
      delete data.mobile_account_no;
      delete data.bank_name;
      delete data.bank_cheque_no;
      delete data.bank_account_no;
    }
    axios
      .post(baseUrl + `/agent/updatePayment`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          navigate(-1);
        } else alert(res.data.message);
      });
  };
  //-------------Add updateAgentPayment End----------------------//
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BackButton title={"Edit Payment"} />
      <div className="  mt-3 gap-5">
        <div className=" client_info_section p-[40px] shadow-lg">
          <h1 className="info_title">Edit Payment</h1>
          <div className={commonUserInfo}>
            <div className={commonUserRow}>
              <div>Agent Name </div>
              <div>:</div>
              <div>{item?.agent?.name}</div>
            </div>
            <div className={commonUserRow}>
              <div>Customer Name </div>
              <div>:</div>
              <div>
                {item?.customer_info?.map((cus, i) => (
                  <span key={cus?.id}>
                    {cus?.name}{" "}
                    {i < item?.customer_info?.length - 1 ? ", " : ""}
                  </span>
                ))}
              </div>
            </div>
            <div className={commonUserRow}>
              <div>Project Name </div>
              <div>:</div>
              <div>{item?.plot_info?.project_name}</div>
            </div>
            <div className={commonUserRow}>
              <div>File/Plot No </div>
              <div>:</div>
              <div>{item?.plot_info?.file_no}</div>
            </div>
          </div>
          <div className="mt-10">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
              <div className="common_input_wraper">
                <label>Payment Purpose</label>
                <input readOnly disabled value={statement?.payment_purpose} />
              </div>
              <div className="common_input_wraper">
                <label>Receivable Amount</label>
                <input
                  placeholder="Amount"
                  readOnly
                  disabled
                  value={receivableAmount !== null ? receivableAmount : ""}
                />
              </div>
              <div className="common_input_wraper">
                <label>Add Agent Money Amount</label>
                <input
                  {...register(`amount`, {
                    pattern: {
                      value: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Insert valid amount",
                    },
                  })}
                  type="text"
                  placeholder=" Amount"
                  defaultValue={statement?.amount}
                />
                {errors?.amount && (
                  <p className={errorMessage}>{errors?.amount?.message}</p>
                )}
              </div>
              <div className="common_input_wraper">
                <label>Pay By</label>
                <select onChange={(e) => handlePaymentMethod(e.target.value)}>
                  <option value={statement?.payment_by} selected>
                    {statement?.payment_by || "Please Select One"}
                  </option>
                  <option value="bkash">Bkash</option>
                  <option value="nagad">Nagad</option>
                  <option value="rocket">Rocket</option>
                  <option value="cheque">Cheque</option>
                  <option value="cash">Cash</option>
                </select>
              </div>
              {paymentMethod === "bkash" ||
              paymentMethod === "nagad" ||
              paymentMethod === "rocket" ? (
                <div className="common_input_wraper">
                  <label>Mobile Account Number</label>
                  <input
                    {...register(`mobile_account_no`, {
                      pattern: {
                        value: /^(0|\+?[1-9][0-9]*)$/,
                        message: "Insert country code before your number",
                      },
                    })}
                    type="text"
                    placeholder={`Mobile Account Number`}
                    defaultValue={statement?.mobile_account_no}
                  />
                  {errors?.mobile_account_no && (
                    <p className={errorMessage}>
                      {errors?.mobile_account_no?.message}
                    </p>
                  )}
                </div>
              ) : null}
              {paymentMethod === "cheque" ? (
                <>
                  <div className="common_input_wraper">
                    <label>Bank Name</label>
                    <input
                      {...register("bank_name")}
                      type="text"
                      placeholder={`Bank Name`}
                      defaultValue={statement?.bank_name}
                    />
                  </div>
                  <div className="common_input_wraper">
                    <label>Bank Account Number</label>
                    <input
                      {...register("bank_account_no")}
                      type="text"
                      placeholder={`Bank Account Number`}
                      defaultValue={statement?.bank_account_no}
                    />
                  </div>
                  <div className="common_input_wraper">
                    <label>Bank Cheque Number</label>
                    <input
                      {...register("bank_cheque_no")}
                      type="text"
                      placeholder={`Bank Cheque Number`}
                      defaultValue={statement?.bank_cheque_no}
                    />
                  </div>
                </>
              ) : null}
              <div className="common_input_wraper">
                <label>Txn ID / Bank Recite No.</label>
                <input
                  {...register("invoice_no")}
                  type="text"
                  placeholder=" Txn ID / Bank Recite No."
                  defaultValue={statement?.invoice_no}
                />
              </div>
              <div className="common_input_wraper">
                <label>Note</label>
                <textarea
                  {...register("note")}
                  rows={3}
                  placeholder="Note"
                  defaultValue={statement?.note}
                />
              </div>
            </div>
          </div>
          <div className="text-center py-10">
            <button type="submit" className="submit_button">
              Update
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditAgentPayment;
