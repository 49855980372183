
import dbimgone from '../images/icon/dbimgone.png'
import dbimgtwo from '../images/icon/dbimgtwo.png'
import dbimgthree from '../images/icon/dbimgthree.png'
import dbimgfour from '../images/icon/dbimgfour.png'
import dbimgfive from '../images/icon/dbimgfive.png'
import dbimgsix from '../images/icon/dbimgsix.png'
import dbimgseven from '../images/icon/dbimgseven.png'
import dbimgeight from '../images/icon/dbimgeight.png'
import dbsiimgeight from '../images/icon/dbsiimgeight.png'
import map from '../images/icon/dbsiimgfive.png'
export const customerInfoData=[
    {
        title:"Total Number of Customer",
        url:dbimgone
        
    },
    {
        title:"(Customer) Total Payable Amount",
        url:dbimgtwo
    },
    {
        title:"(Customer) Total Payment Paid Complete",
        url:dbimgthree
    },
    {
        title:"(Customer) Total Payment Due",
        url:dbimgfour
    },
    {
        title:"Number Of Customer Payment Complete",
        url:dbimgfive
    },
    {
        title:"Number Of Customer Land Registration Complete",
        url:dbimgsix
    },
    {
        title:"Booking Cancel Customer  Number",
        url:dbimgseven
    },
    {
        title:"Number of Customer Booking Cancel Payment Return Complete",
        url:dbimgeight
    },
    {
        title:"Total Number Of Agent",
        url:dbimgone
    },
    {
        title:"(Agent) Total Payable Amount",
        url:dbimgtwo
    },
    {
        title:"(Agent) Total Payment Paid Complete",
        url:dbimgthree
    },
    {
        title:"(Agent) Total Payment Due",
        url:dbimgfour
    },
]
export const sellerInfoData=[
    {
        title:"Total Number of Land seller",
        url:dbimgone
    },
    {
        title:"(Land seller) Total Payable Amount",
        url:dbimgtwo
    },
    {
        title:"(Land seller) Total Payment Paid Complete",
        url:dbimgthree
    },
    {
        title:"(Land seller) Total Payment Due",
        url:dbimgfour
    },
    {
        title:"Total Land Size (Decimel)",
        url:map
    },
    {
        title:"Land Purchase Complete Number  of seller",
        url:dbimgfive
    },
    {
        title:"Land Purchase Complete Total Land Size (Decimel)",
        url:dbimgsix
    },
    {
        title:"Number of Purchase Land Dispute",
        url:dbsiimgeight
    },
    {
        title:"Total Number of Land Broker",
        url:dbimgone
    },
    {
        title:"(Land Broker) Total Payable Amount",
        url:dbimgtwo
    },
    {
        title:"(Land Broker) Total Payment Paid Complete",
        url:dbimgthree
    },
    {
        title:"(Land Broker) Total Payment Due",
        url:dbimgfour
    },
]


