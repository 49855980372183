import React from "react";
import BackButton from "../../../../shared/BackButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { baseUrl } from "../../../../BaseUrl";
import {
  commonUserInfo,
  commonUserRow,
  errorMessage,
} from "../../../../asset/commoncssConstant/CommonCssConstant";

const LandBrokerCreatePayment = () => {
  const item = useLocation().state.item;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  //-------------CreateLandBrokerPayment Start----------------------//
  const onSubmit = (data) => {
    data.land_seller_agreements_id = item.id;
    axios
      .post(baseUrl + `/landBroker/createPayment`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          navigate(-1);
        }
      });
  };
  //-------------CreateLandBrokerPayment End----------------------//
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BackButton title={"Create Payment"} />
      <div className="  mt-3 gap-5">
        <div className=" client_info_section p-[40px] shadow-lg">
          <h1 className="info_title">Create Payment</h1>
          <div className={commonUserInfo}>
            <div className={commonUserRow}>
              <div>Land Broker Name </div>
              <div>:</div>
              <div>{item?.land_broker?.name}</div>
            </div>
            <div className={commonUserRow}>
              <div>Land Seller Name </div>
              <div>:</div>
              <div>
                {item?.seller_info?.map((seller) => (
                  <span key={seller.id}>{seller.name}</span>
                ))}
              </div>
            </div>
            <div className={commonUserRow}>
              <div>Project Name </div>
              <div>:</div>
              <div>
                {item?.bs_info?.map((bs) => (
                  <span key={bs.id}>
                    {
                      bs?.rs_info?.sa_info?.cs_info?.mouza_info?.project_info
                        ?.project_name
                    }
                  </span>
                ))}
              </div>
            </div>
          </div>
          <div className="mt-10">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              <div className="common_input_wraper">
                <label>Payment Purpose</label>
                <select {...register("payment_purpose")} name="" id="" required>
                  <option value="" selected>
                    Select Purpose
                  </option>
                  <option value="2">Extra Money</option>
                  <option value="3">Conveyance</option>
                  <option value="4">Mobile Bill</option>
                  <option value="5">Entertainment</option>
                </select>
              </div>
              <div className="common_input_wraper">
                <label>Receivable Amount</label>
                <input
                  {...register(`amount`, {
                    pattern: {
                      value: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Insert valid amount",
                    },
                  })}
                  type="text"
                  placeholder="Receivable Amount"
                />
                {errors?.amount && (
                  <p className={errorMessage}>{errors?.amount?.message}</p>
                )}
              </div>
              <div className="col-span-1 md:col-span-2 lg:col-span-3"></div>
              <div className="common_input_wraper">
                <label>Note</label>
                <textarea {...register("note")} rows={3} placeholder="Note" />
              </div>
            </div>
            <div className="text-center pb-12 mt-10">
              <button type="submit" className="submit_button">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default LandBrokerCreatePayment;
