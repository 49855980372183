export const commonAddButton = "flex md:justify-end lg:justify-end";
export const commonCaption = "px-2 flex flex-col gap-4 md:flex-row md:justify-between md:items-end  mt-[20px]";
export const commonResponsiveMenu = "flex flex-wrap justify-between items-end gap-4 mt-[20px]";
export const commonResponsiveAmount = "flex justify-between items-center gap-2 order-3 md:order-2 flex-wrap";


export const addCustomerCss =
  "bg-gradient-to-r from-[#0C768A] via-[#1e788a] to-[#2894a8] hover:bg-gradient-to-r hover:from-[#0b5b6b] hover:via-[#15606f] hover:to-[#257a88] text-white text-[14px] mx-2 px-[8px] py-[6px] md:px-[13px] md:py-[10px] rounded-[5px] flex items-center ";
  export const commonFormTitle = "text-[#87898f] font-bold uppercase text-[18px] bg-[#F5F6F8] py-3 my-4 mx-4 md:mx-4 lg:mx-0 text-center rounded-md";
  export const commonFormMenu = "grid grid-cols-1 gap-4 px-4 md:px-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5";
  export const errorMessage = "text-red-500 pt-2 text-sm";

  export const commonBankMenu = "grid gap-4 px-4 md:px-4 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1";
  export const commonBankColumnSpan = "col-span-1 lg:col-span-2 xl:col-span-2";
  export const mapImg = "h-[20vh] w-[80vw] md:h-[40vh] lg:h-[50vh] xl:h-[80vh]";
  export const commonUserInfo = "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 bg-[#F5F6F8] border border-[#d5d7d7] my-10  rounded-lg p-5";
  export const commonUserRow = "grid grid-cols-[150px_10px_1fr]";

export const dashboardBodyWraper =
  "bg-[#FFFFFF]   rounded-[4px] lg:px-[20px] py-[30px]";

export const agent_info_label = "text-[14px] font-[500] text-[#5F5F5F]";

export const commonTitle =
  "text-[#47484A] font-[700] text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl flex items-center mb-2";

export const commonCount =
  "bg-gradient-to-r from-[#1e788a] to-[#00A3E2] text-white text-[14px] px-2 font-[400]   py-[12px] outline-none  rounded-[5px]";
  
  export const commonSearchInput =
  "w-[165px] px-2 py-2 md:w-[200px] md:px-[10px] md:py-[10px] lg:w-[245px] lg:px-[10px] lg:py-[10px] outline-none border-[1px] border-[#218194] rounded-[5px] text-[12px] lg:text-[16px] font-[500]";

export const commonSearchFiled =
  "max-w-[245px] px-[10px] order-1 md:order-3 py-[10px] outline-none border-[1px] border-[#218194] rounded-[5px] text-[16px] font-[500]";

export const commonTableRow = "text-[#e3e4e7] text-center uppercase bg-[#0C768A]";

export const commonTableHeadingOne = "flex items-center justify-evenly py-1";
export const commonTableHeadingTwo =
  "flex items-center p-2 gap-3 justify-center";

export const commonAction = "h-7 w-[34px] rounded-lg";

export const commonEditTitle =
  "text-[#87898f] font-bold uppercase text-[18px] bg-[#F5F6F8] py-3 my-4 text-center rounded-md";
export const commonUpdateTitle =
  "text-white font-bold my-[40px] text-[16px] bg-[#0C768A] py-1  text-center";
