import React, { useEffect, useState } from "react";
import Pagination from "../../../shared/pagination/Pagination";
import AddImg from "../../../asset/images/clientpaymentinformation/add.png";
import ViewImg from "../../../asset/images/clientlist/view.png";
import ActionImg from "../../../asset/images/clientlist/action.png";
import { Link } from "react-router-dom";
import CommonPrintPdfBtn from "../../../shared/CommonPrintPdfBtn";
import { baseUrl } from "../../../BaseUrl";
import axios from "axios";
import { LuArrowDownUp } from "react-icons/lu";
import {
  commonAction,
  // commonCount,
  commonSearchFiled,
  commonTableHeadingOne,
  commonTableRow,
  commonTitle,
} from "../../../asset/commoncssConstant/CommonCssConstant";
import useCommonPDF from "../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../shared/useCommonPrint/useCommonPrint";

const ClientLandSellAgreementCompleteList = () => {
  const [
    clientLandSellAgreementCompleteList,
    setClientLandSellAgreementCompleteList,
  ] = useState([]);
  const [dolilVariationList, setDolilVariationList] = useState(null);
  const [sellerAgreementList, setSellerAgreementList] = useState(null);
  const [searchDolil, setSearchDolil] = useState("");
  const token = localStorage.getItem("token");

  const [paginationData, setPaginationData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const { toPDF, targetRef } = useCommonPDF({ title: "sell-agreement-list" });

  const handlePrint = useCommonPrint({
    targetRef,
    title: "sell agreement list",
    message: "thank you",
  });

  // pagination part start here
  const handleNext = () => {
    if (page === pageCount) {
      return page;
    } else {
      return setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page === 1) {
      return page;
    } else {
      return setPage(page - 1);
    }
  };

  //------get data for clientLandSellAgreementCompleteList,dolilVariationList,sellerAgreementList start-----------------
  useEffect(() => {
    axios
      .get(baseUrl + `/customer/landAgreement/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res.data.data);
        setClientLandSellAgreementCompleteList(res.data.data);
      });

    axios
      .get(baseUrl + `/dolilVaritation/list`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => setDolilVariationList(res.data.data));

    axios
      .get(baseUrl + `/landSeller/sellerAgreement/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setSellerAgreementList(res.data.data);
      });
  }, [token, page]);

  //------get data for clientLandSellAgreementCompleteList,dolilVariationList,sellerAgreementList end-----------------
  return (
    <>
      <h1 className={`${commonTitle}`}>
        Client Land Sell Agreement Complete List
      </h1>
      <div className="bg-[#FFFFFF]   rounded-[4px] px-[20px] py-[30px] my-[30px]">
        <div className="flex flex-wrap justify-between items-end gap-2 mt-[20px]">
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} />
          {/* <div className="flex justify-between items-center gap-2 order-3 md:order-2 flex-wrap">
            <div className={`${commonCount}`}>
              <span>Number of Deed </span>: <span>5000</span>
            </div>
            <div className={`${commonCount}`}>
              <span>Agreement Complete Number of Client </span>:{" "}
              <span>5000</span>
            </div>
            <div className={`${commonCount}`}>
              <span>Agreement Complete Land Size </span>: <span>5000</span>
            </div>
            <div className={`${commonCount}`}>
              <span>Available Land Size after Sell </span>: <span>5000</span>
            </div>
          </div> */}
          <input
            onChange={(e) => setSearchDolil(e.target.value.toLowerCase())}
            type="text"
            // placeholder="Select Search Type"
            placeholder="Search dolil variation"
            className={`${commonSearchFiled}`}
          />
        </div>

        <div ref={targetRef} className="table_responsive mt-[40px]">
          <table>
            <thead>
              <tr className={`${commonTableRow}`}>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span>Agreement Date</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span>Deed No</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span>Customer Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span>File No / Plot No.</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span>Dolil Variation</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span> Land Size (ojudangsho)</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span> Land Size (Katha)</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span> BS Dag</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span>BS Khatiyan</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span>Project Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span>
                      Land Seller <br />
                      Deed No
                    </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span>
                      {" "}
                      Land Seller <br />
                      Deed Date
                    </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span>
                      {" "}
                      Land Seller <br />
                      Dolil Variation
                    </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span>Land Seller Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span>
                      Land Seller deed <br />
                      Land Size
                    </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingOne}`}>
                    <span>
                      Available Land Size <br />
                      After Sell
                    </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`py-2`}>
                    <span>
                      {" "}
                      Action <br />
                      Create / Edit / view
                    </span>{" "}
                  </div>
                </th>
              </tr>
            </thead>

            <tbody>
              {paginationData
                ?.filter((info) => {
                  return searchDolil.toLowerCase() !== ""
                    ? info?.land_agreement?.dolil_variation?.name
                        .toLowerCase()
                        .includes(searchDolil)
                    : info;
                })
                .map((item, i) => (
                  <tr className="text-center" key={item.id}>
                    <td>{item?.land_agreement?.agreement_date}</td>
                    <td>{item?.land_agreement?.deed_no}</td>
                    <td>
                      {item?.customer_info?.map((cus) => (
                        <span key={cus?.id}>
                          {cus?.name}{" "}
                          {cus !==
                            item.customer_info[item.customer_info.length - 1] &&
                            ", "}
                        </span>
                      ))}
                    </td>
                    <td>
                      {item?.plot_info?.file_no} / {item?.plot_info?.no_of_plot}{" "}
                    </td>
                    <td> {item?.land_agreement?.dolil_variation?.name}</td>
                    <td>{item?.land_agreement?.land_size_ojutangsho}</td>
                    <td>{item?.land_agreement?.land_size_katha}</td>
                    <td>
                      {item?.land_agreement?.land_seller_agreement?.bs_info?.map(
                        (bs) => (
                          <span key={bs?.id}>{bs?.bs_dag_no}</span>
                        )
                      )}
                    </td>
                    <td>
                      {item?.land_agreement?.land_seller_agreement?.bs_info?.map(
                        (bs) => (
                          <span key={bs?.id}>{bs?.bs_khatiyan_no}</span>
                        )
                      )}
                    </td>
                    <td>{item?.plot_info?.project_name}</td>
                    <td>
                      {
                        item?.land_agreement?.land_seller_agreement
                          ?.purchase_deed_no
                      }
                    </td>
                    <td>
                      {
                        item?.land_agreement?.land_seller_agreement
                          ?.sub_deed_date
                      }
                    </td>
                    <td>
                      {
                        item?.land_agreement?.land_seller_agreement
                          ?.dolil_variation?.name
                      }
                    </td>
                    <td>
                      {item?.land_agreement?.land_seller_agreement?.seller_info?.map(
                        (seller) => (
                          <span key={seller?.id}>{seller?.name}</span>
                        )
                      )}
                    </td>
                    <td>
                      {
                        item?.land_agreement?.land_seller_agreement
                          ?.land_size_katha
                      }
                    </td>
                    <td>
                      {parseFloat(
                        item?.land_agreement?.land_seller_agreement
                          ?.land_size_katha
                      ) - parseFloat(item?.land_agreement?.land_size_katha)}
                    </td>
                    <td>
                      <span className="flex gap-2 justify-center">
                        <Link
                          to={`/dashboard/client-land-sell-agreement-complete-list/${
                            !item?.land_agreement ? "add" : "edit"
                          }`}
                          state={{
                            item: item,
                            dolilVariationList: dolilVariationList,
                            sellerAgreementList: sellerAgreementList,
                          }}
                        >
                          <img
                            className={commonAction}
                            src={!item?.land_agreement ? AddImg : ActionImg}
                            alt=""
                          />
                        </Link>
                        <Link
                          to={`/dashboard/client-land-sell-agreement-complete-list/view`}
                          state={{
                            item: item,
                          }}
                        >
                          <img className={commonAction} src={ViewImg} alt="" />
                        </Link>
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <Pagination
          data={clientLandSellAgreementCompleteList}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          setPageCount={setPageCount}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      </div>
    </>
  );
};

export default ClientLandSellAgreementCompleteList;
