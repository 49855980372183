import React, { useEffect, useState } from "react";
import Pagination from "../../../shared/pagination/Pagination";
import {
  commonAction,
  commonCaption,
  commonSearchInput,
  commonTableHeadingTwo,
  commonTableRow,
  commonTitle,
  dashboardBodyWraper,
} from "../../../asset/commoncssConstant/CommonCssConstant";
import CommonPrintPdfBtn from "../../../shared/CommonPrintPdfBtn";
import ViewBtn from "../../../asset/images/clientlist/view.png";
import { Link } from "react-router-dom";
import { baseUrl } from "../../../BaseUrl";
import axios from "axios";
import { LuArrowDownUp } from "react-icons/lu";
import useCommonPDF from "../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../shared/useCommonPrint/useCommonPrint";

const LandBrokerWorkList = () => {
  const [landBokerWorkList, setLandBokerWorkList] = useState([]);
  const token = localStorage.getItem("token");
  const [searchValue, setSearchValue] = useState("");

  const [paginationData, setPaginationData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const { toPDF, targetRef } = useCommonPDF({ title: "land-broker-list" });

  const handlePrint = useCommonPrint({
    targetRef,
    title: "land-broker-list",
    message: "thank you",
  });

  // pagination part start here
  const handleNext = () => {
    if (page === pageCount) {
      return page;
    } else {
      return setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page === 1) {
      return page;
    } else {
      return setPage(page - 1);
    }
  };
  //------get land boker list data start-------------
  useEffect(() => {
    axios
      .get(baseUrl + `/landBroker/workList`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLandBokerWorkList(res.data.data);
      });
  }, [token, page]);
  //------get land boker list data end-------------
  return (
    <div>
      <h1 className={`${commonTitle}`}>Land Broker Work List</h1>
      <div className={dashboardBodyWraper}>
        <div className={commonCaption}>
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} />
          <input
            onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
            type="text"
            placeholder="Search broker name"
            className={commonSearchInput}
          />
        </div>

        <div ref={targetRef} className="table_responsive mt-[40px]">
          <table>
            <thead>
              <tr className={`${commonTableRow}`}>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Agreement Date</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Broker Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Present Address</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo} whitespace-nowrap`}>
                    <span>Broker mobile no. 1</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo} whitespace-nowrap`}>
                    <span>Broker mobile no. 2</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Seller Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Seller mobile no</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Project Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Total Price</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Land Size (Katha)</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Land Broker Money</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>
                      {" "}
                      Acton <br /> View
                    </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {paginationData
                ?.filter((info) => {
                  return searchValue !== ""
                    ? info?.land_broker?.name
                        ?.toLowerCase()
                        .includes(searchValue)
                    : info;
                })
                .map((item, i) => (
                  <tr key={item.id} className="text-center">
                    <td>{item?.agreement_date}</td>
                    <td>{item?.land_broker?.name}</td>
                    <td>{item?.land_broker?.present_address}</td>
                    <td>
                      <span>{item?.land_broker?.mobile_number_1}</span>
                    </td>
                    <td>
                      <span>{item?.land_broker?.mobile_number_2}</span>
                    </td>
                    <td>
                      {item?.seller_info?.map((seller) => (
                        <div key={seller.id}>{seller.name}</div>
                      ))}
                    </td>
                    <td>
                      <div>
                        {item?.seller_info?.map((seller) => (
                          <div key={seller.id}>{seller.mobile_no_1}</div>
                        ))}
                      </div>
                    </td>
                    <td>
                      {" "}
                      {item?.bs_info?.map((bs) => (
                        <div key={bs.id}>
                          {
                            bs?.rs_info?.sa_info?.cs_info?.mouza_info
                              ?.project_info?.project_name
                          }
                        </div>
                      ))}
                    </td>
                    <td>
                      <span className="font-bold">
                        {item?.price_info?.total_price}
                      </span>
                    </td>
                    <td>
                      <span className="font-bold">{item?.land_size_katha}</span>
                    </td>
                    <td>
                      <span className="font-bold">
                        {item?.broker_price_info?.broker_money}
                      </span>
                    </td>
                    <td>
                      <div className="flex justify-center">
                        <Link
                          to={`/dashboard/land-broker-work-list/view/${item?.id}`}
                          state={{
                            item: item,
                          }}
                        >
                          <img className={commonAction} src={ViewBtn} alt="" />
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <Pagination
          data={landBokerWorkList}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          setPageCount={setPageCount}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      </div>
    </div>
  );
};

export default LandBrokerWorkList;
