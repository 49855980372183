import React from "react";
import CommonPrintPdfBtn from "../../../../shared/CommonPrintPdfBtn";
import BackButton from "../../../../shared/BackButton";
import { useLocation } from "react-router-dom";
import useCommonPDF from "../../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../../shared/useCommonPrint/useCommonPrint";

const SaSubDeedView = () => {
  const item = useLocation().state.item;
  const { toPDF, targetRef } = useCommonPDF({ title: "sa sub deed-details" });

  const handlePrint = useCommonPrint({
    targetRef,
    title: "sa sub deed-details",
    message: "thank you",
  });
  return (
    <div>
      <BackButton title={"SA Sub-deed Information"} />
      <div className="grid lg:grid-cols-12  mt-3 gap-5">
        <div ref={targetRef} className="col-span-9 order-2 lg:order-1 client_info_section p-[40px] ">
          <div className="grid md:grid-cols-2 bg-[#F5F6F8] border border-[#d5d7d7]  px-10 rounded-[5px] mb-10">
            <div className="agent_view_table ">
              <table style={{ borderSpacing: "0 8px" }}>
                <tbody>
                  <tr>
                    <td>
                      <strong>Project Name</strong>{" "}
                    </td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>
                      <strong>
                        {
                          item?.sa_info?.cs_info?.mouza_info?.project_info
                            ?.project_name
                        }
                      </strong>{" "}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="agent_view_table ">
              <table style={{ borderSpacing: "0 8px" }}>
                <tbody>
                  <tr>
                    <td>
                      <strong>Mouza Name</strong>{" "}
                    </td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>
                      <strong>
                        {item?.sa_info?.cs_info?.mouza_info?.mouza_name}
                      </strong>{" "}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="grid md:grid-cols-2">
            <div className="agent_view_table ">
              <table>
                <tbody>
                  <tr>
                    <td>SA Sub-deed Create Date</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item.created_at.slice(0, 10)}</td>
                  </tr>
                  <tr>
                    <td>SA Dag</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.sa_info?.sa_dag_no}</td>
                  </tr>
                  <tr>
                    <td>SA Khatiyan</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.sa_info?.sa_khatiyan_no}</td>
                  </tr>
                  <tr>
                    <td>Sub-deed Date</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.sub_deed_date}</td>
                  </tr>
                  <tr>
                    <td>Sub-deed No</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.sub_deed_no}</td>
                  </tr>
                  <tr>
                    <td>Sub-deed Land Size</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.sub_deed_land_size}</td>
                  </tr>
                  <tr>
                    <td>Sub-deed Registry Office</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.sub_deed_registry_office}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="agent_view_table">
              {item?.sa_saller_buyer?.map((person, i) => {
                return (
                  <div key={person.id}>
                    <table>
                      <tbody>
                        <tr>
                          <td>Seller Name</td>
                          <td>
                            <span className="mx-6">:</span>
                          </td>
                          <td>{person.seller_name}</td>
                        </tr>
                        <tr>
                          <td>Seller Father Name</td>
                          <td>
                            <span className="mx-6">:</span>
                          </td>
                          <td>{person.seller_father_name}</td>
                        </tr>
                        <tr>
                          <td>Buyer Name</td>
                          <td>
                            <span className="mx-6">:</span>
                          </td>
                          <td>{person.buyer_name}</td>
                        </tr>
                        <tr>
                          <td>Buyer Father Name</td>
                          <td>
                            <span className="mx-6">:</span>
                          </td>
                          <td>{person.buyer_father_name}</td>
                        </tr>
                      </tbody>
                    </table>
                    {item.sa_saller_buyer.length > 1 && <hr />}
                  </div>
                );
              })}
            </div>
          </div>
          <h3 className="text-[14px] text-[#717A82] font-[700]">Description</h3>
          <p className="text-[14px] text-[#8F8F8F] font-[400]">
            {item?.description}
          </p>
        </div>

        {/* right side button group  */}
        <div className="col-span-3 order-1 lg:order-2  ">
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} isBlock={true} />
          {/* <div className="commonprintpdfbtn whitespace-nowrap mt-5">
            <button className="bg-[#F97316]">Legal Paper Folder</button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SaSubDeedView;
