import React, { useEffect, useState } from "react";
import CommonPrintPdfBtn from "../../../shared/CommonPrintPdfBtn";
import {
  commonAction,
  commonCaption,
  commonSearchInput,
  dashboardBodyWraper,
} from "../../../asset/commoncssConstant/CommonCssConstant";
import Pagination from "../../../shared/pagination/Pagination";
import { Link } from "react-router-dom";
import ViewImg from "../../../asset/images/clientlist/view.png";
import PlusIcon from "../../../asset/images/clientpaymentinformation/add.png";
import axios from "axios";
import { baseUrl } from "../../../BaseUrl";
import { LuArrowDownUp } from "react-icons/lu";
import {
  commonTableHeadingTwo,
  commonTableRow,
  commonTitle,
} from "../../../asset/commoncssConstant/CommonCssConstant";
import useCommonPDF from "../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../shared/useCommonPrint/useCommonPrint";

const SellerPaymentList = () => {
  const token = localStorage.getItem("token");
  const [paymentList, setPaymentList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [paginationData, setPaginationData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const {toPDF, targetRef} = useCommonPDF({ title: "seller-payment-list"});

  const handlePrint = useCommonPrint({
    targetRef,
    title: "seller-payment-list",
    message: "thank you",
  });

  // pagination part start here
  const handleNext = () => {
    if (page === pageCount) {
      return page;
    } else {
      return setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page === 1) {
      return page;
    } else {
      return setPage(page - 1);
    }
  };

  //------------get data landSeller/sellerPayment/view start------------
  useEffect(() => {
    axios
      .get(baseUrl + `/landSeller/sellerPayment/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setPaymentList(res.data.data);
      });
  }, [token]);
  //------------get data landSeller/sellerPayment/view end------------

  return (
    <div>
      <h1 className={`${commonTitle}`}>Seller Payment List</h1>
      <div className={dashboardBodyWraper}>
        <div className={commonCaption}>
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} />
          <input
            onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
            type="text"
            // placeholder="Search Seller Name / ID..."
            placeholder="Search Seller Name"
            className={commonSearchInput}
          />
        </div>

        <div ref={targetRef} className="table_responsive mt-[40px] text-center">
          <table>
            <thead>
              <tr className={`${commonTableRow}`}>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Seller Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Dolil Variation</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Agreement Date</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Seller Deed No</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Seller Land Size</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Total Land Price </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Paid Amount</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Due Amount</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Next Payment Date</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Add Payment</span>{" "}
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>View Statement</span>{" "}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {paginationData
                ?.filter((info) => {
                  return searchValue.toLowerCase() !== ""
                    ? info?.seller_info
                        ?.map((seller) => {
                          return seller?.name;
                        })
                        ?.join("")
                        .toLowerCase()
                        .includes(searchValue)
                    : info;
                })
                ?.map((item, idx) => (
                  <tr className="text-center" key={idx}>
                    <td>
                      {item?.seller_info?.map((seller, i) => (
                        <span className="grid grid-cols-1" key={seller.id}>
                          {seller?.name}
                        </span>
                      ))}
                    </td>
                    <td>{item?.dolil_variation?.name}</td>
                    <td>{item.agreement_date}</td>
                    <td>{item?.sub_deed_no}</td>
                    <td>{item?.land_size_katha}</td>
                    <td>{item?.price_info?.total_price}</td>
                    <td>{item?.price_info?.paid_amount}</td>
                    <td>
                      {item?.price_info?.total_price -
                        item?.price_info?.paid_amount}
                    </td>
                    <td>-</td>

                    <td>
                      <span className="flex justify-center">
                        <Link
                          to={`/dashboard/seller-payment/add`}
                          state={{
                            item,
                          }}
                        >
                          <img className={commonAction} src={PlusIcon} alt="" />
                        </Link>
                      </span>
                    </td>
                    <td>
                      <span className="flex justify-center">
                        <Link
                          to={`/dashboard/seller-payment/view/${item.id}`}
                          state={{
                            item,
                          }}
                        >
                          <img className={commonAction} src={ViewImg} alt="" />
                        </Link>
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <Pagination
          data={paymentList}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          setPageCount={setPageCount}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      </div>
    </div>
  );
};

export default SellerPaymentList;
