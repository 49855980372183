import React from "react";
import BackButton from "../../../shared/BackButton";
import CommonPrintPdfBtn from "../../../shared/CommonPrintPdfBtn";
import BlankImg from "../../../asset/blankimg.png";
import BlankImgTwo from "../../../asset/blankimgtwo.png";
const LandSellerInformationView = () => {
  return (
    <div>
      <BackButton
        title={" Registry Complete Land Information (From Land Owner)"}
      />

      <div className="grid lg:grid-cols-12  mt-3 gap-5">
        <div className="col-span-9 order-2 lg:order-1 client_info_section p-[40px] ">
          <div className=" client_info_section p-[40px] ">
            <div className="grid grid-cols-2">
              <div className="agent_view_table  ">
                <h1 className="client_info_title">Land Information</h1>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        Land Purchase/ <br />
                        Sub-deed Date
                      </td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>05-07-2023</td>
                    </tr>
                    <tr>
                      <td>Sub-deed Number</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>521073</td>
                    </tr>
                    <tr>
                      <td>Project Name</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>Munshiganj Project</td>
                    </tr>
                    <tr>
                      <td>Mouza Name</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>Mr. Abul </td>
                    </tr>
                    <tr>
                      <td>Purchased Land Size (Decimal)</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>521073</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="agent_view_table  ">
                <table>
                  <tbody>
                    <tr>
                      <td>Purchased Land CS/SA Daag No</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>521073</td>
                    </tr>
                    <tr>
                      <td>Purchased Land CS/SA KH No</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>521073</td>
                    </tr>
                    <tr>
                      <td>Purchased Land RS Daag No</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>Munshiganj Project</td>
                    </tr>
                    <tr>
                      <td>Purchased Land RS KH No</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>521073 </td>
                    </tr>
                    <tr>
                      <td>Purchased Land BS Daag No</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>521073</td>
                    </tr>
                    <tr>
                      <td>Purchased Land BS KH No</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>521073</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="grid grid-cols-2 mt-10">
              <div className="agent_view_table  ">
                <h1 className="client_info_title">Land Price Information</h1>
                <table>
                  <tbody>
                    <tr>
                      <td>Purchase Land Size (Katha)</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>05-07-2023</td>
                    </tr>
                    <tr>
                      <td>Purchase Land Price (Per Katha)</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>521073</td>
                    </tr>
                    <tr>
                      <td>Purchase Land Total Price</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>Munshiganj Project</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="agent_view_table  ">
                <table>
                  <tbody>
                    <tr>
                      <td>Payment Start Date</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>521073</td>
                    </tr>
                    <tr>
                      <td>Approx Payment Complete Date</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>521073</td>
                    </tr>
                    <tr>
                      <td>Approx Registry Date</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>Munshiganj Project</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <h1 className="client_info_title">Seller Information</h1>
            <div className="grid grid-cols-2  mt-2">
              <div className="agent_view_table  ">
                <h1 className="text-[#414141] font-[700] text-[12px]">
                  Seller Information 1
                </h1>
                <table>
                  <tbody>
                    <tr>
                      <td>Seller ID</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>5487</td>
                    </tr>
                    <tr>
                      <td>Seller Name</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>MD. Habibul Bashar</td>
                    </tr>
                    <tr>
                      <td>Seller Ph No. 01</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>01234567891</td>
                    </tr>
                    <tr>
                      <td>Seller Ph No. 02</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>01234567891</td>
                    </tr>
                    <tr>
                      <td>Seller Father’s Name</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>Father Name</td>
                    </tr>
                    <tr>
                      <td>Seller Mother’s Name</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>Mothers Name</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="agent_view_table  ">
                <table>
                  <tbody>
                    <tr>
                      <td>Seller NID No</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>0123458792</td>
                    </tr>
                  </tbody>
                </table>
                <img src={BlankImg} alt="" />
              </div>
            </div>
            <div className="grid grid-cols-2 mt-10">
              <div className="agent_view_table  ">
                <h1 className="text-[#414141] font-[700] text-[12px]">
                  Seller Information 2
                </h1>
                <table>
                  <tbody>
                    <tr>
                      <td>Seller ID</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>5487</td>
                    </tr>
                    <tr>
                      <td>Seller Name</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>MD. Habibul Bashar</td>
                    </tr>
                    <tr>
                      <td>Seller Ph No. 01</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>01234567891</td>
                    </tr>
                    <tr>
                      <td>Seller Ph No. 02</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>01234567891</td>
                    </tr>
                    <tr>
                      <td>Seller Father’s Name</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>Father Name</td>
                    </tr>
                    <tr>
                      <td>Seller Mother’s Name</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>Mothers Name</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="agent_view_table  ">
                <table>
                  <tbody>
                    <tr>
                      <td>Seller NID No</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>0123458792</td>
                    </tr>
                  </tbody>
                </table>
                <img src={BlankImg} alt="" />
              </div>
            </div>
            <div className="grid grid-cols-2 mt-10">
              <div className="agent_view_table  ">
                <h1 className="client_info_title">Land Broker Information</h1>
                <table>
                  <tbody>
                    <tr>
                      <td>Land Broker ID</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>5487</td>
                    </tr>
                    <tr>
                      <td>Land Broker Name</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>MD. Habibul Bashar</td>
                    </tr>
                    <tr>
                      <td>Land Broker Ph No. 01</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>01234567891</td>
                    </tr>
                    <tr>
                      <td>Land Broker Ph No. 02</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>01234567891</td>
                    </tr>
                    <tr>
                      <td>Land Broker Father’s Name</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>Father Name</td>
                    </tr>
                    <tr>
                      <td>Land Broker Mother’s Name</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>Mothers Name</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="agent_view_table  ">
                <h1 className="text-[#414141] font-[700] text-[12px]">
                  Land Broker Agrement
                </h1>
                <div className="grid grid-cols-4 gap-5">
                  <img src={BlankImgTwo} alt="" />
                  <img src={BlankImgTwo} alt="" />
                  <img src={BlankImgTwo} alt="" />
                  <img src={BlankImgTwo} alt="" />
                  <img src={BlankImgTwo} alt="" />
                  <img src={BlankImgTwo} alt="" />
                  <img src={BlankImgTwo} alt="" />
                  <img src={BlankImgTwo} alt="" />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 mt-10 ">
              <div className="w-[90%]">
                <h1 className="text-[#414141] font-[700] text-[12px]">
                  Dolil Image
                </h1>
                <div className="grid grid-cols-4 gap-5">
                  <img
                    className="h-[188px]  object-fill"
                    src={BlankImgTwo}
                    alt=""
                  />
                  <img
                    className="h-[188px]  object-fill"
                    src={BlankImgTwo}
                    alt=""
                  />
                  <img
                    className="h-[188px]  object-fill"
                    src={BlankImgTwo}
                    alt=""
                  />
                  <img
                    className="h-[188px]  object-fill"
                    src={BlankImgTwo}
                    alt=""
                  />
                  <img
                    className="h-[188px]  object-fill"
                    src={BlankImgTwo}
                    alt=""
                  />
                  <img
                    className="h-[188px]  object-fill"
                    src={BlankImgTwo}
                    alt=""
                  />
                  <img
                    className="h-[188px]  object-fill"
                    src={BlankImgTwo}
                    alt=""
                  />
                  <img
                    className="h-[188px]  object-fill"
                    src={BlankImgTwo}
                    alt=""
                  />
                </div>
              </div>
              <div>
                <h1 className="text-[#414141] font-[700] text-[12px]">
                  Another Document Image
                </h1>
                <div className="grid grid-cols-4 gap-5 ">
                  <img
                    className="h-[188px]  object-fill"
                    src={BlankImgTwo}
                    alt=""
                  />
                  <img
                    className="h-[188px]  object-fill"
                    src={BlankImgTwo}
                    alt=""
                  />
                  <img
                    className="h-[188px]  object-fill"
                    src={BlankImgTwo}
                    alt=""
                  />
                  <img
                    className="h-[188px]  object-fill"
                    src={BlankImgTwo}
                    alt=""
                  />
                  <img
                    className="h-[188px]  object-fill"
                    src={BlankImgTwo}
                    alt=""
                  />
                  <img
                    className="h-[188px]  object-fill"
                    src={BlankImgTwo}
                    alt=""
                  />
                  <img
                    className="h-[188px]  object-fill"
                    src={BlankImgTwo}
                    alt=""
                  />
                  <img
                    className="h-[188px]  object-fill"
                    src={BlankImgTwo}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* right side button group  */}
        <div className="col-span-3 order-1 lg:order-2  ">
          <CommonPrintPdfBtn isBlock={true} /> 
        </div>
      </div>
    </div>
  );
};

export default LandSellerInformationView;
