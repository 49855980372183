import { RouterProvider } from "react-router-dom";
import "./App.css";
import "./style/common.css";
import routesList from "./routes/routes";

function App() {
  return (
      <div className="bg-white max-h-screen w-screen">
        <RouterProvider router={routesList} />
      </div>
  );
}

export default App;
