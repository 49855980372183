import { Link } from "react-router-dom";
import AddUserImg from "../../../asset/images/clientlist/add-user.png";
import Pagination from "../../../shared/pagination/Pagination";
import CommonPrintPdfBtn from "../../../shared/CommonPrintPdfBtn";
import {
  addCustomerCss,
  commonAction,
  commonAddButton,
  commonCaption,
  commonSearchInput,
  commonTableHeadingTwo,
  commonTableRow,
  commonTitle,
  dashboardBodyWraper,
} from "../../../asset/commoncssConstant/CommonCssConstant";
import ActionBtn from "../../../asset/images/clientlist/action.png";
import ViewBtn from "../../../asset/images/clientlist/view.png";
import FolderImg from "../../../asset/images/clientlandinformation/back-folder.png";
import axios from "axios";
import { baseUrl, baseUrlImg } from "../../../BaseUrl";
import { useEffect, useState } from "react";
import { LuArrowDownUp } from "react-icons/lu";
import useCommonPDF from "../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../shared/useCommonPrint/useCommonPrint";

const CsdagAndKhatiyan = () => {
  const [csDagKhatiyanList, setCsDagKhatiyanList] = useState([]);
  const [mouzaInfoList, setMouzaInfoList] = useState([]);
  const token = localStorage.getItem("token");
  const [searchValue, setSearchValue] = useState("");

  const [paginationData, setPaginationData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const { toPDF, targetRef } = useCommonPDF({ title: "cs dag & khatiyan" });

  const handlePrint = useCommonPrint({
    targetRef,
    title: "cs dag & khatiyan",
    message: "thank you",
  });

  // pagination part start here
  const handleNext = () => {
    if (page === pageCount) {
      return page;
    } else {
      return setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page === 1) {
      return page;
    } else {
      return setPage(page - 1);
    }
  };
  //------get data for csDagKhatiyanList and mouzaInfoList  start-----------------
  useEffect(() => {
    axios
      .get(baseUrl + `/landInformationBank/csDagKhatiyan/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setCsDagKhatiyanList(res.data.data);
      });
    axios
      .get(baseUrl + `/landInformationBank/mouzaInformation/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setMouzaInfoList(res.data.data);
      });
  }, [token, page]);
  //------get data for  csDagKhatiyanList and mouzaInfoList end-----------------

  return (
    <div>
      <h1 className={`${commonTitle}`}>CS Dag & Khatiyan</h1>
      <div className={dashboardBodyWraper}>
        <div className={commonAddButton}>
          <Link
            to={`/dashboard/cs-dag-khatiyan/add`}
            state={{
              mouzaInfoList: mouzaInfoList,
            }}
          >
            <button className={addCustomerCss}>
              Add CS Dag & Khatiyan
              <img className="ml-[30px]" src={AddUserImg} alt="" />
            </button>
          </Link>
        </div>
        <div className={commonCaption}>
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} />
          <input
            onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
            type="text"
            placeholder="Search"
            className={commonSearchInput}
          />
        </div>

        <div ref={targetRef} className="table_responsive mt-[40px]">
          <table>
            <thead>
              <tr className={`${commonTableRow}`}>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>CS Create</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Project Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Mouza Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> CS Dag</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> CS Khatiyan</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Total CS Use Area</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>{" "}
                </th>
                <th>
                  <div className={`py-2`}>
                    <span> CS Porca Scan Copy</span>{" "}
                  </div>
                </th>
                <th>
                  <div className={`py-2`}>
                    <span>CS Dag Map Scan Copy</span>{" "}
                  </div>
                </th>
                <th>
                  <div className={`py-2`}>
                    <span>
                      {" "}
                      Action <br />
                      Update / View
                    </span>{" "}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {paginationData
                ?.filter((info) => {
                  return searchValue.toLowerCase() !== ""
                    ? info?.created_at
                        ?.slice(0, 10)
                        .toLowerCase()
                        .includes(searchValue) ||
                        info?.mouza_info.project_info?.project_name
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.mouza_info?.mouza_name
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.cs_dag_no?.toString().toLowerCase().includes(searchValue) ||
                        info?.cs_khatiyan_no
                          ?.toString().toLowerCase()
                          .includes(searchValue) ||
                        info?.total_cs_use_area
                          ?.toString().toLowerCase()
                          .includes(searchValue)
                    : info;
                })
                ?.map((csDagKhatiyan) => (
                  <tr className="text-center" key={csDagKhatiyan.id}>
                    <td>{csDagKhatiyan.created_at.slice(0, 10)}</td>
                    <td>
                      {csDagKhatiyan.mouza_info.project_info.project_name}{" "}
                    </td>
                    <td>{csDagKhatiyan.mouza_info.mouza_name}</td>
                    <td>{csDagKhatiyan.cs_dag_no}</td>
                    <td>
                      <span className="font-bold">
                        {csDagKhatiyan.cs_khatiyan_no}
                      </span>
                    </td>
                    <td>{csDagKhatiyan.total_cs_use_area}</td>
                    <td>
                      <img
                        className={`w-11 rounded-lg mx-auto`}
                        src={
                          csDagKhatiyan.cs_porca_scan_copy
                            ? baseUrlImg + csDagKhatiyan.cs_porca_scan_copy
                            : FolderImg
                        }
                        alt=""
                      />
                    </td>
                    <td>
                      <img
                        className={`w-11 rounded-lg mx-auto`}
                        src={
                          csDagKhatiyan.cs_dag_map_scan_copy
                            ? baseUrlImg + csDagKhatiyan.cs_dag_map_scan_copy
                            : FolderImg
                        }
                        alt=""
                      />
                    </td>
                    <td>
                      <span className="flex gap-2 justify-center">
                        <Link
                          to={`/dashboard/cs-dag-khatiyan/edit`}
                          state={{
                            csDagKhatiyan: csDagKhatiyan,
                            mouzaInfoList: mouzaInfoList,
                          }}
                        >
                          <img
                            className={commonAction}
                            src={ActionBtn}
                            alt=""
                          />
                        </Link>
                        <Link
                          to={`/dashboard/cs-dag-khatiyan/view`}
                          state={{
                            csDagKhatiyan: csDagKhatiyan,
                          }}
                        >
                          <img className={commonAction} src={ViewBtn} alt="" />
                        </Link>
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <Pagination
          data={csDagKhatiyanList}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          setPageCount={setPageCount}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      </div>
    </div>
  );
};

export default CsdagAndKhatiyan;
