import React, { useState } from "react";
import {
  commonFormMenu,
  commonUserInfo,
  commonUserRow,
  dashboardBodyWraper,
  errorMessage,
} from "../../../../asset/commoncssConstant/CommonCssConstant";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { baseUrl } from "../../../../BaseUrl";
import BackButton from "../../../../shared/BackButton";

const AddSellerPaymentList = () => {
  const token = localStorage.getItem("token");
  const { item } = useLocation()?.state ?? {};
  const {
    id,
    seller_info,
    dolil_variation,
    agreement_date,
    bs_info,
    land_size_katha,
    price_info,
  } = item ?? {};
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [paymentMethod, setPaymentMethod] = useState(null);

  //-------payment method select functionality start--------------------------------
  const handlePaymentMethod = (name) => {
    setPaymentMethod(name);
  };
  //-------payment method select functionality end--------------------------------

  //-------------Add Payment Start----------------------//
  const onSubmit = (data) => {
    data.land_seller_agreement_price_information_id = id;
    data.pay_by = paymentMethod;

    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });

    axios
      .post(baseUrl + `/landSeller/sellerPayment/store`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          navigate("/dashboard/seller-payment");
        }
      });
  };
  //-------------Add Payment End----------------------//

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BackButton title={"Add Payment"} />

      <div className={`${dashboardBodyWraper} py-[4px] px-2`}>
        <div className={commonUserInfo}>
          <div className={commonUserRow}>
            <div>Seller Name</div>
            <div>:</div>
            <div>
              {seller_info?.map((seller, i) => (
                <span key={seller.id}>
                  {seller?.name}
                  {i < seller_info?.length - 1 ? ", " : ""}
                </span>
              ))}
            </div>
          </div>
          <div className={commonUserRow}>
            <div>Dolil Variation:</div>
            <div>:</div>
            <div>{dolil_variation?.name}</div>
          </div>
          <div className={commonUserRow}>
            <div>Agreement Date</div>
            <div>:</div>
            <div>{agreement_date}</div>
          </div>
          <div className={commonUserRow}>
            <div>Seller Land Size</div>
            <div>:</div>
            <div>{land_size_katha}</div>
          </div>
          <div className={commonUserRow}>
            <div>Total Land Price</div>
            <div>:</div>
            <div>{price_info?.total_price}</div>
          </div>
          <div className={commonUserRow}>
            <div>Due Amount</div>
            <div>:</div>
            <div>{price_info?.total_price - price_info?.paid_amount}</div>
          </div>
        </div>
        <div className={commonFormMenu}>
          <div className="common_input_wraper">
            <label>Pay Amount</label>
            <input
              {...register(`amount`, {
                pattern: {
                  value: /^[0-9]+(\.[0-9]+)?$/,
                  message: "Insert valid amount",
                },
              })}
              name="amount"
              type="number"
              placeholder="Pay Amount"
            />
            {errors?.amount && (
              <p className={errorMessage}>{errors?.amount?.message}</p>
            )}
          </div>
          <div className="common_input_wraper">
            <label>Amount (In Words)</label>
            <input
              {...register("amount_in_words", {
                pattern: {
                  value: /^[A-Za-z]+[A-Za-z0-9\-_~(\s]*[A-Za-z0-9\s)]$/,
                  message: "Insert valid amount(word)",
                },
              })}
              name="amount_in_words"
              type="text"
              placeholder="Amount (In Words)"
            />
            {errors?.amount_in_words && (
              <p className={errorMessage}>{errors?.amount_in_words?.message}</p>
            )}
          </div>
          <div className="common_input_wraper">
            <label>Pay By</label>
            <select onChange={(e) => handlePaymentMethod(e.target.value)}>
              <option value="" disabled selected>
                Please select One
              </option>
              <option value="bkash">Bkash</option>
              <option value="nagad">Nagad</option>
              <option value="rocket">Rocket</option>
              <option value="cheque">Bank Cheque</option>
              <option value="cash">Cash</option>
            </select>
          </div>
          {paymentMethod === "mobile" ? (
            <div className="common_input_wraper">
              <label>Mobile Account Number</label>
              <input
                type="text"
                placeholder={`Mobile Account Number`}
                {...register("mobile_banking_account_no", {
                  pattern: {
                    value: /^(0|\+?[1-9][0-9]*)$/,
                    message: "Insert country code before your number",
                  },
                })}
              />
              {errors?.mobile_banking_account_no && (
                <p className={errorMessage}>
                  {errors?.mobile_banking_account_no?.message}
                </p>
              )}
            </div>
          ) : null}
          {paymentMethod === "cheque" ? (
            <>
              <div className="common_input_wraper">
                <label>Bank Name</label>
                <input
                  type="text"
                  placeholder={`Bank Name`}
                  {...register("bank_name")}
                />
              </div>
              <div className="common_input_wraper">
                <label>Bank Account Number</label>
                <input
                  type="text"
                  placeholder={`Bank Account Number`}
                  {...register("bank_account_no")}
                />
              </div>
              <div className="common_input_wraper">
                <label>Bank Cheque Number</label>
                <input
                  type="text"
                  placeholder={`Bank Cheque Number`}
                  {...register("bank_cheque_no")}
                />
              </div>
            </>
          ) : null}
          <div className="common_input_wraper">
            <label>Next Payment Date</label>
            <input type="date" {...register("next_payment_date")} />
          </div>
          <div className="common_input_wraper">
            <label>Payment Receiver</label>
            <input
              type="text"
              placeholder={`Payment Receiver`}
              {...register("payment_receiver_name")}
            />
          </div>
          <div className="common_input_wraper">
            <label>Image Upload</label>
            <input {...register("image")} name="image" type="file" />
          </div>
          <div className="col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-5"></div>
          <div className="common_input_wraper col-span-1 lg:col-span-2">
            <label>Description</label>
            <textarea
              rows={3}
              placeholder={`Description`}
              {...register("description")}
            />
          </div>
        </div>

        <div className="text-center py-10">
          <button type="submit" className="submit_button">
            Submit
          </button>
        </div>
      </div>
    </form>
  );
};

export default AddSellerPaymentList;
