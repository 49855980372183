import React from "react";
import CommonPrintPdfBtn from "../../../../shared/CommonPrintPdfBtn";
import BackButton from "../../../../shared/BackButton";
import { useLocation } from "react-router-dom";
import useCommonPDF from "../../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../../shared/useCommonPrint/useCommonPrint";

const RsDagAndKHatiyanView = () => {
  const rsDagKhatiyan = useLocation().state.rsDagKhatiyan;
  const { toPDF, targetRef } = useCommonPDF({ title: "rs dag & khatiyan-info" });

  const handlePrint = useCommonPrint({
    targetRef,
    title: "rs dag & khatiyan-info",
    message: "thank you",
  });
  return (
    <div>
      <BackButton title={"  RS Information"} />
      <div className="grid lg:grid-cols-12  mt-3 gap-5">
        <div ref={targetRef} className="col-span-9 order-2 lg:order-1 client_info_section p-[40px] ">
          <div className=" client_info_section p-[40px] ">
            <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2">
              <div className="agent_view_table">
                <table>
                  <tbody>
                    <tr>
                      <td>RS Create Date</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{rsDagKhatiyan.created_at.slice(0, 10)}</td>
                    </tr>
                    <tr>
                      <td>Project Name</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>
                        {
                          rsDagKhatiyan?.sa_info?.cs_info?.mouza_info
                            ?.project_info?.project_name
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>Mouza Name</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>
                        {
                          rsDagKhatiyan?.sa_info?.cs_info?.mouza_info
                            ?.mouza_name
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>RS Dag </td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{rsDagKhatiyan?.rs_dag_no}</td>
                    </tr>
                    <tr>
                      <td>RS Khatiyan </td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{rsDagKhatiyan?.rs_khatiyan_no}</td>
                    </tr>
                    <tr>
                      <td>Total RS Area </td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{rsDagKhatiyan?.total_rs_area}</td>
                    </tr>
                    <tr>
                      <td>Total RS (Use Area) </td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{rsDagKhatiyan?.total_rs_use_area}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="agent_view_table">
                {rsDagKhatiyan?.rs_recorded_person?.map((person) => {
                  return (
                    <div key={person.id}>
                      <table>
                        <tbody>
                          <tr>
                            <td> RS Recorded Person Name</td>
                            <td>
                              <span className="mx-6">:</span>
                            </td>
                            <td>{person.rs_recorded_person}</td>
                          </tr>
                          <tr>
                            <td> RS Recorded Person’s Father Name</td>
                            <td>
                              <span className="mx-6">:</span>
                            </td>
                            <td>{person.rs_recorded_person_fathers_name}</td>
                          </tr>
                          <tr>
                            <td>Recorded Person Ownership Size</td>
                            <td>
                              <span className="mx-6">:</span>
                            </td>
                            <td>{person.rs_recorded_person_ownership_size}</td>
                          </tr>
                        </tbody>
                      </table>
                      <hr />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        {/* right side button group  */}
        <div className="col-span-3 order-1 lg:order-2  ">
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} isBlock={true} />
          {/* <div className="commonprintpdfbtn whitespace-nowrap mt-5">
            <button className="bg-[#F97316]">Legal Paper Folder</button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default RsDagAndKHatiyanView;
