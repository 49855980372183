import React, { useEffect, useState } from "react";
import BackButton from "../../../../shared/BackButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { baseUrl } from "../../../../BaseUrl";
import {
  commonBankMenu,
  commonFormTitle,
  errorMessage,
} from "../../../../asset/commoncssConstant/CommonCssConstant";

const EditSaSubDeedList = () => {
  const [sellerBuyerForm, setSellerBuyerForm] = useState([]);
  const [defaultData, setDefaultData] = useState(null);
  const dagKhaytanForSelect = useLocation().state.dagKhaytanForSelect;
  const item = useLocation().state.item;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    setSellerBuyerForm(item?.sa_saller_buyer);
  }, [item]);
  //-------get data by id select option start---------------
  const handleSelectById = (e) => {
    e.preventDefault();
    setDefaultData(
      dagKhaytanForSelect?.find((list) => list.sa_dag_no === e.target.value)
    );
  };
  //-------get data by id select option end---------------
  //--------Add More sellerBuyerForm or delete functionality start---------
  const handleSellerBuyerFormAdd = () => {
    setSellerBuyerForm([
      ...sellerBuyerForm,
      {
        id: "",
        seller_name: "",
        seller_father_name: "",
        buyer_name: "",
        buyer_father_name: "",
      },
    ]);
    reset();
  };
  const handleSellerBuyerFormRemove = () => {
    let lastElement = [...sellerBuyerForm].pop();
    if (!lastElement.id) {
      setSellerBuyerForm([...sellerBuyerForm].slice(0, -1));
    }
  };
  //--------Add More sellerBuyerForm or delete functionality end---------

  //--------landSubDeedBank/saSubDeed/update start---------
  const onSubmit = (data) => {
    data.seller_buyer_info = data?.seller_buyer_info?.slice(
      0,
      sellerBuyerForm?.length
    );
    data.id = item.id;
    data.sa_id = defaultData?.id || item?.sa_info?.id;
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (typeof value == "object") {
        for (let i = 0; i < value.length; i++) {
          let names = Object.keys(value[i]);
          names.forEach((name) => {
            formData.append(`seller_buyer_info[${i}][${name}]`, value[i][name]);
          });
        }
      } else {
        formData.append(key, value);
      }
    });

    axios
      .post(baseUrl + `/landSubDeedBank/saSubDeed/update`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          navigate("/dashboard/sa-subdeed-list");
        }
      });
  };
  //--------landSubDeedBank/saSubDeed/update end---------
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BackButton title={"Edit SA Sub-deed Information"} />
      <div className={`bg-[#FFFFFF] rounded-[4px]  my-[30px]`}>
        <div className="p-5">
          <p className={commonFormTitle}>Sa Sub-deed Information</p>
          <div className={commonBankMenu}>
            <div className="common_input_wraper">
              <label>SA Dag *</label>
              <select onChange={(e) => handleSelectById(e)}>
                <option
                  value={defaultData?.sa_dag_no || item?.sa_info?.sa_dag_no}
                >
                  {" "}
                  {defaultData?.sa_dag_no || item?.sa_info?.sa_dag_no}
                </option>
                {dagKhaytanForSelect.map((list) => (
                  <option key={list.id} value={list.sa_dag_no}>
                    {list.sa_dag_no}
                  </option>
                ))}
              </select>
            </div>
            <div className="common_input_wraper">
              <label>SA Khatiyan</label>
              <input
                type="text"
                placeholder="SA Khatiyan"
                readOnly
                disabled
                value={
                  defaultData?.sa_khatiyan_no || item?.sa_info?.sa_khatiyan_no
                }
              />
            </div>
            <div className="common_input_wraper">
              <label>Sub-deed Date</label>
              <input
                {...register("sub_deed_date", {
                  required: "Sub-deed Date is required",
                })}
                type="date"
                defaultValue={item.sub_deed_date}
              />
              {errors?.sub_deed_date && (
                <p className={errorMessage}>{errors?.sub_deed_date?.message}</p>
              )}
            </div>
            <div className="common_input_wraper">
              <label>Sub-deed No.</label>
              <input
                {...register(`sub_deed_no`, {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Insert valid Sub-deed no.",
                  },
                })}
                type="text"
                defaultValue={item.sub_deed_no}
              />
              {errors?.sub_deed_date && (
                <p className={errorMessage}>{errors?.sub_deed_date?.message}</p>
              )}
            </div>
            <div className="common_input_wraper">
              <label>Sub-deed Land Size</label>
              <input
                {...register(`sub_deed_land_size`, {
                  pattern: {
                    value: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Insert valid land size",
                  },
                })}
                type="text"
                step="0.01"
                defaultValue={item.sub_deed_land_size}
              />
              {errors?.sub_deed_land_size && (
                <p className={errorMessage}>
                  {errors?.sub_deed_land_size?.message}
                </p>
              )}
            </div>
            <div className="common_input_wraper">
              <label>Sub-deed Registry Office</label>
              <input
                {...register("sub_deed_registry_office")}
                type="text"
                defaultValue={item.sub_deed_registry_office}
              />
            </div>
          </div>
          <div className="m-4  border-[1px] border-[#d5d7d7] rounded-[5px]">
            {sellerBuyerForm.map((sellerBuyer, i) => {
              return (
                <div key={i}>
                  <div className={commonBankMenu}>
                    {/* <div className="common_input_wraper hidden">
                      <label>SA Seller And Buyer Id</label>
                      <input
                        {...register(`seller_buyer_info[${i}].id`)}
                        type="text"
                        placeholder="SA Seller And Buyer Id"
                        defaultValue={sellerBuyer.id}
                      />
                    </div> */}
                    <div className="common_input_wraper">
                      <label>Seller Name</label>
                      <input
                        {...register(`seller_buyer_info[${i}].seller_name`, {
                          pattern: {
                            value:
                              /^[A-Za-z]+[A-Za-z0-9\-_~(\s]*[A-Za-z0-9\s)]$/,
                            message: "Insert valid name",
                          },
                        })}
                        type="text"
                        placeholder="Seller Name"
                        defaultValue={sellerBuyer.seller_name}
                      />
                      {errors &&
                        errors.seller_buyer_info &&
                        errors.seller_buyer_info[i] && (
                          <p className={errorMessage}>
                            {errors?.seller_buyer_info[i]?.seller_name?.message}
                          </p>
                        )}
                    </div>
                    <div className="common_input_wraper">
                      <label>Seller Father Name</label>
                      <input
                        {...register(
                          `seller_buyer_info[${i}].seller_father_name`,
                          {
                            pattern: {
                              value:
                                /^[A-Za-z]+[A-Za-z0-9\-_~(\s]*[A-Za-z0-9\s)]$/,
                              message: "Insert valid name",
                            },
                          }
                        )}
                        type="text"
                        placeholder="Seller Father Name"
                        defaultValue={sellerBuyer.seller_father_name}
                      />
                      {errors &&
                        errors.seller_buyer_info &&
                        errors.seller_buyer_info[i] && (
                          <p className={errorMessage}>
                            {
                              errors?.seller_buyer_info[i]?.seller_father_name
                                ?.message
                            }
                          </p>
                        )}
                    </div>
                    <div className="common_input_wraper">
                      <label>Buyer Name</label>
                      <input
                        {...register(`seller_buyer_info[${i}].buyer_name`, {
                          pattern: {
                            value:
                              /^[A-Za-z]+[A-Za-z0-9\-_~(\s]*[A-Za-z0-9\s)]$/,
                            message: "Insert valid name",
                          },
                        })}
                        type="text"
                        placeholder="Buyer Name"
                        defaultValue={sellerBuyer.buyer_name}
                      />
                      {errors &&
                        errors.seller_buyer_info &&
                        errors.seller_buyer_info[i] && (
                          <p className={errorMessage}>
                            {errors?.seller_buyer_info[i]?.buyer_name?.message}
                          </p>
                        )}
                    </div>
                    <div className="common_input_wraper">
                      <label>Buyer Father Name</label>
                      <input
                        {...register(
                          `seller_buyer_info[${i}].buyer_father_name`,
                          {
                            pattern: {
                              value:
                                /^[A-Za-z]+[A-Za-z0-9\-_~(\s]*[A-Za-z0-9\s)]$/,
                              message: "Insert valid name",
                            },
                          }
                        )}
                        type="text"
                        placeholder="Buyer Father Name"
                        defaultValue={sellerBuyer.buyer_father_name}
                      />
                      {errors &&
                        errors.seller_buyer_info &&
                        errors.seller_buyer_info[i] && (
                          <p className={errorMessage}>
                            {
                              errors?.seller_buyer_info[i]?.buyer_father_name
                                ?.message
                            }
                          </p>
                        )}
                    </div>
                  </div>
                  {sellerBuyerForm.length - 1 === i &&
                    sellerBuyerForm.length > 1 &&
                    ![...sellerBuyerForm].pop().id && (
                      <button
                        className={`delete_customer mt-4`}
                        type="button"
                        onClick={handleSellerBuyerFormRemove}
                      >
                        Delete{" "}
                      </button>
                    )}
                </div>
              );
            })}

            <div>
              <button
                type="button"
                className="add_customer"
                onClick={handleSellerBuyerFormAdd}
              >
                Add More Saler And Buyer <span className="plus">+</span>
              </button>
            </div>
          </div>

          <div
            className={`common_input_wraper common_input_wraper w-full lg:w-1/2 p-4`}
          >
            <label>Description</label>
            <textarea
              rows={4}
              {...register("description")}
              placeholder="Buyer & Seller All Personal & Land Information"
              type="text"
              defaultValue={item.description}
            />
          </div>
          <div className="text-end py-10 pr-4">
            <button type="submit" className="submit_button">
              Update
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditSaSubDeedList;
