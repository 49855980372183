import React, { useState } from "react";
import BackButton from "../../../../shared/BackButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { baseUrl } from "../../../../BaseUrl";
import axios from "axios";
import {
  commonTableHeadingTwo,
  commonTableRow,
  commonUserRow,
  errorMessage,
} from "../../../../asset/commoncssConstant/CommonCssConstant";

const AddClientLandSellAgreementCompleteList = () => {
  const item = useLocation().state?.item;
  const dolilVariationList = useLocation().state?.dolilVariationList;
  const sellerAgreementList = useLocation().state?.sellerAgreementList;
  const [dolilVariationId, setDolilVariationId] = useState(null);
  const [purchaseDeedId, setPurchaseDeedId] = useState(null);
  const [dataByPurchaseDeedId, setDataByPurchaseDeedId] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  //---------handleDolilVariationSelect and handleDeedNoSelect functionality start--------
  const handleDolilVariationSelect = (id) => {
    setDolilVariationId(id);
  };
  const handleDeedNoSelect = (id) => {
    setPurchaseDeedId(id);
    setDataByPurchaseDeedId(
      sellerAgreementList?.find((data) => data.id === +id)
    );
  };
  //---------handleDolilVariationSelect and handleDeedNoSelect functionality end--------

  //--------customer/landAgreement/store start---------
  const onSubmit = (data) => {
    data.customer_land_information_id = item?.id;
    data.land_seller_agreement_id = purchaseDeedId;
    data.deed_no = dataByPurchaseDeedId?.purchase_deed_no;
    data.dolil_variation_id = dolilVariationId;
    if (data.dolil_variation_id === "4") {
      delete data.registry_office;
    }
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (value instanceof FileList) {
        for (let i = 0; i < value.length; i++) {
          formData.append(`${key}[${i}]`, value[i]);
        }
      } else {
        formData.append(key, value);
      }
    });
    axios
      .post(baseUrl + `/customer/landAgreement/store`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          navigate("/dashboard/client-land-sell-agreement-complete-list");
        } else {
          alert(res.data.message);
        }
      });
  };
  //--------customer/landAgreement/store end---------

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <BackButton title={"Add Client Land Sell Agreement"} />
        <div className="  mt-3 gap-5">
          <div className=" client_info_section p-[40px] shadow-lg">
            <div className="border border-[#d5d7d7] rounded-lg p-5 bg-[#F5F6F8] my-3">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                <div className={commonUserRow}>
                  <div>File / Plot No</div>
                  <div>:</div>
                  <div className="mx-3">
                    <p>{item?.plot_info?.file_no}</p>
                  </div>
                </div>
                <div className={commonUserRow}>
                  <div>Customer Name </div>
                  <div>:</div>
                  <div className="mx-3">
                    {item?.customer_info?.map((cus, i) => (
                      <span key={cus?.id}>
                        {cus?.name}
                        {i < item?.customer_info?.length - 1 ? ", " : ""}
                      </span>
                    ))}
                  </div>
                </div>
                <div className={commonUserRow}>
                  <div>Booking Date </div>
                  <div>:</div>
                  <div className="mx-3">
                    <p>{item?.booking_date}</p>
                  </div>
                </div>
                <div className={commonUserRow}>
                  <div>Registry Date </div>
                  <div>:</div>
                  <div className="mx-3">
                    <p>{item?.expected_reg_date}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              <div className="common_input_wraper">
                <label>Agreement Date</label>
                <input
                  {...register("agreement_date", {
                    required: "agreement date is required",
                  })}
                  type="date"
                />
                {errors?.agreement_date && (
                  <p className={errorMessage}>
                    {errors?.agreement_date?.message}
                  </p>
                )}
              </div>
              <div className="common_input_wraper">
                <label>Land Size (Katha)</label>
                <input
                  {...register(`land_size_katha`, {
                    pattern: {
                      value: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Insert valid land size",
                    },
                  })}
                  type="text"
                  placeholder="Land Size (Katha)"
                />
                {errors?.land_size_katha && (
                  <p className={errorMessage}>
                    {errors?.land_size_katha?.message}
                  </p>
                )}
              </div>
              <div className="common_input_wraper">
                <label>Land Size (Ojudangsho)</label>
                <input
                  {...register(`land_size_ojutangsho`, {
                    pattern: {
                      value: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Insert valid land size",
                    },
                  })}
                  type="text"
                  placeholder="Land Size (Ojudangsho)"
                />
                {errors?.land_size_ojutangsho && (
                  <p className={errorMessage}>
                    {errors?.land_size_ojutangsho?.message}
                  </p>
                )}
              </div>
              <div className="common_input_wraper">
                <label>Deed No</label>
                <select
                  onChange={(e) => handleDeedNoSelect(e.target.value)}
                  required
                >
                  <option value="" disabled selected>
                    Select Deed No
                  </option>
                  {sellerAgreementList?.map((sellerAgreement) => (
                    <option key={sellerAgreement.id} value={sellerAgreement.id}>
                      {sellerAgreement.purchase_deed_no}
                    </option>
                  ))}
                </select>
              </div>
              <div className="common_input_wraper">
                <label>Land Registry Complete Date</label>
                <input {...register("registry_complete_date")} type="date" />
              </div>
              <div className="common_input_wraper">
                <label>Land Registry Sub Deed No</label>
                <input
                  {...register(`registry_sub_deed_no`, {
                    pattern: {
                      value: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Insert valid sub deed",
                    },
                  })}
                  type="text"
                  placeholder="Land Registry Sub Deed No"
                />
                {errors?.registry_sub_deed_no && (
                  <p className={errorMessage}>
                    {errors?.registry_sub_deed_no?.message}
                  </p>
                )}
              </div>
              <div className="common_input_wraper">
                <label>Dolil Variation</label>
                <select
                  onChange={(e) => handleDolilVariationSelect(e.target.value)}
                  required
                >
                  <option value="" disabled selected>
                    Select Variation
                  </option>
                  {dolilVariationList?.map((variation) => (
                    <option key={variation.id} value={variation.id}>
                      {variation.name}
                    </option>
                  ))}
                </select>
              </div>
              <div
                className={
                  dolilVariationId === "4" ? `hidden` : `common_input_wraper`
                }
              >
                <label>Registry Office</label>
                <input
                  {...register(`registry_office`, {
                    pattern: {
                      value: /^[A-Za-z]+[A-Za-z0-9\-_~(\s]*[A-Za-z0-9\s)]$/,
                      message: "Insert valid registry office",
                    },
                  })}
                  type="text"
                  placeholder="Registry Office"
                />
                {errors?.registry_office && (
                  <p className={errorMessage}>
                    {errors?.registry_office?.message}
                  </p>
                )}
              </div>
              <div className="common_input_wraper">
                <label>Namejari No.</label>
                <input
                  {...register(`namejari_no`, {
                    pattern: {
                      value: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Insert valid namejari no.",
                    },
                  })}
                  type="text"
                  placeholder="Namejari No"
                />
                {errors?.namejari_no && (
                  <p className={errorMessage}>{errors?.namejari_no?.message}</p>
                )}
              </div>
              <div className="common_input_wraper">
                <label>Namejari Date</label>
                <input {...register("namejari_date")} type="date" />
              </div>
              <div className="common_input_wraper">
                <label>Documents</label>
                <input {...register("documents")} type="file" multiple />
              </div>
              <div className="col-span-1 md:col-span-2 lg:col-span-3"></div>
              <div className="common_input_wraper">
                <label>Description</label>
                <textarea
                  rows={4}
                  {...register("description")}
                  placeholder="Description"
                ></textarea>
              </div>
            </div>
            <div className="pt-5 text-center">
              <button type="submit" className="submit_button">
                Submit
              </button>
            </div>
            {dataByPurchaseDeedId && (
              <div className="table_responsive text-center mt-[30px]">
                <table>
                  <thead>
                    <tr className={`${commonTableRow}`}>
                      <th>
                        <span className={`${commonTableHeadingTwo}`}>
                          BS Daag No
                        </span>
                      </th>
                      <th>
                        <span className={`${commonTableHeadingTwo}`}>
                          BS Khotiyan
                        </span>
                      </th>
                      <th>
                        <span className={`${commonTableHeadingTwo}`}>
                          BS Size
                        </span>
                      </th>
                      <th>
                        <span className={`${commonTableHeadingTwo}`}>
                          RS Daag No
                        </span>
                      </th>
                      <th>
                        <span className={`${commonTableHeadingTwo}`}>
                          RS Khotiyan
                        </span>
                      </th>
                      <th>
                        <span className={`${commonTableHeadingTwo}`}>
                          SA Dag No
                        </span>
                      </th>
                      <th>
                        <span className={`${commonTableHeadingTwo}`}>
                          SA Khatiyan
                        </span>
                      </th>
                      <th>
                        <span className={`${commonTableHeadingTwo}`}>
                          CS Daag No
                        </span>
                      </th>
                      <th>
                        <span className={`${commonTableHeadingTwo}`}>
                          CS Khatiyan
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataByPurchaseDeedId?.bs_info?.map((bs) => {
                      return (
                        <tr className="text-center" key={bs?.id}>
                          <td>{bs?.bs_dag_no}</td>
                          <td>{bs?.bs_khatiyan_no}</td>
                          <td>{bs?.total_bs_area}</td>
                          <td>{bs?.rs_info?.rs_dag_no}</td>
                          <td>{bs?.rs_info?.rs_khatiyan_no}</td>
                          <td>{bs?.rs_info?.sa_info?.sa_dag_no}</td>
                          <td>{bs?.rs_info?.sa_info?.sa_khatiyan_no}</td>
                          <td>{bs?.rs_info?.sa_info?.cs_info?.cs_dag_no}</td>
                          <td>
                            {bs?.rs_info?.sa_info?.cs_info?.cs_khatiyan_no}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddClientLandSellAgreementCompleteList;
