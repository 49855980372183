import { useForm } from "react-hook-form";
import {
  commonFormTitle,
  dashboardBodyWraper,
  errorMessage,
} from "../../../../asset/commoncssConstant/CommonCssConstant";
import BackButton from "../../../../shared/BackButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { baseUrl } from "../../../../BaseUrl";

const AddMouzaInformation = () => {
  const [defaultProject, setDefaultProject] = useState(null);
  const projectList = useLocation().state.projectList;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  //-------get project info by id select option start---------------
  const handleProject = (e) => {
    e.preventDefault();
    setDefaultProject(
      projectList.find((project) => project.project_name === e.target.value)
    );
  };
  //-------get project info by id select option end---------------

  //------------landInformationBank/mouzaInformation/store start-------------
  const onSubmit = (data) => {
    data.project_id = defaultProject?.id;
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (value instanceof FileList) {
        for (let i = 0; i < value.length; i++) {
          formData.append(key, value.item([i]));
        }
      } else {
        formData.append(key, value);
      }
    });
    axios
      .post(baseUrl + `/landInformationBank/mouzaInformation/store`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          navigate(-1);
        }
      });
  };
  //------------landInformationBank/mouzaInformation/store end-------------
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BackButton title={"Add Mouza  Information"} />
      <div className={`${dashboardBodyWraper} py-[4px] `}>
        <div>
          <p className={commonFormTitle}>Mouza Information</p>

          <div className="grid gap-4 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1  px-4 md:px-4">
            <div className="common_input_wraper">
              <label>Project Name *</label>
              <select onChange={(e) => handleProject(e)} required>
                <option value="">Select Your Project</option>
                {projectList.map((project) => (
                  <option key={project.id} value={project.project_name}>
                    {project.project_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="common_input_wraper">
              <label>Mouza Name</label>
              <input
                {...register("mouza_name", {
                  required: "Mouza name is required",
                  pattern: {
                    value: /^[A-Za-z]+[A-Za-z0-9\-_~(\s]*[A-Za-z0-9\s)]$/,
                    message: "Please insert valid mouza name",
                  },
                })}
                type="text"
                placeholder="Mouza Name"
                defaultChecked={defaultProject?.project_name}
              />
              {errors?.mouza_name && (
                <p className={errorMessage}>{errors?.mouza_name?.message}</p>
              )}
            </div>

            <div className="common_input_wraper">
              <label>Add Mouza Map Image (PP size 450x570)</label>
              <input
                {...register("map_image", {
                  required: "Map image is required",
                })}
                type="file"
              />
              {errors?.map_image && (
                <p className={errorMessage}>
                  {errors?.map_image?.message}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="text-center mt-10 pb-12">
          <button type="submit" className="submit_button">
            Submit
          </button>
        </div>
      </div>
    </form>
  );
};

export default AddMouzaInformation;
