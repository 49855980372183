import { useForm } from "react-hook-form";
import {
  commonFormTitle,
  dashboardBodyWraper,
  errorMessage,
} from "../../../../asset/commoncssConstant/CommonCssConstant";
import BackButton from "../../../../shared/BackButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { baseUrl } from "../../../../BaseUrl";
const AddSaDagAndKhaytan = () => {
  const [ownerForm, setOwnerForm] = useState([{ recorded_person: "" }]);
  const [defaultData, setDefaultData] = useState(null);
  const csDagKhatiyanInfoList = useLocation().state.csDagKhatiyanInfoList;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  //-------get cs info by id select option start---------------
  const handleSelectById = (e) => {
    e.preventDefault();
    setDefaultData(
      csDagKhatiyanInfoList.find(
        (project) => project.cs_dag_no === e.target.value
      )
    );
  };
  //-------get cs info by id select option end---------------

  //--------Add More ownerForm Form or delete functionality start---------
  const handleOwnerFormAdd = () => {
    setOwnerForm([...ownerForm, { recorded_person: "" }]);
  };
  const handleOwnerFormRemove = () => {
    setOwnerForm([...ownerForm].slice(0, -1));
  };
  //--------Add More ownerForm Form or delete functionality end---------

  //--------landInformationBank/saDagKhatiyan/store start---------
  const onSubmit = (data) => {
    data.recorded_person = data?.recorded_person?.slice(0, ownerForm?.length);
    data.cs_id = defaultData.id;
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (typeof value == "object") {
        if (key === "recorded_person") {
          for (let i = 0; i < value.length; i++) {
            let names = Object.keys(value[i]);
            names.forEach((name) => {
              formData.append(`recorded_person[${i}][${name}]`, value[i][name]);
            });
          }
        }
        if (key !== "recorded_person") {
          if (value instanceof FileList) {
            for (let i = 0; i < value.length; i++) {
              formData.append(key, value.item([i]));
            }
          }
        }
      } else {
        formData.append(key, value);
      }
    });

    axios
      .post(baseUrl + `/landInformationBank/saDagKhatiyan/store`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          navigate(-1);
        }
      });
  };
  //--------landInformationBank/saDagKhatiyan/store end---------
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BackButton title={"Add SA  Information"} />
      <div className={`${dashboardBodyWraper} py-[4px] `}>
        <div>
          <p className={commonFormTitle}>Cs dag & Khatiyan Information</p>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 px-4 md:px-4">
            <div className="common_input_wraper">
              <label>CS Dag *</label>
              <select onChange={(e) => handleSelectById(e)} required>
                <option value="">Select CS Dag</option>
                {csDagKhatiyanInfoList.map((cs) => (
                  <option key={cs.id} value={cs.cs_dag_no}>
                    {cs.cs_dag_no}
                  </option>
                ))}
              </select>
            </div>
            <div className="common_input_wraper">
              <label>SA Dag no.</label>
              <input
                {...register("sa_dag_no", {
                  required: "Dag no is required",
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Please insert number",
                  },
                })}
                type="text"
                placeholder="Sa Dag No"
              />
              {errors?.sa_dag_no && (
                <p className={errorMessage}>{errors?.sa_dag_no?.message}</p>
              )}
            </div>
            <div className="common_input_wraper">
              <label>SA Khatiyan no.</label>
              <input
                {...register("sa_khatiyan_no", {
                  required: "Dag no is required",
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Please insert number",
                  },
                })}
                type="text"
                placeholder="Sa Khatiyan No"
              />
              {errors?.sa_khatiyan_no && (
                <p className={errorMessage}>
                  {errors?.sa_khatiyan_no?.message}
                </p>
              )}
            </div>
            <div className="common_input_wraper">
              <label>Total SA Area</label>
              <input
                {...register("total_sa_area", {
                  required: "Sa area is required",
                  pattern: {
                    value: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Please insert number",
                  },
                })}
                type="text"
                placeholder="Total Cs Area"
              />
              {errors?.total_sa_area && (
                <p className={errorMessage}>{errors?.total_sa_area?.message}</p>
              )}
            </div>
            <div className="common_input_wraper">
              <label>Total SA Use Area</label>
              <input
                {...register("total_sa_use_area", {
                  required: "Sa area is required",
                  pattern: {
                    value: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Please insert number",
                  },
                })}
                type="text"
                placeholder="Total Cs Use Area"
              />
              {errors?.total_sa_use_area && (
                <p className={errorMessage}>
                  {errors?.total_sa_use_area?.message}
                </p>
              )}
            </div>

            <div className="common_input_wraper">
              <label>Add SA Porca Scan Copy</label>
              <input
                {...register("sa_porca_scan_copy", {
                  required: "Porca scan copy is required",
                })}
                type="file"
              />
              {errors?.sa_porca_scan_copy && (
                <p className={errorMessage}>
                  {errors?.sa_porca_scan_copy?.message}
                </p>
              )}
            </div>

            <div className="common_input_wraper">
              <label>SA Dag Map Scan Copy</label>
              <input
                {...register("sa_dag_map_scan_copy", {
                  required: "Map scan copy is required",
                })}
                type="file"
              />
               {errors?.sa_dag_map_scan_copy && (
                <p className={errorMessage}>
                  {errors?.sa_dag_map_scan_copy?.message}
                </p>
              )}
            </div>
          </div>
          <div className="m-4  border-[1px] border-[#d5d7d7] rounded-[5px] p-4">
            {ownerForm.map((owner, i) => {
              return (
                <div key={i}>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 px-4 md:px-4">
                    {/* <div className="common_input_wraper hidden">
                      <label>SA Recorded Person Id</label>
                      <input
                        {...register(`recorded_person[${i}].id`)}
                        type="text"
                        placeholder="SA Recorded Person Id"
                      />
                    </div> */}
                    <div className="common_input_wraper">
                      <label>SA Recorded Person</label>
                      <input
                         {...register(
                          `recorded_person[${i}].sa_recorded_person`,
                          {
                            required: "Recorder name is required",
                            pattern: {
                              value: /^[A-Za-z]+[A-Za-z0-9\-_~(\s]*[A-Za-z0-9\s)]$/,
                              message:
                                "Insert valid name",
                            },
                          }
                        )}
                        type="text"
                        placeholder="SA Recorded Person"
                      />
                      {errors &&
                        errors.recorded_person &&
                        errors.recorded_person[i] && (
                          <p className={errorMessage}>
                            {
                              errors?.recorded_person[i]?.sa_recorded_person
                                ?.message
                            }
                          </p>
                        )}
                    </div>
                    <div className="common_input_wraper">
                      <label>SA Recorded Person’s Father Name</label>
                      <input
                      {...register(
                        `recorded_person[${i}].sa_recorded_person_fathers_name`,
                        {
                          required: "Father name is required",
                          pattern: {
                            value: /^[A-Za-z]+[A-Za-z0-9\-_~(\s]*[A-Za-z0-9\s)]$/,
                            message:
                              "Insert valid name",
                          },
                        }
                      )}
                        type="text"
                        placeholder="SA Recorded Person’s Father Name"
                      />
                       {errors &&
                        errors.recorded_person &&
                        errors.recorded_person[i] && (
                          <p className={errorMessage}>
                            {
                              errors?.recorded_person[i]?.sa_recorded_person_fathers_name
                                ?.message
                            }
                          </p>
                        )}
                    </div>
                    <div className="common_input_wraper">
                      <label>Recorded Person Ownership Size</label>
                      <input
                      {...register(`recorded_person[${i}].sa_recorded_person_ownership_size`, {
                        required: "Ownership size is required",
                        pattern: {
                          value: /^[0-9]+(\.[0-9]+)?$/,
                          message: "Please insert number",
                        },
                      })}
                        type="text"
                        placeholder="Recorded Person Ownership Size"
                      />
                       {errors &&
                        errors.recorded_person &&
                        errors.recorded_person[i] && (
                          <p className={errorMessage}>
                            {errors?.recorded_person[i]?.sa_recorded_person_ownership_size?.message}
                          </p>
                        )}
                    </div>
                  </div>
                  {(ownerForm.length - 1 === i && ownerForm.length) > 1 && (
                    <button
                      className="delete_customer mt-4"
                      type="button"
                      onClick={handleOwnerFormRemove}
                    >
                      Delete{" "}
                    </button>
                  )}
                </div>
              );
            })}

            <div>
              <button
                type="button"
                className="add_customer"
                onClick={handleOwnerFormAdd}
              >
                Add More Owner <span className="plus">+</span>
              </button>
            </div>
          </div>
        </div>

        <div className="text-center mt-10 pb-12">
          <button type="submit" className="submit_button">
            Submit
          </button>
        </div>
      </div>
    </form>
  );
};

export default AddSaDagAndKhaytan;
