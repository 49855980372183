import React from "react";
import { useNavigate } from "react-router-dom";
import LeftArrow from "../asset/images/clientlist/left-arrow.png";
import { commonTitle } from "../asset/commoncssConstant/CommonCssConstant";

const BackButton = ({ title }) => {
  const navigate = useNavigate();
  return (
    <div>
      <h1 className={`${commonTitle}`}>
        <span onClick={() => navigate(-1)} className="mr-3 cursor-pointer">
          <img src={LeftArrow} alt="" />
        </span>{" "}
        {title}
      </h1>
    </div>
  );
};

export default BackButton;
