import React from "react";
import { commonFormMenu, errorMessage } from "../../../../../asset/commoncssConstant/CommonCssConstant";

const LandPriceInfo = ({ register, price_info, errors }) => {
  return (
    <div className={commonFormMenu}>
      <div className="common_input_wraper">
        <label>Purchase Land Price (Per Katha)</label>
        <input
          type="text"
          placeholder="Purchase Land Price (Per Katha)"
          defaultValue={price_info?.price_per_katha}
          {...register(`price_per_katha`, {
            pattern: {
              value: /^[0-9]+(\.[0-9]+)?$/,
              message: "Insert valid price",
            },
          })}
        />
        {errors?.price_per_katha && (
          <p className={errorMessage}>{errors?.price_per_katha?.message}</p>
        )}
      </div>
      <div className="common_input_wraper">
        <label>Purchase Land Total Price</label>
        <input
          type="text"
          placeholder="Purchase Land Total Price"
          defaultValue={price_info?.total_price}
          {...register(`total_price`, {
            pattern: {
              value: /^[0-9]+(\.[0-9]+)?$/,
              message: "Insert valid price",
            },
          })}
        />
        {errors?.total_price && (
          <p className={errorMessage}>{errors?.total_price?.message}</p>
        )}
      </div>
      <div className="common_input_wraper">
        <label>Payment Start Date</label>
        <input
          type="date"
          defaultValue={price_info?.payment_start_date}
          {...register("payment_start_date", {
            require: "Payment Start Date required",
          })}
        />
       {errors?.payment_start_date && (
          <p className={errorMessage}>{errors?.payment_start_date?.message}</p>
        )}
      </div>
      <div className="common_input_wraper">
        <label>Approx Payment Complete Date</label>
        <input
          type="date"
          defaultValue={price_info?.approx_payment_complete_date}
          {...register("approx_payment_complete_date", {
            require: "Payment Complete Date required",
          })}
        />
        {errors?.approx_payment_complete_date && (
          <p className={errorMessage}>
            {errors?.approx_payment_complete_date?.message}
          </p>
        )}
      </div>
      <div className="common_input_wraper">
        <label>Approx Registry Date</label>
        <input
          type="date"
          defaultValue={price_info?.approx_registry_date}
          {...register("approx_registry_date", {
            require: "Registry Date required",
          })}
        />
        {errors?.approx_registry_date && (
          <p className={errorMessage}>{errors?.approx_registry_date?.message}</p>
        )}
      </div>
    </div>
  );
};

export default LandPriceInfo;
