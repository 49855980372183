import React, { useState } from "react";
import BackButton from "../../../../shared/BackButton";
import {
  commonFormMenu,
  commonFormTitle,
  dashboardBodyWraper,
  errorMessage,
} from "../../../../asset/commoncssConstant/CommonCssConstant";
import LandPriceInfo from "./form/LandPriceInformation";
import LandBrokerInfoForm from "./form/LandBroker";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import LandInformation from "./form/LandInformation";
import SellerInfo from "./form/SellerInfo";
import axios from "axios";
import { baseUrl } from "../../../../BaseUrl";

const AddSellerAgreementList = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [bsSelectCount, setBsSelectCount] = useState(1);
  const [isBsReFetchIdx, setBsReFetchIdx] = useState(false);
  const [bsFromSelect, setBsFromSelect] = useState([]);
  const [bsFromSelectDisabled, setBsFromSelectDisabled] = useState([]);

  const [sellerSelectCount, setSellerSelectCount] = useState(1);
  const [isSellerReFetchIdx, setSellerReFetchIdx] = useState(false);
  const [sellerFromSelect, setSellerFromSelect] = useState([]);
  const [sellerFromSelectDisabled, setSellerFromSelectDisabled] = useState([]);

  const {
    bsInfoForSelect,
    landSellerForSelect,
    dolilVaritationForSelect,
    landBokerForSelect,
  } = useLocation()?.state ?? {};

  //----------bsSellect and sellerSelect functionality start-----------
  const handleBsFromSelect = (targetSelect, bsId) => {
    const itemIdx = bsFromSelect.findIndex(
      (item) => item.targetSelect === targetSelect
    );

    const bsInfo = bsInfoForSelect.find((bsInfo) => bsInfo.id === bsId);

    if (itemIdx !== -1) {
      const arr = bsFromSelect;

      arr.splice(itemIdx, 1, { targetSelect, bsInfo });
      setBsFromSelect(arr);
    } else {
      setBsFromSelect([...bsFromSelect, { targetSelect, bsInfo }]);
    }

    setBsReFetchIdx(!isBsReFetchIdx);
  };
  const handleSellerFromSelect = (targetSelect, sellerId) => {
    const itemIdx = sellerFromSelect.findIndex(
      (item) => item.targetSelect === targetSelect
    );

    const sellerInfo = landSellerForSelect.find(
      (sellerInfo) => sellerInfo.id === sellerId
    );

    if (itemIdx !== -1) {
      const arr = sellerFromSelect;

      arr.splice(itemIdx, 1, { targetSelect, sellerInfo });
      setSellerFromSelect(arr);
    } else {
      setSellerFromSelect([...sellerFromSelect, { targetSelect, sellerInfo }]);
    }

    setSellerReFetchIdx(!isSellerReFetchIdx);
  };
  //----------bsSellect and sellerSelect functionality end-----------

  //-------------Add Seller Agreement List Start----------------------//
  const onSubmit = (data) => {
    data.bsInfo = bsFromSelect.map((bsFromSelect) => {
      return { bs_id: bsFromSelect.bsInfo.id };
    });

    data.sellerInfo = sellerFromSelect.map((sellerFromSelect) => {
      return { land_seller_id: sellerFromSelect.sellerInfo.id };
    });

    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (value instanceof FileList) {
        for (let i = 0; i < value.length; i++) {
          formData.append(`${key}[${i}]`, value[i]);
        }
      } else if (value instanceof Array) {
        if (key === "bsInfo") {
          for (let i = 0; i < value.length; i++) {
            formData.append(`${key}[${i}]`, value[i].bs_id);
          }
        } else {
          for (let i = 0; i < value.length; i++) {
            formData.append(`${key}[${i}]`, value[i].land_seller_id);
          }
        }
      } else {
        formData.append(key, value);
      }
    });

    axios
      .post(baseUrl + `/landSeller/sellerAgreement/store`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          navigate(-1);
        }
      });
  };
  //-------------Add Seller Agreement List End----------------------//

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <BackButton title={"Add Land Seller Agreement"} />
        <div className={`${dashboardBodyWraper} py-[4px] `}>
          <p className={commonFormTitle}>Land Information</p>
          <div>
            <div className={commonFormMenu}>
              <div className="common_input_wraper">
                <label>Agreement Date</label>
                <input
                  type="date"
                  {...register("agreement_date", {
                    required: "Agreement Date is required",
                  })}
                />
                {errors?.agreement_date && (
                  <p className={errorMessage}>
                    {errors?.agreement_date?.message}
                  </p>
                )}
              </div>
              <div className="common_input_wraper">
                <label>Land Size(Katha)</label>
                <input
                  type="text"
                  placeholder="Land Size(Katha)"
                  {...register(`land_size_katha`, {
                    pattern: {
                      value: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Insert valid land size",
                    },
                  })}
                />
                {errors?.land_size_katha && (
                  <p className={errorMessage}>
                    {errors?.land_size_katha?.message}
                  </p>
                )}
              </div>
              <div className="common_input_wraper">
                <label>Purchase Deed No.</label>
                <input
                  type="text"
                  placeholder="Purchase Deed No"
                  {...register(`purchase_deed_no`, {
                    pattern: {
                      value: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Insert valid deed no.",
                    },
                  })}
                />
                {errors?.purchase_deed_no && (
                  <p className={errorMessage}>
                    {errors?.purchase_deed_no?.message}
                  </p>
                )}
              </div>
              <div className="common_input_wraper">
                <label>Dolil Variation</label>
                <select {...register("dolil_variation_id")} required>
                  <option value="" selected disabled>
                    Select Dolil Variation Type
                  </option>
                  {dolilVaritationForSelect?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="common_input_wraper">
                <label>Land Purchase/Sub-deed Date</label>
                <input
                  type="date"
                  {...register("sub_deed_date", { required: "Purchase/Sub-deed Date required" })}
                />
                 {errors?.sub_deed_date && (
                  <p className={errorMessage}>{errors?.sub_deed_date?.message}</p>
                )}
              </div>
              <div className="common_input_wraper">
                <label>Sub Deed Number</label>
                <input
                  type="text"
                  placeholder="Sub Deed Number"
                  {...register(`sub_deed_no`, {
                    pattern: {
                      value: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Insert valid land size",
                    },
                  })}
                />
                {errors?.sub_deed_no && (
                  <p className={errorMessage}>{errors?.sub_deed_no?.message}</p>
                )}
              </div>
              <div className="common_input_wraper">
                <label>Dolil Image (Multiple)</label>
                <input type="file" multiple {...register("dolil_image")} />
              </div>
              <div className="common_input_wraper">
                <label>Another Document Image (Multiple)</label>
                <input type="file" multiple {...register("other_image")} />
              </div>
              <div className="common_input_wraper">
                <label>Porcha Image (Multiple)</label>
                <input type="file" multiple {...register("porca_image")} />
              </div>
            </div>
            <div className="border-[1px] border-[#218194] rounded-[8px] m-4">
              {[...Array(bsSelectCount)].map((_, idx) => (
                <LandInformation
                  key={idx}
                  targetSelect={idx}
                  handleBsFromSelect={handleBsFromSelect}
                  isBsReFetchIdx={isBsReFetchIdx}
                  bsFromSelect={bsFromSelect}
                  bsFromSelectDisabled={bsFromSelectDisabled}
                  setBsFromSelectDisabled={setBsFromSelectDisabled}
                  bsInfoForSelect={bsInfoForSelect}
                />
              ))}
            </div>
          </div>
          <div className="my-10">
            <button
              onClick={() => setBsSelectCount((prev) => ++prev)}
              type="button"
              className="add_customer"
            >
              Add More BS <span className="plus">+</span>
            </button>
          </div>
          <p className={commonFormTitle}>Land Price Information</p>
          <LandPriceInfo register={register} errors={errors} />
          <p className={commonFormTitle}>Seller Information</p>
          {[...Array(sellerSelectCount)].map((_, idx) => (
            <SellerInfo
              key={idx}
              targetSelect={idx}
              handleSellerFromSelect={handleSellerFromSelect}
              isSellerReFetchIdx={isSellerReFetchIdx}
              sellerFromSelect={sellerFromSelect}
              sellerFromSelectDisabled={sellerFromSelectDisabled}
              setSellerFromSelectDisabled={setSellerFromSelectDisabled}
              landSellerForSelect={landSellerForSelect}
            />
          ))}
          <div className="my-10">
            <button
              type="button"
              className="add_customer"
              onClick={() => setSellerSelectCount((prev) => ++prev)}
            >
              Add More Seller <span className="plus">+</span>
            </button>
          </div>

          <p className={commonFormTitle}>Land Broker Information</p>
          <LandBrokerInfoForm
            register={register}
            errors={errors}
            landBokerForSelect={landBokerForSelect}
          />

          <div className="text-center py-10">
            <button type="submit" className="submit_button">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddSellerAgreementList;
