import React from "react";
import {
  StyleSheet,
  Document,
  Page,
  View,
  Text,
  Image,
} from "@react-pdf/renderer";
import shaplaCity from "./shapla-city.png";

const CreateReceiptPDF = ({ item, statement }) => {
  const { land_info } = item ?? {};
  const {
    money_receipt_no,
    mobile_banking_account_no,
    bank_name,
    bank_cheque_no,
    payment_date,
    payment_against,
  } = statement ?? {};

  const styles = StyleSheet.create({
    page: {
      backgroundColor: "#fff",
    },
    section: {
      margin: 30,
      padding: 10,
      flexGrow: 1,
      fontSize: 12,
    },
    header: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    title: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: 20,
    },
    content: {
      marginTop: 50,
    },
    signature: {
      marginTop: 50,
      width: "100%",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.header}>
            <View>
              <Text>Customer Copy</Text>
              <View style={{ marginTop: 5, color: "#808080" }}>
                <Text>Money Receipt No.</Text>
                <Text style={{ marginTop: 2 }}>{money_receipt_no}</Text>
              </View>
            </View>
            <View style={{ maxWidth: "45%", textAlign: "center" }}>
              <Image
                src={shaplaCity}
                style={{ width: 200, height: "auto", marginHorizontal: "auto" }}
              />
              <View style={{ marginTop: 5, color: "#808080", fontSize: 10 }}>
                <Text>
                  Corporate office: Sara Tower (17th Floor), 11/A, Toyenbee
                  Circular Road, Shapla Chattar, Motijheel C/A, Dhaka-1000
                </Text>
                <Text style={{ marginTop: 3 }}>
                  Project office: North Manda, Ending side of Manda Road
                  (Opposite to Ethical Garments), Mugda, Dhaka-1214
                </Text>
              </View>
            </View>
            <View>
              <Text
                style={{
                  fontSize: 16,
                  backgroundColor: "#00a652",
                  color: "#fff",
                  paddingVertical: 3,
                  paddingHorizontal: 10,
                  borderRadius: 10,
                }}
              >
                Money Receipt
              </Text>
            </View>
          </View>

          <View style={styles.title}>
            <View style={{ flexDirection: "row", flexWrap: "wrap", gap: 5 }}>
              <View style={{ flexDirection: "row" }}>
                <Text>Customer ID: </Text>
                <Text style={{ color: "#808080" }}>
                  {land_info?.customer_info?.map((customer, i) => (
                    <React.Fragment key={customer.id}>
                      <Text>{customer?.id}</Text>
                      {i < land_info?.customer_info?.length - 1 && (
                        <Text>, </Text>
                      )}
                    </React.Fragment>
                  ))}
                </Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text>File No: </Text>
                <Text style={{ color: "#808080" }}>
                  {land_info?.plot_info?.file_no}
                </Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text>Project Name: </Text>
                <Text style={{ color: "#808080" }}>
                  {land_info?.plot_info?.project_name}
                </Text>
              </View>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text>Date: </Text>
              <Text style={{ color: "#808080" }}>
                {new Date().toLocaleDateString()}
              </Text>
            </View>
          </View>

          <View style={styles.content}>
            <Text>
              Received with special thanks from
              <Text style={{ color: "#808080" }}>
                {land_info?.customer_info?.map((customer, i) => (
                  <React.Fragment key={customer.id}>
                    <Text> {customer?.name}</Text>
                    {i < land_info?.customer_info?.length - 1 && <Text>,</Text>}
                  </React.Fragment>
                ))}
              </Text>
              . Pay with
              <Text style={{ color: "#808080" }}>
                {mobile_banking_account_no
                  ? " Mobile Account"
                  : bank_name
                  ? " By Bank"
                  : " By Cash"}
              </Text>
              , Payment Date
              <Text style={{ color: "#808080" }}>
                {" "}
                {new Date(payment_date).toLocaleDateString()}{" "}
              </Text>
              <Text>
                {mobile_banking_account_no || bank_name ? "of " : null}
              </Text>
              <Text style={{ color: "#808080" }}>
                {mobile_banking_account_no
                  ? mobile_banking_account_no
                  : bank_name
                  ? bank_cheque_no
                  : null}
              </Text>
              <Text>{bank_name ? " Bank " : ", "}</Text>
              <Text style={{ color: "#808080" }}>
                {bank_name ? bank_name + " " : null}{" "}
              </Text>
              Against
              <Text style={{ color: "#808080" }}> {payment_against}</Text>.
            </Text>
          </View>

          <View style={styles.signature}>
            <View>
              <Text>___________________</Text>
              <Text style={{ marginHorizontal: "auto", marginTop: 3 }}>
                Authorized Signature
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            borderWidth: 1,
            borderStyle: "dotted",
            borderColor: "#808080",
          }}
        ></View>
        <View style={styles.section}>
          <View style={styles.header}>
            <View>
              <Text>Office Copy</Text>
              <View style={{ marginTop: 5, color: "#808080" }}>
                <Text>Money Receipt No.</Text>
                <Text style={{ marginTop: 2 }}>{money_receipt_no}</Text>
              </View>
            </View>
            <View style={{ maxWidth: "45%", textAlign: "center" }}>
              <Image
                src={shaplaCity}
                style={{ width: 200, height: "auto", marginHorizontal: "auto" }}
              />
              <View style={{ marginTop: 5, color: "#808080", fontSize: 10 }}>
                <Text>
                  Corporate office: Sara Tower (17th Floor), 11/A, Toyenbee
                  Circular Road, Shapla Chattar, Motijheel C/A, Dhaka-1000
                </Text>
                <Text style={{ marginTop: 3 }}>
                  Project office: North Manda, Ending side of Manda Road
                  (Opposite to Ethical Garments), Mugda, Dhaka-1214
                </Text>
              </View>
            </View>
            <View>
              <Text
                style={{
                  fontSize: 16,
                  backgroundColor: "#00a652",
                  color: "#fff",
                  paddingVertical: 3,
                  paddingHorizontal: 10,
                  borderRadius: 10,
                }}
              >
                Money Receipt
              </Text>
            </View>
          </View>

          <View style={styles.title}>
            <View style={{ flexDirection: "row", flexWrap: "wrap", gap: 5 }}>
              <View style={{ flexDirection: "row" }}>
                <Text>Customer ID: </Text>
                <Text style={{ color: "#808080" }}>
                  {land_info?.customer_info?.map((customer, i) => (
                    <React.Fragment key={customer.id}>
                      <Text>{customer?.id}</Text>
                      {i < land_info?.customer_info?.length - 1 && (
                        <Text>, </Text>
                      )}
                    </React.Fragment>
                  ))}
                </Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text>File No: </Text>
                <Text style={{ color: "#808080" }}>
                  {land_info?.plot_info?.file_no}
                </Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text>Project Name: </Text>
                <Text style={{ color: "#808080" }}>
                  {land_info?.plot_info?.project_name}
                </Text>
              </View>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text>Date: </Text>
              <Text style={{ color: "#808080" }}>
                {new Date().toLocaleDateString()}
              </Text>
            </View>
          </View>

          <View style={styles.content}>
            <Text>
              Received with special thanks from
              <Text style={{ color: "#808080" }}>
                {land_info?.customer_info?.map((customer, i) => (
                  <React.Fragment key={customer.id}>
                    <Text> {customer?.name}</Text>
                    {i < land_info?.customer_info?.length - 1 && <Text>,</Text>}
                  </React.Fragment>
                ))}
              </Text>
              . Pay with
              <Text style={{ color: "#808080" }}>
                {mobile_banking_account_no
                  ? " Mobile Account"
                  : bank_name
                  ? " By Bank"
                  : " By Cash"}
              </Text>
              , Payment Date
              <Text style={{ color: "#808080" }}>
                {" "}
                {new Date(payment_date).toLocaleDateString()}{" "}
              </Text>
              <Text>
                {mobile_banking_account_no || bank_name ? "of " : null}
              </Text>
              <Text style={{ color: "#808080" }}>
                {mobile_banking_account_no
                  ? mobile_banking_account_no
                  : bank_name
                  ? bank_cheque_no
                  : null}
              </Text>
              <Text>{bank_name ? " Bank " : ", "}</Text>
              <Text style={{ color: "#808080" }}>
                {bank_name ? bank_name + " " : null}{" "}
              </Text>
              Against
              <Text style={{ color: "#808080" }}> {payment_against}</Text>.
            </Text>
          </View>

          <View style={styles.signature}>
            <View>
              <Text>___________________</Text>
              <Text style={{ marginHorizontal: "auto", marginTop: 3 }}>
                Authorized Signature
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default CreateReceiptPDF;
