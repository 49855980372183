import React from "react";
import { StyleSheet, Document, Page, View, Text } from "@react-pdf/renderer";

const CreateInfoPDF = ({ item, statement }) => {
  const { land_info, total_amount } = item ?? {};
  const {
    payment_date,
    id: pid,
    money_receipt_no,
    paid_by,
    payment_against,
    amount,
    bank_name,
    bank_cheque_no,
    bank_account_no,
    mobile_banking_account_no,
  } = statement ?? {};

  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "#fff",
    },
    section: {
      margin: 30,
      padding: 10,
      flexGrow: 1,
    },
    title: {
      textAlign: "center",
      textDecoration: "underline",
    },
    table: {
      display: "table",
      width: "100%",
      marginTop: 50,
      borderWidth: 1,
      borderRadius: 5,
      padding: 5,
    },
    tableNext: {
      display: "table",
      width: "100%",
      marginTop: 50,
    },
    tableRow: {
      flexDirection: "row",
      alignItems: "center",
    },
    tableCol: {
      width: "40%",
      flexGrow: 1,
    },
    lastTableCol: {
      width: "50%",
    },
    cell: {
      fontSize: 12,
      padding: 3,
    },
    colSeparator: {
      width: 20,
      textAlign: "center",
    },
    signature: {
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
      marginTop: 300,
      fontSize: 12,
    },
    signatureTitle: {
      marginTop: 10,
      marginHorizontal: "auto",
    },
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>Payment Information</Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.cell}>Project Name</Text>
                  </View>
                  <View style={styles.colSeparator}>
                    <Text style={styles.cell}>:</Text>
                  </View>
                  <View style={[styles.tableCol, styles.lastTableCol]}>
                    <Text style={styles.cell}>
                      {land_info?.plot_info?.project_name}
                    </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.cell}>File / Plot No</Text>
                  </View>
                  <View style={styles.colSeparator}>
                    <Text style={styles.cell}>:</Text>
                  </View>
                  <View style={[styles.tableCol, styles.lastTableCol]}>
                    <Text style={styles.cell}>
                      {land_info?.plot_info?.file_no}
                    </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.cell}>Total Price</Text>
                  </View>
                  <View style={styles.colSeparator}>
                    <Text style={styles.cell}>:</Text>
                  </View>
                  <View style={[styles.tableCol, styles.lastTableCol]}>
                    <Text style={styles.cell}>{total_amount}</Text>
                  </View>
                </View>
              </View>

              <View style={styles.tableCol}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.cell}>Customer Name</Text>
                  </View>
                  <View style={styles.colSeparator}>
                    <Text style={styles.cell}>:</Text>
                  </View>
                  <View style={[styles.tableCol, styles.lastTableCol]}>
                    <Text style={styles.cell}>
                      {land_info?.customer_info?.map((customer, i) => (
                        <React.Fragment key={customer.id}>
                          <Text>{customer?.name}</Text>
                          {i < land_info?.customer_info?.length - 1 && (
                            <Text>, </Text>
                          )}
                        </React.Fragment>
                      ))}
                    </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.cell}>Customer ID</Text>
                  </View>
                  <View style={styles.colSeparator}>
                    <Text style={styles.cell}>:</Text>
                  </View>
                  <View style={[styles.tableCol, styles.lastTableCol]}>
                    <Text style={styles.cell}>
                      {land_info?.customer_info?.map((customer, i) => (
                        <React.Fragment key={customer.id}>
                          <Text>{customer?.id}</Text>
                          {i < land_info?.customer_info?.length - 1 && (
                            <Text>, </Text>
                          )}
                        </React.Fragment>
                      ))}
                    </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.cell}>Land Size</Text>
                  </View>
                  <View style={styles.colSeparator}>
                    <Text style={styles.cell}>:</Text>
                  </View>
                  <View style={[styles.tableCol, styles.lastTableCol]}>
                    <Text style={styles.cell}>
                      {land_info?.plot_info?.plot_size_katha}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>

          <View style={styles.tableNext}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.cell}>Payment Date</Text>
              </View>
              <View style={styles.colSeparator}>
                <Text style={styles.cell}>:</Text>
              </View>
              <View style={[styles.tableCol, styles.lastTableCol]}>
                <Text style={styles.cell}>
                  {new Date(payment_date).toLocaleDateString()}
                </Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.cell}>Payment ID</Text>
              </View>
              <View style={styles.colSeparator}>
                <Text style={styles.cell}>:</Text>
              </View>
              <View style={[styles.tableCol, styles.lastTableCol]}>
                <Text style={styles.cell}>{pid}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.cell}>Money Receipt SL No</Text>
              </View>
              <View style={styles.colSeparator}>
                <Text style={styles.cell}>:</Text>
              </View>
              <View style={[styles.tableCol, styles.lastTableCol]}>
                <Text style={styles.cell}>{money_receipt_no}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.cell}>Pay By</Text>
              </View>
              <View style={styles.colSeparator}>
                <Text style={styles.cell}>:</Text>
              </View>
              <View style={[styles.tableCol, styles.lastTableCol]}>
                <Text style={styles.cell}>{paid_by}</Text>
              </View>
            </View>
            {mobile_banking_account_no ? (
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.cell}>Mobile Account No</Text>
                </View>
                <View style={styles.colSeparator}>
                  <Text style={styles.cell}>:</Text>
                </View>
                <View style={[styles.tableCol, styles.lastTableCol]}>
                  <Text style={styles.cell}>{mobile_banking_account_no}</Text>
                </View>
              </View>
            ) : null}
            {bank_name ? (
              <>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.cell}>Bank Name</Text>
                  </View>
                  <View style={styles.colSeparator}>
                    <Text style={styles.cell}>:</Text>
                  </View>
                  <View style={[styles.tableCol, styles.lastTableCol]}>
                    <Text style={styles.cell}>{bank_name}</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.cell}>Bank Account No</Text>
                  </View>
                  <View style={styles.colSeparator}>
                    <Text style={styles.cell}>:</Text>
                  </View>
                  <View style={[styles.tableCol, styles.lastTableCol]}>
                    <Text style={styles.cell}>{bank_account_no}</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.cell}>Bank Cheque No</Text>
                  </View>
                  <View style={styles.colSeparator}>
                    <Text style={styles.cell}>:</Text>
                  </View>
                  <View style={[styles.tableCol, styles.lastTableCol]}>
                    <Text style={styles.cell}>{bank_cheque_no}</Text>
                  </View>
                </View>
              </>
            ) : null}
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.cell}>Payment Against</Text>
              </View>
              <View style={styles.colSeparator}>
                <Text style={styles.cell}>:</Text>
              </View>
              <View style={[styles.tableCol, styles.lastTableCol]}>
                <Text style={styles.cell}>{payment_against}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.cell}>Previous Due Amount</Text>
              </View>
              <View style={styles.colSeparator}>
                <Text style={styles.cell}>:</Text>
              </View>
              <View style={[styles.tableCol, styles.lastTableCol]}>
                <Text style={styles.cell}>-</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.cell}>Pay Amount</Text>
              </View>
              <View style={styles.colSeparator}>
                <Text style={styles.cell}>:</Text>
              </View>
              <View style={[styles.tableCol, styles.lastTableCol]}>
                <Text style={styles.cell}>{amount}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.cell}>After payment Due Amount</Text>
              </View>
              <View style={styles.colSeparator}>
                <Text style={styles.cell}>:</Text>
              </View>
              <View style={[styles.tableCol, styles.lastTableCol]}>
                <Text style={styles.cell}>-</Text>
              </View>
            </View>
          </View>
          <View style={styles.signature}>
            <View>
              <Text>___________________</Text>
              <Text style={styles.signatureTitle}>Customer Signature</Text>
            </View>
            <View>
              <Text>________________</Text>
              <Text style={styles.signatureTitle}>Official Signature</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default CreateInfoPDF;
