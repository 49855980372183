import React, { useEffect, useState } from "react";
import CommonPrintPdfBtn from "../../../shared/CommonPrintPdfBtn";
import {
  commonAction,
  commonCaption,
  commonSearchInput,
  commonTableHeadingTwo,
  commonTableRow,
  commonTitle,
  dashboardBodyWraper,
} from "../../../asset/commoncssConstant/CommonCssConstant";
import Pagination from "../../../shared/pagination/Pagination";
import ViewImg from "../../../asset/images/clientlist/view.png";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../../BaseUrl";
import { LuArrowDownUp } from "react-icons/lu";
import useCommonPDF from "../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../shared/useCommonPrint/useCommonPrint";

const ClientPlotPaymentCompleteList = () => {
  const [plotCompletePaymentList, setCompletePlotPaymentList] = useState([]);
  const token = localStorage.getItem("token");
  const [searchValue, setSearchValue] = useState("");
  const [paginationData, setPaginationData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const { toPDF, targetRef } = useCommonPDF({
    title: "plot-payment-complete-list",
  });

  const handlePrint = useCommonPrint({
    targetRef,
    title: "plot-payment-complete-list",
    message: "thank you",
  });

  // pagination part start here
  const handleNext = () => {
    if (page === pageCount) {
      return page;
    } else {
      return setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page === 1) {
      return page;
    } else {
      return setPage(page - 1);
    }
  };

  //------get data for plotBank/paymentCompletePlots start-----------------
  useEffect(() => {
    axios
      .get(baseUrl + `/plotBank/paymentCompletePlots`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setCompletePlotPaymentList(res.data.data);
      });
  }, [token, page]);
  //------get data for plotBank/paymentCompletePlots end-----------------

  return (
    <div>
      <h1 className={`${commonTitle}`}>Client Plot Payment Complete List</h1>
      <div className={dashboardBodyWraper}>
        <div className={commonCaption}>
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} />
          <input
            onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
            type="text"
            // placeholder="Search by Project Name / Plot Size / Customer Name  / Customer ID /  Mutation Date / Mutation No   "
            placeholder="Search"
            className={commonSearchInput}
          />
        </div>

        <div ref={targetRef} className="table_responsive mt-[40px] text-center">
          <table>
            <thead>
              <tr className={`${commonTableRow}`}>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Booking Date</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Customer Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Project Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> File / Plot No</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Sector</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Plot Size Katha</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>
                      {" "}
                      Plot Size <br />
                      Ojudangsho
                    </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Plot Dimension</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Total Price</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>
                      {" "}
                      Action <br /> View
                    </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {paginationData
                ?.filter((info) => {
                  return searchValue.toLowerCase() !== ""
                    ? info?.land_info?.booking_date
                        ?.toLowerCase()
                        .includes(searchValue) ||
                        info?.land_info?.customer_info
                          ?.map((cus) => cus?.name)
                          ?.join("")
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.land_info?.plot_info?.project_name
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.land_info?.plot_info?.file_no
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.land_info?.plot_info?.sector
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.land_info?.plot_info?.plot_size_katha
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.land_info?.plot_info?.plot_size_ojutangsho
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.land_info?.plot_info?.plot_dimension
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.total_amount
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue)
                    : info;
                })
                ?.map((item) => (
                  <tr className="text-center" key={item?.id}>
                    <td>{item?.land_info?.booking_date}</td>
                    <td>
                      {item?.land_info?.customer_info?.map((cus) => (
                        <p key={cus.id}>{cus?.name}</p>
                      ))}
                    </td>
                    <td>{item?.land_info?.plot_info?.project_name}</td>
                    <td>{item?.land_info?.plot_info?.file_no}</td>
                    <td>{item?.land_info?.plot_info?.sector}</td>
                    <td>{item?.land_info?.plot_info?.plot_size_katha}</td>
                    <td>{item?.land_info?.plot_info?.plot_size_ojutangsho}</td>
                    <td>{item?.land_info?.plot_info?.plot_dimension}</td>
                    <td>{item?.total_amount}</td>
                    <td>
                      <span className="flex justify-center">
                        <Link
                          to={`/dashboard/client-plot-payment-complete-list/view`}
                          state={{
                            item: item,
                          }}
                        >
                          <img className={commonAction} src={ViewImg} alt="" />
                        </Link>
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <Pagination
          data={plotCompletePaymentList}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          setPageCount={setPageCount}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      </div>
    </div>
  );
};

export default ClientPlotPaymentCompleteList;
