import React from "react";
import { useLocation } from "react-router-dom";
import { baseUrlImg } from "../../../../BaseUrl";
import BackButton from "../../../../shared/BackButton";

const UserInformation = () => {
  const singleInfo = useLocation().state.data;
  return (
    <div className="max-w-[1360px]">
      <BackButton title={"User Details"} />
      <div className="bg-[#FFFFFF] p-8">
        <div className="border border-[#CBCBCB] rounded-lg mb-5">
          <h4 className="text-center bg-[#F5F6F8] p-2 rounded-t-lg border-b border-b-[#C7C6C6] text-[#87898f] text-xl font-medium">
            User Information
          </h4>
          <div className="md:flex justify-between ps-5 pt-8 pb-20 pe-10">
            <div className="flex flex-col gap-4 text-[#353535] font-semibold text-base">
              <div className="grid grid-cols-[1fr_20px_1fr] items-center">
                <h5>User Name</h5>
                <p>:</p>
                <p className=" text-start">{singleInfo?.username}</p>
              </div>
              <div className="grid grid-cols-[1fr_20px_1fr] items-center">
                <h5>User ID</h5>
                <p>:</p>
                <p className=" text-start">{singleInfo?.id}</p>
              </div>
              <div className="grid grid-cols-[1fr_20px_1fr] items-center">
                <h5>Designation</h5>
                <p>:</p>
                <p className=" text-start whitespace-break-spaces">
                  {singleInfo?.designation ? singleInfo?.designation : ""}
                </p>
              </div>
              <div className="grid grid-cols-[1fr_20px_1fr] items-center">
                <h5 className="whitespace-break-spaces">
                  Email Address/Phone Number
                </h5>
                <p>:</p>
                <p className=" text-start">{singleInfo?.mobile_no}</p>
              </div>
            </div>
            <div className="flex flex-col gap-4 text-[#353535] font-semibold text-base pt-4 md:pt-0">
              <div className="grid grid-cols-[1fr_20px_1fr] items-center">
                <h5>User Role</h5>
                <p>:</p>
                <p className=" text-start">{singleInfo?.roles[0]?.name}</p>
              </div>
            </div>
          </div>
        </div>
        <div className=" border border-[#CBCBCB] rounded-lg bg-[#FFF]">
          <h4 className="text-center bg-[#F5F6F8] p-2 rounded-t-lg border-b border-b-[#C7C6C6] text-[#87898f] text-xl font-medium">
            Document
          </h4>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 px-4">
            <div className="grid grid-cols-2 items-center w-11/12 md:w-full">
              <h5 className="text-[#353535] font-semibold md:w-5/6">
                Upload Doc & Image :
              </h5>
              <img
                className="p-2 w-[100%] sm:h-3/4 md:h-4/5 lg:h-5/6 xl:h- my-2"
                src={baseUrlImg + singleInfo?.image}
                alt=""
              />
            </div>
            <div className="grid grid-cols-2 items-center w-11/12 md:w-full">
              <h5 className="text-[#353535] font-semibold">Remarks : </h5>

              <p className="h-20 w-[100%] text-sm sm:text-base sm:h-24 overflow-x-hidden  border border-[#C7C6C6] rounded-lg p-3 my-2">
                {singleInfo?.remarks
                  ? singleInfo?.remarks
                  : "Please insert  remarks"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInformation;
