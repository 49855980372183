import React from "react";
import BackButton from "../../../../shared/BackButton";
import { agent_info_label } from "../../../../asset/commoncssConstant/CommonCssConstant";
import MaskGroup from "../../../../asset/images/clientlist/Maskgroup.png";
import { useLocation } from "react-router-dom";
import { baseUrlImg } from "../../../../BaseUrl";
import CommonPrintPdfBtn from "../../../../shared/CommonPrintPdfBtn";
import useCommonPDF from "../../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../../shared/useCommonPrint/useCommonPrint";

const SellerView = () => {
  const item = useLocation().state.item;
  const {
    dob,
    email,
    father_name,
    id,
    image,
    join_date,
    mobile_no_1,
    mobile_no_2,
    mother_name,
    name,
    nid,
    permanent_address,
    present_address,
  } = item ?? {};
  const {toPDF, targetRef} = useCommonPDF({ title: "seller-details"});

  const handlePrint = useCommonPrint({
    targetRef,
    title: "seller-details",
    message: "thank you",
  });

  return (
    <div>
      <BackButton title={"Seller Information"} />
      <div className="grid lg:grid-cols-12  mt-3 gap-5">
        <div ref={targetRef} className="col-span-9 order-2 lg:order-1 client_info_section p-[40px]">
          <h1 className="client_info_title text-[25px] mb-8 md:text-center">
            Seller Information
          </h1>
          <div className="flex flex-col-reverse md:flex-row md:justify-between gap-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
              <div className="agent_view_table ">
                <table>
                  <tbody>
                    <tr>
                      <td>Seller Join Date</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{join_date}</td>
                    </tr>
                    <tr>
                      <td>Seller Name</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{name}</td>
                    </tr>
                    <tr>
                      <td>Father’s Name</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{father_name}</td>
                    </tr>
                    <tr>
                      <td>Mother’s Name</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{mother_name}</td>
                    </tr>

                    <tr>
                      <td>National ID Number</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{nid}</td>
                    </tr>
                    <tr>
                      <td>Date of Birth</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{dob}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="agent_view_table ">
                <table>
                  <tbody>
                    <tr>
                      <td>Email Address</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{email}</td>
                    </tr>
                    <tr>
                      <td>Phone Number 01</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{mobile_no_1}</td>
                    </tr>
                    <tr>
                      <td>Phone Number 02</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{mobile_no_2}</td>
                    </tr>
                    <tr>
                      <td>Present Address</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{present_address}</td>
                    </tr>
                    <tr>
                      <td>Permanent Address</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{permanent_address}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
              <img
                className="h-[222px] w-[190px] border border-[#5F646E] p-2"
                src={image ? baseUrlImg + image : MaskGroup}
                alt=""
              />
          </div>
          {/* signature */}
          <div className="mt-[50px] md:mt-[200px] flex justify-between">
            <div>
              <div className="border-t-[1px] border-[#333547] w-[180px]">
                <span className={`${agent_info_label} text-center block`}>
                  Customer’s Signature
                </span>
              </div>
            </div>
            <div>
              <div className="border-t-[1px] border-[#333547] w-[180px] lg:ml-auto">
                <span className={`${agent_info_label} text-center block`}>
                  Official Signature
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-3 order-1 lg:order-2">
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} isBlock={true} />
          {/* <div className="commonprintpdfbtn whitespace-nowrap mt-5">
            <button className="bg-orange-500">Legal Paper Folder</button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SellerView;
