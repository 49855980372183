import { Navigate, useLocation } from "react-router-dom";
const PrivateAuth = ({ children }) => {
  const token=localStorage.getItem('token')
  const { pathname } = useLocation();
  if (!token) {
    return <Navigate to={"/login"} state={{ path: pathname }} />;
  }
  return children;
};

export default PrivateAuth;
