import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import BackButton from "../../../../shared/BackButton";
import ActionImg from "../../../../asset/images/clientlist/action.png";
import ViewImg from "../../../../asset/images/clientlist/view.png";
import CommonPrintPdfBtn from "../../../../shared/CommonPrintPdfBtn";
import { baseUrl } from "../../../../BaseUrl";
import axios from "axios";
import {
  commonAction,
  commonTableHeadingTwo,
  commonTableRow,
  commonUserInfo,
  commonUserRow,
} from "../../../../asset/commoncssConstant/CommonCssConstant";
import Pagination from "../../../../shared/pagination/Pagination";
import useCommonPDF from "../../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../../shared/useCommonPrint/useCommonPrint";

const ViewClientPaymentAndDueList = () => {
  const [item, setItem] = useState(null);
  const { Id } = useParams();
  const token = localStorage.getItem("token");
  const [paginationData, setPaginationData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const { toPDF, targetRef } = useCommonPDF({
    title: "client-payment & due-details",
  });

  const handlePrint = useCommonPrint({
    targetRef,
    title: "client payment details",
    message: "thank you",
  });

  // pagination part start here
  const handleNext = () => {
    if (page === pageCount) {
      return page;
    } else {
      return setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page === 1) {
      return page;
    } else {
      return setPage(page - 1);
    }
  };

  //---------get single data by daynamic route id start--------------------
  useEffect(() => {
    axios
      .get(baseUrl + `/customer/paymentInformation/statementView/${Id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setItem(res.data.data);
      });
  }, [token, Id, page]);
  //---------get single data by daynamic route id end--------------------
  return (
    <div>
      <BackButton title={"Client Due Amount Details View"} />
      <div className="grid lg:grid-cols-12 gap-5">
        <div
          ref={targetRef}
          className="col-span-10 overflow-auto order-2 lg:order-1 client_info_section p-[20px]"
        >
          <h1 className="info_title mb-5">User And Project Information</h1>
          <div className={`${commonUserInfo} mt-3`}>
            <div className={commonUserRow}>
              <div>File / Plot No </div>
              <div>:</div>
              <div className="mx-3">
                <p>{item?.land_info?.plot_info?.file_no}</p>
              </div>
            </div>
            <div className={commonUserRow}>
              <div>Booking Date </div>
              <div>:</div>
              <div className="mx-3">
                <p>{item?.land_info?.booking_date}</p>
              </div>
            </div>
            <div className={commonUserRow}>
              <div>Customer Name </div>
              <div>:</div>
              <div className="mx-3">
                {item?.land_info?.customer_info?.map((cus, i) => (
                  <span key={cus?.id}>
                    {cus?.name}{" "}
                    {i < item?.land_info?.customer_info?.length - 1 ? ", " : ""}
                  </span>
                ))}
              </div>
            </div>
            <div className={commonUserRow}>
              <div>Project Name </div>
              <div>:</div>
              <div className="mx-3">
                <p>{item?.land_info?.plot_info?.project_name}</p>
              </div>
            </div>
          </div>
          <h1 className="info_title mb-5">Payment Information</h1>
          <div className="grid lg:grid-cols-12">
            <div className="client_info_table order-2 lg:order-1 col-span-12 mt-3  overflow-x-scroll">
              <table>
                <tr>
                  <td>#</td>
                  <td>Total</td>
                  <td>Total Paid</td>
                  <td>Total due</td>
                </tr>
                <tr>
                  <td>Total Price</td>
                  <td>{item?.total_amount}</td>
                  <td>{item?.total_payment_amount}</td>
                  <td>{item?.total_due_amount}</td>
                </tr>
                <tr>
                  <td>Booking Money</td>
                  <td>{item?.total_booking_money}</td>
                  <td>{item?.booking_money_paid}</td>
                  <td>{item?.due_booking_amount}</td>
                </tr>
                <tr>
                  <td>Down Payment</td>
                  <td>{item?.total_downpayment_amount}</td>
                  <td>{item?.downpayment_paid}</td>
                  <td>{item?.due_downpayment_amount}</td>
                </tr>
                <tr>
                  <td>Installment</td>
                  <td>{item?.total_installment_amount}</td>
                  <td>{item?.total_installment_amount_paid}</td>
                  <td>{item?.due_installment_amount}</td>
                </tr>
              </table>
            </div>
          </div>
          <div className="my-5">
            <h1 className="client_info_title">Booking Money</h1>
            <div>
              <div className="table_responsive mt-[40px]">
                <table>
                  <thead>
                    <tr className={commonTableRow}>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          <span> Date</span>{" "}
                        </div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          <span> Money Receipt SL No</span>{" "}
                        </div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          <span> Payment By</span>{" "}
                        </div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          <span>Mobile A/c No</span>{" "}
                        </div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          <span>
                            {" "}
                            Bank Name <br /> Bank A/c No <br />
                            Bank Cheque No
                          </span>{" "}
                        </div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          <span> Payment Amount</span>{" "}
                        </div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          <span> Action</span>{" "}
                        </div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          <span> View Info</span>{" "}
                        </div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          <span>Money Receipt</span>{" "}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginationData?.map((statement) => (
                      <tr className="text-center" key={statement.id}>
                        <td>{statement?.payment_date}</td>
                        <td>{statement?.money_receipt_no}</td>
                        <td>{statement?.paid_by}</td>
                        <td>{statement?.mobile_banking_account_no || "-"}</td>
                        <td>
                          {statement?.bank_name || "_"} <br />
                          {statement?.bank_account_no} <br />
                          {statement?.bank_cheque_no}
                        </td>
                        <td>
                          <span>৳ {statement?.amount}</span>
                        </td>
                        <td>
                          <span className="flex gap-3 justify-center">
                            <Link
                              to={`/dashboard/client-payment-and-due-list/edit`}
                              state={{
                                statement,
                                item,
                              }}
                            >
                              <img
                                className={commonAction}
                                src={ActionImg}
                                alt=""
                              />
                            </Link>
                          </span>
                        </td>

                        <td>
                          <span className="flex justify-center">
                            <Link
                              to={`/dashboard/client-payment-and-due-list/payment-info-pdf`}
                              state={{ item, statement }}
                            >
                              <img
                                className={commonAction}
                                src={ViewImg}
                                alt=""
                              />
                            </Link>
                          </span>
                        </td>
                        <td>
                          <span className="flex justify-center">
                            <Link
                              to={`/dashboard/client-payment-and-due-list/money-receipt-pdf`}
                              state={{ item, statement }}
                            >
                              <img
                                className={commonAction}
                                src={ViewImg}
                                alt=""
                              />
                            </Link>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                data={item?.booking_money_statement || []}
                paginationData={paginationData}
                setPaginationData={setPaginationData}
                page={page}
                setPage={setPage}
                pageCount={pageCount}
                setPageCount={setPageCount}
                handleNext={handleNext}
                handlePrevious={handlePrevious}
              />
            </div>
          </div>
          <div className="my-5">
            <h1 className="client_info_title">Downpayment</h1>
            <div>
              <div className="table_responsive mt-[40px]">
                <table>
                  <thead>
                    <tr className={commonTableRow}>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          <span>Date</span>{" "}
                        </div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          <span> Money Receipt SL No</span>{" "}
                        </div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          <span> Payment By</span>{" "}
                        </div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          <span> Mobile A/c No</span>{" "}
                        </div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          <span>
                            {" "}
                            Bank Name <br /> Bank A/c No <br />
                            Bank Cheque No
                          </span>{" "}
                        </div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          <span>Payment Amount</span>{" "}
                        </div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          <span>Action</span>{" "}
                        </div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          <span>View Info</span>{" "}
                        </div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          <span>Money Receipt</span>{" "}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginationData?.map((statement) => (
                      <tr className="text-center" key={statement.id}>
                        <td>{statement?.payment_date}</td>
                        <td>{statement?.money_receipt_no}</td>
                        <td>{statement?.paid_by}</td>
                        <td>{statement?.mobile_banking_account_no || "-"}</td>
                        <td>
                          {statement?.bank_name || "_"} <br />
                          {statement?.bank_account_no} <br />
                          {statement?.bank_cheque_no}
                        </td>
                        <td>
                          <span>৳ {statement?.amount}</span>
                        </td>
                        <td>
                          <span className="flex justify-center">
                            <Link
                              to={`/dashboard/client-payment-and-due-list/edit`}
                              state={{
                                statement,
                                item,
                              }}
                            >
                              <img
                                className={commonAction}
                                src={ActionImg}
                                alt=""
                              />
                            </Link>
                          </span>
                        </td>
                        <td>
                          <span className="flex justify-center">
                            <Link
                              to={`/dashboard/client-payment-and-due-list/payment-info-pdf`}
                              state={{ item, statement }}
                            >
                              <img
                                className={commonAction}
                                src={ViewImg}
                                alt=""
                              />
                            </Link>
                          </span>
                        </td>
                        <td>
                          <span className="flex justify-center">
                            <Link
                              to={`/dashboard/client-payment-and-due-list/money-receipt-pdf`}
                              state={{ item, statement }}
                            >
                              <img
                                className={commonAction}
                                src={ViewImg}
                                alt=""
                              />
                            </Link>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                data={item?.downpayment_statement || []}
                paginationData={paginationData}
                setPaginationData={setPaginationData}
                page={page}
                setPage={setPage}
                pageCount={pageCount}
                setPageCount={setPageCount}
                handleNext={handleNext}
                handlePrevious={handlePrevious}
              />
            </div>
          </div>
          <div className="my-5">
            <h1 className="client_info_title">Installment</h1>
            <div>
              <div className="table_responsive mt-[40px]">
                <table>
                  <thead>
                    <tr className={commonTableRow}>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          <span> Date</span>{" "}
                        </div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          <span>Money Receipt SL No</span>{" "}
                        </div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          <span> Payment By</span>{" "}
                        </div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          <span> Mobile A/c No</span>{" "}
                        </div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          <span>
                            Bank Name <br /> Bank A/c No <br />
                            Bank Cheque No
                          </span>{" "}
                        </div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          <span> Payment Amount</span>{" "}
                        </div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          <span>Action</span>{" "}
                        </div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          <span>View Info</span>{" "}
                        </div>
                      </th>
                      <th>
                        <div className={commonTableHeadingTwo}>
                          <span>Money Receipt</span>{" "}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {item?.installment_statement?.map((statement) => (
                      <tr className="text-center" key={statement.id}>
                        <td>{statement?.payment_date}</td>
                        <td>{statement?.money_receipt_no}</td>
                        <td>{statement?.paid_by}</td>
                        <td>{statement?.mobile_banking_account_no || "-"}</td>
                        <td>
                          {statement?.bank_name || "_"} <br />
                          {statement?.bank_account_no} <br />
                          {statement?.bank_cheque_no}
                        </td>
                        <td>
                          <span>৳ {statement?.amount}</span>
                        </td>
                        <td>
                          <span className="flex gap-3 justify-center">
                            <Link
                              to={`/dashboard/client-payment-and-due-list/edit`}
                              state={{
                                statement,
                                item,
                              }}
                            >
                              <img
                                className={commonAction}
                                src={ActionImg}
                                alt=""
                              />
                            </Link>
                          </span>
                        </td>
                        <td>
                          <span className="flex justify-center">
                            <Link
                              to={`/dashboard/client-payment-and-due-list/payment-info-pdf`}
                              state={{ item, statement }}
                            >
                              <img
                                className={commonAction}
                                src={ViewImg}
                                alt=""
                              />
                            </Link>
                          </span>
                        </td>
                        <td>
                          <span className="flex justify-center">
                            <Link
                              to={`/dashboard/client-payment-and-due-list/money-receipt-pdf`}
                              state={{ item, statement }}
                            >
                              <img
                                className={commonAction}
                                src={ViewImg}
                                alt=""
                              />
                            </Link>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                data={item?.installment_statement || []}
                paginationData={paginationData}
                setPaginationData={setPaginationData}
                page={page}
                setPage={setPage}
                pageCount={pageCount}
                setPageCount={setPageCount}
                handleNext={handleNext}
                handlePrevious={handlePrevious}
              />
            </div>
          </div>
        </div>

        {/* right side button group  */}
        <div className="col-span-2 order-1 lg:order-2  ">
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} isBlock={true} />
          {/* <div className="commonprintpdfbtn whitespace-nowrap mt-5">
            <button className="bg-orange-500">Legal Paper Folder</button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ViewClientPaymentAndDueList;
