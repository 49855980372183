import React, { useEffect, useState } from "react";
import CommonPrintPdfBtn from "../../../shared/CommonPrintPdfBtn";
import {
  addCustomerCss,
  commonAction,
  commonAddButton,
  commonCaption,
  commonSearchInput,
  commonTableHeadingTwo,
  commonTableRow,
  commonTitle,
  dashboardBodyWraper,
} from "../../../asset/commoncssConstant/CommonCssConstant";
import Pagination from "../../../shared/pagination/Pagination";
import AddUserImg from "../../../asset/images/clientlist/add-user.png";
import ViewImg from "../../../asset/images/clientlist/view.png";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../../BaseUrl";
import { LuArrowDownUp } from "react-icons/lu";
import useCommonPDF from "../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../shared/useCommonPrint/useCommonPrint";

const ClientMutationCompletePlotList = () => {
  const [mutationCompleteList, setMutationCompleteList] = useState([]);
  const [landAgreementList, setLandAgreementList] = useState([]);
  const token = localStorage.getItem("token");
  const [searchValue, setSearchValue] = useState("");
  const [paginationData, setPaginationData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const { toPDF, targetRef } = useCommonPDF({
    title: "mutation-complete-list",
  });

  const handlePrint = useCommonPrint({
    targetRef,
    title: "mutation-complete-list",
    message: "thank you",
  });

  // pagination part start here
  const handleNext = () => {
    if (page === pageCount) {
      return page;
    } else {
      return setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page === 1) {
      return page;
    } else {
      return setPage(page - 1);
    }
  };

  //------get data for plotBank/mutationComplete/view and customer/landAgreement/view start-----------------
  useEffect(() => {
    axios
      .get(baseUrl + `/plotBank/mutationComplete/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setMutationCompleteList(res.data.data);
      });
    axios
      .get(baseUrl + `/customer/landAgreement/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const filterData = res.data.data.filter((data) => data.land_agreement);
        setLandAgreementList(filterData);
      });
  }, [token, page]);
  //------get data for plotBank/mutationComplete/view and customer/landAgreement/view end-----------------

  return (
    <div>
      <h1 className={`${commonTitle}`}>Client Mutation Complete Plot List</h1>
      <div className={dashboardBodyWraper}>
        <div className={commonAddButton}>
          <Link
            to={`/dashboard/client-mutation-complete-plot-list/add`}
            state={{
              landAgreementList: landAgreementList,
            }}
          >
            <button className={addCustomerCss}>
              <span>Add Mutation Details</span>
              <img className="ml-[30px]" src={AddUserImg} alt="" />
            </button>
          </Link>
        </div>
        <div className={commonCaption}>
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} />
          <input
            onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
            type="text"
            // placeholder="Search by Project Name / Plot Size / Customer Name  / Customer ID /  Mutation Date / Mutation No   "
            placeholder="Search"
            className={commonSearchInput}
          />
        </div>

        <div ref={targetRef} className="table_responsive mt-[40px] text-center">
          <table>
            <thead>
              <tr className={`${commonTableRow}`}>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Registry Date</span>
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Registry Deed No</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> BS Dag no</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Customer Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Project Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> File / Plot No</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Mutation Date</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Mutation No</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Sector</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Plot Size Katha</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>
                      {" "}
                      Plot Size <br />
                      Ojudangsho
                    </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Plot Dimension</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>
                      {" "}
                      Action <br /> View
                    </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {paginationData
                ?.filter((info) => {
                  return searchValue.toLowerCase() !== ""
                    ? info?.customer_land_agreement_info?.registry_complete_date
                        ?.toLowerCase()
                        .includes(searchValue) ||
                        info?.item?.customer_land_agreement_info?.deed_no
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.customer_land_agreement_info?.land_seller_agreement?.bs_info
                          ?.map((bs) => bs?.bs_dag_no)
                          ?.join("")
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.customer_land_agreement_info?.customer_land_info?.customer_info
                          ?.map((cus) => cus?.name)
                          ?.join("")
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.customer_land_agreement_info?.customer_land_info.plot_info?.project_name
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.customer_land_agreement_info?.customer_land_info.plot_info?.file_no
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.mutation_date
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.mutation_no
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.item?.customer_land_agreement_info?.customer_land_info?.plot_info?.sector
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.customer_land_agreement_info?.customer_land_info?.plot_info?.plot_size_katha
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.customer_land_agreement_info?.customer_land_info?.plot_info?.plot_size_ojutangsho
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.customer_land_agreement_info?.customer_land_info?.plot_info?.plot_dimension
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue)
                    : info;
                })
                ?.map((item) => (
                  <tr className="text-center" key={item?.id}>
                    <td>
                      {
                        item?.customer_land_agreement_info
                          ?.registry_complete_date
                      }
                    </td>
                    <td> {item?.customer_land_agreement_info?.deed_no}</td>
                    <td>
                      {item?.customer_land_agreement_info?.land_seller_agreement?.bs_info?.map(
                        (bs) => (
                          <p key={bs?.id}>{bs?.bs_dag_no}</p>
                        )
                      )}
                    </td>
                    <td>
                      {item?.customer_land_agreement_info?.customer_land_info?.customer_info?.map(
                        (cus) => (
                          <p key={cus?.id}>{cus?.name}</p>
                        )
                      )}
                    </td>
                    <td>
                      {
                        item?.customer_land_agreement_info?.customer_land_info
                          .plot_info?.project_name
                      }
                    </td>
                    <td>
                      {" "}
                      {
                        item?.customer_land_agreement_info?.customer_land_info
                          .plot_info?.file_no
                      }
                    </td>
                    <td>{item?.mutation_date}</td>
                    <td>{item?.mutation_no}</td>
                    <td>
                      {
                        item?.customer_land_agreement_info?.customer_land_info
                          .plot_info?.sector
                      }
                    </td>
                    <td>
                      {
                        item?.customer_land_agreement_info?.customer_land_info
                          .plot_info?.plot_size_katha
                      }
                    </td>
                    <td>
                      {
                        item?.customer_land_agreement_info?.customer_land_info
                          .plot_info?.plot_size_ojutangsho
                      }
                    </td>
                    <td>
                      {
                        item?.customer_land_agreement_info?.customer_land_info
                          .plot_info?.plot_dimension
                      }
                    </td>
                    <td>
                      <span className="flex justify-center">
                        <Link
                          to={`/dashboard/client-mutation-complete-plot-list/view`}
                          state={{
                            item: item,
                          }}
                        >
                          <img className={commonAction} src={ViewImg} alt="" />
                        </Link>
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <Pagination
          data={mutationCompleteList}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          setPageCount={setPageCount}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      </div>
    </div>
  );
};

export default ClientMutationCompletePlotList;
