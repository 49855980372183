import React from "react";
import BackButton from "../../../shared/BackButton";
import CommonPrintPdfBtn from "../../../shared/CommonPrintPdfBtn";
import { useLocation } from "react-router-dom";
import { baseUrlImg } from "../../../BaseUrl";
import useCommonPDF from "../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../shared/useCommonPrint/useCommonPrint";

const ProjectLandPurchaseDetailsView = () => {
  const { item } = useLocation()?.state ?? {};

  const { toPDF, targetRef } = useCommonPDF({
    title: "land-information",
  });
  
  const handlePrint = useCommonPrint({
    targetRef,
    title: "land information",
    message: "thank you",
  });

  return (
    <div>
      <BackButton
        title={" Registry Complete Land Information (From Land Owner)"}
      />

      <div className="grid lg:grid-cols-12  mt-3 gap-5 relative">
        <div
          ref={targetRef}
          className="col-span-9 order-2 lg:order-1 client_info_section p-[40px] "
        >
          <div className=" client_info_section ">
            <div>
              <h1 className="client_info_title text-center mt-0">
                Land Information
              </h1>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2  xl:grid-cols-2 my-3 ">
              <div className="agent_view_table">
                <table>
                  <tbody>
                    <tr>
                      <td>Sub-deed Date</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{item?.sub_deed_date}</td>
                    </tr>
                    <tr>
                      <td>Sub-deed Number</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{item?.sub_deed_no}</td>
                    </tr>
                    <tr>
                      <td>Project Name</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>
                        {item?.bs_info?.map((item) => (
                          <div>
                            {
                              item.rs_info.sa_info.cs_info.mouza_info
                                .project_info.project_name
                            }
                          </div>
                        ))}
                      </td>
                    </tr>
                    <tr>
                      <td>Mouza Name</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>
                        {item?.bs_info?.map((item) => (
                          <div>
                            {item.rs_info.sa_info.cs_info.mouza_info.mouza_name}
                          </div>
                        ))}
                      </td>
                    </tr>
                    <tr>
                      <td>Land Size (Katha)</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>{item?.land_size_katha}</td>
                    </tr>
                    <tr>
                      <td>Land BS KH No</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>
                        {" "}
                        {item?.bs_info?.map((item) => (
                          <div>{item.bs_khatiyan_no}</div>
                        ))}
                      </td>
                    </tr>
                    <tr>
                      <td>Land CS Daag No</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>
                        {item?.bs_info?.map((item) => (
                          <div>{item.rs_info.sa_info.cs_info.cs_dag_no}</div>
                        ))}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="agent_view_table">
                <table>
                  <tbody>
                    <tr>
                      <td>Land CS KH No</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>
                        {" "}
                        {item?.bs_info?.map((item) => (
                          <div>
                            {item.rs_info.sa_info.cs_info.cs_khatiyan_no}
                          </div>
                        ))}
                      </td>
                    </tr>
                    <tr>
                      <td>Land SA Daag No</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>
                        {item?.bs_info?.map((item) => (
                          <div>{item.rs_info.sa_info.sa_dag_no}</div>
                        ))}
                      </td>
                    </tr>
                    <tr>
                      <td>Land SA KH No</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>
                        {item?.bs_info?.map((item) => (
                          <div>{item.rs_info.sa_info.sa_khatiyan_no}</div>
                        ))}
                      </td>
                    </tr>
                    <tr>
                      <td>Land RS Daag No</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>
                        {item?.bs_info?.map((item) => (
                          <div>{item.rs_info.rs_dag_no}</div>
                        ))}
                      </td>
                    </tr>
                    <tr>
                      <td>Land RS KH No</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>
                        {item?.bs_info?.map((item) => (
                          <div>{item.rs_info.rs_khatiyan_no}</div>
                        ))}
                      </td>
                    </tr>
                    <tr>
                      <td>Land BS Daag No</td>
                      <td>
                        <span className="mx-6">:</span>
                      </td>
                      <td>
                        {" "}
                        {item?.bs_info?.map((item) => (
                          <div>{item.bs_dag_no}</div>
                        ))}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div>
              <h1 className="client_info_title text-center mt-0">
                Land Price Information
              </h1>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2  xl:grid-cols-2 mb-3">
                <div className="agent_view_table  ">
                  <table>
                    <tbody>
                      <tr>
                        <td>Purchase Land Size (Katha)</td>
                        <td>
                          <span className="mx-6">:</span>
                        </td>
                        <td>{item?.land_size_katha}</td>
                      </tr>
                      <tr>
                        <td>Purchase Land Price (Per Katha)</td>
                        <td>
                          <span className="mx-6">:</span>
                        </td>
                        <td>{item?.price_info?.price_per_katha}</td>
                      </tr>
                      <tr>
                        <td>Purchase Land Total Price</td>
                        <td>
                          <span className="mx-6">:</span>
                        </td>
                        <td>{item?.price_info?.total_price}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="agent_view_table">
                  <table>
                    <tbody>
                      <tr>
                        <td>Payment Start Date</td>
                        <td>
                          <span className="mx-6">:</span>
                        </td>
                        <td>{item?.price_info?.payment_start_date}</td>
                      </tr>
                      <tr>
                        <td>Approx Payment Complete Date</td>
                        <td>
                          <span className="mx-6">:</span>
                        </td>
                        <td>
                          {item?.price_info?.approx_payment_complete_date}
                        </td>
                      </tr>
                      <tr>
                        <td>Approx Registry Date</td>
                        <td>
                          <span className="mx-6">:</span>
                        </td>
                        <td>{item?.price_info?.approx_registry_date}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <h1 className="client_info_title text-center mt-0">
              Seller Information
            </h1>
            {item?.seller_info?.map((seller, idx) => {
              return (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2  xl:grid-cols-2  mt-2">
                  <div className="agent_view_table  ">
                    <h1 className="text-[#414141] font-[700] text-[12px]">
                      Seller Information {++idx}
                    </h1>
                    <table>
                      <tbody>
                        <tr>
                          <td>Seller Name</td>
                          <td>
                            <span className="mx-6">:</span>
                          </td>
                          <td>{seller?.name}</td>
                        </tr>
                        <tr>
                          <td>Seller Phone Number 1</td>
                          <td>
                            <span className="mx-6">:</span>
                          </td>
                          <td>{seller?.mobile_no_1}</td>
                        </tr>
                        <tr>
                          <td>Seller Phone Number 2</td>
                          <td>
                            <span className="mx-6">:</span>
                          </td>
                          <td>{seller?.mobile_no_2}</td>
                        </tr>
                        <tr>
                          <td>Seller Father’s Name</td>
                          <td>
                            <span className="mx-6">:</span>
                          </td>
                          <td>{seller?.father_name}</td>
                        </tr>
                        <tr>
                          <td>Seller Mother’s Name</td>
                          <td>
                            <span className="mx-6">:</span>
                          </td>
                          <td>{seller?.mother_name}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="agent_view_table  ">
                    <table>
                      <tbody>
                        <tr>
                          <td>Seller NID No</td>
                          <td>
                            <span className="mx-6">:</span>
                          </td>
                          <td>{seller?.nid}</td>
                        </tr>
                      </tbody>
                    </table>
                    <img
                      className="border h-3/4 w-1/3"
                      src={baseUrlImg + seller?.image}
                      alt=""
                    />
                  </div>
                </div>
              );
            })}
            <div>
              <h1 className="client_info_title text-center my-10">
                Land Broker Information
              </h1>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2  xl:grid-cols-2 mb-10">
                <div className="agent_view_table  ">
                  <table>
                    <tbody>
                      <tr>
                        <td>Land Broker Name</td>
                        <td>
                          <span className="mx-6">:</span>
                        </td>
                        <td>{item?.land_broker?.name}</td>
                      </tr>
                      <tr>
                        <td>Land Broker Ph No. 01</td>
                        <td>
                          <span className="mx-6">:</span>
                        </td>
                        <td>{item?.land_broker?.mobile_number_1}</td>
                      </tr>
                      <tr>
                        <td>Land Broker Ph No. 02</td>
                        <td>
                          <span className="mx-6">:</span>
                        </td>
                        <td>{item?.land_broker?.mobile_number_2}</td>
                      </tr>
                      <tr>
                        <td>Land Broker Father’s Name</td>
                        <td>
                          <span className="mx-6">:</span>
                        </td>
                        <td>{item?.land_broker?.father_name}</td>
                      </tr>
                      <tr>
                        <td>Land Broker Mother’s Name</td>
                        <td>
                          <span className="mx-6">:</span>
                        </td>
                        <td>{item?.land_broker?.mother_name}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="agent_view_table  ">
                  <h1 className="text-[#414141] font-[700] text-[12px]">
                    Land Broker Image
                  </h1>
                  <div>
                    {/* <img src={baseUrlImg + item?.land_broker?.image} alt="" /> */}
                    <div>
                      {item?.land_broker_agreements?.map((agree) => (
                        <div>
                          <img
                            alt=""
                            className="w-[50%]"
                            src={baseUrlImg + agree?.file_path}
                            title="land-agrement-file"
                          ></img>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-3">
              <div>
                <h1 className="text-[#414141] font-[700] text-[12px] my-2">
                  Dolil Image
                </h1>
                <div>
                  {item?.dolil_image?.map((dolilImg) => (
                    <img
                      className="w-[60%]"
                      src={baseUrlImg + dolilImg?.file_path}
                      alt=""
                    />
                  ))}
                </div>
              </div>
              <div>
                <h1 className="text-[#414141] font-[700] text-[12px] my-2">
                  Another Document Image
                </h1>
                <div>
                  {item?.other_image?.map((otherImg) => (
                    <img
                      className="w-[60%]"
                      src={baseUrlImg + otherImg?.file_path}
                      alt=""
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* right side button group  */}
        <div className="col-span-3 order-1 lg:order-2  ">
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} isBlock={true} />
        </div>
      </div>
    </div>
  );
};

export default ProjectLandPurchaseDetailsView;
