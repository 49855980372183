import axios from "axios";
import React, { useEffect, useState } from "react";
import { baseUrl } from "../../../BaseUrl";

const ProjectAdminList = () => {
  const [userList, setUserList] = useState([]);
  const token = localStorage.getItem("token");

  //-----------get all user list start --------------------
  useEffect(() => {
    axios
      .get(baseUrl + `/view-all-users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setUserList(res.data.allusers);
      });
  }, [token]);
  //-----------get all user list end --------------------

  // admin userList
  const adminUserList = userList?.filter(
    (admin) => admin?.roles[0].name === "Admin"
  );
  console.log(adminUserList);

  return (
    <div className="w-[928px] overflow-x-scroll  md:overflow-auto">
      <div className="bg-[#FFFFFF] p-8">
        <h1 className="bg-[#F5F6F8] border border-[#C7C6C6] rounded-lg text-[#47484A] text-xl text-center font-[700] text-[24px] py-5 mb-[11px]">
          Project Admin List
        </h1>
        <table className="border border-[#C7C6C6] w-full">
          <thead className="bg-[#F5F6F8] text-[#47484A] text-center text-xl font-[500] text-[24px]">
            <tr className="border-b border-[#C7C6C6] ">
              <th className=" p-2 ">User Name</th>
              <th className="p-2">Phone No</th>
              <th className="p-2">Project Name</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {adminUserList?.map((user) => (
              <tr key={user?.id} className="border-b border-[#C7C6C6] ">
                <td>
                  <p className="p-2">{user?.name}</p>
                </td>
                <td>
                  <p className="p-2">{user?.mobile_no}</p>
                </td>
                <td>
                  <div className="p-2 flex flex-wrap justify-center ">
                    {user?.projects?.map(
                      (project) => project?.single_project_info?.project_name
                    ).join(", ")}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProjectAdminList;
