import React from "react";
import CommonPrintPdfBtn from "../../../../shared/CommonPrintPdfBtn";
import BackButton from "../../../../shared/BackButton";
import { useLocation } from "react-router-dom";
import useCommonPDF from "../../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../../shared/useCommonPrint/useCommonPrint";

const CsMouzaMapDescription = () => {
  const item = useLocation().state.item;
  const { toPDF, targetRef } = useCommonPDF({ title: "cs mouza map-details" });

  const handlePrint = useCommonPrint({
    targetRef,
    title: "cs mouza map-details",
    message: "thank you",
  });
  return (
    <div>
      <BackButton title={"CS Mouza Map Information"} />
      <div className="grid lg:grid-cols-12  mt-3 gap-5">
        <div
          ref={targetRef}
          className="col-span-9 order-2 lg:order-1 client_info_section p-[40px] "
        >
          <div className="grid md:grid-cols-2 bg-[#F5F6F8] border border-[#d5d7d7] px-10 rounded-[5px] mb-10">
            <div className="agent_view_table ">
              <table style={{ borderSpacing: "0 8px" }}>
                <tbody>
                  <tr>
                    <td>
                      <strong>Project Name</strong>{" "}
                    </td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>
                      <strong>
                        {item?.cs_info?.mouza_info?.project_info?.project_name}
                      </strong>{" "}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="agent_view_table ">
              <table style={{ borderSpacing: "0 8px" }}>
                <tbody>
                  <tr>
                    <td>
                      <strong>Mouza Name</strong>{" "}
                    </td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>
                      <strong>{item?.cs_info?.mouza_info?.mouza_name}</strong>{" "}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="grid md:grid-cols-2">
            <div className="agent_view_table ">
              <table>
                <tbody>
                  <tr>
                    <td>CS Mouza Map Create Date</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.created_at?.slice(0, 10)}</td>
                  </tr>
                  <tr>
                    <td>CS Dag</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.cs_info?.cs_dag_no}</td>
                  </tr>
                  <tr>
                    <td>CS Khatiyan</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.cs_info?.cs_khatiyan_no}</td>
                  </tr>
                  <tr>
                    <td>CS Land Size (Sotangso)</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.land_size_sotangsho}</td>
                  </tr>
                  <tr>
                    <td>CS Land Size (Ohudangso)</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.land_size_ojutangsho}</td>
                  </tr>
                  <tr>
                    <td>CS Land Size (Sq.fit)</td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.land_size_sq_feet}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="agent_view_table ">
              <table>
                <tbody>
                  <tr>
                    <td>CS Land East to west (Sq.fit) </td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.land_eastTowest_sq_feet}</td>
                  </tr>
                  <tr>
                    <td> CS Land North to South (Sq.fit) </td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.land_northToSouth_sq_feet}</td>
                  </tr>
                  <tr>
                    <td>CS Land East & South (Angle) </td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.land_eastAndSouth_angle}</td>
                  </tr>
                  <tr>
                    <td>CS Land East & North (Angle) </td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.land_eastAndNorth_angle}</td>
                  </tr>
                  <tr>
                    <td>CS Land West & South (Angle) </td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.land_westAndSouth_angle}</td>
                  </tr>
                  <tr>
                    <td>CS Land West & North (Angle) </td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.land_westAndNorth_angle}</td>
                  </tr>
                  <tr>
                    <td>
                      East , South Corner to West , North Corner (Length){" "}
                    </td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.eastSouth_to_westNorth_length}</td>
                  </tr>
                  <tr>
                    <td>South , West Cornerto North , East Corner (Length) </td>
                    <td>
                      <span className="mx-6">:</span>
                    </td>
                    <td>{item?.southWest_to_northEast_length}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <h3 className="text-[14px] text-[#717A82] font-[700]">Description</h3>
          <p className="text-[14px] text-[#8F8F8F] font-[400]">
            {item?.description}
          </p>
        </div>

        {/* right side button group  */}
        <div className="col-span-3 order-1 lg:order-2  ">
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} isBlock={true} />
          {/* <div className="commonprintpdfbtn whitespace-nowrap mt-5">
            <button className="bg-[#F97316]">Legal Paper Folder</button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default CsMouzaMapDescription;
