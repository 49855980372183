import React from "react";
import {
  commonFormMenu,
  errorMessage,
} from "../../../../../asset/commoncssConstant/CommonCssConstant";

const LandBrokerInfoForm = ({
  register,
  errors,
  brokerFromSelect,
  landBokerForSelect,
}) => {
  return (
    <div className={commonFormMenu}>
      <div className="common_input_wraper">
        <label>Land Broker Name</label>
        <select {...register("landBrokerID")} required>
          <option value="" selected disabled>
            Select Land Broker Name
          </option>
          {landBokerForSelect?.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
      </div>

      {/* <div className="common_input_wraper">
        <label>Land Broker Mobile No</label>
        <input
          type="text"
          placeholder="Land Broker Mobile No"
          readOnly
          disabled
          value={brokerFromSelect?.mobile_number_1}
        />
      </div>
      <div className="common_input_wraper">
        <label>Land Broker Father Name</label>
        <input
          type="text"
          placeholder="Land Broker Father Name"
          readOnly
          disabled
          value={brokerFromSelect?.father_name}
        />
      </div> */}
      <div className="common_input_wraper">
        <label>Land Broker Money Amount</label>
        <input
          {...register(`broker_money`, {
            pattern: {
              value: /^[0-9]+(\.[0-9]+)?$/,
              message: "Insert valid price",
            },
          })}
          type="text"
          placeholder="Land Broker Money Amount"
        />
        {errors?.broker_money && (
          <p className={errorMessage}>{errors?.broker_money?.message}</p>
        )}
      </div>
      <div className="common_input_wraper">
        <label>Agreement Upload (Multiple)</label>
        <input {...register("broker_agreement")} type="file" multiple />
      </div>
    </div>
  );
};

export default LandBrokerInfoForm;
