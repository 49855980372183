import React from "react";

const CustomerLandInfoForm = ({ register }) => {
  return (
    <div>
      <p className="text-[#6B2CEA] font-bold text-[16px] bg-[#F0EAFD] py-1 text-center">
        Land Information
      </p>

      <div className="grid gap-4 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
        <div className="common_input_wraper">
          <label>Project ID</label>
          <input
            {...register("file_no")}
            type="text"
            placeholder="Project ID."
          />
        </div>
        <div className="common_input_wraper">
          <label>Mouza ID</label>
          <input
            {...register("mouza_name")}
            type="text"
            placeholder="Mouza ID"
          />
        </div>
        <div className="common_input_wraper">
          <label>CS ID</label>
          <input
            {...register("booking_date")}
            type="text"
          />
        </div>
        <div className="common_input_wraper">
          <label>SA ID</label>
          <input
            {...register("expected_reg_date")}
            type="text"
          />
        </div>
        <div className="common_input_wraper">
          <label>RS ID</label>
          <input
            {...register("project_name")}
            type="text"
            
          />
        </div>
        <div className="common_input_wraper">
          <label>BS ID</label>
          <input
            {...register("land_size")}
            type="text"
           
          />
        </div>
        <div className="common_input_wraper">
          <label>Land Size(Katha)</label>
          <input
            {...register("land_size")}
            type="text"
           
          />
        </div>
        <div className="common_input_wraper">
          <label>Land Purchase/Sub-deed Date</label>
          <input
            {...register("land_size")}
            type="date"
           
          />
        </div>
        <div className="common_input_wraper">
          <label>Sub-deed Number</label>
          <input
            {...register("land_size")}
            type="text"
           
          />
        </div>
        <div className="common_input_wraper">
          <label>Dolil Image</label>
          <input
            {...register("land_size")}
            type="file"
           
          />
        </div>
        <div className="common_input_wraper">
          <label>Another Document Image</label>
          <input
            {...register("land_size")}
            type="file"
           
          />
        </div>
        <div className="common_input_wraper">
          <label>Porca Image</label>
          <input
            {...register("land_size")}
            type="file"
           
          />
        </div>
      </div>
    </div>
  );
};

export default CustomerLandInfoForm;
