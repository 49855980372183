import React from 'react';
import RightArrow from '../../../../../asset/images/agentlist/rightarrow.png'
const LandBrokerForm = ({register}) => {
    return (
        <div>
      <p className="text-[#6B2CEA] font-bold text-[16px] bg-[#F0EAFD] py-1 my-[40px]  text-center">
      Land Broker Information
      </p>

      <div className="grid gap-4 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
        <div className="common_input_wraper">
          <label>Land Broker ID</label>
          <input {...register("total_amount")}type="text" placeholder="Land Broker ID" />
        </div>
        <div className="common_input_wraper">
          <label>Land Broker Name</label>
          <input {...register("land_price_per_decimal")} disabled type="text" placeholder="Land Broker Name" />
        </div>
        <div className="common_input_wraper">
          <label>Land Broker Ph No. 01</label>
          <input {...register("booking_money")}type="text" disabled placeholder="Land Broker Ph No. 01" />
        </div>
        <div className="common_input_wraper">
          <label>Land Broker Money Amount</label>
          <input {...register("downpayment_amount")}type="text" placeholder="Land Broker Money Amount" />
        </div>
        {/* <div className="common_input_wraper">
          <label>Agreement Upload (Multiple) </label>
          <input {...register("total_installment_amount")}type="file"  />
        </div> */}
        <div className="common_input_wraper">
            <label>Agreement Upload (Multiple)</label>
            <label className="multiPleFileLable mt-2" htmlFor="multiplefile">
              Choose Files
              <img src={RightArrow} alt="" />
            </label>
            <input
              {...register("other_file")}
              name="other_file"
              style={{ display: "none" }}
              id="multiplefile"
              type="file"
              multiple
            />
          </div>
       
      </div>
    </div>
    );
};

export default LandBrokerForm;