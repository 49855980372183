import React, { useEffect, useState } from "react";
import Pagination from "../../../shared/pagination/Pagination";
import ViewImg from "../../../asset/images/clientlist/view.png";
import CommonPrintPdfBtn from "../../../shared/CommonPrintPdfBtn";
import axios from "axios";
import { baseUrl } from "../../../BaseUrl";
import { Link } from "react-router-dom";
import { LuArrowDownUp } from "react-icons/lu";
import {
  commonAction,
  // commonCount,
  // commonResponsiveAmount,
  commonResponsiveMenu,
  commonSearchFiled,
  commonTableHeadingTwo,
  commonTableRow,
  commonTitle,
} from "../../../asset/commoncssConstant/CommonCssConstant";
import useCommonPDF from "../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../shared/useCommonPrint/useCommonPrint";

const PurchasePowerOfAtornyList = () => {
  const [lists, setLists] = useState([]);
  const token = localStorage.getItem("token");
  const [searchValue, setSearchValue] = useState("");
  const [paginationData, setPaginationData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const { toPDF, targetRef } = useCommonPDF({
    title: "power-entry-list",
  });

  const handlePrint = useCommonPrint({
    targetRef,
    title: "power entry list",
    message: "thank you",
  });

  // pagination part start here
  const handleNext = () => {
    if (page === pageCount) {
      return page;
    } else {
      return setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page === 1) {
      return page;
    } else {
      return setPage(page - 1);
    }
  };

  //--------get PurchasePowerOfAtornyList data start---------------
  useEffect(() => {
    axios
      .get(baseUrl + `/purchasedLandDetails/viewByDolilVariation/2`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLists(res.data.data);
      });
  }, [token, page]);
  //--------get PurchasePowerOfAtornyList data end---------------
  return (
    <>
      <h1 className={`${commonTitle}`}>Purchase Power of eternity List</h1>
      <div className="bg-[#FFFFFF]   rounded-[4px] px-[20px] py-[30px] my-[30px]">
        <div className={commonResponsiveMenu}>
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} />
          {/* <div className={commonResponsiveAmount}>
            <div className={`${commonCount}`}>
              <span className="">Land Size </span>: <span>5000</span>
            </div>
            <div className={`${commonCount}`}>
              <span>Land Price </span>: <span>5000</span>
            </div>
            <div className={`${commonCount}`}>
              <span>Total Paid </span>: <span>5000</span>
            </div>
            <div className={`${commonCount}`}>
              <span>Total Due </span>: <span>5000</span>
            </div>
            <div className={`${commonCount}`}>
              <span className="mr-3">Number Of Land</span>: <span>5000</span>
            </div>
          </div> */}

          <input
            onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
            type="text"
            // placeholder="Select Search Type"
            placeholder="Search deed no"
            className={`${commonSearchFiled}`}
          />
        </div>

        <div ref={targetRef} className="table_responsive mt-[40px]">
          <table>
            <thead>
              <tr className={`${commonTableRow}`}>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Agreement Date</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>
                      {" "}
                      Purchase <br />
                      Deed No
                    </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> BS Dag</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> BS Khatiyan</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Project Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>
                      {" "}
                      Purchase <br />
                      Land Size
                    </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>
                      {" "}
                      Land Price <br />
                      (Per Katha){" "}
                    </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>
                      {" "}
                      Total Land <br />
                      Price{" "}
                    </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Paid Amount</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Due Amount</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Purchase Power of eternity Complete</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Seller Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>View</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="text-center">
              {paginationData
                ?.filter((info) => {
                  return searchValue !== ""
                    ? info?.purchase_deed_no
                        ?.toString()
                        .toLowerCase()
                        .includes(searchValue)
                    : info;
                })
                ?.map((item, i) => (
                  <tr key={item.id}>
                    <td>{item?.agreement_date}</td>
                    <td>{item?.purchase_deed_no}</td>
                    <td>
                      <div className="font-bold">
                        {item?.bs_info?.map((bs, i) => (
                          <p key={bs?.id}>{bs?.bs_dag_no}</p>
                        ))}
                      </div>
                    </td>
                    <td>
                      <div className="font-bold">
                        {item?.bs_info?.map((bs, i) => (
                          <p key={bs?.id}>{bs?.bs_khatiyan_no}</p>
                        ))}
                      </div>
                    </td>
                    <td>
                      <div className="font-bold">
                        {item?.bs_info?.map((bs, i) => (
                          <p key={bs?.id}>
                            {
                              bs?.rs_info?.sa_info?.cs_info?.mouza_info
                                ?.project_info?.project_name
                            }
                          </p>
                        ))}
                      </div>
                    </td>
                    <td>{item?.land_size_katha}</td>
                    <td>{item?.price_info?.price_per_katha}</td>
                    <td>{item?.price_info?.total_price}</td>
                    <td>{item?.price_info?.paid_amount}</td>
                    <td>
                      {item?.price_info?.total_price -
                        item?.price_info?.paid_amount}
                    </td>
                    <td>{item?.dolil_variation?.name}</td>
                    <td>
                      {item?.seller_info?.map((seller, i) => (
                        <span key={seller?.id}>{seller?.name}</span>
                      ))}
                    </td>
                    <td>
                      <div className="flex justify-center">
                        <Link
                          to={`/dashboard/project-land-purchase-details/view`}
                          state={{
                            item: item,
                          }}
                        >
                          <img className={commonAction} src={ViewImg} alt="" />
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <Pagination
          data={lists}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          setPageCount={setPageCount}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      </div>
    </>
  );
};

export default PurchasePowerOfAtornyList;
