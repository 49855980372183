import React from "react";

import { useLocation, useNavigate } from "react-router-dom";
import {
  commonFormMenu,
  dashboardBodyWraper,
  errorMessage,
} from "../../../../asset/commoncssConstant/CommonCssConstant";
import axios from "axios";
import { baseUrl } from "../../../../BaseUrl";
import { useForm } from "react-hook-form";
import BackButton from "../../../../shared/BackButton";

const EditLandBroker = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const landBroker = location.state.data;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const token = localStorage.getItem("token");

  //-------------Edit LandBroker Start----------------------//
  const onSubmit = (data) => {
    data.id = landBroker.id;
    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      if (value instanceof FileList) {
        formData.append(key, value.item(0));
      } else {
        formData.append(key, value);
      }
    });

    axios
      .post(baseUrl + `/landBroker/update`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          navigate(-1);
        }
      });
  };
  //-------------Edit LandBroker End----------------------//

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BackButton title={"Edit Land Broker"} />
      <div className={`${dashboardBodyWraper} py-[4px] px-2 `}>
        <div className={commonFormMenu}>
          <div className="common_input_wraper">
            <label>Join Date</label>
            <input
              {...register("joining_date", {
                required: "join date is required",
              })}
              name="joining_date"
              defaultValue={landBroker.joining_date}
              type="date"
            />
            {errors?.joining_date && (
              <p className={errorMessage}>{errors?.joining_date?.message}</p>
            )}
          </div>
          <div className="common_input_wraper">
            <label>Land Broker Name </label>
            <input
              {...register(`name`, {
                pattern: {
                  value: /^[A-Za-z]+[A-Za-z0-9\-_~(\s]*[A-Za-z0-9\s)]$/,
                  message: "Insert valid broker name",
                },
              })}
              name="name"
              defaultValue={landBroker.name}
              type="text"
              placeholder="Land Broker Name  "
            />
            {errors?.name && (
              <p className={errorMessage}>{errors?.name?.message}</p>
            )}
          </div>
          <div className="common_input_wraper">
            <label>Father’s Name</label>
            <input
              {...register(`father_name`, {
                pattern: {
                  value: /^[A-Za-z]+[A-Za-z0-9\-_~(\s]*[A-Za-z0-9\s)]$/,
                  message: "Insert valid Father name",
                },
              })}
              name="father_name"
              defaultValue={landBroker.father_name}
              type="text"
              placeholder="Father’s Name"
            />
            {errors?.father_name && (
              <p className={errorMessage}>{errors?.father_name?.message}</p>
            )}
          </div>
          <div className="common_input_wraper">
            <label>Mother’s Name</label>
            <input
              {...register(`mother_name`, {
                pattern: {
                  value: /^[A-Za-z]+[A-Za-z0-9\-_~(\s]*[A-Za-z0-9\s)]$/,
                  message: "Insert valid mother name",
                },
              })}
              name="mother_name"
              defaultValue={landBroker.mother_name}
              type="text"
              placeholder="Mother’s Name"
            />
            {errors?.mother_name && (
              <p className={errorMessage}>{errors?.mother_name?.message}</p>
            )}
          </div>
          <div className="common_input_wraper">
            <label>Email Address</label>
            <input
              {...register(`email`, {
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Insert valid email address",
                },
              })}
              name="email"
              defaultValue={landBroker.email}
              type="text"
              placeholder="Email Address"
            />
            {errors?.email && (
              <p className={errorMessage}>{errors?.email?.message}</p>
            )}
          </div>
          <div className="common_input_wraper">
            <label>National ID Number</label>
            <input
              {...register(`nid`, {
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Insert valid NID number",
                },
              })}
              name="nid"
              defaultValue={landBroker.nid}
              type="text"
              placeholder="National ID Number"
            />
            {errors?.nid && (
              <p className={errorMessage}>{errors?.nid?.message}</p>
            )}
          </div>
          <div className="common_input_wraper">
            <label>Date of Birth</label>
            <input
              {...register("dob", { required: "Date of Birth is required" })}
              name="dob"
              defaultValue={landBroker.dob}
              type="date"
              placeholder="File No."
            />
            {errors?.dob && (
              <p className={errorMessage}>{errors?.dob?.message}</p>
            )}
          </div>
          <div className="common_input_wraper">
            <label>Land Broker Mobile No. 01</label>
            <input
              {...register(`mobile_number_1`, {
                pattern: {
                  value: /^(0|\+?[1-9][0-9]*)$/,
                  message: "Insert country code before your number",
                },
              })}
              name="mobile_number_1"
              defaultValue={landBroker.mobile_number_1}
              type="text"
              placeholder="Land Broker Mobile No. 01"
            />
            {errors?.mobile_number_1 && (
              <p className={errorMessage}>{errors?.mobile_number_1?.message}</p>
            )}
          </div>
          <div className="common_input_wraper">
            <label>Land Broker Mobile No. 02</label>
            <input
              {...register(`mobile_number_2`, {
                pattern: {
                  value: /^(0|\+?[1-9][0-9]*)$/,
                  message: "Insert country code before your number",
                },
              })}
              name="mobile_number_2"
              defaultValue={landBroker.mobile_number_2}
              type="text"
              placeholder="Land Broker Mobile No. 02"
            />
            {errors?.mobile_number_2 && (
              <p className={errorMessage}>{errors?.mobile_number_2?.message}</p>
            )}
          </div>
          <div className="common_input_wraper">
            <label>Educational Background</label>
            <input
              {...register("educational_background", {
                required: "Educational Background required",
              })}
              name="educational_background"
              defaultValue={landBroker.educational_background}
              type="text"
              placeholder="Educational Background"
            />
            {errors?.educational_background && (
              <p className={errorMessage}>
                {errors?.educational_background?.message}
              </p>
            )}
          </div>
          <div className="col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-5"></div>
          <div className="common_input_wraper">
            <label>Present Address</label>
            <textarea
              {...register("present_address", {
                required: "Present Address required",
              })}
              name="present_address"
              defaultValue={landBroker.present_address}
              rows={3}
              placeholder="Present Address"
            />
            {errors?.present_address && (
              <p className={errorMessage}>{errors?.present_address?.message}</p>
            )}
          </div>
          <div className="common_input_wraper">
            <label>Permanent Address</label>
            <textarea
              {...register("permanent_address", {
                required: "Permanent Address required",
              })}
              name="permanent_address"
              defaultValue={landBroker.permanent_address}
              rows={3}
              placeholder="Permanent Address"
            />
            {errors?.permanent_address && (
              <p className={errorMessage}>
                {errors?.permanent_address?.message}
              </p>
            )}
          </div>
          <div className="col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-5"></div>
          <div className="common_input_wraper">
            <label>Land Broker’s Image (PP size 450x570)</label>
            <input
              {...register("image", { required: "Broker image is required" })}
              name="image"
              type="file"
            />
            {errors?.image && (
              <p className={errorMessage}>{errors?.image?.message}</p>
            )}
          </div>
          <div className="common_input_wraper">
            <label>Other file Upload (Multiple)</label>
            <input
              {...register("other_file", {
                required: "This filed is required",
              })}
              name="image"
              type="file"
              multiple
            />
            {errors?.other_file && (
              <p className={errorMessage}>{errors?.other_file?.message}</p>
            )}
          </div>
          {/* <div className="common_input_wraper">
            <label>Other file Upload (Multiple)</label>
            <label className="multiPleFileLable mt-2" htmlFor="multiplefile">
              Choose Files
              <img src={RightArrow} alt="" />
            </label>
            <input
              {...register("other_file")}
              name="other_file"
              style={{ display: "none" }}
              id="multiplefile"
              type="file"
              multiple
            />
          </div> */}
        </div>

        <div className="text-center py-10">
          <button type="submit" className="submit_button">
            Update
          </button>
        </div>
      </div>
    </form>
  );
};

export default EditLandBroker;
