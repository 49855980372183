import { Link } from "react-router-dom";
import AddUserImg from "../../../asset/images/clientlist/add-user.png";
import Pagination from "../../../shared/pagination/Pagination";
import CommonPrintPdfBtn from "../../../shared/CommonPrintPdfBtn";
import {
  addCustomerCss,
  commonAction,
  commonAddButton,
  commonCaption,
  commonSearchInput,
  commonTableHeadingTwo,
  commonTableRow,
  commonTitle,
  dashboardBodyWraper,
} from "../../../asset/commoncssConstant/CommonCssConstant";
import ActionBtn from "../../../asset/images/clientlist/action.png";
import ViewBtn from "../../../asset/images/clientlist/view.png";
import FolderImg from "../../../asset/images/clientlandinformation/back-folder.png";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl, baseUrlImg } from "../../../BaseUrl";
import { LuArrowDownUp } from "react-icons/lu";
import useCommonPDF from "../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../shared/useCommonPrint/useCommonPrint";

const RsDagAndKhatiyan = () => {
  const [rsDagKhatiyanList, setRsDagKhatiyanList] = useState([]);
  const [saDagKhatiyanInfoList, setSaDagKhatiyanInfoList] = useState([]);
  const token = localStorage.getItem("token");
  const [searchValue, setSearchValue] = useState("");
  const [paginationData, setPaginationData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const { toPDF, targetRef } = useCommonPDF({ title: "rs dag & khatiyan" });

  const handlePrint = useCommonPrint({
    targetRef,
    title: "rs dag & khatiyan",
    message: "thank you",
  });

  // pagination part start here
  const handleNext = () => {
    if (page === pageCount) {
      return page;
    } else {
      return setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page === 1) {
      return page;
    } else {
      return setPage(page - 1);
    }
  };

  //------get data for rsDagKhatiyanList and mouzaInfoList  start-----------------
  useEffect(() => {
    axios
      .get(baseUrl + `/landInformationBank/saDagKhatiyan/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setSaDagKhatiyanInfoList(res.data.data);
      });
    axios
      .get(baseUrl + `/landInformationBank/rsDagKhatiyan/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setRsDagKhatiyanList(res.data.data);
      });
  }, [token, page]);
  //------get data for rsDagKhatiyanList and mouzaInfoList end-----------------

  return (
    <div>
      <h1 className={`${commonTitle}`}>RS Dag & Khatiyan</h1>
      <div className={dashboardBodyWraper}>
        <div className={commonAddButton}>
          <Link
            to={`/dashboard/rs-dag-khatiyan/add`}
            state={{
              saDagKhatiyanInfoList: saDagKhatiyanInfoList,
            }}
          >
            <button className={addCustomerCss}>
              Add RS Dag & Khatiyan
              <img className="ml-[30px]" src={AddUserImg} alt="" />
            </button>
          </Link>
        </div>
        <div className={commonCaption}>
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} />
          <input
            onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
            type="text"
            placeholder="Search"
            className={commonSearchInput}
          />
        </div>

        <div ref={targetRef} className="table_responsive mt-[40px]">
          <table>
            <thead>
              <tr className={`${commonTableRow}`}>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> RS Create</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Project Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Mouza Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> RS Dag</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> RS Khatiyan</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Total RS Area</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> Total RS Use Area</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> RS Porca Scan Copy</span>{" "}
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span> RS Dag Map Scan Copy</span>{" "}
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>
                      {" "}
                      Action <br />
                      Update / View
                    </span>{" "}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {paginationData
                ?.filter((info) => {
                  return searchValue.toLowerCase() !== ""
                    ? info?.created_at
                        ?.slice(0, 10)
                        .toLowerCase()
                        .includes(searchValue) ||
                        info?.sa_info?.cs_info?.mouza_info?.project_info?.project_name
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.sa_info?.cs_info?.mouza_info?.mouza_name
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.rs_dag_no
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.rs_khatiyan_no
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.total_rs_area
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.total_rs_use_area
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue)
                    : info;
                })
                ?.map((item, i) => (
                  <tr key={item.id}>
                    <td className="text-center">
                      {item?.created_at?.slice(0, 10)}
                    </td>
                    <td className="text-center">
                      {
                        item?.sa_info?.cs_info?.mouza_info?.project_info
                          ?.project_name
                      }
                    </td>
                    <td className="text-center">
                      {item?.sa_info?.cs_info?.mouza_info?.mouza_name}
                    </td>
                    <td className="text-center">{item?.rs_dag_no}</td>
                    <td className="text-center">{item?.rs_khatiyan_no}</td>
                    <td className="text-center">{item?.total_rs_area}</td>
                    <td className="text-center">{item?.total_rs_use_area}</td>
                    <td className="text-center">
                      <img
                        className="w-11 rounded-lg mx-auto"
                        src={
                          item.rs_porca_scan_copy
                            ? baseUrlImg + item.rs_porca_scan_copy
                            : FolderImg
                        }
                        alt=""
                      />
                    </td>
                    <td className="text-center">
                      <img
                        className="w-11 rounded-lg mx-auto"
                        src={
                          item.rs_dag_map_scan_copy
                            ? baseUrlImg + item.rs_dag_map_scan_copy
                            : FolderImg
                        }
                        alt=""
                      />
                    </td>
                    <td className="text-center">
                      <span className="flex gap-2 justify-center">
                        <Link
                          to={`/dashboard/rs-dag-khatiyan/edit`}
                          state={{
                            rsDagKhatiyan: item,
                            saDagKhatiyanInfoList: saDagKhatiyanInfoList,
                          }}
                        >
                          <img
                            className={commonAction}
                            src={ActionBtn}
                            alt=""
                          />
                        </Link>
                        <Link
                          to={`/dashboard/rs-dag-khatiyan/view`}
                          state={{
                            rsDagKhatiyan: item,
                          }}
                        >
                          <img className={commonAction} src={ViewBtn} alt="" />
                        </Link>
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <Pagination
          data={rsDagKhatiyanList}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          setPageCount={setPageCount}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      </div>
    </div>
  );
};

export default RsDagAndKhatiyan;
