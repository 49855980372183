import { createBrowserRouter } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import Dashbaord from "../modules/Dashboard/Dashbaord";
import ClientView from "../modules/clientList/viewClient/ClientView";
import ClientList from "../modules/clientList/ClientList";
import AddClient from "../modules/clientList/addClient/AddClient";
import ClientPriceInformation from "../modules/clientpriceinformation/ClientPriceInformation";
import ClientPriceInformationView from "../modules/clientpriceinformation/ClientPriceInfoView/ClientPriceInformationView";
import AddClientPaymentDueList from "../modules/clientpaymentinformation/clientPaymentInformationDueList/addClientPaymentDuelist/AddClientPaymentDueList";
import MoneyReceiptFolderView from "../modules/clientpaymentinformation/moneyReceiptFolderView/MoneyReceiptFolderView";
import ClientLandDescription from "../modules/clientlanddescription/ClientLandDescription";
import AddClientLandDescription from "../modules/clientlanddescription/addClientLandDescription/AddClientLandDescription";
import ClientLandDescriptionView from "../modules/clientlanddescription/clientLandDescriptionView/ClientLandDescriptionView";
import EditClientLandDescription from "../modules/clientlanddescription/editClientLandDescription/EditClientLandDescription";
import RegistryCompleteLandInformation from "../modules/projectLandDetails/RegistryCompleteLandInformation/RegistryCompleteLandInformation";
import LandSellerInformation from "../modules/landSellerInformation/LandSellerInformation";
import LandSellerPaymentInformation from "../modules/landsellerpaymentInformation/LandSellerPaymentInformation";
import MouzaMapLandPurchasefromLandOwner from "../modules/MouzaMapLandPurchasefromLandOwner/MouzaMapLandPurchasefromLandOwner";
import MouzaMapLandSelltoCustomer from "../modules/MouzaMapLandSelltoCustomer/MouzaMapLandSelltoCustomer";
import AgentList from "../modules/agentInformation/AgentList/AgentList";
import AgentListView from "../modules/agentInformation/AgentList/agenntListView/AgentListView";
import EditAgent from "../modules/agentInformation/AgentList/editAgent/EditAgent";
import AddAgent from "../modules/agentInformation/AgentList/addAgent/AddAgent";
import AgentWorkList from "../modules/agentInformation/AgentWorkList/AgentWorkList";
import AgentWorkListView from "../modules/agentInformation/AgentWorkList/agentWorkiListView/AgentWorkListView";
import AgentPaymentList from "../modules/agentInformation/AgentPaymentList/AgentPaymentList";
import CreateAgentPayment from "../modules/agentInformation/AgentPaymentList/createAgentPayment/CreateAgentPayment";
import AddAgentPayment from "../modules/agentInformation/AgentPaymentList/addAgentPayment/AddAgentPayment";
import LandBrokerList from "../modules/landbrokerinformation/landbrokerlist/LandBrokerList";
import AddLandBroker from "../modules/landbrokerinformation/landbrokerlist/addlandbroker/AddLandBroker";
import EditLandBroker from "../modules/landbrokerinformation/landbrokerlist/editlandbroker/EditLandBroker";
import LandBrokerView from "../modules/landbrokerinformation/landbrokerlist/landbrokerview/LandBrokerView";
import LandBrokerWorkList from "../modules/landbrokerinformation/landBrokerWorkList/LandBrokerWorkList";
import ViewLandBrokerWork from "../modules/landbrokerinformation/landBrokerWorkList/ViewLandBrokerWork/ViewLandBrokerWork";
import LandBrokerPaymentList from "../modules/landbrokerinformation/landbrokerpaymentlist/LandBrokerPaymentList";
import LandBrokerCreatePayment from "../modules/landbrokerinformation/landbrokerpaymentlist/createPayment/LandBrokerCreatePayment";
import LandBrokerAddPayment from "../modules/landbrokerinformation/landbrokerpaymentlist/addPayment/LandBrokerAddPayment";
import LandSellerInformationView from "../modules/landSellerInformation/landsellerinformationview/LandSellerInformationView";
import LandSellerPaymentAdd from "../modules/landsellerpaymentInformation/landsellerpaymentadd/LandSellerPaymentAdd";
import LandSellerPaymentEdit from "../modules/landsellerpaymentInformation/landsellerpaymentedit/LandSellerPaymentEdit";
import LandSellerPaymentView from "../modules/landsellerpaymentInformation/landSellerPaymentview/LandSellerPaymentView";
import Login from "./Login";
import PrivateAuth from "./PrivateAuth";
import AllStateMent from "../modules/allstatement/AllStateMent";
import ProjectInformation from "../modules/landinformationbank/projectinformation/ProjectInformation";
import MouzaInformation from "../modules/landinformationbank/mouzainformation/MouzaInformation";
import CsdagAndKhatiyan from "../modules/landinformationbank/csdagandkhatiyan/CsdagAndKhatiyan";
import SadagAndKhatiyan from "../modules/landinformationbank/sadagandkhatiyan/SadagAndKhatiyan";
import RsDagAndKhatiyan from "../modules/landinformationbank/rsdagandkhatiyan/RsDagAndKhatiyan";
import BsDagAndKhatiyan from "../modules/landinformationbank/bsdagandkhatiyan/BsDagAndKhatiyan";
import AddProjectInformation from "../modules/landinformationbank/projectinformation/addprojectinformation/AddProjectInformation";
import EditProjectInformatiion from "../modules/landinformationbank/projectinformation/editprojectinformation/EditProjectInformatiion";
import AddMouzaInformation from "../modules/landinformationbank/mouzainformation/addmouzainformation/AddMouzaInformation";
import EditMouzaInformation from "../modules/landinformationbank/mouzainformation/editmouzainformation/EditMouzaInformation";
import AddCsDagAndKhaytan from "../modules/landinformationbank/csdagandkhatiyan/addcsdagandkhatiyan/AddCsDagAndKhatiyan";
import EditCsDagKhatiyan from "../modules/landinformationbank/csdagandkhatiyan/editcsdagkhatiyan/EditCsDagKhatiyan";
import AddSaDagAndKhaytan from "../modules/landinformationbank/sadagandkhatiyan/addsadagandkhatiyan/AddSaDagAndKhaytan";
import EditSaDagAndKhaytan from "../modules/landinformationbank/sadagandkhatiyan/editsadagandkhatiyan/EditSaDagAndKhaytan";
import AddRsDagAndKhatiYan from "../modules/landinformationbank/rsdagandkhatiyan/addrsdagandkhatiyan/AddRsDagAndKhatiYan";
import EditRsDagAndKhatiyan from "../modules/landinformationbank/rsdagandkhatiyan/editrsdagandkhatiyan/EditRsDagAndKhatiyan";
import AddBsDagAndKhatiyan from "../modules/landinformationbank/bsdagandkhatiyan/addbsdagandkhatiyan/AddBsDagAndKhatiyan";
import EditBsDagAndKhatiyan from "../modules/landinformationbank/bsdagandkhatiyan/editbsdagandkhatiyan/EditBsDagAndKhatiyan";
import AddLandDetails from "../modules/projectLandDetails/RegistryCompleteLandInformation/addCustomer/AddLandDetails";
import EditLandDetails from "../modules/projectLandDetails/RegistryCompleteLandInformation/editLandDetails/EditLandDetails";
import EditClient from "../modules/clientList/editClient/EditClient";
import CsMutationList from "../modules/landmutationbank/csmutationlist/CsMutationList";
import RsMutationList from "../modules/landmutationbank/rsmutationlist/RsMutationList";
import SaMutationList from "../modules/landmutationbank/samutationlist/SaMutationList";
import BsMutationList from "../modules/landmutationbank/bsmutationlist/BsMutationList";
import BsMutationView from "../modules/landmutationbank/bsmutationlist/bsmotionview/BsMutationView";
import SaMutationView from "../modules/landmutationbank/samutationlist/saMutationView/SaMutationView";
import RsMutationView from "../modules/landmutationbank/rsmutationlist/rsmutationview/RsMutationView";
import CsMutationView from "../modules/landmutationbank/csmutationlist/csMutationView/CsMutationView";
import AddCsMutationInfo from "../modules/landmutationbank/csmutationlist/addCsMutation/AddCsMutationInfo";
import AddBsMutationInfo from "../modules/landmutationbank/bsmutationlist/addbsmutationInfo/AddBsMutationInfo";
import AddRsMutationInfo from "../modules/landmutationbank/rsmutationlist/addRsMutationInfo/AddRsMutationInfo";
import AddSaMutationInfo from "../modules/landmutationbank/samutationlist/addSaMutationInfo/AddSaMutationInfo";
import EditCsMutation from "../modules/landmutationbank/csmutationlist/editcsmutation/EditCsMutation";
import EditSaMutation from "../modules/landmutationbank/samutationlist/editsamutation/EditSaMutation";
import EditRsMutation from "../modules/landmutationbank/rsmutationlist/editrsmutationlist/EditRsMutation";
import EditBsMutation from "../modules/landmutationbank/bsmutationlist/editBsMutation/EditBsMutation";
import CsSubDeedList from "../modules/landsubdeedbank/csSubDeedList/CsSubDeedList";
import SaSubDeedList from "../modules/landsubdeedbank/sasubdeedlist/SaSubDeedList";
import RsSubDeedList from "../modules/landsubdeedbank/rsSubDeedList/RsSubDeedList";
import BsSubDeedList from "../modules/landsubdeedbank/bsSubDeedList/BsSubDeedList";
import AddCsSubDeedList from "../modules/landsubdeedbank/csSubDeedList/cssubdeedlistAdd/AddCsSubDeedList";
import EditSubDeedList from "../modules/landsubdeedbank/csSubDeedList/EditsubDeedList/EditSubDeedList";
import AddSaSubDeedList from "../modules/landsubdeedbank/sasubdeedlist/addSaSubDeedList/AddSaSubDeedList";
import EditSaSubDeedList from "../modules/landsubdeedbank/sasubdeedlist/editSaSubDeedList/EditSaSubDeedList";
import AddRsSubDeedList from "../modules/landsubdeedbank/rsSubDeedList/addRsSubDeedList/AddRsSubDeedList";
import EditRsSubDeedList from "../modules/landsubdeedbank/rsSubDeedList/editRsSubDeedList/EditRsSubDeedList";
import AddBsSubDeedList from "../modules/landsubdeedbank/bsSubDeedList/AddBsSubDeedList/AddBsSubDeedList";
import EditBsSubDeedList from "../modules/landsubdeedbank/bsSubDeedList/editBsSubDeedList/EditBsSubDeedList";
import ViewCsDagAndKhatiyan from "../modules/landinformationbank/csdagandkhatiyan/viewCsDagAndKhatiyan/ViewCsDagAndKhatiyan";
import BsSubDeedView from "../modules/landsubdeedbank/bsSubDeedList/bsSubDeedView/BsSubDeedView";
import CsSubDeedView from "../modules/landsubdeedbank/csSubDeedList/csSubDeedView/CsSubDeedView";
import SaSubDeedView from "../modules/landsubdeedbank/sasubdeedlist/saSubDeedListView/SaSubDeedView";
import RsSubDeedListView from "../modules/landsubdeedbank/rsSubDeedList/rsSubDeedView/RsSubDeedListView";
import SaDagAndKHatiyanView from "../modules/landinformationbank/sadagandkhatiyan/sadagandkhatiyanview/SaDagAndKHatiyanView";
import RsDagAndKHatiyanView from "../modules/landinformationbank/rsdagandkhatiyan/rsDagAndKhatiyanview/RsDagAndKHatiyanView";
import ViewBsDagAndKhatiyan from "../modules/landinformationbank/bsdagandkhatiyan/ViewbsDagAndKhatiyan/ViewBsDagAndKhatiyan";
import CsDisputeList from "../modules/LandDisputeBank/CsDisputeList/CsDisputeList";
import SaDisputeList from "../modules/LandDisputeBank/SaDisputeList/SaDisputeList";
import RsDisputeList from "../modules/LandDisputeBank/RsDisputeList/RsDisputeList";
import BsDisputeList from "../modules/LandDisputeBank/BsDisputeList/BsDisputeList";
import AddCsDisputeList from "../modules/LandDisputeBank/CsDisputeList/addCsDisputeList/AddCsDisputeList";
import EditCsDisputeList from "../modules/LandDisputeBank/CsDisputeList/editCsDisputeList/EditCsDisputeList";
import AddSaDisputeList from "../modules/LandDisputeBank/SaDisputeList/addSaDisputeList/AddSaDisputeList";
import EditSaDisputeList from "../modules/LandDisputeBank/SaDisputeList/editSaDisputeList/EditSaDisputeList";
import AddRsDisputeList from "../modules/LandDisputeBank/RsDisputeList/addRsDisputeList/AddRsDisputeList";
import EditRsDisputeList from "../modules/LandDisputeBank/RsDisputeList/editRsDisputeList/EditRsDisputeList";
import AddBsDisputeList from "../modules/LandDisputeBank/BsDisputeList/addBsDisputeList/AddBsDisputeList";
import EditBsDisputeList from "../modules/LandDisputeBank/BsDisputeList/editBsDisputeList/EditBsDisputeList";
import ViewCsDisputeList from "../modules/LandDisputeBank/CsDisputeList/viewCsDisputeList/ViewCsDisputeList";
import ViewSaDisputeList from "../modules/LandDisputeBank/SaDisputeList/viewSaDisputeList/ViewSaDisputeList";
import ViewRsDisputeList from "../modules/LandDisputeBank/RsDisputeList/viewRsDisputeList/ViewRsDisputeList";
import ViewBsDisputeList from "../modules/LandDisputeBank/BsDisputeList/viewBsDisputeList/ViewBsDisputeList";
import CsMouzaMapList from "../modules/LandMouzaMapBank/CsMouzaMapList/CsMouzaMapList";
import SaMouzaMapList from "../modules/LandMouzaMapBank/SaMouzaMapList/SaMouzaMapList";
import RsMouzaMapList from "../modules/LandMouzaMapBank/RsMouzaMapList/RsMouzaMapList";
import BsMouzaMapList from "../modules/LandMouzaMapBank/BsMouzaMapList/BsMouzaMapList";
import AddCsMouzaMap from "../modules/LandMouzaMapBank/CsMouzaMapList/addCsMouzaMap/AddCsMouzaMap";
import EditCsMouzaMap from "../modules/LandMouzaMapBank/CsMouzaMapList/editCsMouzaMap/EditCsMouzaMap";
import AddSaMouzaMap from "../modules/LandMouzaMapBank/SaMouzaMapList/addSaMouzaMap/AddSaMouzaMap";
import EditSaMouzaMap from "../modules/LandMouzaMapBank/SaMouzaMapList/editSaMouzaMap/EditSaMouzaMap";
import AddRsMouzaMap from "../modules/LandMouzaMapBank/RsMouzaMapList/addRsMouzaMap/AddRsMouzaMap";
import EditRsMouzaMap from "../modules/LandMouzaMapBank/RsMouzaMapList/editRsMouzaMap/EditRsMouzaMap";
import AddBsMouzaMap from "../modules/LandMouzaMapBank/BsMouzaMapList/addBsMouzaMap/AddBsMouzaMap";
import EditBsMouzaMap from "../modules/LandMouzaMapBank/BsMouzaMapList/editBsMouzaMap/EditBsMouzaMap";
import BsDagMapImage from "../modules/LandMouzaMapBank/BsMouzaMapList/bsDagMapImage/BsDagMapImage";
import CsDagMouzaMapImage from "../modules/LandMouzaMapBank/CsMouzaMapList/csDagMouzaMapImage/CsDagMouzaMapImage";
import SaDagMouzaMapImage from "../modules/LandMouzaMapBank/SaMouzaMapList/SaDagMouzaMapImage/SaDagMouzaMapImage";
import RsDagMapImage from "../modules/LandMouzaMapBank/RsMouzaMapList/RsDagMapImage/RsDagMapImage";
import CsMouzaMapDescription from "../modules/LandMouzaMapBank/CsMouzaMapList/csMouzaMapDescription/CsMouzaMapDescription";
import SaMouzaMapDescription from "../modules/LandMouzaMapBank/SaMouzaMapList/saMouzaMapDescription/SaMouzaMapDescription";
import RsMouzaMapDescription from "../modules/LandMouzaMapBank/RsMouzaMapList/RsMouzaMapDescription/RsMouzaMapDescription";
import BsMouzaMapDescription from "../modules/LandMouzaMapBank/BsMouzaMapList/BsMouzaMapDescription/BsMouzaMapDescription";
import PlotList from "../modules/plotbank/plotList/PlotList";
import AddPlot from "../modules/plotbank/plotList/addPlot/AddPlot";
import EditPlot from "../modules/plotbank/plotList/editPlot/EditPlot";
import ViewPlot from "../modules/plotbank/plotList/viewPlot/ViewPlot";
import PlotALotListWithCustomer from "../modules/plotbank/plotalotlistwithcustomer/PlotALotListWithCustomer";
import ViewPlotALotListWithCustomer from "../modules/plotbank/plotalotlistwithcustomer/viewPlotALotListWithCustomer/ViewPlotALotListWithCustomer";
import RemainingPlotList from "../modules/plotbank/remainingplotlist/RemainingPlotList";
import ViewRemainingPlotList from "../modules/plotbank/remainingplotlist/viewRemainingPlotList/ViewRemainingPlotList";
import ClientRegistryCompletePlotList from "../modules/plotbank/clientRegistryCompletePlotList/ClientRegistryCompletePlotList";
import ClientRegistryCompletePlotListView from "../modules/plotbank/clientRegistryCompletePlotList/ClientRegistryCompletePlotListView/ClientRegistryCompletePlotListView";
import ClientMutationCompletePlotList from "../modules/plotbank/clientMutationcompletePlotList/ClientMutationCompletePlotList";
import ViewClientMutationCompletePlotList from "../modules/plotbank/clientMutationcompletePlotList/viewClientMutationCompletePlotList/ViewClientMutationCompletePlotList";
import ClientPlotPaymentCompleteList from "../modules/plotbank/clientPlotPaymentCompleteList/ClientPlotPaymentCompleteList";
import ViewClientPlotPaymentCompleteList from "../modules/plotbank/clientPlotPaymentCompleteList/ViewClientPlotPaymentCompleteList/ViewClientPlotPaymentCompleteList";
import ClientRegistryPaymentCompleteList from "../modules/plotbank/clientRegistryPaymentCompleteList/ClientRegistryPaymentCompleteList";
import ViewclientRegistryPaymentCompleteList from "../modules/plotbank/clientRegistryPaymentCompleteList/ViewclientRegistryPaymentCompleteList/ViewclientRegistryPaymentCompleteList";
import AddRegistryPaymentCompleteList from "../modules/plotbank/clientRegistryPaymentCompleteList/AddRegistryPaymentCompleteList/AddRegistryPaymentCompleteList";
import AddClientMutationCompletePlotList from "../modules/plotbank/clientMutationcompletePlotList/AddClientMutationCompletePlotList/AddClientMutationCompletePlotList";
import ClientPlotDetailsList from "../modules/clientPlotDetailsList/ClientPlotDetailsList";
import PurchaseAgreementCompleteList from "../modules/projectLandDetails/purchaseAgreementCompleteList/PurchaseAgreementCompleteList";
import PurchaseSubKoblaDeedCompleteList from "../modules/projectLandDetails/purchaseSubKobolaDeedCompleteList/PurchaseSubKoblaDeedCompleteList";
import PurchasePowerOfAtornyList from "../modules/projectLandDetails/purchasePowerOfAtornyList/PurchasePowerOfAtornyList";
import PurchaseRegisteredBaynaList from "../modules/projectLandDetails/purchaseRegistedBaynaList/PurchaseRegisteredBaynaList";
import PurchaseUnregistredBaynaList from "../modules/projectLandDetails/purchaseUnregisteredBaynaList/PurchaseUnregistredBaynaList";
import PurchasePowerOfEternyForCourt from "../modules/projectLandDetails/purchasePowerOfEternyForCourt/PurchasePowerOfEternyForCourt";
import PurchaseLandMap from "../modules/projectLandDetails/purchaseLandMap/PurchaseLandMap";
import SellerList from "../modules/landSellerInformation/sellerList/SellerList";
import AddSeller from "../modules/landSellerInformation/sellerList/addSellerList/AddSeller";
import SellerAgreementList from "../modules/landSellerInformation/sellerAgreementList/SellerAgreementList";
import SellerPaymentList from "../modules/landSellerInformation/sellerPaymentList/SellerPaymentList";
// import CurentOwenerSubDeedDetails from "../modules/landsubdeedbank/currentOwnerSubDeedDetails/CurentOwenerSubDeedDetails";
import ClientLandSellAgreementCompleteList from "../modules/clientLandSellDetails/ClientLandSellAgreementCompleteList/ClientLandSellAgreementCompleteList";
import AddClientLandSellAgreementCompleteList from "../modules/clientLandSellDetails/ClientLandSellAgreementCompleteList/AddClientLandSellAgreementCompleteList/AddClientLandSellAgreementCompleteList";
import EditClientLandSellAgreementCompleteList from "../modules/clientLandSellDetails/ClientLandSellAgreementCompleteList/EditClientLandSellAgreementCompleteList/EditClientLandSellAgreementCompleteList";
import ViewClientLandSellAgreementCompleteList from "../modules/clientLandSellDetails/ClientLandSellAgreementCompleteList/ViewClientLandSellAgreementCompleteList/ViewClientLandSellAgreementCompleteList";
import ClientLandSellSubKoboladeedCompleteList from "../modules/clientLandSellDetails/ClientLandSellSubKoboladeedCompleteList/ClientLandSellSubKobolaDeedCompleteList";
import ClientLandSellPowerOfEternityList from "../modules/clientLandSellDetails/ClientLandSellPowerOfEternityList/ClientLandSellPowerOfEternityList";
import ClientLandSellRegisteredBaynaList from "../modules/clientLandSellDetails/ClientLandSellRegisteredBaynaList/ClientLandSellRegisteredBaynaList";
import ClientLandSellUnregisteredBaynaList from "../modules/clientLandSellDetails/ClientLandSellUnregisteredBaynaList/ClientLandSellUnregisteredBaynaList";
import ClientLandSellPowerOfEternityForCourt from "../modules/clientLandSellDetails/ClientLandSellPowerOfEternityForCourt/ClientLandSellPowerOfEternityForCourt";
import SellerView from "../modules/landSellerInformation/sellerList/sellerView/SellerView";
import EditSeller from "../modules/landSellerInformation/sellerList/editSeller/EditSeller";
import ClientLandSellDetailsEdit from "../modules/clientLandSellDetails/clientLandSellDetailsEdit/ClientLandSellDetailsEdit";
import AddSellerAgreementList from "../modules/landSellerInformation/sellerAgreementList/AddSellerAgreementList/AddSellerAgreementList";
import EditSellerAgreementList from "../modules/landSellerInformation/sellerAgreementList/EditSellerAgreementList/EditSellerAgreementlist";
import SellerAgreementListView from "../modules/landSellerInformation/sellerAgreementList/sellerAgreementListView/SellerAgreementListView";
import AddSellerPaymentList from "../modules/landSellerInformation/sellerPaymentList/AddSellerPaymentList/AddSellerPaymentList";
import EditSellerPaymentList from "../modules/landSellerInformation/sellerPaymentList/editSellerPaymentList/EditSellerPaymentList";
import ViewSellerPaymentList from "../modules/landSellerInformation/sellerPaymentList/viewSellerPaymentList/ViewSellerPaymentList";
import ViewSellerPaymentPDF from "../modules/landSellerInformation/sellerPaymentList/pdf/ViewSellerPaymentPDF";
import AddClientPriceInfo from "../modules/clientpriceinformation/addClientPriceInfo/AddClientPriceInfo";
import UpdateClientPriceInfo from "../modules/clientpriceinformation/updateClientPriceInfo/UpdateClientPriceInfo";
import ClientPaymentAndDueList from "../modules/clientpaymentinformation/clientPaymentInformationDueList/ClientPaymentInAndDueList";
import EditClientPaymentDueList from "../modules/clientpaymentinformation/clientPaymentInformationDueList/editClientPaymentDueList/EditClientPaymentDueList";
import ViewClientPaymentAndDueList from "../modules/clientpaymentinformation/clientPaymentInformationDueList/clientPaymenAndDueListView/ViewClientPaymentAndDueList";
import ViewClientPaymentPDF from "../modules/clientpaymentinformation/clientPaymentInformationDueList/pdf/ViewClientPaymentPDF";
import ViewClientReceiptPDF from "../modules/clientpaymentinformation/clientPaymentInformationDueList/pdf/ViewClientReceiptPDF";
import ViewClientLandSellDetails from "../modules/clientLandSellDetails/ViewclientLandSellDetails/ViewClientLandSellDetails";
import ViewAgentPaymentList from "../modules/agentInformation/AgentPaymentList/viewAgentPaymentList/ViewAgentPaymentList";
import AddMouzaMapLandPurchaseFromLandOwner from "../modules/MouzaMapLandPurchasefromLandOwner/addMouzaMapLandPurchaseFromLandOwner/AddMouzaMapLandPurchaseFromLandOwner";
import EditMouzaMapLandPurchaseFromLandOwner from "../modules/MouzaMapLandPurchasefromLandOwner/EditMouzaMapLandPurchaseFromLandOwner/EditMouzaMapLandPurchaseFromLandOwner";
import MapFolderMouzaMapLandPurchaseFromLandOwner from "../modules/MouzaMapLandPurchasefromLandOwner/MapFolderMouzaMapLandPurchaseFromLandOwner/MapFolderMouzaMapLandPurchaseFromLandOwner";
import AddMouzaMapLandSelltoCustomer from "../modules/MouzaMapLandSelltoCustomer/AddMouzaMapLandSelltoCustomer/AddMouzaMapLandSelltoCustomer";
import EditMouzaMapLandSelltoCustomer from "../modules/MouzaMapLandSelltoCustomer/EditMouzaMapLandSelltoCustomer/EditMouzaMapLandSelltoCustomer";
import MapFolderMouzaMapLandSellToCustomer from "../modules/MouzaMapLandSelltoCustomer/MapFolderMouzaMapLandSelltoCustomer/MapFolderMouzaMapLandSellToCustomer";
import EditAgentPayment from "../modules/agentInformation/AgentPaymentList/editAgentPayment/EditAgentPayment";
import ViewAgentPaymentPDf from "../modules/agentInformation/AgentPaymentList/paymentInfoPdf/ViewAgentPaymentPDf";
import ProjectLandPurchaseDetailsView from './../modules/projectLandDetails/projectLandPurchaseDetailsView/projectLandPurchaseDetailsView';
import ViewLandBrokerPayment from "../modules/landbrokerinformation/landbrokerpaymentlist/viewLandBrokerPayment/ViewLandBrokerPayment";
import EditLandBrokerPayment from "../modules/landbrokerinformation/landbrokerpaymentlist/editLandBrokerPayment/EditLandBrokerPayment";
import ViewLandBrokerPaymentPdf from "../modules/landbrokerinformation/landbrokerpaymentlist/pdfLandBrokerPayment/ViewLandBrokerPaymentPdf";
import CreateRole from "../modules/RolePermission/CreateRole/CreateRole";
import UserList from "../modules/RolePermission/UserList/UserList";
import ProjectAdminList from "../modules/RolePermission/ProjectAdminList/ProjectAdminList";
import CreateUser from "../modules/RolePermission/UserList/CreateUser/CreateUser";
import EditUser from "../modules/RolePermission/UserList/EditeUser/EditUser";
import UserInformation from "../modules/RolePermission/UserList/UserInformation/UserInformation";


const routesList = createBrowserRouter([
  {
    path: "/",
    element: (
      <PrivateAuth>
        <MainLayout />
      </PrivateAuth>
    ),
    children: [
      //dashboard start
      {
        element: <Dashbaord />,
        index: true,
      },
      //dashboard end
      //client information start
      {
        element: <ClientList />,
        path: "dashboard/client-list",
      },
      {
        element: <AddClient />,
        path: "dashboard/client-list/add-client",
      },
      {
        element: <EditClient />,
        path: "dashboard/client-list/edit",
      },
      {
        element: <ClientView />,
        path: "dashboard/client-list/view",
      },
      {
        element: <ClientPlotDetailsList />,
        path: "/dashboard/client-plot-list",
      },
      //client information end
      //client price information start
      {
        path: "dashboard/client-price-information",
        element: <ClientPriceInformation />,
      },
      {
        path: "dashboard/client-price-information/add",
        element: <AddClientPriceInfo />,
      },
      {
        path: "dashboard/client-price-information/update",
        element: <UpdateClientPriceInfo />,
      },
      {
        path: "dashboard/client-price-information/view",
        element: <ClientPriceInformationView />,
      },
      //client price information end

      //Client Payment Information Start (4.0)
      // Client Payment And Due List start
      {
        path: "/dashboard/client-payment-and-due-list",
        element: <ClientPaymentAndDueList />,
      },
      {
        path: "dashboard/client-payment-and-due-list/add",
        element: <AddClientPaymentDueList />,
      },
      {
        path: "/dashboard/client-payment-and-due-list/edit",
        element: <EditClientPaymentDueList />,
      },
      {
        path: "/dashboard/client-payment-and-due-list/view/:Id",
        element: <ViewClientPaymentAndDueList />,
      },
      {
        path: "/dashboard/client-payment-and-due-list/payment-info-pdf",
        element: <ViewClientPaymentPDF />,
      },
      {
        path: "/dashboard/client-payment-and-due-list/money-receipt-pdf",
        element: <ViewClientReceiptPDF />,
      },
      {
        path: "/dashboard/client-payment-and-due-list/money-receipt-folder",
        element: <MoneyReceiptFolderView />,
      },
      // Client Payment And Due List end
      //Client Payment Information end (4.0)

      //client land sell details start(5)
      // common
      {
        path: "dashboard/client-land-sell-details/view",
        element: <ViewClientLandSellDetails />,
      },
      {
        path: "dashboard/client-land-sell-details/edit",
        element: <ClientLandSellDetailsEdit />,
      },
      // common
      {
        element: <ClientLandSellAgreementCompleteList />,
        path: "/dashboard/client-land-sell-agreement-complete-list",
      },
      {
        element: <AddClientLandSellAgreementCompleteList />,
        path: "/dashboard/client-land-sell-agreement-complete-list/add",
      },
      {
        element: <EditClientLandSellAgreementCompleteList />,
        path: "/dashboard/client-land-sell-agreement-complete-list/edit",
      },
      {
        element: <ViewClientLandSellAgreementCompleteList />,
        path: "/dashboard/client-land-sell-agreement-complete-list/view",
      },
      {
        element: <ClientLandSellSubKoboladeedCompleteList />,
        path: "/dashboard/Client-LandSell-SubKobola-deed-CompleteList",
      },
      {
        element: <ClientLandSellPowerOfEternityList />,
        path: "/dashboard/client-land-sell-power-of-eterny-list",
      },
      {
        element: <ClientLandSellRegisteredBaynaList />,
        path: "/dashboard/client-land-sell-registered-baynalist",
      },
      {
        element: <ClientLandSellUnregisteredBaynaList />,
        path: "/dashboard/client-land-sell-unregistered-baynalist",
      },
      {
        element: <ClientLandSellPowerOfEternityForCourt />,
        path: "/dashboard/client-land-sell-powerofeternity-for-court",
      },
      //client land sell details end(5)

      //  project land purchase details start (6)
      {
        path: "/dashboard/purchase-agreement-complete-list",
        element: <PurchaseAgreementCompleteList />,
      },
      {
        path: "/dashboard/project-land-purchase-details/view",
        element: <ProjectLandPurchaseDetailsView />,
      },
      {
        path: "/dashboard/purchase-sub-kobola-deed-complete-list",
        element: <PurchaseSubKoblaDeedCompleteList />,
      },
      {
        path: "/dashboard/purchase-power-of-eternity-list",
        element: <PurchasePowerOfAtornyList />,
      },
      {
        path: "/dashboard/purchase-register-bayna-list",
        element: <PurchaseRegisteredBaynaList />,
      },
      {
        path: "/dashboard/purchase-unregister-bayna-list",
        element: <PurchaseUnregistredBaynaList />,
      },
      {
        path: "/dashboard/purchase-powerof-eterny-forcourt",
        element: <PurchasePowerOfEternyForCourt />,
      },
     /*  {
        path: "/dashboard/purchase-land-map",
        element: <PurchaseLandMap />,
      }, */
      //  project land purchase details end   (6)

      //  Land Seller Information start    (7)
      {
        path: "dashboard/seller-list",
        element: <SellerList />,
      },
      {
        path: "dashboard/seller-list/add",
        element: <AddSeller />,
      },
      {
        path: "dashboard/seller-list/edit",
        element: <EditSeller />,
      },
      {
        path: "dashboard/seller-list/view",
        element: <SellerView />,
      },
      {
        path: "/dashboard/sellerAgreement",
        element: <SellerAgreementList />,
      },
      {
        path: "/dashboard/sellerAgreement/add",
        element: <AddSellerAgreementList />,
      },
      {
        path: "/dashboard/sellerAgreement/edit",
        element: <EditSellerAgreementList />,
      },
      {
        path: "/dashboard/sellerAgreement/view",
        element: <SellerAgreementListView />,
      },
      {
        path: "/dashboard/seller-payment",
        element: <SellerPaymentList />,
      },
      {
        path: "/dashboard/seller-payment/add",
        element: <AddSellerPaymentList />,
      },
      {
        path: "/dashboard/seller-payment/edit",
        element: <EditSellerPaymentList />,
      },
      {
        path: "/dashboard/seller-payment/view/:Id",
        element: <ViewSellerPaymentList />,
      },
      {
        path: "/dashboard/seller-payment/view/pdf",
        element: <ViewSellerPaymentPDF />,
      },
      //  Land Seller Information end      (7)
      //  Mouza map land purchase from land owner start      (8)
      /* {
        path: "dashboard/mouzamap-landpurchase-fromlandowner",
        element: <MouzaMapLandPurchasefromLandOwner />,
      },
      {
        path: "/dashboard/mouzamap-landpurchase-fromlandowner/add",
        element: <AddMouzaMapLandPurchaseFromLandOwner />,
      },
      {
        path: "/dashboard/mouzamap-landpurchase-fromlandowner/edit",
        element: <EditMouzaMapLandPurchaseFromLandOwner />,
      },
      {
        path: "/dashboard/mouzamap-landpurchase-fromlandowner/map-folder",
        element: <MapFolderMouzaMapLandPurchaseFromLandOwner />,
      }, */

      //  Mouza map land purchase from land owner end       (8)
      //  Mouza map (land sell to customer) start        (9)
      /* {
        path: "dashboard/mouzamap-landsell-tocustomer",
        element: <MouzaMapLandSelltoCustomer />,
      },
      {
        path: "/dashboard/mouzamap-landsell-tocustomer/add",
        element: <AddMouzaMapLandSelltoCustomer />,
      },
      {
        path: "/dashboard/mouzamap-landsell-tocustomer/edit",
        element: <EditMouzaMapLandSelltoCustomer />,
      },
      {
        path: "/dashboard/mouzamap-landsell-tocustomer/map-folder",
        element: <MapFolderMouzaMapLandSellToCustomer />,
      }, */
      //  Mouza map (land sell to customer) end          (9)
      //  Agent Information start           (10)
      {
        path: "/dashboard/agent-list",
        element: <AgentList />,
      },
      {
        path: "/dashboard/agent-list/add",
        element: <AddAgent />,
      },
      {
        path: "/dashboard/agent-list/view",
        element: <AgentListView />,
      },
      {
        path: "dashboard/agent-list/edit",
        element: <EditAgent />,
      },
      {
        path: "/dashboard/agent-work-list",
        element: <AgentWorkList />,
      },
      {
        path: "/dashboard/agent-work-list/view",
        element: <AgentWorkListView />,
      },
      {
        path: "/dashboard/agent-payment-list",
        element: <AgentPaymentList />,
      },
      {
        path: "/dashboard/agent-payment-list/create",
        element: <CreateAgentPayment />,
      },
      {
        path: "/dashboard/agent-payment-list/add",
        element: <AddAgentPayment />,
      },
      {
        path: "/dashboard/agent-payment-list/view/:Id",
        element: <ViewAgentPaymentList />,
      },
      {
        path: "/dashboard/agent-payment-list/edit",
        element: <EditAgentPayment />,
      },
      {
        path: "/dashboard/agent-payment-list/payment-info-pdf",
        element: <ViewAgentPaymentPDf />
      },
      //  Agent Information end             (10)
      //  Land Broker Information start     (11)
      {
        path: "/dashboard/land-broker-list",
        element: <LandBrokerList />,
      },
      {
        path: "/dashboard/land-broker-list/add",
        element: <AddLandBroker />,
      },
      {
        path: "dashboard/land-broker-list/edit",
        element: <EditLandBroker />,
      },
      {
        path: "dashboard/land-broker-list/view",
        element: <LandBrokerView />,
      },
      {
        path: "/dashboard/land-broker-work-list",
        element: <LandBrokerWorkList />,
      },
      {
        path: "/dashboard/land-broker-work-list/view/:id",
        element: <ViewLandBrokerWork />,
      },
      {
        path: "/dashboard/land-broker-payment-list",
        element: <LandBrokerPaymentList />,
      },
      {
        path: "/dashboard/land-broker-payment-list/create-payment",
        element: <LandBrokerCreatePayment />,
      },
      {
        path: "/dashboard/land-broker-payment-list/add-payment",
        element: <LandBrokerAddPayment />,
      },
      {
        path: "/dashboard/land-broker-payment-list/view/:Id",
        element: <ViewLandBrokerPayment />,
      },
      {
        path: "/dashboard/land-broker-payment-list/edit",
        element: <EditLandBrokerPayment />,
      },
      {
        path: "/dashboard/land-broker-payment-list/payment-info-pdf",
        element: <ViewLandBrokerPaymentPdf/>
      },
      //  Land Broker Information end       (11)
      //  All StateMent Start         (12)
      // {
      //   element: <AllStateMent />,
      //   path: "/dashboard/all-statement",
      // },
      //  All StateMent End           (12)
      //  Land Information Bank Start           (13)
      {
        element: <ProjectInformation />,
        path: "/dashboard/project-info",
      },
      {
        element: <AddProjectInformation />,
        path: "/dashboard/project-info/add",
      },
      {
        element: <EditProjectInformatiion />,
        path: "/dashboard/project-info/edit",
      },
      {
        element: <MouzaInformation />,
        path: "/dashboard/mouza-info",
      },
      {
        element: <AddMouzaInformation />,
        path: "/dashboard/mouza-info/add",
      },
      {
        element: <EditMouzaInformation />,
        path: "/dashboard/mouza-info/edit",
      },
      // cs dag and khatiyan
      {
        element: <CsdagAndKhatiyan />,
        path: "/dashboard/cs-dag-khatiyan",
      },
      {
        element: <ViewCsDagAndKhatiyan />,
        path: "/dashboard/cs-dag-khatiyan/view",
      },
      {
        element: <AddCsDagAndKhaytan />,
        path: "/dashboard/cs-dag-khatiyan/add",
      },
      {
        element: <EditCsDagKhatiyan />,
        path: "/dashboard/cs-dag-khatiyan/edit",
      },
      // sa dag and khatiyan
      {
        element: <SadagAndKhatiyan />,
        path: "/dashboard/sa-dag-khatiyan",
      },
      {
        element: <SaDagAndKHatiyanView />,
        path: "/dashboard/sa-dag-khatiyan/view",
      },
      {
        element: <AddSaDagAndKhaytan />,
        path: "/dashboard/sa-dag-khatiyan/add",
      },
      {
        element: <EditSaDagAndKhaytan />,
        path: "/dashboard/sa-dag-khatiyan/edit",
      },
      // rs dag and khatiyan
      {
        element: <RsDagAndKhatiyan />,
        path: "/dashboard/rs-dag-khatiyan",
      },
      {
        element: <AddRsDagAndKhatiYan />,
        path: "/dashboard/rs-dag-khatiyan/add",
      },
      {
        element: <RsDagAndKHatiyanView />,
        path: "/dashboard/rs-dag-khatiyan/view",
      },
      {
        element: <EditRsDagAndKhatiyan />,
        path: "/dashboard/rs-dag-khatiyan/edit",
      },
      // bs dag and khatiyan
      {
        element: <BsDagAndKhatiyan />,
        path: "/dashboard/bs-dag-khatiyan",
      },
      {
        element: <AddBsDagAndKhatiyan />,
        path: "/dashboard/bs-dag-khatiyan/add",
      },
      {
        element: <ViewBsDagAndKhatiyan />,
        path: "/dashboard/bs-dag-khatiyan/view",
      },
      {
        element: <EditBsDagAndKhatiyan />,
        path: "/dashboard/bs-dag-khatiyan/edit",
      },
      //  Land Information Bank End             (13)
      //  Land Mutation Bank    Start             (14)
      // cs mutation
      {
        element: <CsMutationList />,
        path: "/dashboard/cs-mutation-list",
      },
      {
        element: <CsMutationView />,
        path: "/dashboard/cs-mutation-list/view",
      },
      {
        element: <EditCsMutation />,
        path: "/dashboard/cs-mutation-list/edit",
      },
      {
        element: <AddCsMutationInfo />,
        path: "/dashboard/cs-mutation-list/add",
      },
      // Sa mutaion
      {
        element: <SaMutationList />,
        path: "/dashboard/sa-mutation-list",
      },
      {
        element: <SaMutationView />,
        path: "/dashboard/sa-mutation-list/view",
      },
      {
        element: <EditSaMutation />,
        path: "/dashboard/sa-mutation-list/edit",
      },
      {
        element: <AddSaMutationInfo />,
        path: "/dashboard/sa-mutation-list/add",
      },
      // Rs mutation
      {
        element: <RsMutationList />,
        path: "/dashboard/rs-mutation-list",
      },
      {
        element: <RsMutationView />,
        path: "/dashboard/rs-mutation-list/view",
      },
      {
        element: <AddRsMutationInfo />,
        path: "/dashboard/rs-mutation-list/add",
      },
      {
        element: <EditRsMutation />,
        path: "/dashboard/rs-mutation-list/edit",
      },
      // Bs Mutation
      {
        element: <BsMutationList />,
        path: "/dashboard/bs-mutation-list",
      },
      {
        element: <BsMutationView />,
        path: "/dashboard/bs-mutation-list/view",
      },
      {
        element: <AddBsMutationInfo />,
        path: "/dashboard/bs-mutation-list/add",
      },
      {
        element: <EditBsMutation />,
        path: "/dashboard/bs-mutation-list/edit",
      },
      //  Land Mutation Bank    End               (14)
      //  Land Sub Deed  Bank   Start               (15)
      // cs deed list
      {
        element: <CsSubDeedList />,
        path: "/dashboard/cs-subdeed-list",
      },
      {
        element: <CsSubDeedView />,
        path: "/dashboard/cs-subdeed-list/view",
      },
      {
        element: <AddCsSubDeedList />,
        path: "/dashboard/cs-subdeed-list/add",
      },
      {
        element: <EditSubDeedList />,
        path: "/dashboard/cs-subdeed-list/edit",
      },
      // sa deed list
      {
        element: <SaSubDeedList />,
        path: "/dashboard/sa-subdeed-list",
      },
      {
        element: <SaSubDeedView />,
        path: "/dashboard/sa-subdeed-list/view",
      },
      {
        element: <AddSaSubDeedList />,
        path: "/dashboard/sa-subdeed-list/add",
      },
      {
        element: <EditSaSubDeedList />,
        path: "/dashboard/sa-subdeed-list/edit",
      },
      // rs sub deed
      {
        element: <RsSubDeedList />,
        path: "/dashboard/rs-subdeed-list",
      },
      {
        element: <RsSubDeedListView />,
        path: "/dashboard/rs-subdeed-list/view",
      },
      {
        element: <AddRsSubDeedList />,
        path: "/dashboard/rs-subdeed-list/add",
      },
      {
        element: <EditRsSubDeedList />,
        path: "/dashboard/rs-subdeed-list/edit",
      },
      // bs sub deed list
      {
        element: <BsSubDeedList />,
        path: "/dashboard/bs-subdeed-list",
      },
      {
        element: <BsSubDeedView />,
        path: "/dashboard/bs-subdeed-list/view",
      },
      {
        element: <AddBsSubDeedList />,
        path: "/dashboard/bs-subdeed-list/add",
      },
      {
        element: <EditBsSubDeedList />,
        path: "/dashboard/bs-subdeed-list/edit",
      },
      // Current sub deed
      // {
      //   element: <CurentOwenerSubDeedDetails />,
      //   path: "/dashboard/current-owner-subdeed-details",
      // },
      //  Land Sub Deed  Bank   End               (15)
      //  Land Dispute  Bank   Start                (16)

      // cs dispute list
      {
        element: <CsDisputeList />,
        path: "/dashboard/cs-dispute-list",
      },
      {
        element: <ViewCsDisputeList />,
        path: "/dashboard/cs-dispute-list/view",
      },
      {
        element: <AddCsDisputeList />,
        path: "/dashboard/cs-dispute-list/add",
      },
      {
        element: <EditCsDisputeList />,
        path: "/dashboard/cs-dispute-list/edit",
      },
      // Sa dispute list
      {
        element: <SaDisputeList />,
        path: "/dashboard/sa-dispute-list",
      },
      {
        element: <ViewSaDisputeList />,
        path: "/dashboard/sa-dispute-list/view",
      },
      {
        element: <AddSaDisputeList />,
        path: "/dashboard/sa-dispute-list/add",
      },
      {
        element: <EditSaDisputeList />,
        path: "/dashboard/sa-dispute-list/edit",
      },
      // Rs dispute list
      {
        element: <RsDisputeList />,
        path: "/dashboard/rs-dispute-list",
      },
      {
        element: <ViewRsDisputeList />,
        path: "/dashboard/rs-dispute-list/view",
      },
      {
        element: <AddRsDisputeList />,
        path: "/dashboard/rs-dispute-list/add",
      },
      {
        element: <EditRsDisputeList />,
        path: "/dashboard/rs-dispute-list/edit",
      },
      // Bs Dispute list
      {
        element: <BsDisputeList />,
        path: "/dashboard/bs-dispute-list",
      },
      {
        element: <ViewBsDisputeList />,
        path: "/dashboard/bs-dispute-list/view",
      },
      {
        element: <AddBsDisputeList />,
        path: "/dashboard/bs-dispute-list/add",
      },
      {
        element: <EditBsDisputeList />,
        path: "/dashboard/bs-dispute-list/edit",
      },

      //  Land Dispute  Bank   End                  (16)
      //  Land Mouza Map  Bank  Start                  (17)
      // Cs mouza map bank
      {
        element: <CsMouzaMapList />,
        path: "/dashbooard/cs-mouza-map-list",
      },
      {
        element: <CsMouzaMapDescription />,
        path: "/dashbooard/cs-mouza-map-list/description",
      },
      {
        element: <AddCsMouzaMap />,
        path: "/dashbooard/cs-mouza-map-list/add",
      },
      {
        element: <EditCsMouzaMap />,
        path: "/dashbooard/cs-mouza-map-list/edit",
      },
      {
        element: <CsDagMouzaMapImage />,
        path: "/dashbooard/cs-mouza-map-list/cs-dag-mouza-map",
      },
      // Sa Mouza Map
      {
        element: <SaMouzaMapList />,
        path: "/dashbooard/sa-mouza-map-list",
      },
      {
        element: <SaMouzaMapDescription />,
        path: "/dashbooard/sa-mouza-map-list/description",
      },
      {
        element: <SaDagMouzaMapImage />,
        path: "/dashbooard/sa-mouza-map-list/sa-dag-mouza-map-image",
      },
      {
        element: <AddSaMouzaMap />,
        path: "/dashbooard/sa-mouza-map-list/add",
      },
      {
        element: <EditSaMouzaMap />,
        path: "/dashbooard/sa-mouza-map-list/edit",
      },

      // Rs Mouza Map
      {
        element: <RsMouzaMapList />,
        path: "/dashbooard/rs-mouza-map-list",
      },
      {
        element: <RsMouzaMapDescription />,
        path: "/dashbooard/rs-mouza-map-list/description",
      },
      {
        element: <RsDagMapImage />,
        path: "/dashbooard/rs-mouza-map-list/rs-dag-map-image",
      },
      {
        element: <AddRsMouzaMap />,
        path: "/dashbooard/rs-mouza-map-list/add",
      },
      {
        element: <EditRsMouzaMap />,
        path: "/dashbooard/rs-mouza-map-list/edit",
      },

      // Bs Mouza Map
      {
        element: <BsMouzaMapList />,
        path: "/dashbooard/bs-mouza-map-list",
      },
      {
        element: <BsMouzaMapDescription />,
        path: "/dashbooard/bs-mouza-map-list/description",
      },
      {
        element: <AddBsMouzaMap />,
        path: "/dashbooard/bs-mouza-map-list/add",
      },
      {
        element: <EditBsMouzaMap />,
        path: "/dashbooard/bs-mouza-map-list/edit",
      },
      {
        element: <BsDagMapImage />,
        path: "/dashbooard/bs-mouza-map-list/bs-dag-map-image",
      },

      //  Land Mouza Map  Bank  End                    (17)

      //  Land Plot  Bank  Start                    (18)
      {
        element: <PlotList />,
        path: "/dashboard/plotlist",
      },
      {
        element: <AddPlot />,
        path: "/dashboard/plotlist/add",
      },
      {
        element: <EditPlot />,
        path: "/dashboard/plotlist/edit",
      },
      {
        element: <ViewPlot />,
        path: "/dashboard/plotlist/view",
      },
      // plot a lot list with customer
      {
        element: <PlotALotListWithCustomer />,
        path: "/dashboard/plot-a-lot-list-with-customer",
      },
      {
        element: <ViewPlotALotListWithCustomer />,
        path: "/dashboard/plot-a-lot-list-with-customer/view",
      },
      // remaining plot list
      {
        element: <RemainingPlotList />,
        path: "/dashboard/remaining-plot-list",
      },
      {
        element: <ViewRemainingPlotList />,
        path: "/dashboard/remaining-plot-list/view",
      },
      // client registry complete plot list
      {
        element: <ClientRegistryCompletePlotList />,
        path: "/dashboard/client-registry-complete-plot",
      },
      {
        element: <ClientRegistryCompletePlotListView />,
        path: "/dashboard/client-registry-complete-plot/view",
      },

      // client mutation complete list
      {
        element: <ClientMutationCompletePlotList />,
        path: "/dashboard/client-mutation-complete-plot-list",
      },
      {
        element: <AddClientMutationCompletePlotList />,
        path: "/dashboard/client-mutation-complete-plot-list/add",
      },
      {
        element: <ViewClientMutationCompletePlotList />,
        path: "/dashboard/client-mutation-complete-plot-list/view",
      },
      // client plot payment complete list
      {
        element: <ClientPlotPaymentCompleteList />,
        path: "/dashboard/client-plot-payment-complete-list",
      },
      {
        element: <ViewClientPlotPaymentCompleteList />,
        path: "/dashboard/client-plot-payment-complete-list/view",
      },
      // client registry  payment complete list

     /*  {
        element: <ClientRegistryPaymentCompleteList />,
        path: "/dashboard/client-registry-payment-complete-list",
      },
      {
        element: <ViewclientRegistryPaymentCompleteList />,
        path: "/dashboard/client-registry-payment-complete-list/view",
      },
      {
        element: <AddRegistryPaymentCompleteList />,
        path: "/dashboard/client-registry-payment-complete-list/add",
      }, */
      //  Land Plot  Bank  End                      (18)
      // {
      //   path: "dashboard/client-price-information/payment-shedule/add",
      //   element: <AddPaymentShedule />,
      // },

      // Role Permission Start
      {
        path: "/dashboard/userList",
        element: <UserList />
      },
      {
        path: "/dashboard/userList/createUser",
        element: <CreateUser />
      },
      {
        path: "/dashboard/userList/editUser",
        element: <EditUser />
      },
      {
        path: "/dashboard/userList/userInformation",
        element: <UserInformation />
      },
      {
        path: "/dashboard/projectAdminList",
        element: <ProjectAdminList />
      },
      {
        path: "/dashboard/createRole",
        element: <CreateRole />
      },

      // Role Permission End

      {
        path: "dashboard/client-land-description",
        element: <ClientLandDescription />,
      },
      {
        path: "dashboard/client-land-description/add/:id",
        element: <AddClientLandDescription />,
      },
      {
        path: "dashboard/client-land-description/view/:id",
        element: <ClientLandDescriptionView />,
      },
      {
        path: "dashboard/client-land-description/edit/:id",
        element: <EditClientLandDescription />,
      },
      {
        path: "dashboard/registry-complete-land-information",
        element: <RegistryCompleteLandInformation />,
      },
      {
        path: "dashboard/registry-complete-land-information/add-customer",
        element: <AddLandDetails />,
      },
      {
        path: "dashboard/registry-complete-land-information/edit/:id",
        element: <EditLandDetails />,
      },
      {
        path: "dashboard/land-seller-information",
        element: <LandSellerInformation />,
      },
      {
        path: "dashboard/land-seller-information/view/:id",
        element: <LandSellerInformationView />,
      },
      {
        path: "dashboard/land-seller-payment-information",
        element: <LandSellerPaymentInformation />,
      },
      {
        path: "dashboard/land-seller-payment-information/add/:id",
        element: <LandSellerPaymentAdd />,
      },
      {
        path: "dashboard/land-seller-payment-information/edit/:id",
        element: <LandSellerPaymentEdit />,
      },
      {
        path: "dashboard/land-seller-payment-information/view/:id",
        element: <LandSellerPaymentView />,
      },
      {
        path: "*",
        element: <h1>not found page under construction </h1>,
      },
    ],
  },
  {
    element: <Login />,
    path: "/login",
  },
  {
    path: "*",
    element: <h1>Page not found</h1>,
  },
]);

export default routesList;
