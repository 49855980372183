import { Link, useParams } from "react-router-dom";
import CommonPrintPdfBtn from "../../../../shared/CommonPrintPdfBtn";
import Pagination from "../../../../shared/pagination/Pagination";
import PrintIcon from "../../../../asset/images/printer.png";
import ActionImg from "../../../../asset/images/clientlist/action.png";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../../../../BaseUrl";
import {
  commonAction,
  commonTableHeadingTwo,
  commonTableRow,
  commonUserInfo,
  commonUserRow,
} from "../../../../asset/commoncssConstant/CommonCssConstant";
import BackButton from "../../../../shared/BackButton";
import useCommonPDF from "../../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../../shared/useCommonPrint/useCommonPrint";

const ViewSellerPaymentList = () => {
  let { Id } = useParams();
  const token = localStorage.getItem("token");
  const [item, setItem] = useState(null);

  const [paginationData, setPaginationData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const { toPDF, targetRef } = useCommonPDF({
    title: "seller-payment-details",
  });

  const handlePrint = useCommonPrint({
    targetRef,
    title: "seller-payment-details",
    message: "thank you",
  });

  // pagination part start here
  const handleNext = () => {
    if (page === pageCount) {
      return page;
    } else {
      return setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page === 1) {
      return page;
    } else {
      return setPage(page - 1);
    }
  };

  const {
    seller_info,
    dolil_variation,
    agreement_date,
    bs_info,
    land_size_katha,
    price_info,
  } = item ?? {};
  //------------get data /landSeller/sellerPayment/singleView/${Id} start------------
  useEffect(() => {
    axios
      .get(baseUrl + `/landSeller/sellerPayment/singleView/${Id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setItem(res.data.data);
      });
  }, [token, Id, page]);
  //------------get data /landSeller/sellerPayment/singleView/${Id} end------------
  return (
    <div>
      <BackButton title={"Seller Land & Price Statement"} />
      <div className="bg-[#FFFFFF] px-[20px] py-[30px] my-[30px]">
        <div className={`text-end`}>
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} />
        </div>
        <div ref={targetRef}>
          <div className="psi_customer_box">
            <div className={commonUserInfo}>
              <div className={commonUserRow}>
                <div>Seller Name</div>
                <div>:</div>
                <div>
                  {seller_info?.map((seller, i) => (
                    <span key={seller.id}>
                      {seller?.name}
                      {i < seller_info?.length - 1 ? ", " : ""}
                    </span>
                  ))}
                </div>
              </div>
              <div className={commonUserRow}>
                <div>Dolil Variation</div>
                <div>:</div>
                <div>{dolil_variation?.name}</div>
              </div>
              <div className={commonUserRow}>
                <div>Agreement Date</div>
                <div>:</div>
                <div>{agreement_date}</div>
              </div>
              <div className={commonUserRow}>
                <div>Seller Land Size</div>
                <div>:</div>
                <div>{land_size_katha}</div>
              </div>
              <div className={commonUserRow}>
                <div>Total Land Price</div>
                <div>:</div>
                <div>{price_info?.total_price}</div>
              </div>
              <div className={commonUserRow}>
                <div>Due Amount</div>
                <div>:</div>
                <div>{price_info?.total_price - price_info?.paid_amount}</div>
              </div>
            </div>
          </div>
          <p className="font-semibold underline text-[##5F5F5F] text-[18px] mt-[40px]">
            Payment Information
          </p>
          <div className="table_responsive mt-[40px] text-center">
            <table>
              <thead>
                <tr className={commonTableRow}>
                  <th>
                    <span className={commonTableHeadingTwo}>Payment Date</span>
                  </th>
                  <th>
                    <span className={commonTableHeadingTwo}>
                      Payment Receiver Name
                    </span>
                  </th>
                  <th>
                    <span className={commonTableHeadingTwo}>Payment By</span>
                  </th>
                  <th>
                    <span className={commonTableHeadingTwo}>Paid Amount</span>
                  </th>
                  <th>
                    <span className={commonTableHeadingTwo}>Due Amount</span>
                  </th>
                  <th>
                    <span className={commonTableHeadingTwo}>Description</span>
                  </th>
                  <th>
                    <span className={commonTableHeadingTwo}>
                      Update Payment
                    </span>
                  </th>
                  <th>
                    <span className={commonTableHeadingTwo}>Print</span>
                  </th>
                </tr>
              </thead>

              <tbody>
                {paginationData?.map((payment) => (
                  <tr className="text-center" key={payment?.id}>
                    <td>
                      <span>
                        {new Date(payment?.created_at).toLocaleDateString()}
                      </span>
                    </td>
                    <td>
                      <span>{payment?.payment_receiver_name}</span>
                    </td>
                    <td>
                      <span>{payment?.pay_by}</span>
                    </td>
                    <td>
                      <span>{payment?.amount}</span>
                    </td>
                    <td>
                      <span>-</span>
                    </td>
                    <td>
                      <span>{payment?.description}</span>
                    </td>
                    <td>
                      <span className="flex justify-center">
                        <Link
                          to={`/dashboard/seller-payment/edit`}
                          state={{
                            item,
                            payment,
                          }}
                        >
                          <img
                            className={commonAction}
                            src={ActionImg}
                            alt=""
                          />
                        </Link>
                      </span>
                    </td>
                    <td>
                      <Link
                        to={`/dashboard/seller-payment/view/pdf`}
                        state={{
                          item,
                          next_payment_date: payment?.next_payment_date,
                        }}
                      >
                        <img
                          className={`${commonAction} mx-auto`}
                          src={PrintIcon}
                          alt=""
                        />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="psi_customer_box">
            <div className={commonUserInfo}>
              <div className={commonUserRow}>
                <div>Seller Name</div>
                <div>:</div>
                <div>
                  {seller_info?.map((seller, i) => (
                    <span key={seller.id}>
                      {seller?.name}
                      {i < seller_info?.length - 1 ? ", " : ""}
                    </span>
                  ))}
                </div>
              </div>
              <div className={commonUserRow}>
                <div>Dolil Variation</div>
                <div>:</div>
                <div>{dolil_variation?.name}</div>
              </div>
              <div className={commonUserRow}>
                <div>Agreement Date</div>
                <div>:</div>
                <div>{agreement_date}</div>
              </div>
              <div className={commonUserRow}>
                <div>Seller Land Size</div>
                <div>:</div>
                <div>{land_size_katha}</div>
              </div>
              <div className={commonUserRow}>
                <div>Total Land Price</div>
                <div>:</div>
                <div>{price_info?.total_price}</div>
              </div>
              <div className={commonUserRow}>
                <div>Due Amount</div>
                <div>:</div>
                <div>{price_info?.total_price - price_info?.paid_amount}</div>
              </div>
            </div>
          </div>
          <p className="font-semibold underline text-[##5F5F5F] text-[18px] mt-[40px]">
            Payment Information
          </p>
          <div className="table_responsive mt-[40px] text-center">
            <table>
              <thead>
                <tr className={commonTableRow}>
                  <th>
                    <span className={commonTableHeadingTwo}>Payment Date</span>
                  </th>
                  <th>
                    <span className={commonTableHeadingTwo}>
                      Payment Receiver Name
                    </span>
                  </th>
                  <th>
                    <span className={commonTableHeadingTwo}>Payment By</span>
                  </th>
                  <th>
                    <span className={commonTableHeadingTwo}>Paid Amount</span>
                  </th>
                  <th>
                    <span className={commonTableHeadingTwo}>Due Amount</span>
                  </th>
                  <th>
                    <span className={commonTableHeadingTwo}>Description</span>
                  </th>
                  <th>
                    <span className={commonTableHeadingTwo}>
                      Update Payment
                    </span>
                  </th>
                  <th>
                    <span className={commonTableHeadingTwo}>Print</span>
                  </th>
                </tr>
              </thead>

              <tbody>
                {paginationData?.map((payment) => (
                  <tr className="text-center" key={payment?.id}>
                    <td>
                      <span>
                        {new Date(payment?.created_at).toLocaleDateString()}
                      </span>
                    </td>
                    <td>
                      <span>{payment?.payment_receiver_name}</span>
                    </td>
                    <td>
                      <span>{payment?.pay_by}</span>
                    </td>
                    <td>
                      <span>{payment?.amount}</span>
                    </td>
                    <td>
                      <span>-</span>
                    </td>
                    <td>
                      <span>{payment?.description}</span>
                    </td>
                    <td>
                      <span className="flex justify-center">
                        <Link
                          to={`/dashboard/seller-payment/edit`}
                          state={{
                            item,
                            payment,
                          }}
                        >
                          <img
                            className={commonAction}
                            src={ActionImg}
                            alt=""
                          />
                        </Link>
                      </span>
                    </td>
                    <td>
                      <Link
                        to={`/dashboard/seller-payment/view/pdf`}
                        state={{
                          item,
                          next_payment_date: payment?.next_payment_date,
                        }}
                      >
                        <img
                          className={`${commonAction} mx-auto`}
                          src={PrintIcon}
                          alt=""
                        />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <Pagination
          data={price_info?.payment_statement || []}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          setPageCount={setPageCount}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      </div>
    </div>
  );
};

export default ViewSellerPaymentList;
