import React from "react";
import LeftArrow from "../../../asset/images/clientlist/left-arrow.png";
import { useLocation, useNavigate } from "react-router-dom";
import "./clientview.css";
import { commonTitle } from "../../../asset/commoncssConstant/CommonCssConstant";
import CommonPrintPdfBtn from "../../../shared/CommonPrintPdfBtn";
import useCommonPDF from "../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../shared/useCommonPrint/useCommonPrint";

const ClientView = () => {
  const navigate = useNavigate();
  const clientData = useLocation().state.clientData;
  const { toPDF, targetRef } = useCommonPDF({
    title: "client-details",
  });

  const handlePrint = useCommonPrint({
    targetRef,
    title: "client details",
    message: "thank you",
  });

  return (
    <>
      <h1 className={`${commonTitle} flex items-center`}>
        <span onClick={() => navigate(-1)} className="mr-3 cursor-pointer">
          <img src={LeftArrow} alt="" />
        </span>{" "}
        View Client Information
      </h1>

      <div className="grid lg:grid-cols-12  mt-3 gap-5">
        <div
          ref={targetRef}
          className="col-span-9 order-2 lg:order-1 client_info_section p-[40px]"
        >
          <h1 className="info_title">Information</h1>
          <div className="grid lg:grid-cols-12 pb-10">
            <div className="client_info_table order-2 lg:order-1 col-span-12 mt-3">
              <table className="overflow-x-scroll">
                <tr>
                  <td>
                    <p>
                      Booking Date <span>:</span>
                      <span className="font-bold text-[#5F5F5F]">
                        {clientData.booking_date}
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      Booking Money <span>:</span>
                      <span className="font-bold text-[#5F5F5F]">
                        {clientData.customer_land_price.total_booking_money} TK
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      File / Plot No <span>:</span>
                      <span className="font-bold text-[#5F5F5F]">
                        {clientData.plot_info.file_no}
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      Total Instalment amount <span>:</span>
                      <span className="font-bold text-[#5F5F5F]">
                        {
                          clientData.customer_land_price
                            .total_installment_amount
                        }{" "}
                        TK
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Land Price Per Decimal <span>:</span>
                      <span className="font-bold text-[#5F5F5F]">
                        {clientData.customer_land_price.land_price_per_decimal}{" "}
                        TK
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      Total Price <span>:</span>
                      <span className="font-bold text-[#5F5F5F]">
                        {clientData.customer_land_price.total_amount} TK
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Project Name <span>:</span>
                      <span className="font-bold text-[#5F5F5F]">
                        {clientData.plot_info.project_name}
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      No of Instalment <span>:</span>
                      <span className="font-bold text-[#5F5F5F]">
                        {clientData.customer_land_price.no_of_installment}
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Per Month Installment Amount<span>:</span>
                      <span className="font-bold text-[#5F5F5F]">
                        {clientData.customer_land_price.per_month_installment}{" "}
                        TK
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      Down Payment <span>:</span>
                      <span className="font-bold text-[#5F5F5F]">
                        {
                          clientData.customer_land_price
                            .total_downpayment_amount
                        }{" "}
                        TK
                      </span>
                    </p>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <h1 className="client_info_title">Client's Plot Information</h1>
          <div className="customer_info_wraper">
            <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-3">
              <div className="client_info_table_without_border">
                <table>
                  <tr>
                    <td>Road No</td>
                    <td>
                      <span className="mx-5">:</span>
                    </td>
                    <td>{clientData.plot_info.road_number_name}</td>
                  </tr>
                  <tr>
                    <td>Plot Facing</td>
                    <td>
                      <span className="mx-5">:</span>
                    </td>
                    <td>{clientData.plot_info.plot_facing}</td>{" "}
                  </tr>
                  <tr>
                    <td>Sector</td>
                    <td>
                      <span className="mx-5">:</span>
                    </td>
                    <td>{clientData.plot_info.sector}</td>{" "}
                  </tr>
                  <tr>
                    <td>Plot Size (katha)</td>
                    <td>
                      <span className="mx-5">:</span>
                    </td>
                    <td>{clientData.plot_info.plot_size_katha}</td>{" "}
                  </tr>
                </table>
              </div>
              <div className="client_info_table_without_border">
                <table>
                  <tr>
                    <td>Plot Dimension</td>
                    <td>
                      <span className="mx-5">:</span>
                    </td>
                    <td>{clientData.plot_info.plot_dimension}</td>{" "}
                  </tr>
                  <tr>
                    <td>Booking Date</td>
                    <td>
                      <span className="mx-5">:</span>
                    </td>
                    <td>{clientData.booking_date}</td>{" "}
                  </tr>
                  <tr>
                    <td>Expected Registration Date</td>
                    <td>
                      <span className="mx-5">:</span>
                    </td>
                    <td>{clientData.expected_reg_date}</td>{" "}
                  </tr>
                  <tr>
                    <td>Plot Size (Ojutangsho)</td>
                    <td>
                      <span className="mx-5">:</span>
                    </td>
                    <td>{clientData.plot_info.plot_size_ojutangsho}</td>{" "}
                  </tr>
                </table>
              </div>
            </div>
          </div>

          <hr className="bg-[#EAEAEA] my-[40px] h-[1px] w-full" />
          {/* <h1 className="client_info_title pb-5">Client’s Information</h1>
          {clientData.customer_info.map((customer, index) => {
            return (
              <div key={customer.id} className="customer_info_wraper">
                <div>
                  <h1 className="pb-2">Customer : {customer.id}</h1>
                  <div className="flex gap-10 border  p-4  mb-4">
                    <div className="border h-[140px] w-[120px] p-2 rounded-md">
                      <img
                        className="w-full h-full"
                        src={
                          customer.image
                            ? baseUrlImg + customer.image
                            : MaskGroup
                        }
                        alt=""
                      />
                    </div>
                    <div className="flex items-center ">
                      <div className="flex flex-col gap-1 text-[#4a4949]">
                        <div className="grid grid-cols-[100px_30px_200px] items-center text-2xl font-[600]">
                          <h4>Name</h4>
                          <h4>:</h4>
                          <small>{customer.name}</small>
                        </div>

                        <div className="grid grid-cols-[100px_30px_200px] items-center text-lg font-[500]">
                          {" "}
                          <h4>Email</h4>
                          <h4>:</h4>
                          <small>{customer.email}</small>
                        </div>
                        <div className="grid grid-cols-[100px_30px_200px] items-center text-lg font-[500]">
                          {" "}
                          <h4>Phone</h4>
                          <h4>:</h4>
                          <small>{customer.mobile_number_1}</small>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="grid lg:grid-cols-2 gap-3">
                    <div className="client_info_table_without_border">
                      <table>
                        <tr>
                          <td>Father’s Name</td>
                          <td>
                            <span className="mx-5">:</span>
                          </td>
                          <td>{customer.father_name}</td>
                        </tr>
                        <tr>
                          <td>Mother’s Name</td>
                          <td>
                            <span className="mx-5">:</span>
                          </td>
                          <td>{customer.mother_name}</td>
                        </tr>
                        <tr>
                          <td>Husband’s/Father’s Name</td>
                          <td>
                            <span className="mx-5">:</span>
                          </td>
                          <td>
                            {customer.spouse_name || customer.father_name} (
                            {customer.spouse_type || "Father"})
                          </td>
                        </tr>

                        <tr>
                          <td>Mailing Address</td>
                          <td>
                            <span className="mx-5">:</span>
                          </td>
                          <td>{customer.mailing_address}</td>
                        </tr>
                        <tr>
                          <td>Date of Birth</td>
                          <td>
                            <span className="mx-5">:</span>
                          </td>
                          <td>{customer.dob}</td>
                        </tr>
                        <tr>
                          <td>National ID Number</td>
                          <td>
                            <span className="mx-5">:</span>
                          </td>
                          <td>{customer.nid}</td>
                        </tr>
                      </table>
                    </div>
                    <div className="client_info_table_without_border">
                      <table>
                        <tr>
                          <td>Booking Date</td>
                          <td>
                            <span className="mx-5">:</span>
                          </td>
                          <td>{clientData.booking_date}</td>
                        </tr>
                        <tr>
                          <td>Office Address (optional)</td>
                          <td>
                            <span className="mx-5">:</span>
                          </td>
                          <td>{customer.office_address} (optional)</td>
                        </tr>
                        <tr>
                          <td>Permanent Address</td>
                          <td>
                            <span className="mx-5">:</span>
                          </td>
                          <td>{customer.permanent_address}</td>
                        </tr>
                        <tr>
                          <td>Other File No</td>
                          <td>
                            <span className="mx-5">:</span>
                          </td>
                          <td>{customer.other_file_no}</td>
                        </tr>
                        <tr>
                          <td>Profession</td>
                          <td>
                            <span className="mx-5">:</span>
                          </td>
                          <td>{customer.profession}</td>
                        </tr>
                        <tr>
                          <td>Designation (optional)</td>
                          <td>
                            <span className="mx-5">:</span>
                          </td>
                          <td>{customer.designation} (optional)</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>

                {(clientData.customer_info.length - 2 === index &&
                  clientData.customer_info.length) > 1 && (
                  <hr className="bg-[#EAEAEA] w-9/12 mx-auto my-[40px] h-[1px] " />
                )}
              </div>
            );
          })}

          <hr className="bg-[#EAEAEA] my-[40px] h-[1px] w-full" /> */}

          {/* <h1 className="client_info_title">Agent Information</h1> */}

          {/*   <form className="grid lg:grid-cols-2 gap-5">
            <div className="common_input_wraper grid grid-cols-2 items-center">
              <span className={`${agent_info_label} lg:text-center`}>
                Agent Name
              </span>
              <input
                name="mouza_name"
                type="text"
                placeholder="Media Name"
                disabled
                value={clientData.agent.name}
              />
            </div>
            <div className="common_input_wraper grid grid-cols-2 items-center">
              <span className={`${agent_info_label} lg:text-center`}>
                Agent Phone Number
              </span>
              <input
                name="mouza_name"
                type="text"
                placeholder="Agent Phone Number"
                disabled
                value={clientData.agent.mobile_number_1}
              />
            </div>

            <div className="common_input_wraper grid grid-cols-2 items-center">
              <span className={`${agent_info_label} lg:text-center`}>
                Agent Money Amount
              </span>
              <input
                name="mouza_name"
                type="text"
                placeholder="Agent Money Amount"
                disabled
                value={clientData?.agent_price?.agent_money}
              />
            </div>
            <div className="common_input_wraper grid grid-cols-2 items-center"></div>
            <div className="mt-16">
              <div className="border-t-[1px] border-[#333547] max-w-[200px]">
                <span className={`${agent_info_label} text-center block`}>
                  Customer’s Signature
                </span>
              </div>
            </div>
            <div className="mt-16">
              <div className="border-t-[1px] border-[#333547] max-w-[200px] lg:ml-auto">
                <span className={`${agent_info_label} text-center block`}>
                  Official Signature
                </span>
              </div>
            </div>
          </form> */}
        </div>
        <div className="col-span-3 order-1 lg:order-2  ">
          <CommonPrintPdfBtn toPDF={toPDF} handlePrint={handlePrint} isBlock={true} />
          {/* <div className="commonprintpdfbtn whitespace-nowrap mt-5">
            <button className="bg-orange-500">Legal Paper Folder</button>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default ClientView;
