import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { dashboardBodyWraper } from "../../../../asset/commoncssConstant/CommonCssConstant";
import BackButton from "../../../../shared/BackButton";

import { Fragment, useState } from "react";
import CustomerLandInfoForm from "../addCustomer/form/CustomerLandInfoForm";
import CustomerLandPriceInfo from "../addCustomer/form/CustomerLandPriceInfo";
import LandBrokerForm from "../addCustomer/form/LandBrokerForm";
import SellerForm from "../addCustomer/form/SellerForm";


const EditLandDetails = () => {
  const [sellers, setSellers] = useState([{ seller: "" }]);
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();

  //--------Add More Customer Form or delete functionality start---------
  const handleAddMoreSeller = () => {
    setSellers([...sellers, { seller: "" }]);
  };
  const handleRemoveSeller = () => {
    setSellers([...sellers].slice(0, -1));
  };
  //--------Add More Customer Form or delete functionality end---------

  const onSubmit = (data) => {
    data.customer = data?.customer?.slice(0, sellers?.length);
    console.log(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BackButton title={"Edit Land Details "} />
      <div className={`${dashboardBodyWraper} py-[4px] `}>
        <CustomerLandInfoForm register={register} />
        <CustomerLandPriceInfo register={register} />
        <p className="text-[#6B2CEA] font-bold my-[40px] text-[16px] bg-[#F0EAFD] py-1  text-center">
          Seller Information
        </p>

        {sellers.map((customer, i) => (
          <Fragment key={i}>
            <SellerForm register={register} customer={customer} i={i} />
            {sellers.length - 1 === i && sellers.length > 1 && (
              <button
                className="delete_customer"
                type="button"
                onClick={handleRemoveSeller}
              >
                Delete{" "}
              </button>
            )}
            {sellers.length > 1 ? <hr className="my-10" /> : null}
          </Fragment>
        ))}
        <div className="my-10">
          <button
            type="button"
            className="add_customer"
            onClick={handleAddMoreSeller}
          >
            Add More Seller <span className="plus">+</span>
          </button>
        </div>

        <LandBrokerForm register={register} />

        <div className="text-end pb-12 mt-5">
          <button type="submit" className="submit_button">
            Submit
          </button>
        </div>
      </div>
    </form>
  );
};

export default EditLandDetails;
