import React, { useState } from "react";
import "./login.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import { baseUrl } from "../BaseUrl";
import { useNavigate } from "react-router";
import { FaPhoneSquare, FaUser } from "react-icons/fa";
import { MdSecurity } from "react-icons/md";

const Login = () => {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const [forgetPassword, setForgetPassword] = useState(false);

  const onSubmit = (data) => {
    axios.post(baseUrl + `/login`, data).then((res) => {
      console.log(res);
      if (res.data.status === "success") {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("user", JSON.stringify(res.data.user_info));
        navigate("/");
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {forgetPassword ? (
        <div className="login_wraper ">
          <div className="form_box_wraper">
            <h1>Forgot Password</h1>

            <div className="input-box">
            <FaPhoneSquare className="mt-[4px] w-3 text-[#ccc] " />
              <input
                {...register("number")}
                name="number"
                type="text"
                placeholder="Enter Your Phone Number"
              />
            </div>

            <button type="submit" className="login-btn">
              send
            </button>
            <div className="flex justify-center">
              <button>
                {" "}
                <small onClick={() => setForgetPassword(!forgetPassword)}>
                  Back To Sign In
                </small>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="login_wraper ">
          <div className="form_box_wraper">
            <h1>Login</h1>

            <div className="input-box">
            <FaUser className="mt-[3px] w-3 text-[#ccc] " />
              <input
                {...register("username")}
                name="username"
                type="text"
                placeholder="User Name"
              />
            </div>

            <div className="input-box">
            <MdSecurity className="mt-[3px] w-[14px] text-[#ffff]" />
              <input
                {...register("password")}
                name="password"
                type="password"
                placeholder="Password"
                // autoComplete="password"
                id="myInput"
              />
            </div>
            <button type="submit" className="login-btn">
              Sign In
            </button>
            <div className="flex justify-center">
              <button>
                <small onClick={() => setForgetPassword(!forgetPassword)}>
                  Forgot Your Password ?
                </small>
              </button>
            </div>
          </div>
        </div>
      )}
    </form>
  );
};

export default Login;
