import React, { useEffect, useState } from "react";
import Pagination from "../../shared/pagination/Pagination";
import CommonPrintPdfBtn from "../../shared/CommonPrintPdfBtn";
import ViewImg from "../../asset/images/clientlist/view.png";
import AddImg from "../../asset/images/clientpaymentinformation/add.png";
import UpdateImg from "../../asset/images/clientlist/action.png";
import { Link } from "react-router-dom";
import {
  commonAction,
  commonCaption,
  commonSearchInput,
  commonTableHeadingTwo,
  commonTableRow,
  commonTitle,
} from "../../asset/commoncssConstant/CommonCssConstant";
import { baseUrl } from "../../BaseUrl";
import axios from "axios";
import { LuArrowDownUp } from "react-icons/lu";
import useCommonPDF from "../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../shared/useCommonPrint/useCommonPrint";

const ClientPriceInformation = () => {
  const [customerPriceInfoList, setCustomerPriceInfoList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const token = localStorage.getItem("token");

  const [paginationData, setPaginationData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const { toPDF, targetRef } = useCommonPDF({
    title: "client-price-info",
  });

  const handlePrint = useCommonPrint({
    targetRef,
    title: "client price info",
    message: "thank you",
  });

  // pagination part start here
  const handleNext = () => {
    if (page === pageCount) {
      return page;
    } else {
      return setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page === 1) {
      return page;
    } else {
      return setPage(page - 1);
    }
  };

  //------get data for customer/priceInformation/view start-----------------
  useEffect(() => {
    axios
      .get(baseUrl + `/customer/priceInformation/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setCustomerPriceInfoList(res.data.data);
      });
  }, [token, page]);
  //------get data for customer/priceInformation/view end-----------------
  return (
    <>
      <h1 className={commonTitle}>Client Price Information </h1>
      <div className="bg-[#FFFFFF]   rounded-[4px] px-[20px] py-[30px] my-[30px]">
        <div className={commonCaption}>
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} />
          <input
            onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
            type="text"
            placeholder="Search"
            className={commonSearchInput}
          />
        </div>

        <div ref={targetRef} className="table_responsive mt-[40px]">
          <table>
            <thead>
              <tr className={commonTableRow}>
                <th>
                  <div className={commonTableHeadingTwo}>
                    <span>Booking Date</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>

                <th>
                  <div className={commonTableHeadingTwo}>
                    <span>Customer’s Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={commonTableHeadingTwo}>
                    <span>Project Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={commonTableHeadingTwo}>
                    <span>File / Plot No</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={commonTableHeadingTwo}>
                    <span>Land Size (Katha)</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={commonTableHeadingTwo}>
                    <span>
                      Land Size <br /> (Ojutangsho)
                    </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={commonTableHeadingTwo}>
                    <span className="whitespace-nowrap">
                      Booking Money (Tk)
                    </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={commonTableHeadingTwo}>
                    <span className="whitespace-nowrap">Down payment (Tk)</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={commonTableHeadingTwo}>
                    <span>Total instalment amount (Tk)</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={commonTableHeadingTwo}>
                    <span>
                      Total Land <br /> Price (Tk)
                    </span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>

                <th>
                  <div className={commonTableHeadingTwo}>
                    <span>No of instalment</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={commonTableHeadingTwo}>
                    <span> Per Instalment Amount (Tk)</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={commonTableHeadingTwo}>
                    <span>Agent Name</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={commonTableHeadingTwo}>
                    <span>Agent Phone</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={commonTableHeadingTwo}>
                    <span>Agent Money (Tk)</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={commonTableHeadingTwo}>
                    <span>Register Date</span>{" "}
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div>
                    <span>
                      {" "}
                      Action <br />
                      Create / Update / View
                    </span>{" "}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {paginationData
                ?.filter((info) => {
                  return searchValue.toLowerCase() !== ""
                    ? info?.booking_date?.toLowerCase().includes(searchValue) ||
                        info?.customer_info
                          ?.map((c) => {
                            return c.name;
                          })
                          .join(" ")
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.plot_info?.project_name
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.plot_info?.file_no
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.plot_info?.plot_size_katha
                          .toString()
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.plot_info?.plot_size_ojutangsho
                          .toString()
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.customer_land_price?.total_booking_money
                          .toString()
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.customer_land_price?.total_downpayment_amount
                          .toString()
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.customer_land_price?.total_installment_amount
                          .toString()
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.customer_land_price?.total_amount
                          .toString()
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.customer_land_price?.no_of_installment
                          .toString()
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.customer_land_price?.per_month_installment
                          .toString()
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.agent?.name
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        (
                          info?.agent?.mobile_number_1 +
                          info?.agent?.mobile_number_2
                        )
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.agent_price?.agent_money
                          .toString()
                          ?.toLowerCase()
                          .includes(searchValue) ||
                        info?.expected_reg_date
                          ?.toLowerCase()
                          .includes(searchValue)
                    : info;
                })
                .map((item) => (
                  <tr className="text-center" key={item?.id}>
                    <td>{item?.booking_date}</td>
                    <td>
                      {item?.customer_info?.map((cus) => (
                        <p key={cus?.id}>{cus?.name}</p>
                      ))}
                    </td>
                    <td>{item?.plot_info?.project_name}</td>
                    <td>{item?.plot_info?.file_no}</td>
                    <td>{item?.plot_info?.plot_size_katha}</td>
                    <td>{item?.plot_info?.plot_size_ojutangsho}</td>
                    <td>{item?.customer_land_price?.total_booking_money}</td>
                    <td>
                      {item?.customer_land_price?.total_downpayment_amount}
                    </td>
                    <td>
                      {item?.customer_land_price?.total_installment_amount}
                    </td>
                    <td>{item?.customer_land_price?.total_amount}</td>
                    <td>{item?.customer_land_price?.no_of_installment}</td>
                    <td>{item?.customer_land_price?.per_month_installment}</td>
                    <td>{item?.agent?.name}</td>
                    <td>
                      {item?.agent?.mobile_number_1} &{" "}
                      {item?.agent?.mobile_number_2}
                    </td>
                    <td>{item?.agent_price?.agent_money}</td>
                    <td>{item?.expected_reg_date}</td>
                    <td>
                      <span className="flex gap-3 justify-center">
                        <Link
                          to={`/dashboard/client-price-information/${
                            item?.customer_land_price?.booking_money_date ===
                            null
                              ? "add"
                              : "update"
                          }`}
                          state={{
                            item: item,
                          }}
                        >
                          <img
                            className={commonAction}
                            src={
                              item?.customer_land_price?.booking_money_date ===
                              null
                                ? AddImg
                                : UpdateImg
                            }
                            alt=""
                          />
                        </Link>
                        <Link
                          to={`/dashboard/client-price-information/view`}
                          state={{
                            item: item,
                          }}
                        >
                          <img className={commonAction} src={ViewImg} alt="" />
                        </Link>
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <Pagination
          data={customerPriceInfoList}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          setPageCount={setPageCount}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      </div>
    </>
  );
};

export default ClientPriceInformation;
