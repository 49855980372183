import React, { useEffect, useState } from "react";
import BackButton from "../../../../shared/BackButton";
import CommonPrintPdfBtn from "../../../../shared/CommonPrintPdfBtn";
import ActionImg from "../../../../asset/images/clientlist/action.png";
import PrinterImg from "../../../../asset/images/printer.png";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../../../BaseUrl";
import Pagination from "../../../../shared/pagination/Pagination";
import {
  commonAction,
  commonTableHeadingTwo,
  commonTableRow,
} from "../../../../asset/commoncssConstant/CommonCssConstant";
import useCommonPDF from "../../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../../shared/useCommonPrint/useCommonPrint";

const ViewLandBrokerPayment = () => {
  const [item, setItem] = useState(null);
  const { Id } = useParams();
  const token = localStorage.getItem("token");
  const [paginationData, setPaginationData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const { toPDF, targetRef } = useCommonPDF({
    title: "broker-payment-details",
  });

  const handlePrint = useCommonPrint({
    targetRef,
    title: "broker-payment-details",
    message: "thank you",
  });

  // pagination part start here
  const handleNext = () => {
    if (page === pageCount) {
      return page;
    } else {
      return setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page === 1) {
      return page;
    } else {
      return setPage(page - 1);
    }
  };

  //-----------get single data from land broker payment list start --------------------
  useEffect(() => {
    axios
      .get(baseUrl + `/landBroker/paymentStatement/${Id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setItem(res?.data?.data);
      });
  }, [token, Id, page]);
  //-----------get single data from land broker payment list end --------------------
  return (
    <div>
      <BackButton title={" Land Broker Payment Statement "} />

      <div className=" client_info_section p-[40px] ">
        <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} />
        <div ref={targetRef}>
          <h1 className="client_info_title mt-5">
            Land Broker Work Information
          </h1>
          <div className="agent_view_table grid grid-cols-1 md:grid-cols-2 gap-5">
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>Agreement Date</td>
                    <td>
                      <span className="mx-4">:</span>
                    </td>
                    <td>{item?.agreement_date}</td>
                  </tr>
                  <tr>
                    <td>Broker Name</td>
                    <td>
                      <span className="mx-4">:</span>
                    </td>
                    <td>{item?.land_broker?.name}</td>
                  </tr>
                  <tr>
                    <td>Present Address</td>
                    <td>
                      <span className="mx-4">:</span>
                    </td>
                    <td>{item?.land_broker?.present_address}</td>
                  </tr>
                  <tr>
                    <td>Mobile no. 1</td>
                    <td>
                      <span className="mx-4">:</span>
                    </td>
                    <td>{item?.land_broker?.mobile_number_1}</td>
                  </tr>
                  <tr>
                    <td>Mobile no. 2</td>
                    <td>
                      <span className="mx-4">:</span>
                    </td>
                    <td>{item?.land_broker?.mobile_number_2}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>Seller Name</td>
                    <td>
                      <span className="mx-4">:</span>
                    </td>
                    <td>
                      {" "}
                      {item?.seller_info?.map((seller) => (
                        <div key={seller.id}>{seller.name}</div>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <td>Project Name</td>
                    <td>
                      <span className="mx-4">:</span>
                    </td>
                    <td>
                      {item?.bs_info?.map((bs) => (
                        <div key={bs.id}>
                          {
                            bs?.rs_info?.sa_info?.cs_info?.mouza_info
                              ?.project_info?.project_name
                          }
                        </div>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <td>Total Price</td>
                    <td>
                      <span className="mx-4">:</span>
                    </td>
                    <td> {item?.price_info?.total_price}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="payment_shedule_customer_info grid grid-cols-1 md:grid-cols-3 my-2">
            <p className="border-1  border p-2 text-start">
              Land Broker Money <span className="mx-3">:</span>{" "}
              <span>{item?.broker_price_info?.broker_money}</span>
            </p>
            <p className="border-1  border p-2 text-start">
              Paid <span className="mx-3">:</span>{" "}
              <span>{item?.broker_price_info?.broker_money_paid}</span>
            </p>
            <p className="border-1  border p-2 text-start">
              Due <span className="mx-3">:</span>{" "}
              <span>
                {item?.broker_price_info?.broker_money -
                  item?.broker_price_info?.broker_money_paid}
              </span>
            </p>
          </div>
          <div className="payment_shedule_customer_info grid grid-cols-1 md:grid-cols-3 my-2">
            <p className="border-1  border p-2 text-start">
              Extra Money <span className="mx-3">:</span>{" "}
              <span>{item?.broker_price_info?.extra_money}</span>
            </p>
            <p className="border-1  border p-2 text-start">
              Paid <span className="mx-3">:</span>{" "}
              <span>{item?.broker_price_info?.extra_money_paid}</span>
            </p>
            <p className="border-1  border p-2 text-start">
              Due <span className="mx-3">:</span>{" "}
              <span>
                {item?.broker_price_info?.extra_money -
                  item?.broker_price_info?.extra_money_paid}
              </span>
            </p>
          </div>
          <div className="payment_shedule_customer_info grid grid-cols-1 md:grid-cols-3 my-2">
            <p className="border-1  border p-2 text-start">
              Conveyance Money <span className="mx-3">:</span>{" "}
              <span>{item?.broker_price_info?.conveyance_money}</span>
            </p>
            <p className="border-1  border p-2 text-start">
              Paid <span className="mx-3">:</span>{" "}
              <span>{item?.broker_price_info?.conveyance_money_paid}</span>
            </p>
            <p className="border-1  border p-2 text-start">
              Due <span className="mx-3">:</span>{" "}
              <span>
                {item?.broker_price_info?.conveyance_money -
                  item?.broker_price_info?.conveyance_money_paid}
              </span>
            </p>
          </div>
          <div className="payment_shedule_customer_info grid grid-cols-1 md:grid-cols-3 my-2">
            <p className="border-1  border p-2 text-start">
              Mobile Bill Money <span className="mx-3">:</span>{" "}
              <span>{item?.broker_price_info?.mobile_bill}</span>
            </p>
            <p className="border-1  border p-2 text-start">
              Paid <span className="mx-3">:</span>{" "}
              <span>{item?.broker_price_info?.mobile_bill_paid}</span>
            </p>
            <p className="border-1  border p-2 text-start">
              Due <span className="mx-3">:</span>{" "}
              <span>
                {item?.broker_price_info?.mobile_bill -
                  item?.broker_price_info?.mobile_bill_paid}
              </span>
            </p>
          </div>
          <div className="payment_shedule_customer_info grid grid-cols-1 md:grid-cols-3 my-2">
            <p className="border-1  border p-2 text-start">
              Entertainment Money <span className="mx-3">:</span>{" "}
              <span>{item?.broker_price_info?.entertainment}</span>
            </p>
            <p className="border-1  border p-2 text-start">
              Paid <span className="mx-3">:</span>{" "}
              <span>{item?.broker_price_info?.entertainment_paid}</span>
            </p>
            <p className="border-1  border p-2 text-start">
              Due <span className="mx-3">:</span>{" "}
              <span>
                {item?.broker_price_info?.entertainment -
                  item?.broker_price_info?.entertainment_paid}
              </span>
            </p>
          </div>
          <div className="table_responsive mt-[40px]">
            <table>
              <thead>
                <tr className={commonTableRow}>
                  <th>
                    <span className={commonTableHeadingTwo}>Date</span>
                  </th>
                  <th>
                    <span className={commonTableHeadingTwo}>
                      {" "}
                      Payment Purpose
                    </span>
                  </th>
                  <th>
                    <span className={commonTableHeadingTwo}> Payment Type</span>
                  </th>
                  <th>
                    <span className={commonTableHeadingTwo}>
                      {" "}
                      Txn ID / Recite No
                    </span>
                  </th>
                  <th>
                    <span className={commonTableHeadingTwo}>
                      {" "}
                      Payment Amount
                    </span>
                  </th>
                  <th>
                    <span className={commonTableHeadingTwo}>
                      {" "}
                      Payment Description
                    </span>
                  </th>
                  <th>
                    <span className={commonTableHeadingTwo}>
                      Update Payment
                    </span>
                  </th>
                  <th>
                    <span className={commonTableHeadingTwo}>Print</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {paginationData?.map((statement) => (
                  <tr key={statement?.id} className="text-center">
                    <td>{statement?.created_at?.slice(0, 10)}</td>
                    <td>{statement?.payment_purpose}</td>
                    <td>
                      <span className="font-bold">{statement?.payment_by}</span>
                    </td>
                    <td>
                      <span className="font-bold">{statement?.invoice_no}</span>
                    </td>
                    <td>
                      <span className="font-bold">{statement?.amount}</span>
                    </td>
                    <td>{statement?.note}</td>
                    <td>
                      <span className="flex  justify-center">
                        <Link
                          to={`/dashboard/land-broker-payment-list/edit`}
                          state={{
                            item,
                            statement,
                          }}
                        >
                          <img
                            className={commonAction}
                            src={ActionImg}
                            alt=""
                          />
                        </Link>
                      </span>
                    </td>
                    <td>
                      <span className="flex justify-center">
                        <Link
                          to={`/dashboard/land-broker-payment-list/payment-info-pdf`}
                          state={{
                            item,
                            statement,
                          }}
                        >
                          <img
                            className={commonAction}
                            src={PrinterImg}
                            alt=""
                          />
                        </Link>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <Pagination
          data={item?.broker_price_info?.broker_payment_statement || []}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          setPageCount={setPageCount}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      </div>
    </div>
  );
};

export default ViewLandBrokerPayment;
