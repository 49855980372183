import React, { useEffect, useState } from "react";
import CommonPrintPdfBtn from "../../../shared/CommonPrintPdfBtn";
import {
  addCustomerCss,
  commonAction,
  commonAddButton,
  commonCaption,
  commonSearchInput,
  commonTableHeadingTwo,
  commonTableRow,
  commonTitle,
  dashboardBodyWraper,
} from "../../../asset/commoncssConstant/CommonCssConstant";
import AddUserImg from "../../../asset/images/clientlist/add-user.png";
import ViewBtn from "../../../asset/images/clientlist/view.png";
import Pagination from "../../../shared/pagination/Pagination";
import FolderImg from "../../../asset/images/clientlandinformation/back-folder.png";
import ActionImg from "../../../asset/images/clientlist/action.png";
import axios from "axios";
import { baseUrl } from "../../../BaseUrl";
import { Link } from "react-router-dom";
import { LuArrowDownUp } from "react-icons/lu";
import useCommonPDF from "../../../shared/useCommonPDF/useCommonPDF";
import useCommonPrint from "../../../shared/useCommonPrint/useCommonPrint";

const SaMouzaMapList = () => {
  const [saMouzaMapList, setSaMouzaMapList] = useState([]);
  const [dagKhaytanForSelect, setDagKhaytanForSelect] = useState([]);
  const token = localStorage.getItem("token");
  const [searchValue, setSearchValue] = useState("");
  const [paginationData, setPaginationData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const { toPDF, targetRef } = useCommonPDF({ title: "sa mouza map list" });

  const handlePrint = useCommonPrint({
    targetRef,
    title: "sa mouza map list",
    message: "thank you",
  });

  // pagination part start here
  const handleNext = () => {
    if (page === pageCount) {
      return page;
    } else {
      return setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page === 1) {
      return page;
    } else {
      return setPage(page - 1);
    }
  };

  //------get data for saMouzaMapList and saDagKhatiyanList start-----------------
  useEffect(() => {
    axios
      .get(baseUrl + `/landMouzaMapBank/saMouzaMapList/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setSaMouzaMapList(res.data.data);
      });
    axios
      .get(baseUrl + `/landInformationBank/saDagKhatiyan/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setDagKhaytanForSelect(res.data.data);
      });
  }, [token, page]);
  //------get data for saMouzaMapList and saDagKhatiyanList end-----------------
  return (
    <div>
      <h1 className={`${commonTitle}`}>SA Mouza Map</h1>
      <div className={dashboardBodyWraper}>
        <div className={commonAddButton}>
          <Link
            to={`/dashbooard/sa-mouza-map-list/add`}
            state={{
              dagKhaytanForSelect: dagKhaytanForSelect,
            }}
          >
            <button className={addCustomerCss}>
              <span>Add SA Mouza Map</span>
              <img className="ml-[30px]" src={AddUserImg} alt="" />
            </button>
          </Link>
        </div>
        <div className={commonCaption}>
          <CommonPrintPdfBtn handlePrint={handlePrint} toPDF={toPDF} />
          <input
            onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
            type="text"
            placeholder="Search"
            className={commonSearchInput}
          />
        </div>

        <div ref={targetRef} className="table_responsive mt-[40px]">
          <table>
            <thead>
              <tr className={`${commonTableRow}`}>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>Create Date</span>
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>SA Dag</span>
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>SA Khatiyan</span>
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>SA Land Size (Sotangso)</span>
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>SA Land Size (Ohudangso)</span>
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>SA Land Size (Sq.fit)</span>
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>SA Land East to west (Sq.fit)</span>
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>SA Land North to South (Sq.fit)</span>
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>SA Land East & South (Angle)</span>
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>SA Land East & North (Angle)</span>
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>SA Land West & South (Angle)</span>
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>SA Land West & North (Angle)</span>
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>
                      East , South to <br /> West , North Corner (Length)
                    </span>
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>South , West to North , East Corner (Length)</span>
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>SA Dag Map Image</span>
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
                <th>
                  <div className={`${commonTableHeadingTwo}`}>
                    <span>
                      Action <br />
                      Update / View
                    </span>
                    <span>
                      <LuArrowDownUp />
                    </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {paginationData
                ?.filter((info) => {
                  return searchValue.toLowerCase() !== ""
                    ? info?.created_at
                        ?.slice(0, 10)
                        .toLowerCase()
                        .includes(searchValue) ||
                        info?.sa_info?.sa_dag_no
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.sa_info?.sa_khatiyan_no
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.land_size_sotangsho
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.land_size_ojutangsho
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.land_size_sq_feet
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.land_eastTowest_sq_feet
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.land_northToSouth_sq_feet
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.land_eastAndSouth_angle
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.land_eastAndNorth_angle
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.land_westAndSouth_angle
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.land_westAndNorth_angle
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.eastSouth_to_westNorth_length
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue) ||
                        info?.southWest_to_northEast_length
                          ?.toString()
                          .toLowerCase()
                          .includes(searchValue)
                    : info;
                })
                ?.map((item, i) => (
                  <tr className="text-center" key={i}>
                    <td>{item?.created_at?.slice(0, 10)}</td>
                    <td>{item?.sa_info?.sa_dag_no}</td>
                    <td>{item?.sa_info?.sa_khatiyan_no}</td>
                    <td>{item?.land_size_sotangsho}</td>
                    <td>{item?.land_size_ojutangsho}</td>
                    <td>{item?.land_size_sq_feet}</td>
                    <td>{item?.land_eastTowest_sq_feet}</td>
                    <td>{item?.land_northToSouth_sq_feet}</td>
                    <td>{item?.land_eastAndSouth_angle}</td>
                    <td>{item?.land_eastAndNorth_angle}</td>
                    <td>{item?.land_westAndSouth_angle}</td>
                    <td>{item?.land_westAndNorth_angle}</td>
                    <td>{item?.eastSouth_to_westNorth_length}</td>
                    <td>{item?.southWest_to_northEast_length}</td>
                    <td>
                      <Link
                        to={`/dashbooard/sa-mouza-map-list/sa-dag-mouza-map-image`}
                        state={{
                          item: item,
                        }}
                      >
                        <img className="mx-auto" src={FolderImg} alt="" />
                      </Link>
                    </td>
                    <td>
                      <span className="flex gap-2 justify-center">
                        <Link
                          to={`/dashbooard/sa-mouza-map-list/edit`}
                          state={{
                            item: item,
                            dagKhaytanForSelect: dagKhaytanForSelect,
                          }}
                        >
                          <img
                            className={commonAction}
                            src={ActionImg}
                            alt=""
                          />
                        </Link>
                        <Link
                          to={`/dashbooard/sa-mouza-map-list/description`}
                          state={{
                            item: item,
                          }}
                        >
                          <img className={commonAction} src={ViewBtn} alt="" />
                        </Link>
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <Pagination
          data={saMouzaMapList}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          setPageCount={setPageCount}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      </div>
    </div>
  );
};

export default SaMouzaMapList;
